import { i18n } from "services/i18nService";

import { SUB_COMPONENT } from "views/Reports/reportConstants";

export const ExportTransfers = {
  userHasSufficientPrivilege: () => true,
  planIncludesReport: (plus) => plus,
  customReportNameFetchList: [],
  customFieldObjectTypes: ["transfer"],
  columns: [
    { apiName: "TransferNumber", label: "Transfer #", default: true },
    { apiName: "FromLocation", label: "From Location", default: true },
    { apiName: "ToLocation", label: "To Location", default: true },
    { apiName: "Date", default: true },
    { apiName: "Item", default: true },
    { apiName: "SKU" },
    { apiName: "Quantity", default: true, number: true },
    { apiName: "UOM" },
    { apiName: "AccountingCost", label: "Accounting Cost", number: true },
    { apiName: "ShippingCost", label: "Shipping Cost", number: true },
    { apiName: "Description", default: true },
    { apiName: "Memo", label: "Comment" },
    { apiName: "LineNumber", label: "Line Number", number: true },
    { apiName: "Lot" },
    { apiName: "FromBin", label: "From Bin" },
    { apiName: "ToBin", label: "To Bin" },
    { apiName: "Job" },
    { apiName: "Class" },
    { apiName: "TrackingNumber", label: "Tracking Number" },
    { apiName: "ShipMethod", label: "Ship Method" },
    { apiName: "Department" },
    { apiName: "Starred", number: true },
    { apiName: "Archived" },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date1Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("filter.FromLocation"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.ToLocation"),
      component: "LocationFilter",
      apiName: "users",
      defaultValue: null,
      required: true,
      type: "filter",
    },
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Class"),
      component: "ClassFilter",
      apiName: "class",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Department"),
      component: "DepartmentFilter",
      apiName: "department",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.KeywordSearch"),
      component: "FrmTextFieldWithHoverHelp",
      apiName: "nameContains",
      defaultValue: null,
      helpText: i18n("reports.hoverHelp.PurchaseKeywordSearch"),
      type: "filter",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedTransactions"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
