import { Money } from "classes/DecimalClasses";

import { isoToLocalDateTime } from "services/utility/dates";

export function afterGet(record) {
  const newRecord = {
    ...record,
    date: isoToLocalDateTime(record.date),
    amount: new Money(record.amount),
  };

  return newRecord;
}
