import { FormControlLabel, Radio, RadioGroup } from "@mui/material";

import { Tooltip } from "components/Tooltip";

function getTooltip(option) {
  const { tooltip, tooltipFunc } = option;
  if (tooltipFunc) {
    return tooltipFunc();
  }
  return tooltip ? tooltip : "";
}

export function FrmRadioGroup(props) {
  const { value, onValueChange, name, options, disabled } = props;

  return (
    <RadioGroup
      onChange={(e) => onValueChange(name, e.target.value)}
      value={value}
    >
      {options.map((option, i) => (
        <Tooltip key={i} title={getTooltip(option)} placement="bottom">
          <FormControlLabel
            value={option.value}
            control={<Radio />}
            label={option.label}
            sx={{ position: "relative", top: "-0.5rem" }}
            // you can pass a disabled property on
            // an option to disable just that option
            disabled={disabled || option.disabled}
          />
        </Tooltip>
      ))}
    </RadioGroup>
  );
}
