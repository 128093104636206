import { Box } from "@mui/material";

import { Layout } from "Layout";

import { ListPage } from "components/ListPage/ListPage";

import { i18n } from "services/i18nService";

export function ConfigurationList(props) {
  const { objectType } = props;

  return (
    <Layout pageTitle={i18n(`objectType.${objectType}.SentencePlural`)}>
      <Box sx={{ display: "flex", position: "relative" }}>
        <ListPage objectType={objectType} isConfigurationList />
      </Box>
    </Layout>
  );
}
