import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
} from "@mui/material";
import { Button } from "@mui/material";

import { ButtonProgress } from "components/utility/ButtonProgress";
import { FormErrors } from "components/utility/FormErrors";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import {
  updatePaymentMethod,
  getSingleton,
} from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

import { useErrors } from "hooks/useErrors";

import { openAlert } from "globalState/alertSlice";

export function PaymentMethod(props) {
  const { close, setAccountInformation } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [isComponentLoaded, setIsComponentLoaded] = useState(false);
  const { errors, setErrors } = useErrors();

  const stripe = useRef(null);
  const cardElement = useRef(null);

  const dispatch = useDispatch();

  useEffect(() => setIsComponentLoaded(true), []);

  useEffect(() => {
    stripe.current = window.Stripe(process.env.REACT_APP_STRIPE_TOKEN);
    const elements = stripe.current.elements();
    cardElement.current = elements.create("card", {
      hidePostalCode: true,
    });

    // check if the component is mounted and then mount the card element
    if (isComponentLoaded) {
      // card.mount("#card-element");
      cardElement.current.mount("#card-element");
    }
  }, [isComponentLoaded]);

  async function savePaymentMethod(e) {
    e.preventDefault();
    setIsLoading(true);
    const { token, error } = await stripe.current.createToken(
      cardElement.current
    );
    if (error) {
      handleProgramError(i18n("alert.ErrorSavingPaymentMethod"));
    } else {
      const { id, card } = token;
      const { success } = await updatePaymentMethod({
        token: id,
        cardType: card.brand,
        lastFour: card.last4,
      });
      if (success) {
        const message = i18n("alert.SavePaymentMethodSuccess");
        setAccountInformation(await getSingleton("company/accountInfo"));
        dispatch(openAlert({ type: "success", message }));
        close();
      } else {
        handleProgramError(i18n("alert.ErrorSavingPaymentMethod"));
      }
    }
    setIsLoading(false);
  }

  return (
    <>
      <Dialog open={true} onClose={close}>
        <form id="payment-form" onSubmit={savePaymentMethod}>
          <DialogTitle>
            {i18n("payment.EnterYourPaymentInformation")}
          </DialogTitle>
          <DialogContent>
            <FormErrors errors={errors} setErrors={setErrors} />
            <VSpace space={1} />
            <div id="card-element" />
          </DialogContent>
          <DialogActions>
            <Button color="secondary" onClick={close}>
              {i18n("global.Cancel")}
            </Button>
            <div style={{ position: "relative" }}>
              <Button color="secondary" disabled={isLoading} type="submit">
                {i18n("global.Save")}
              </Button>
              {isLoading && <ButtonProgress color="secondary" />}
            </div>
          </DialogActions>
        </form>
      </Dialog>
    </>
  );
}
