import { FilterSelectField } from "components/formFields/FilterSelectField";

import { i18n } from "services/i18nService";

import { OBJECT_TYPES } from "appConstants";

const OPTIONS = {
  estimate: [
    { id: "Pending", name: i18n("filter.status.Pending") },
    { id: "Accepted", name: i18n("filter.status.Accepted") },
    { id: "Rejected", name: i18n("filter.status.Rejected") },
    { id: "Closed", name: i18n("filter.status.Closed") },
    { id: "Converted", name: i18n("filter.status.Converted") },
    { id: "All", name: i18n("filter.All") },
  ],
  serial: [
    { id: "InStock", name: i18n("filter.status.InStock") },
    { id: "Used", name: i18n("filter.status.Used") },
    { id: "Shipped", name: i18n("filter.status.Shipped") },
    { id: "AdjustedOut", name: i18n("filter.status.AdjustedOut") },
    { id: "All", name: i18n("filter.All") },
  ],
  lot: [
    { id: "All", name: i18n("filter.All") },
    {
      id: "NotExpiredNotRecalled",
      name: i18n("filter.expiredRecalled.notExpiredNotRecalled"),
    },
    { id: "Expired", name: i18n("filter.expiredRecalled.expired") },
    { id: "Recalled", name: i18n("filter.expiredRecalled.recalled") },
  ],
  invoice: [
    { id: "notvoided", name: i18n("filter.status.NotVoided") },
    { id: "voided", name: i18n("filter.status.Voided") },
    { id: "All", name: i18n("filter.All") },
  ],
  task: [
    { id: "active", name: i18n("filter.status.Active") },
    { id: "completed", name: i18n("filter.status.Completed") },
    { id: "All", name: i18n("filter.All") },
  ],
  user: [
    { id: "Active", name: i18n("filter.status.Active") },
    { id: "Inactive", name: i18n("filter.status.Inactive") },
    { id: "All", name: i18n("filter.All") },
  ],
};

export function EditStatusFilter(props) {
  const { filters, setFilters, objectType } = props;

  const showStatusDefault =
    objectType === OBJECT_TYPES.TASK.fullString ? "active" : "All";

  const { filterSettings } = filters;
  const { ShowStatus = showStatusDefault } = filterSettings;

  function handleChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ...prevParams.filterSettings,
        ShowStatus: value?.id || "",
      },
      startingRecord: 1,
    }));
  }

  return (
    <FilterSelectField
      options={OPTIONS[objectType]}
      onValueChange={handleChange}
      value={{ id: ShowStatus }}
      label={i18n("filter.Status")}
      dataTesting="statusFilter"
      disableClearable
    />
  );
}
