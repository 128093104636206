import { i18n } from "services/i18nService";

import {
  OPEN_CLOSED_OPTIONS,
  ALL_OPTION_WITH_ALL_ID,
  SUB_COMPONENT,
} from "views/Reports/reportConstants";

export const ExportWorkOrders = {
  userHasSufficientPrivilege: () => true,
  planIncludesReport: (_, pro) => pro,
  customReportNameFetchList: [],
  customFieldObjectTypes: ["workorder"],
  columns: [
    { apiName: "WorkOrderNumber", label: "Work Order #", default: true },
    { apiName: "Customer" },
    { apiName: "Date", default: true },
    { apiName: "Item", default: true },
    { apiName: "SKU" },
    { apiName: "Instructions", default: true },
    { apiName: "Quantity", default: true },
    { apiName: "UOM" },
    { apiName: "Memo", label: "Comment" },
    { apiName: "Closed" },
    { apiName: "Location" },
    { apiName: "Class" },
    { apiName: "AssignedTo", label: "Assigned To" },
    { apiName: "Job" },
    { apiName: "Produced", number: true },
    { apiName: "DueDate", label: "Due Date" },
    { apiName: "LineNumber", label: "Line Number", number: true },
    { apiName: "Archived" },
    { apiName: "Starred", number: true },
    { apiName: "Channel" },
    { apiName: "Department" },
    { apiName: "Priority" },
    { apiName: "Category" },
    { apiName: "Order", label: "For Order" },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date1Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("filter.Locations"),
      component: "LocationsFilter",
      apiName: "locations",
      defaultValue: null,
      placeholder: i18n("reports.placeholder.DefaultIsAll", {
        type: i18n("reports.locations"),
      }),
      type: "filter",
    },
    {
      label: i18n("filter.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Channel"),
      component: "ChannelFilter",
      apiName: "channel",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Class"),
      component: "ClassFilter",
      apiName: "class",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.CustomerType"),
      component: "CustomerTypeFilter",
      apiName: "customerType",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Department"),
      component: "DepartmentFilter",
      apiName: "department",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.KeywordSearch"),
      component: "FrmTextFieldWithHoverHelp",
      apiName: "nameContains",
      defaultValue: null,
      helpText: i18n("reports.hoverHelp.PurchaseKeywordSearch"),
      type: "filter",
    },
    {
      apiName: "orderType",
      label: i18n("reports.OptionLabel.OpenClosed"),
      component: "FrmSelectFromObjects",
      options: OPEN_CLOSED_OPTIONS,
      defaultValue: ALL_OPTION_WITH_ALL_ID,
      type: "option",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedTransactions"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
