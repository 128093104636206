// TODO: This doesn't handle currency values with adornment(s) (like "$" prefix);
// it may need to at some point.
import { Typography } from "@mui/material";

const LINE_ERROR_STYLE = {
  paddingLeft: "0.7rem",
  paddingTop: "0.44px",
  whiteSpace: "nowrap",
  fontSize: "0.625rem",
  fontStyle: "italic",
  color: "error",
};

export function LineItemErrorText(props) {
  const { value } = props;
  return <Typography sx={LINE_ERROR_STYLE}>{value}</Typography>;
}
