import { i18n } from "services/i18nService";
import {
  FLAT_DISCOUNT_OPTION,
  PERCENT_DISCOUNT_OPTION,
  ITEM_DISCOUNT_OPTION,
} from "services/sosInventoryService/priceTier/schema";

import { initialErrorState } from "views/common";

export function isValid(record) {
  const errors = initialErrorState();
  errors.entity = [];

  if (!record.name) {
    errors.messages.push(i18n("isValid.Name"));
    errors.entity.push("name");
  }

  if (
    record.type.id === ITEM_DISCOUNT_OPTION.id &&
    !record.items?.filter(({ item }) => item).length
  ) {
    errors.messages.push(i18n("isValid.LineItemRequired"));
  }

  if (record.type.id === FLAT_DISCOUNT_OPTION.id && !record.amountDiscount) {
    errors.messages.push(i18n("isValid.amountDiscount"));
    errors.entity.push("amountDiscount");
  }

  if (
    record.type.id === PERCENT_DISCOUNT_OPTION.id &&
    !record.percentDiscount
  ) {
    errors.messages.push(i18n("isValid.percentDiscount"));
    errors.entity.push("percentDiscount");
  }

  return errors;
}
