import { Box, Card, CardContent, Typography } from "@mui/material";

import { i18n } from "services/i18nService";

const sx = {
  display: "grid",
  minWidth: "1070px",
  "& a": { color: "primaryLink" },
};

const divStyles = { marginBottom: "1.5rem" };

export function WhatsNew() {
  return (
    <>
      <Box sx={sx} m={2} mb={0}>
        <Box mb={1}>
          <Typography variant="h2">{i18n("whatsNew.WhatsNew")}</Typography>
        </Box>
        <Card variant="outlined" sx={{ maxWidth: 750, mb: 2 }}>
          <CardContent>
            <div style={divStyles}>
              <Typography variant="h4">{i18n("whatsNew.8.heading")}</Typography>
              <ul>
                <li>{i18n("whatsNew.8.1")}</li>
                <li>{i18n("whatsNew.8.2")}</li>
                <li>{i18n("whatsNew.8.3")}</li>
                <li>{i18n("whatsNew.8.4")}</li>
                <li>{i18n("whatsNew.8.5")}</li>
              </ul>
            </div>
            <div style={divStyles}>
              <Typography variant="h4">{i18n("whatsNew.7.heading")}</Typography>
              <ul>
                <li>{i18n("whatsNew.7.1")}</li>
                <li>{i18n("whatsNew.7.2")}</li>
                <li>{i18n("whatsNew.7.3")}</li>
                <li>{i18n("whatsNew.7.4")}</li>
                <li>{i18n("whatsNew.7.5")}</li>
              </ul>
            </div>

            <div style={divStyles}>
              <Typography variant="h4">{i18n("whatsNew.6.heading")}</Typography>
              <ul>
                <li>{i18n("whatsNew.6.1")}</li>
                <li>{i18n("whatsNew.6.2")}</li>
                <li>{i18n("whatsNew.6.3")}</li>
                <li>{i18n("whatsNew.6.4")}</li>
                <li>{i18n("whatsNew.6.5")}</li>
              </ul>
            </div>

            <div style={divStyles}>
              <Typography variant="h4">{i18n("whatsNew.5.heading")}</Typography>
              <ul>
                <li>{i18n("whatsNew.5.1")}</li>
                <li>{i18n("whatsNew.5.2")}</li>
                <li>{i18n("whatsNew.5.3")}</li>
                <li>{i18n("whatsNew.5.4")}</li>
              </ul>
            </div>

            <div style={divStyles}>
              <Typography variant="h4">{i18n("whatsNew.4.heading")}</Typography>
              <ul>
                <li>{i18n("whatsNew.4.1")}</li>
                <li>{i18n("whatsNew.4.2")}</li>
              </ul>
            </div>

            <div style={divStyles}>
              <Typography variant="h4">{i18n("whatsNew.3.heading")}</Typography>
              <ul>
                <li>{i18n("whatsNew.3.1")}</li>
                <li>{i18n("whatsNew.3.2")}</li>
              </ul>
            </div>

            <div style={divStyles}>
              <Typography variant="h4">{i18n("whatsNew.2.heading")}</Typography>
              <ul>
                <li>{i18n("whatsNew.2.1")}</li>
              </ul>
            </div>

            <div style={divStyles}>
              <Typography variant="h4">{i18n("whatsNew.1.heading")}</Typography>
              <ul>
                <li>{i18n("whatsNew.1.1")}</li>
                <li>{i18n("whatsNew.1.2")}</li>
                <li>{i18n("whatsNew.1.3")}</li>
              </ul>
            </div>
          </CardContent>
        </Card>
      </Box>
    </>
  );
}
