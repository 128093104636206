import { i18n } from "services/i18nService";

import {
  SUB_COMPONENT,
  ALL_OPTION_WITH_ALL_ID,
  OPEN_CLOSED_OPTIONS,
} from "views/Reports/reportConstants";

import { ALL_FILTER_OPTION } from "appConstants";

export const ExportSalesOrders = {
  showAddImportColumnsButton: true,
  userHasSufficientPrivilege: () => true,
  planIncludesReport: () => true,
  customReportNameFetchList: [],
  customFieldObjectTypes: ["salesorder"],
  columns: [
    { apiName: "SalesOrderNumber", label: "Sales Order #", default: true },
    { apiName: "Customer", default: true },
    { apiName: "OrderDate", label: "Date", default: true },
    { apiName: "BillAddressLine1", label: "Billing Addr Line 1" },
    { apiName: "BillAddressLine2", label: "Billing Addr Line 2" },
    { apiName: "BillAddressLine3", label: "Billing Addr Line 3" },
    { apiName: "BillAddressLine4", label: "Billing Addr Line 4" },
    { apiName: "BillAddressLine5", label: "Billing Addr Line 5" },
    { apiName: "BillCity", label: "Billing Addr City" },
    { apiName: "BillState", label: "Billing Addr State" },
    { apiName: "BillZip", label: "Billing Addr Postal Code" },
    { apiName: "BillCountry", label: "Billing Addr Country" },
    { apiName: "ShipAddressLine1", label: "Shipping Addr Line 1" },
    { apiName: "ShipAddressLine2", label: "Shipping Addr Line 2" },
    { apiName: "ShipAddressLine3", label: "Shipping Addr Line 3" },
    { apiName: "ShipAddressLine4", label: "Shipping Addr Line 4" },
    { apiName: "ShipAddressLine5", label: "Shipping Addr Line 5" },
    { apiName: "ShipCity", label: "Shipping Addr City" },
    { apiName: "ShipState", label: "Shipping Addr State" },
    { apiName: "ShipZip", label: "Shipping Addr Postal Code" },
    { apiName: "ShipCountry", label: "Shipping Addr Country" },
    { apiName: "Category" },
    { apiName: "Item", default: true },
    { apiName: "SKU" },
    { apiName: "Description", default: true },
    { apiName: "Quantity", default: true, number: true },
    { apiName: "UOM" },
    { apiName: "Rate", label: "Unit Price", number: true },
    { apiName: "Amount", number: true },
    { apiName: "BillEmail", label: "Billing Email" },
    { apiName: "BillPhone", label: "Billing Phone" },
    { apiName: "ShipEmail", label: "Shipping Email" },
    { apiName: "ShipPhone", label: "Shipping Phone" },
    { apiName: "BillCompany", label: "Billing Company" },
    { apiName: "BillContact", label: "Billing Contact" },
    { apiName: "ShipCompany", label: "Shipping Company" },
    { apiName: "ShipContact", label: "Shipping Contact" },
    { apiName: "CustomerMessage", label: "Customer Message" },
    { apiName: "Memo", label: "Comment" },
    { apiName: "IsClosed", label: "Closed" },
    { apiName: "Shipping", label: "Shipping Amount", number: true },
    { apiName: "CustomerPO", label: "Customer PO" },
    { apiName: "Terms" },
    { apiName: "SalesRep", label: "Sales Rep" },
    { apiName: "TaxCode", label: "Tax Code" },
    { apiName: "Currency", label: "Currency" },
    { apiName: "ExchangeRate", label: "Exchange Rate" },
    { apiName: "Location" },
    { apiName: "Department" },
    { apiName: "Channel" },
    { apiName: "Class" },
    { apiName: "AssignedTo", label: "Assigned To" },
    { apiName: "Priority" },
    { apiName: "OrderStage", label: "Stage" },
    { apiName: "Job" },
    { apiName: "Tax", label: "Tax", number: true },
    { apiName: "Picked", label: "Picked", number: true },
    { apiName: "Shipped", label: "Shipped", number: true },
    { apiName: "Invoiced", label: "Invoiced", number: true },
    { apiName: "DueDate", label: "Due Date" },
    { apiName: "Taxable" },
    { apiName: "LineNumber", label: "Line Number", number: true },
    { apiName: "Deposit", label: "Deposit Amount", number: true },
    { apiName: "DepositPercent", label: "Deposit Percent", number: true },
    { apiName: "Discount", label: "Discount Amount", number: true },
    {
      apiName: "DiscountPercent",
      label: "Discount Percent",
      number: true,
      nonImport: true,
    },
    { apiName: "DiscountTaxable", label: "Discount Taxable", number: true },
    { apiName: "Archived" },
    { apiName: "DropShip", label: "Drop Ship" },
    { apiName: "Starred", number: true },
    {
      apiName: "CustomerPartNumber",
      label: "Customer Part Number",
      nonImport: true,
    },
    { apiName: "Cost", label: "Cost", number: true, nonImport: true },
    { apiName: "Margin", label: "Margin", number: true, nonImport: true },
    { apiName: "LinePercentDiscount", label: "Line Discount %", number: true },
    {
      apiName: "ListPrice",
      label: "List Price",
      number: true,
      nonImport: true,
    },
    { apiName: "BigCommerceOrder", label: "BigCommerce Order" },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date1Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("filter.Locations"),
      component: "LocationsFilter",
      apiName: "locations",
      defaultValue: null,
      placeholder: i18n("reports.placeholder.DefaultIsAll", {
        type: i18n("reports.locations"),
      }),
      type: "filter",
    },
    {
      label: i18n("filter.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Channel"),
      component: "ChannelFilter",
      apiName: "channel",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Class"),
      component: "ClassFilter",
      apiName: "class",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.CustomerType"),
      component: "CustomerTypeFilter",
      apiName: "customerType",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Department"),
      component: "DepartmentFilter",
      apiName: "department",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.SalesRep"),
      component: "SalesRepFilter",
      apiName: "salesReps",
      defaultValue: [ALL_FILTER_OPTION],
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.KeywordSearch"),
      component: "FrmTextFieldWithHoverHelp",
      apiName: "nameContains",
      defaultValue: null,
      helpText: i18n("reports.hoverHelp.PurchaseKeywordSearch"),
      type: "filter",
    },
    {
      apiName: "orderType",
      label: i18n("reports.OptionLabel.OpenClosed"),
      component: "FrmSelectFromObjects",
      options: OPEN_CLOSED_OPTIONS,
      defaultValue: ALL_OPTION_WITH_ALL_ID,
      type: "option",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedTransactions"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
