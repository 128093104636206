import { FilterSelectField } from "components/formFields/FilterSelectField";

import { i18n } from "services/i18nService";
import { FORM_TYPES } from "services/sosInventoryService/formTemplate/schema";

import { ALL_FILTER_OPTION } from "appConstants";

export function EditTypeFilter(props) {
  const { filters, setFilters } = props;
  const { filterSettings } = filters;
  const { Type } = filterSettings;

  function handleChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: { ...prevParams.filterSettings, Type: value?.id || "" },
      startingRecord: 1,
    }));
  }

  return (
    <FilterSelectField
      options={[ALL_FILTER_OPTION, ...FORM_TYPES]}
      onValueChange={handleChange}
      value={Type ? { id: Type } : ALL_FILTER_OPTION}
      label={i18n("filter.Type")}
      disableClearable
    />
  );
}
