// See services/sosInventoryService/README.md for information about the structure
// of schema.js files.
import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import {
  extractName,
  formatDocumentInfo,
  formatDateTimeToDate,
  formatNumberLinkField,
  truncate,
  extractLinkedTransactions,
  formatStatus,
  formatBooleanToYesOrBlank,
  formatDecimal,
  formatQuantityWithUom,
  formatLinkableItem,
  extractDescription,
} from "services/utility/formatting";
import {
  inventoryItemTypeEditStrFunction,
  textTypeEditStrFunction,
  decimalTypeEditStrFunction,
  numberTypeEditStrFunction,
  linkedTransactionTypeEditStrFunction,
  referenceTypeEditStrFunction,
  arrayOfReferencesTypeEditStrFunction,
  dateTypeEditStrFunction,
} from "services/utility/list";
import { parseHTML } from "services/utility/parsing";
import { starColumn, TYPES } from "services/utility/schema";

import {
  DEFAULT_DECIMALS_UNROUNDED,
  DEFAULT_DECIMALS_ROUNDED,
  NO_REF_NUMBER_STRING,
} from "appConstants";
import { NO_COLUMNS } from "appConstants";

export const columnInfo = [
  starColumn,
  {
    name: "number",
    fixed: true,
    heading: i18n("columns.ReferenceNumber"),
    formatFunc: formatNumberLinkField,
    minWidth: "7em",
    linkField: true,
    sortable: true,
  },
  {
    name: "date",
    heading: i18n("columns.TxnDate"),
    formatFunc: formatDateTimeToDate,
    defaultSort: true,
    sortDirection: "asc",
    minWidth: "8em",
    sortable: true,
    default: true,
  },
  {
    name: "comment",
    heading: i18n("columns.Comment"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
    sortable: true,
    default: true,
  },
  {
    name: "documents",
    heading: i18n("columns.Documents"),
    formatFunc: formatDocumentInfo,
    minWidth: "15em",
    default: true,
  },
  {
    name: "priority",
    heading: i18n("columns.Priority"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "customer",
    heading: i18n("columns.Customer"),
    formatFunc: extractName,
    minWidth: "10em",
    maxWidth: "25em",
    sortable: true,
  },
  {
    name: "linkedTransaction",
    fieldName: "linkedOrders",
    heading: i18n("columns.ForOrder"),
    formatFunc: extractLinkedTransactions,
  },
  {
    name: "assignedToUser",
    heading: i18n("columns.AssignedTo"),
    formatFunc: extractName,
    minWidth: "10em",
    sortable: true,
  },
  {
    name: "department",
    heading: i18n("columns.Department"),
    formatFunc: extractName,
    sortable: true,
  },
  {
    name: "customerNotes",
    heading: i18n("columns.CustomerNotes"),
    formatFunc: [parseHTML, truncate],
    minWidth: "25em",
    maxWidth: "25em",
  },
  {
    name: "location",
    heading: i18n("columns.Location"),
    formatFunc: extractName,
  },
  {
    name: "channel",
    heading: i18n("columns.Channel"),
    formatFunc: extractName,
  },
  {
    name: "closed",
    heading: i18n("columns.Status"),
    formatFunc: formatStatus,
    sortable: true,
    default: true,
  },
  {
    name: "hasSignature",
    heading: i18n("columns.Signature"),
    formatFunc: formatBooleanToYesOrBlank,
  },
];

export function getEmptyRecord() {
  return {
    starred: 0,
    syncToken: 0,
    number: "",
    date: new Date(),
    customer: null,
    location: null,
    channel: null,
    department: null,
    priority: null,
    assignedToUser: null,
    linkedTransaction: null,
    linkedOrders: [],
    comment: null,
    customerNotes: null,
    customFields: null,
    total: new Decimal(0),
    closed: false,
    archived: false,
    summaryOnly: false,
    hasSignature: false,
    documents: [],
    keys: null,
    values: null,
    lines: [],
  };
}

export const LINE_ITEM_METADATA = {
  lineNumber: {},
  item: {
    type: TYPES.reference,
    editStr: inventoryItemTypeEditStrFunction,
    initialValue: { id: null, name: null, description: null },
  },
  description: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  quantity: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weight: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  weightunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  volume: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  volumeunit: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  class: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  jobWorkcenter: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  linkedTransaction: {
    type: TYPES.linkedTransaction,
    editStr: linkedTransactionTypeEditStrFunction,
  },
  linkedTransactiontransactionType: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  linkedTransactionRefNumber: {
    type: TYPES.text,
    editStr: textTypeEditStrFunction,
  },
  linkedTransactionLineNumber: {
    type: TYPES.number,
    editStr: numberTypeEditStrFunction,
  },
  unitprice: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  amount: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_ROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  altAmount: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  picked: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  shipped: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  invoiced: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  produced: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  returned: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  cost: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  listPrice: {
    type: TYPES.money,
    currency: true,
    placesRight: DEFAULT_DECIMALS_UNROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
  margin: {
    type: TYPES.decimal,
    currency: true,
    placesRight: DEFAULT_DECIMALS_ROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  uom: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  serials: {
    type: TYPES.arrayOfReferences,
    editStr: arrayOfReferencesTypeEditStrFunction,
    initialValue: null,
  },
  bin: {
    type: TYPES.reference,
    editStr: referenceTypeEditStrFunction,
  },
  lot: {
    type: TYPES.reference,
    editStr: textTypeEditStrFunction,
  },
  duedate: {
    type: TYPES.date,
    editStr: dateTypeEditStrFunction,
    initialValue: () => new Date(),
  },
  percentdiscount: {
    type: TYPES.decimal,
    placesRight: DEFAULT_DECIMALS_ROUNDED,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  tax: {
    type: TYPES.decimal,
    editStr: decimalTypeEditStrFunction,
  },
  itemDetails: { initialValue: {} },
  relatedRecords: {
    initialValue: { item: { onhand: null } },
  },
};

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: ["date"],
  NON_UTC_DATETIME_FIELDS: [],
};

export const LINE_ACTIONS = [
  ["quickView", "pdf", "email"],
  "purchaseOrder",
  "pickTicket",
  "transfer",
  "template",
  "archive",
  "openClose",
  "delete",
  "history",
];
export const BATCH_ACTIONS = [
  ["pdf", "email"],
  "delete",
  "archive",
  "openClose",
];

export const REFERENCE_FIELDS = ["customer", "location"];

export const ITEM_CALCULATED_FIELDS = [NO_COLUMNS];

export const QUICK_VIEW_CONFIG = {
  titleSetting: "workOrderTitle",
  footerSetting: "workOrderFooter",
  tableConfig: [{ type: "lines" }],
  headerFields: [
    {
      name: "date",
      formatFunc: formatDateTimeToDate,
      label: i18n("columns.Date"),
    },
    {
      name: "number",
      label: i18n("frmLabel.WorkOrderNumber"),
      defaultValue: NO_REF_NUMBER_STRING,
    },
  ],
  fields: [],
  lines: [
    {
      name: "item",
      formatFunc: formatLinkableItem,
      heading: i18n("columns.Item"),
      align: "left",
      isItemField: true,
    },
    {
      name: "description",
      formatFunc: extractDescription,
      heading: i18n("columns.Instructions"),
      align: "left",
    },
    {
      name: "quantity",
      formatFunc: formatQuantityWithUom,
      heading: i18n("columns.Quantity"),
      align: "right",
    },
    {
      name: "produced",
      formatFunc: formatDecimal,
      heading: i18n("columns.Produced"),
      align: "right",
    },
    {
      name: "duedate",
      formatFunc: formatDate,
      heading: i18n("columns.DueDate"),
    },
    {
      name: "produce",
      heading: i18n("columns.ProduceAll"),
      align: "center",
      isProduce: true,
    },
  ],
};
