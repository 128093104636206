import { MenuItem } from "@mui/material";

import { checkForUnexpectedProps } from "services/utility/misc";

export function NavMenuItem(props) {
  const {
    dataTesting,
    component,
    children,
    to,
    onClick,
    target,
    tabIndex,
    disabled,
    ...unexpected
  } = props;
  checkForUnexpectedProps("NavMenuItem", unexpected);

  return (
    <MenuItem
      sx={{ p: 0, color: "primaryText", overflow: "inherit" }}
      data-testing={dataTesting}
      component={component}
      tabIndex={tabIndex}
      to={to}
      target={target}
      onClick={onClick}
      disabled={disabled}
    >
      {children}
    </MenuItem>
  );
}
