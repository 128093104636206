import { Box, Card, CardContent, Typography, Grid2 } from "@mui/material";

import { i18n } from "services/i18nService";

import { usePrivileges } from "hooks/usePrivileges";

const cardSx = { "& a": { color: "primaryLink" }, maxWidth: 500 };

export function Expired() {
  const { isAdmin } = usePrivileges();
  return (
    <Grid2 container spacing={0} alignItems="center" justifyContent="center">
      <Box sx={{ display: "grid" }} m={2} mb={0}>
        <Box mb={1}>
          <Typography variant="h2">
            {i18n("expiredDisabled.SubscriptionExpired")}
          </Typography>
        </Box>
        <Card variant="outlined" sx={cardSx}>
          <CardContent>
            {isAdmin ? (
              <>
                <Typography sx={{ paddingBottom: "1rem" }}>
                  {i18n("expiredDisabled.Admin.ExpiredMessage.1")}{" "}
                  <a href="/accountinfo">
                    {i18n("expiredDisabled.Admin.ExpiredMessage.2")}
                  </a>{" "}
                  {i18n("expiredDisabled.Admin.ExpiredMessage.3")}
                </Typography>
                <Typography sx={{ paddingBottom: "1rem" }}>
                  {i18n("expiredDisabled.ContactSupport.1")}{" "}
                  <a href="mailto:support@sosinventory.com">
                    support@sosinventory.com
                  </a>{" "}
                  {i18n("expiredDisabled.ContactSupport.2")}
                </Typography>
              </>
            ) : (
              <Typography sx={{ paddingBottom: "1rem" }}>
                {i18n("expiredDisabled.NonAdmin.ExpiredMessage")}
              </Typography>
            )}
          </CardContent>
        </Card>
      </Box>
    </Grid2>
  );
}
