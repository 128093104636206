import { i18n } from "services/i18nService";

import { SUB_COMPONENT } from "views/Reports/reportConstants";

export const ExportBuilds = {
  userHasSufficientPrivilege: () => true,
  planIncludesReport: () => true,
  customReportNameFetchList: [],
  customFieldObjectTypes: ["build"],
  columns: [
    { apiName: "BuildNumber", label: "Build #", default: true },
    { apiName: "Location", default: true },
    { apiName: "Date", default: true },
    { apiName: "Memo", label: "Comment" },
    { apiName: "Assembly", default: true },
    {
      apiName: "AssemblyQuantity",
      label: "Assembly Quantity",
      default: true,
      number: true,
    },
    { apiName: "AssemblyUOM", label: "Assembly UOM" },
    { apiName: "Component", default: true },
    {
      apiName: "ComponentQuantity",
      label: "Component Quantity",
      default: true,
      number: true,
    },
    { apiName: "ComponentUOM", label: "Component UOM" },
    {
      apiName: "ComponentAccountingCost",
      label: "Component Acct Cost",
      number: true,
    },
    {
      apiName: "AssemblyAccountingCost",
      label: "Assembly Acct Cost",
      number: true,
    },
    { apiName: "Notes" },
    { apiName: "ComponentDescription", label: "Component Description" },
    { apiName: "AssemblyDescription", label: "Assembly Description" },
    { apiName: "AssemblyLot", label: "Assembly Lot" },
    { apiName: "AssemblyBin", label: "Assembly Bin" },
    { apiName: "AssemblySerialNumbers", label: "Assembly Serials" },
    { apiName: "ComponentLot", label: "Component Lot" },
    { apiName: "ComponentBin", label: "Component Bin" },
    { apiName: "ComponentSerialNumbers", label: "Component Serials" },
    { apiName: "FromJob", label: "From Job" },
    { apiName: "ToJob", label: "To Job" },
    { apiName: "AssemblyClass", label: "Assembly Class" },
    { apiName: "ComponentClass", label: "Component Class" },
    { apiName: "Department" },
    { apiName: "WorkOrder", label: "Work Order" },
    { apiName: "Archived" },
    { apiName: "Starred", number: true },
    { apiName: "LineNumber", label: "Line Number", number: true },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date1Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("filter.Locations"),
      component: "LocationsFilter",
      apiName: "locations",
      defaultValue: null,
      placeholder: i18n("reports.placeholder.DefaultIsAll", {
        type: i18n("reports.locations"),
      }),
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Class"),
      component: "ClassFilter",
      apiName: "class",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Department"),
      component: "DepartmentFilter",
      apiName: "department",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.OutputItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains2",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.InputItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedTransactions"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
