import { useState } from "react";
import { useSelector } from "react-redux";

import { TextField, FormControlLabel, Box } from "@mui/material";
import { LocalizationProvider, DatePicker } from "@mui/x-date-pickers";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";

import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";
import {
  isValidDateString,
  convertDateToDateString,
} from "services/utility/customFields";
import { datefromDateFormat } from "services/utility/dates";
import { locales } from "services/utility/localization";
import { checkForUnexpectedProps } from "services/utility/misc";

import { DATE_SELECT_MIN_DATE, DATE_SELECT_MAX_DATE } from "appConstants";

const datePickerSx = {
  root: {
    "& .MuiSvgIcon-root": { color: "txnFieldIcons" },
    "& .MuiButtonBase-root": { marginRight: "-12px" },
  },
  textField: {
    input: { display: "none" },
    label: { display: "none" },
    fieldset: { display: "none" },
  },
};

export function CustomFieldDatePicker(props) {
  const { label, value, name, onValueChange, setErrors, ...unexpected } = props;
  checkForUnexpectedProps("DatePicker", unexpected);

  const [localValue, setLocalValue] = useState(value);
  const [dateError, setDateError] = useState(false);

  const dateFormat = useSelector(
    (state) => state.userCompanySettings.localization.dateFormat
  );

  const localeCode = useSelector(
    (state) => state.userCompanySettings.settings.localeCode
  );

  function handleOnBlur() {
    if (dateError) {
      const message = i18n(`error.CustomFieldDateError.${dateFormat}`, {
        name,
      });
      setErrors((prev) => ({
        ...prev,
        customFieldErrors: [...prev.customFieldErrors, { name, message }],
      }));
      return;
    }
    setErrors((prev) => ({
      ...prev,
      customFieldErrors: prev.customFieldErrors.filter(
        (cf) => name !== cf.name
      ),
    }));
    onValueChange(name, localValue);
  }

  function handleOnChange(e) {
    const value = e.target.value;
    setLocalValue(value);
    const isValidDate = !value || isValidDateString(value, dateFormat);
    setDateError(!isValidDate);
  }

  function handlePickerChange(date) {
    const dateString = convertDateToDateString(date, dateFormat);
    onValueChange(name, dateString);
  }

  function getPickerDate(dateString) {
    const date = datefromDateFormat(dateString, dateFormat);

    // if not valid date, then default to today
    return !dateString || date.toString() === "Invalid Date"
      ? new Date()
      : date;
  }

  return (
    <Box sx={{ display: "flex", gap: 0.5, alignItems: "center" }}>
      <TextField
        name={name}
        label={label}
        defaultValue={value}
        onChange={handleOnChange}
        onBlur={handleOnBlur}
        error={dateError}
        margin="dense"
        autoComplete="off"
        variant="outlined"
        InputLabelProps={{ shrink: true }}
        InputProps={{
          endAdornment: (
            <FormControlLabel
              sx={{ ml: 0, mr: 0 }}
              control={
                <LocalizationProvider
                  dateAdapter={AdapterDateFns}
                  adapterLocale={locales[localeCode]}
                >
                  <DatePicker
                    minDate={DATE_SELECT_MIN_DATE}
                    maxDate={DATE_SELECT_MAX_DATE}
                    format={dateFormat}
                    name={name}
                    label={label}
                    value={getPickerDate(localValue)}
                    onChange={handlePickerChange}
                    sx={datePickerSx.root}
                    slotProps={{
                      actionBar: { actions: ["today"] },
                      textField: { sx: datePickerSx.textField },
                    }}
                  />
                </LocalizationProvider>
              }
            />
          ),
        }}
      />
      <HoverHelp title={i18n(`hoverHelp.customField.${dateFormat}`)} />
    </Box>
  );
}
