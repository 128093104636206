import { useState } from "react";
import { useDispatch } from "react-redux";

import { Box } from "@mui/material";
import {
  Dialog,
  DialogContent,
  DialogActions,
  DialogTitle,
  Typography,
} from "@mui/material";
import { Button } from "@mui/material";

import { ActionButton } from "components/ActionButton";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { ButtonProgress } from "components/utility/ButtonProgress";
import { CloseX } from "components/utility/CloseX";
import { FormErrors } from "components/utility/FormErrors";
import { HoverHelp } from "components/utility/HoverHelp";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { cancellationIsValid } from "services/sosInventoryService/company/accountInfo/isValid";
import { CANCELLATION_REASON_OPTIONS } from "services/sosInventoryService/company/accountInfo/schema";
import {
  deletePaymentMethod,
  getSingleton,
} from "services/sosInventoryService/sosApi";

import { useErrors } from "hooks/useErrors";

import { openAlert } from "globalState/alertSlice";

export function RemovePaymentInfo(props) {
  const { setAccountInformation } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);
  const [data, setData] = useState({ reason: null, additionalComments: "" });
  const { errors, setErrors, isInError } = useErrors();
  const [cancellationModalOpen, setCancellationModalOpen] = useState(false);

  async function removePaymentMethod() {
    const { reason, additionalComments } = data;
    const errors = cancellationIsValid(data);
    if (errors.messages.length > 0) {
      setErrors(errors);
      return;
    }
    setIsLoading(true);

    const fullReason = CANCELLATION_REASON_OPTIONS.find(
      ({ id }) => id === reason.id
    );
    const cancellationReason = additionalComments
      ? `${fullReason.reasonString} - ${additionalComments}`
      : fullReason.reasonString;
    const { success, message } = await deletePaymentMethod(cancellationReason);
    if (success) {
      setAccountInformation(await getSingleton("company/accountInfo"));
      const message = i18n("alert.PaymentInformationClearSuccess");
      dispatch(openAlert({ type: "success", message }));
    } else {
      const errorMessage =
        message || i18n("alert.PaymentInformationClearError");
      dispatch(openAlert({ type: "error", message: errorMessage }));
    }
    setIsLoading(false);
  }

  function handleInputFieldChange(name, value) {
    setData((prevData) => ({ ...prevData, [name]: value }));
  }
  return (
    <>
      <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
        <ActionButton
          disabled={isLoading}
          onClick={() => setCancellationModalOpen(true)}
        >
          {i18n("accountInformation.RemovePaymentInformation")}
        </ActionButton>
        <HoverHelp
          title={i18n("accountInformation.ClickLinkToRemovePayment")}
        />
      </Box>
      <Dialog
        open={cancellationModalOpen}
        onClose={() => setCancellationModalOpen(false)}
      >
        <DialogTitle>
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <Typography variant="h3">
              {i18n("accountInformation.SorryToSeeYouGo")}
            </Typography>
            <CloseX onClick={() => setCancellationModalOpen(false)} />
          </div>
        </DialogTitle>
        <DialogContent>
          <FormErrors errors={errors} setErrors={setErrors} />
          <Typography>
            {i18n("accountInformation.AreYouSureYouWantToCancel")}
          </Typography>
          <VSpace space={2} />
          <FrmSelectFromObjects
            name="reason"
            label={i18n("frmLabel.Reason")}
            value={data.reason}
            onValueChange={handleInputFieldChange}
            options={CANCELLATION_REASON_OPTIONS}
            error={isInError("reason")}
          />
          <VSpace space={1} />
          <FrmResizeTextField
            name="additionalComments"
            label={i18n("frmLabel.AdditionalComments")}
            value={data.additionalComments}
            minRows={4}
            width="100%"
            onValueBlur={handleInputFieldChange}
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="secondary"
            onClick={() => setCancellationModalOpen(false)}
          >
            {i18n("accountInformation.KeepSubscription")}
          </Button>
          <div style={{ position: "relative" }}>
            <Button
              color="primary"
              disabled={isLoading}
              onClick={removePaymentMethod}
              sx={{ textTransform: "uppercase" }}
            >
              {i18n("accountInformation.CancelSubscription")}
            </Button>
            {isLoading && <ButtonProgress color="secondary" />}
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
