import { useEffect, useState } from "react";

import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";
import { getList } from "services/sosInventoryService/sosApi";
import { checkForUnexpectedProps } from "services/utility/misc";

export function FrmAssignedTo(props) {
  const {
    value,
    onValueChange,
    error,
    maxWidth,
    name = "assignedToUser",
    label = i18n("frmLabel.AssignedToUser"),
    disableClearable,
    disabled,
    ...unexpected
  } = props;
  checkForUnexpectedProps("FrmAssignedTo", unexpected);
  const [users, setUsers] = useState(null);

  useEffect(() => {
    async function getUsers() {
      setUsers(await getList("user", { status: "active" }));
    }
    getUsers();
  }, []);

  function getValue() {
    if (value?.name) {
      return value;
    }
    return users ? users.find(({ id }) => id === value?.id) : null;
  }

  return (
    <FrmSelectFromObjects
      name={name}
      label={label}
      value={getValue()}
      onValueChange={onValueChange}
      options={users}
      error={error}
      maxWidth={maxWidth}
      disabled={disabled}
      disableClearable={disableClearable}
    />
  );
}
