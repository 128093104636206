import { Typography, Box } from "@mui/material";

import { Loading as LoadingClass } from "classes/Loading";

import { LineItemText } from "components/formFields/LineItemText";
import { LineSkeleton } from "components/formFields/LineSkeleton";
import { LineTableCell } from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";

import { DEFAULT_DECIMALS_UNROUNDED } from "appConstants";

export function LineOnHandWithLot(props) {
  const { onhand, lot, lots, lotTracked } = props;

  const isLoading = onhand instanceof LoadingClass;
  const isLotOnhandLoading = lotTracked && !lot?.onhand && !Array.isArray(lots);

  function getLotOnHand() {
    return lot.onhand
      ? lot.onhand
      : lots.find(({ id }) => id === lot.id).onhand;
  }

  return (
    <LineTableCell
      sx={{
        minWidth: lotTracked && lot ? "8rem" : "5.5rem",
        backgroundColor: isLoading ? "inherit" : "staticTableCell",
      }}
    >
      {isLoading ? (
        <LineSkeleton />
      ) : (
        <Box>
          <LineItemText
            decimal
            decimalPlaces={DEFAULT_DECIMALS_UNROUNDED}
            value={onhand}
            sx={{ color: "staticTableCellText" }}
            dataTesting="lineOnHand"
          />
          {lotTracked && lot && (
            <Box
              sx={{
                display: "flex",
                justifyContent: "space-between",
                pl: 1,
                p: "8.5px 0 8.5px 14px",
              }}
            >
              <Typography
                sx={{ color: "staticTableCellText", fontSize: "0.8125rem" }}
              >
                {i18n("columns.colon.Lot")}
              </Typography>
              {isLotOnhandLoading ? (
                <LineSkeleton sx={{ flexGrow: 1 }} />
              ) : (
                <LineItemText
                  decimal
                  decimalPlaces={DEFAULT_DECIMALS_UNROUNDED}
                  value={getLotOnHand()}
                  sx={{ color: "staticTableCellText" }}
                />
              )}
            </Box>
          )}
        </Box>
      )}
    </LineTableCell>
  );
}
