import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { SelectButtonCombo } from "components/formFields/SelectButtonCombo";

import { i18n } from "services/i18nService";
import { afterTouchLine } from "services/sosInventoryService/purchasingTransaction/afterTouchLine";
import { getRecord } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";
import { getAddOrderPlaceholder } from "services/utility/misc";

import { useVendorPos } from "hooks/useVendorPos";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { OBJECT_TYPES } from "appConstants";

const PURCHASE_ORDER = OBJECT_TYPES.PURCHASE_ORDER.fullString;

export function AddPurchaseOrder(props) {
  const { vendor, lineHandler } = props;

  const dispatch = useDispatch();

  const [value, setValue] = useState(null);
  const options = useVendorPos(vendor);

  // reset the selection whenever the vendor changes
  useEffect(() => setValue(null), [vendor]);

  async function addPurchaseOrder() {
    if (!value) {
      return;
    }
    dispatch(editModalLoadingIndicatorOn());
    const sourcePo = await getRecord(PURCHASE_ORDER, value.id);
    const nonZeroLines = sourcePo.lines.filter(({ received, quantity }) =>
      received.lt(quantity)
    );
    const newLines = nonZeroLines.map((line) => {
      const newLine = {
        ...line,
        linkedTransaction: {
          id: sourcePo.id,
          refNumber: sourcePo.number,
          lineNumber: line.lineNumber,
          transactionType: OBJECT_TYPES.PURCHASE_ORDER.shortString,
        },
        quantity: line.quantity.minus(line.received),
      };
      delete newLine.id;
      return afterTouchLine(newLine, "quantity");
    });
    if (newLines.length) {
      setPageDirty();
      lineHandler({ type: "append", newLines });
    }
    dispatch(editModalLoadingIndicatorOff());
  }

  return (
    <SelectButtonCombo
      key={options}
      disableClearable
      minWidth="12rem"
      maxWidth="12rem"
      selectLabel={i18n("objectType.purchaseorder.Sentence")}
      buttonLabel={i18n("global.Add")}
      optionDisplayText="number"
      value={value}
      onValueChange={(_, value) => setValue(value)}
      onClick={addPurchaseOrder}
      options={options}
      placeholder={getAddOrderPlaceholder(options, i18n("addOrder.SelectPO"))}
      disabled={!options?.length}
    />
  );
}
