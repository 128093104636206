import { Money } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

import { afterTouchLine as baseAfterTouchLine } from "services/utility/afterTouchLine";
import { isNonZeroDecimalOrMoney } from "services/utility/misc";

// when this field (the key) changes, we should run the (value) list of
// updater functions
const UPDATERS = { quantity: [updateTotal] };

export function afterTouchLine(line, changedField) {
  return baseAfterTouchLine(UPDATERS, line, changedField);
}

function updateTotal(line) {
  const { cost, quantity } = line;

  let total;
  // handle the case where quantity is zero...therefore total is zero
  if (!isNonZeroDecimalOrMoney(quantity)) {
    total = Money.ZERO;
  } else {
    // if we're still loading cost, show loading for total as well
    total = cost instanceof Loading ? new Loading() : cost.times(quantity);
  }

  return { ...line, total };
}
