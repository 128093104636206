import { Box, Typography } from "@mui/material";

import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmTextFieldWithHoverHelp } from "components/formFields/FrmTextFieldWithHoverHelp";
import { FrmAssignedTo } from "components/formFields/frm/FrmAssignedTo";
import { FrmOrderStage } from "components/formFields/frm/FrmOrderStage";
import { FrmPriority } from "components/formFields/frm/FrmPriority";

import { i18n } from "services/i18nService";
import { CREATE_TARGET_OPTIONS } from "services/sosInventoryService/aopRule/schema";
import { isLineFieldInError } from "services/utility/errors";

import { useRelated } from "hooks/useRelated";

import { getObjectFromTypeString } from "appConstants";

export function LineTarget(props) {
  const { action, line, handleReferenceChange, triggerForm, rowIndex, errors } =
    props;

  const { fullString } = getObjectFromTypeString(triggerForm);

  const formTemplates = useRelated("formtemplate");

  function isInError(field) {
    return isLineFieldInError(field, rowIndex, errors);
  }

  switch (action) {
    case "Assign":
    case "Notify":
      const name = action === "Notify" ? "notifyUserId" : "assignUserId";
      return (
        <FrmAssignedTo
          name={name}
          label=""
          value={line[name] ? { id: line[name] } : null}
          onValueChange={handleReferenceChange}
          error={isInError("target")}
          disableClearable
        />
      );
    case "Create":
      return (
        <FrmSelectFromObjects
          name="createFormType"
          value={line.createFormType ? { id: line.createFormType } : null}
          onValueChange={handleReferenceChange}
          options={CREATE_TARGET_OPTIONS[fullString]}
          error={isInError("target")}
          disableClearable
        />
      );
    case "Priority":
      return (
        <FrmPriority
          name="priorityId"
          label=""
          onValueChange={handleReferenceChange}
          value={line.priorityId ? { id: line.priorityId } : null}
          error={isInError("target")}
          disableClearable
        />
      );
    case "Stage":
      return (
        <FrmOrderStage
          name="orderStageId"
          onValueChange={handleReferenceChange}
          value={line.orderStageId ? { id: line.orderStageId } : null}
          error={isInError("target")}
          disableClearable
        />
      );
    case "Email":
      return (
        <Box sx={{ pl: "14px" }}>
          <Box sx={{ display: "flex", alignItems: "center", pr: "14px" }}>
            <Typography>{i18n("columns.colon.Address")}</Typography>
            <Box sx={{ flexGrow: 1 }}>
              <FrmTextFieldWithHoverHelp
                name="name"
                onValueBlur={handleReferenceChange}
                value={line.actionEmail}
                helpText={i18n("aopRule.BlankToSendFormDefault")}
                sx={{
                  "& .MuiInputBase-input.MuiOutlinedInput-input": {
                    pb: 0,
                    pt: 0,
                  },
                }}
              />
            </Box>
          </Box>
          <Box sx={{ display: "flex", alignItems: "center" }}>
            <Typography>{i18n("columns.colon.Template")}</Typography>
            <Box sx={{ flexGrow: 1 }}>
              <FrmSelectFromObjects
                sx={{
                  flexGrow: 1,
                  "& .MuiOutlinedInput-root.MuiInputBase-sizeSmall": {
                    pt: 0,
                    pb: 0,
                  },
                }}
                name="emailTemplateId"
                options={
                  formTemplates
                    ? formTemplates.filter(
                        ({ form }) => form.id === triggerForm
                      )
                    : formTemplates
                }
                onValueChange={handleReferenceChange}
                value={
                  line.emailTemplateId ? { id: line.emailTemplateId } : null
                }
                error={isInError("target")}
                disableClearable
              />
            </Box>
          </Box>
        </Box>
      );
    default:
      return null;
  }
}
