import { useEffect, useState } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { Grid2 } from "@mui/material";

import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";

import { i18n } from "services/i18nService";
import { thirdPartyLogin } from "services/utility/authentication";

export function ThirdPartyAuth() {
  const { search } = useLocation();
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    function handleError(authError) {
      navigate("/login", { state: { authError } });
    }

    async function login(code, realmId, error) {
      thirdPartyLogin(code, realmId, error, handleError, setIsLoading);
    }

    const queryParams = new URLSearchParams(search);
    const state = queryParams.get("state");
    const localState = localStorage.getItem("qboState");
    const code = queryParams.get("code");
    const realmId = queryParams.get("realmId");
    const error = queryParams.get("error");
    if (state !== localState) {
      handleError(i18n("auth.IntuitError"));
    } else if (error) {
      handleError(error);
    } else if (code) {
      login(code, realmId, error);
    }
  }, [navigate, search]);

  return (
    <Grid2
      container
      spacing={0}
      alignItems="center"
      justifyContent="center"
      sx={{ minHeight: "100vh" }}
    >
      {isLoading ? (
        <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
      ) : (
        <div>{i18n("login.LoggingIntoSOS")}</div>
      )}
    </Grid2>
  );
}
