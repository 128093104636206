import { normalizeWorkCenters } from "services/utility/beforeSave.js";
import { convertNativeToCustom } from "services/utility/customFields";
import { cleanTransactionNumber } from "services/utility/misc";

export function beforeSave(record) {
  const newRecord = { ...record };

  newRecord.number = cleanTransactionNumber(record.id, record.number);
  newRecord.customFields = convertNativeToCustom(record.customFields);
  newRecord.workcenters = normalizeWorkCenters(record.workcenters);
  return newRecord;
}
