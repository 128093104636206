// see views/common.js for error object structure
import { i18n } from "services/i18nService";
import { purchasingTransactionIsValid } from "services/sosInventoryService/purchasingTransaction/isValid";
import { quantitySerialsIsValid } from "services/utility/isValid";

import { ITEM_TYPES_REQUIRING_BINS } from "appConstants";

export function isValid(record, relatedRecords, lines) {
  let errors = purchasingTransactionIsValid(record, relatedRecords, lines);

  lines.forEach(({ relatedRecords, bin, lineNumber }) => {
    // a bin has been chosen, if the item and location require it
    if (
      relatedRecords.location?.binTracking &&
      relatedRecords.item &&
      ITEM_TYPES_REQUIRING_BINS.includes(relatedRecords.item?.type) &&
      !bin?.id
    ) {
      errors.messages.push(i18n("isValid.LineItem.MissingBin", { lineNumber }));
      errors.line[lineNumber].push("bin");
    }
  });

  errors = quantitySerialsIsValid(errors, lines, record.autoSerialLots);
  return errors;
}
