import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import {
  addPercentAdornment,
  formatLastNameLinkField,
} from "services/utility/formatting";

export const columnInfo = [
  {
    name: "lastName",
    heading: i18n("columns.LastName"),
    fixed: true,
    defaultSort: true,
    formatFunc: formatLastNameLinkField,
    linkField: true,
    sortable: true,
  },
  {
    name: "firstName",
    heading: i18n("columns.FirstName"),
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
  {
    name: "commissionRate",
    heading: i18n("columns.CommissionRate"),
    formatFunc: addPercentAdornment,
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

export function getEmptyRecord() {
  return {
    firstName: null,
    lastName: null,
    commissionRate: Decimal.ZERO,
    commissionAmount: Decimal.ZERO,
    user: null,
    restrictCustomers: false,
  };
}
