import { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import { Box, Typography } from "@mui/material";

import { Layout } from "Layout";

import { NEW_RECORD_SKELETON } from "appConfig";

import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmCheckbox } from "components/formFields/frm/FrmCheckbox";
import { FrmCheckboxWithHoverHelp } from "components/formFields/frm/FrmCheckboxWithHoverHelp";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getRecord } from "services/sosInventoryService/sosApi";
import { PRIVILEGE_OPTIONS } from "services/sosInventoryService/user/schema";
import { isPersistedRecord } from "services/utility/misc";

import { useErrors } from "hooks/useErrors";
import { useRecord } from "hooks/useRecord";

import { MaxUserWarning } from "views/Users/User/MaxUserWarning";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

export function User(props) {
  const { id, onClose, objectType } = props;

  const dispatch = useDispatch();

  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();

  const loadingState = useSelector((state) => state.loading.editModal);

  function handlePrivilegeChange(fielName, value) {
    setRecord((prevRecord) => {
      const privileges = { ...prevRecord.privileges, [fielName]: value };
      return { ...prevRecord, privileges };
    });
  }

  useEffect(() => {
    async function _getRecord() {
      if (id) {
        dispatch(editModalLoadingIndicatorOn());
        const response = await getRecord(objectType, id);
        setRecord(response);
        dispatch(editModalLoadingIndicatorOff());
      } else {
        const emptyRecord = NEW_RECORD_SKELETON[objectType]();
        setRecord(emptyRecord);
      }
    }
    _getRecord();
  }, [setRecord, id, objectType, dispatch]);

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={objectType}
        setErrors={setErrors}
        text={record?.name || ""}
        handleClose={onClose}
        disableSave={record?.master}
      />

      {loadingState && <Loading />}

      {record && (
        <ObjectEditDialogContent>
          <Layout
            pageTitle={
              id
                ? `${i18n(`objectType.${objectType}.Sentence`)} ${
                    record?.name || ""
                  }`
                : i18n(`objectType.${objectType}.New`)
            }
          >
            <Box flexGrow="1" position="relative" m={2} mb={0}>
              <FormErrors errors={errors} setErrors={setErrors} />
              {record ? (
                <Box>
                  {record.master ? (
                    <Typography variant="h4">
                      {i18n("settings.user.masterAdmin")}
                    </Typography>
                  ) : (
                    <>
                      {!isPersistedRecord(record.id) && (
                        <>
                          <MaxUserWarning />
                          <FrmFieldGrid gridAutoFlow="dense">
                            <div style={{ gridColumn: "1 / 2" }}>
                              <FrmTextField
                                name="name"
                                label={i18n("frmLabel.Name")}
                                onValueBlur={handleInputFieldChange}
                                value={record.name}
                              />
                            </div>
                            <div style={{ gridColumn: "2 / 2" }}>
                              <FrmTextField
                                name="email"
                                label={i18n("frmLabel.Email")}
                                onValueBlur={handleInputFieldChange}
                                value={record.email}
                              />
                              <FrmTextField
                                name="confirmEmail"
                                label={i18n("frmLabel.ConfirmEmail")}
                                onValueBlur={handleInputFieldChange}
                                value={record.confirmEmail}
                              />
                            </div>
                          </FrmFieldGrid>
                        </>
                      )}
                      <FrmCheckboxWithHoverHelp
                        name="admin"
                        value={record.admin}
                        onValueChange={handleInputFieldChange}
                        label={i18n("frmLabel.Administrator")}
                        helpText={i18n("hoverHelp.user.Administrator")}
                      />
                      <VSpace space={1} />
                      {PRIVILEGE_OPTIONS.map(
                        ({ groupLabel, groupPrivileges }, i) => (
                          <Box key={i} pb={1}>
                            <Typography
                              variant="tag"
                              component="p"
                              sx={{ textTransform: "uppercase" }}
                            >
                              {groupLabel}
                            </Typography>
                            {groupPrivileges.map((innerPrivilege, i, a) =>
                              typeof innerPrivilege === "object" ? (
                                <Box
                                  key={i}
                                  sx={{
                                    display: "grid",
                                    alignItems: "center",
                                    gridTemplateColumns: "9fr 11fr 12fr 12fr",
                                    rowGap: "0.5rem",
                                  }}
                                >
                                  <Typography
                                    sx={{
                                      pr: "3rem",
                                      gridColumn: 1,
                                      textTransform: "uppercase",
                                      fontSize: "0.7rem",
                                    }}
                                  >
                                    {innerPrivilege.label}
                                  </Typography>
                                  {innerPrivilege.privileges.map(
                                    ({ privilege, label }, i) => (
                                      <Box key={i}>
                                        <FrmCheckbox
                                          key={i}
                                          name={privilege}
                                          disabled={record.admin}
                                          value={record?.privileges[privilege]}
                                          onValueChange={handlePrivilegeChange}
                                          label={label}
                                          sx={{ pr: 2, gridColumn: i + 2 }}
                                        />
                                      </Box>
                                    )
                                  )}
                                </Box>
                              ) : (
                                <Box
                                  key={i}
                                  sx={{
                                    mt:
                                      typeof a[i - 1] === "object" ? "1rem" : 0,
                                  }}
                                >
                                  <FrmCheckbox
                                    name={innerPrivilege}
                                    value={record?.privileges[innerPrivilege]}
                                    onValueChange={handlePrivilegeChange}
                                    label={i18n(`privilege.${innerPrivilege}`)}
                                    sx={{ pr: 2, gridColumn: 1 }}
                                    disabled={record.admin}
                                  />
                                </Box>
                              )
                            )}
                          </Box>
                        )
                      )}
                    </>
                  )}
                </Box>
              ) : (
                !isInError("record") && (
                  <FixedLoadingIndicator
                    text={`${i18n("global.Loading")}...`}
                  />
                )
              )}
            </Box>
            <VSpace space={4} />
          </Layout>
        </ObjectEditDialogContent>
      )}
    </>
  );
}
