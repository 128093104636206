import { useEffect, useState } from "react";

import { QUICKLIST_OBJECT_TYPES } from "appConfig";

import { getQuickList, get } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

import { OBJECT_TYPES } from "appConstants";

// for some object types, use the fullName field instead of name
const USE_FULL_NAME = [OBJECT_TYPES.CLASS.fullString];

/**
 * @name    useRelated
 *
 * @summary get an array of records of the type named by the objectType
 *          parameter, generally for use in populating select lists for
 *          transactions
 *
 * @param   objectType (string) - object type used for GET request
 *
 * @returns (array) record data for the objectType provided
 */

export function useRelated(objectType) {
  const [records, setRecords] = useState(null);

  useEffect(() => {
    async function retrieveRecords() {
      let response;
      if (QUICKLIST_OBJECT_TYPES.includes(objectType)) {
        response = await getQuickList(objectType, { inTransaction: true });
        setRecords(response.data);
      } else {
        response = await get(objectType, { inTransaction: true });
        if (response.success) {
          let preparedRecords = response.data.records;
          if (USE_FULL_NAME.includes(objectType)) {
            preparedRecords = preparedRecords.map((rec) => ({
              ...rec,
              name: rec.fullName,
            }));
          }
          setRecords(preparedRecords);
        } else {
          handleProgramError(
            new Error(
              `useRelated | unsuccessful call to getAll, message: ${response.message}`
            )
          );
        }
      }
    }
    retrieveRecords();
  }, [objectType]);

  return records;
}
