import { useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useSelector } from "react-redux";

import {
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableCell,
  TableFooter,
} from "@mui/material";

import { AddLines } from "components/EditPage/AddLines";
import { ClearLines } from "components/EditPage/ClearLines";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { LineDragInsertCopy } from "components/formFields/line/LineDragInsertCopy";
import { DragHandleHeading } from "components/utility/DragHandle";
import { WarningModal } from "components/utility/WarningModal";

import { i18n } from "services/i18nService";
import { EMPTY_LINE } from "services/sosInventoryService/aopRule/schema";
import { setPageDirty } from "services/utility/edit";
import { textForAddLineOrLines } from "services/utility/lineItems";

import { LineItem } from "views/AopRules/AopRule/LineItem";

const borderStyling = {
  "& .MuiOutlinedInput-root:not(.Mui-error)": {
    "& fieldset": { borderWidth: 0 }, // No border when not in error state
  },
  "& .MuiOutlinedInput-root.Mui-error fieldset": {
    borderWidth: "1px", // Preserve error border styling
  },
  "& .MuiTextField-root": { mt: 0, mb: 0 },
};

export function LineItems(props) {
  const { lines, setRecord, triggerForm, errors } = props;

  const [clearLines, setClearLines] = useState(false);
  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  function insertEmptyLine(insertIndex) {
    setPageDirty();
    const newLines = [...lines];
    newLines.splice(insertIndex, 0, EMPTY_LINE);
    setRecord((prevRecord) => ({ ...prevRecord, lines: newLines }));
  }

  function copyDown(line, index) {
    setPageDirty();
    const updatedLine = line;
    delete updatedLine.id;
    const newLines = [...lines];
    newLines[index + 1] = updatedLine;
    setRecord((prevRecord) => ({ ...prevRecord, lines: newLines }));
  }

  function addLines() {
    setPageDirty();
    const lines = new Array(numLinesToAdd).fill(EMPTY_LINE);
    setRecord((prevRecord) => ({
      ...prevRecord,
      lines: [...prevRecord.lines, ...lines],
    }));
  }

  function doClearLines() {
    setPageDirty();
    setRecord((prevRecord) => ({
      ...prevRecord,
      lines: prevRecord.lines.map(() => EMPTY_LINE),
    }));
    setClearLines(false);
  }

  function handleDrop(result) {
    const { source, destination } = result;
    // verify drop action is valid
    if (!destination || destination.index === source.index) {
      return;
    }

    const newLines = [...lines];
    const [savedSource] = newLines.splice(source.index, 1);
    newLines.splice(destination.index, 0, savedSource);
    setRecord((prevRecord) => ({ ...prevRecord, lines: newLines }));
  }

  if (!lines) {
    return null;
  }

  return (
    <>
      <TableContainer data-testing="lineItems" sx={{ overflowX: "initial" }}>
        <Table size="small" padding="none" stickyHeader>
          <TableHead>
            <TableRow sx={{ verticalAlign: "bottom", lineHeight: "1" }}>
              <DragHandleHeading />
              <ClearLines setClearLines={setClearLines} />
              <LineColumnHeading />
              <LineColumnHeading labelCode="Action" />
              <LineColumnHeading labelCode="Target" />
            </TableRow>
          </TableHead>
          <DragDropContext onDragEnd={handleDrop}>
            <Droppable droppableId="lineItems">
              {(provided) => (
                <TableBody ref={provided.innerRef} {...provided.droppableProps}>
                  {lines.map((line, index) => (
                    <Draggable
                      key={index}
                      draggableId={`${index}`}
                      index={index}
                    >
                      {(draggableProvided, snapshot) => {
                        return (
                          <TableRow
                            ref={draggableProvided.innerRef}
                            {...draggableProvided.draggableProps}
                            sx={{
                              ...draggableProvided.draggableProps.style,
                              position: "inherit",
                              backgroundColor: snapshot.isDragging
                                ? "dragBackground"
                                : "none",
                              ...borderStyling,
                            }}
                            data-testing={"line-" + index}
                          >
                            <LineDragInsertCopy
                              draggableProvided={draggableProvided}
                              snapshot={snapshot}
                              insertEmptyLine={() => insertEmptyLine(index)}
                              lineNumber={index + 1}
                              onCopyDown={() => copyDown(line, index)}
                              showCopyDown={index + 1 !== lines.length}
                            />
                            <LineItem
                              triggerForm={triggerForm}
                              line={line}
                              setRecord={setRecord}
                              draggableProvided={draggableProvided}
                              draggableSnapshot={snapshot}
                              rowIndex={index}
                              errors={errors}
                            />
                          </TableRow>
                        );
                      }}
                    </Draggable>
                  ))}
                  {provided.placeholder}
                </TableBody>
              )}
            </Droppable>
          </DragDropContext>
          <TableFooter>
            <TableRow>
              <TableCell sx={{ border: "none" }} />
              <AddLines
                title={textForAddLineOrLines(numLinesToAdd)}
                onClick={addLines}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <WarningModal
        title={i18n("global.ClearLines")}
        message={i18n("global.clearLinesMessage")}
        open={clearLines}
        onOk={doClearLines}
        onClose={() => setClearLines(false)}
      />
    </>
  );
}
