import { i18n } from "services/i18nService";
import { ADJUSTMENT_FORM_TYPE } from "services/sosInventoryService/formTemplate/schema";

export const columnInfo = [
  {
    name: "name",
    fixed: true,
    heading: i18n("columns.Name"),
    defaultSort: true,
    sortable: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    fixedRightOfAction: true,
    fixed: true,
    sortable: true,
  },
  {
    name: "form",
    heading: i18n("columns.Type"),
    fixedRightOfAction: true,
    fixed: true,
  },
];

export const LINE_ACTIONS = [
  "get",
  "deleteTemplateLibraryForm",
  "downloadTemplate",
];
export const BATCH_ACTIONS = ["get", "deleteTemplateLibraryForm"];

export function getEmptyRecord() {
  return {
    name: null,
    fileName: null,
    description: null,
    form: ADJUSTMENT_FORM_TYPE,
    content: null,
  };
}
