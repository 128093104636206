export const SETTINGS = [
  {
    apiName: "avsAllowed",
    component: "FrmCheckbox",
  },
  {
    apiName: "cvvAllowed",
    component: "FrmCheckbox",
  },
  {
    apiName: "paymentNumber",
    component: "FrmTransactionNumber",
    transactionType: "payment",
    hasInputLabel: true,
  },
  {
    apiName: "companyName",
    component: "FrmTextField",
  },
  {
    apiName: "template",
    component: "FrmFormTemplates",
  },
  {
    apiName: "paymentNotifyEmail",
    component: "FrmTextField",
    maxLength: 255,
  },
];
