import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import { Edit } from "@mui/icons-material";
import { Box, Typography, IconButton } from "@mui/material";

import { Money } from "classes/DecimalClasses";

import { Tooltip } from "components/Tooltip";
import { ExternalLink } from "components/html/ExternalLink";
import { LinkText } from "components/utility/LinkText";

import { i18n } from "services/i18nService";
import {
  getSingleton,
  getPaymentHistory,
} from "services/sosInventoryService/sosApi";
import { getPaymentPdf } from "services/sosInventoryService/sosApi";
import { isoToLocalDateTime } from "services/utility/dates";
import { formatDate } from "services/utility/dates";
import { downloadDocument } from "services/utility/documents";
import { formatDateTimeToDate } from "services/utility/formatting";
import { formatMoney } from "services/utility/formatting";
import { addStripeScript } from "services/utility/payments";

import { AnnualBilling } from "views/AccountInformation/AnnualBilling";
import { CurrentCharges } from "views/AccountInformation/CurrentCharges";
import { PaymentMethod } from "views/AccountInformation/PaymentMethod";
import { PlanEdit } from "views/AccountInformation/PlanEdit";
import { RemovePaymentInfo } from "views/AccountInformation/RemovePaymentInfo";

import {
  loadingIndicatorOn,
  loadingIndicatorOff,
} from "globalState/loadingSlice";

const LABEL_SX = { fontWeight: "bold" };

export function AccountInformation() {
  const [accountInformation, setAccountInformation] = useState();
  const [paymentHistory, setPaymentHistory] = useState();
  const [paymentMethodOpen, setPaymentMethodOpen] = useState(false);
  const [planEditOpen, setPlanEditOpen] = useState(false);

  const dispatch = useDispatch();

  useEffect(() => addStripeScript(), []);

  useEffect(() => {
    async function _getAccountInformation() {
      dispatch(loadingIndicatorOn());
      const response = await getSingleton("company/accountInfo");
      if (response) {
        setAccountInformation(response);
      }
      const { success, data } = await getPaymentHistory();
      if (success) {
        setPaymentHistory(data);
      }
      dispatch(loadingIndicatorOff());
    }
    _getAccountInformation();
  }, [dispatch]);

  const {
    accountNumber,
    expiration,
    paymentMethod,
    plan,
    subscription,
    billableUsers,
    billableUsersCharge,
    billableIntegrations,
    billableIntegrationsCharge,
    billableSmsUsers,
    billableSmsUsersCharge,
    discount,
    salesTax,
    annualPlan,
    isTrial,
    isSubscriber,
  } = accountInformation || {};

  async function retrievePaymentPdf(paymentId, paymentDate) {
    dispatch(loadingIndicatorOn());
    const pdfBlob = await getPaymentPdf(paymentId);
    downloadDocument(pdfBlob, paymentDate);
    dispatch(loadingIndicatorOff());
  }

  if (!accountInformation) {
    return null;
  }

  return (
    <Box sx={{ display: "grid" }} m={2} mb={0}>
      <Box mb={1}>
        <Typography variant="h2" sx={{ mb: 2 }}>
          {i18n("topNav.companyMenu.accountInformation")}
        </Typography>
        <Box
          sx={{ display: "grid", gridTemplateColumns: "13em 1fr", rowGap: 1 }}
        >
          <Typography component="div" sx={LABEL_SX}>
            {i18n("global.AccountNumber")}
          </Typography>
          <Typography component="div">{accountNumber}</Typography>
          <Typography component="div" sx={LABEL_SX}>
            {i18n("accountInformation.subscriptionExpiration")}
          </Typography>
          <Typography component="div">
            {formatDateTimeToDate(isoToLocalDateTime(expiration))}
          </Typography>
          <Typography component="div" sx={LABEL_SX}>
            {i18n("accountInformation.paymentMethod")}
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography sx={{ pt: 0.5 }} component="div">
              {paymentMethod}
            </Typography>
            <Tooltip title={i18n("accountInformation.EditPaymentInformation")}>
              <div>
                <EditIconButton onClick={() => setPaymentMethodOpen(true)} />
              </div>
            </Tooltip>
          </Box>
          {isSubscriber && (
            <>
              <Typography component="div" sx={LABEL_SX}>
                {i18n("accountInformation.Cancellation")}
              </Typography>
              <RemovePaymentInfo
                setAccountInformation={setAccountInformation}
              />
            </>
          )}
          <Typography component="div" sx={LABEL_SX}>
            {i18n("accountInformation.plan")}
          </Typography>
          <Box sx={{ display: "flex", gap: 1 }}>
            <Typography sx={{ pt: 0.5 }} component="div">
              {plan.name}
            </Typography>
            <Tooltip title={i18n("accountInformation.ChangePlans")}>
              <div>
                <EditIconButton onClick={() => setPlanEditOpen(true)} />
              </div>
            </Tooltip>
          </Box>
          <Typography component="div" sx={LABEL_SX}>
            {i18n("accountInformation.currentCharges")}
          </Typography>
          {!isTrial && isSubscriber && (
            <CurrentCharges
              subscription={subscription}
              billableUsers={billableUsers}
              billableUsersCharge={billableUsersCharge}
              billableIntegrations={billableIntegrations}
              billableIntegrationsCharge={billableIntegrationsCharge}
              billableSmsUsers={billableSmsUsers}
              billableSmsUsersCharge={billableSmsUsersCharge}
              salesTax={salesTax}
              discount={discount}
            />
          )}
          {isTrial && (
            <Typography component="div">
              {formatMoney(Money.ZERO, true) + " (free trial)"}
            </Typography>
          )}
          {!isSubscriber && !isTrial && (
            <Typography component="div">
              {formatMoney(Money.ZERO, true) + " (not subscribed)"}
            </Typography>
          )}

          <Typography component="div" sx={LABEL_SX}>
            {i18n("accountInformation.annualBilling")}
          </Typography>
          <AnnualBilling
            annualPlan={annualPlan}
            setAccountInformation={setAccountInformation}
          />
          <Typography component="div" sx={LABEL_SX}>
            {i18n("accountInformation.paymentHistory")}
          </Typography>
          {paymentHistory ? (
            <Box sx={{ display: "flex", flexDirection: "column" }}>
              {paymentHistory.map((payment, i) => (
                <LinkText key={i}>
                  <Box
                    key={payment.id}
                    sx={{ display: "flex", gap: 1 }}
                    onClick={() =>
                      retrievePaymentPdf(payment.id, formatDate(payment.date))
                    }
                  >
                    <Box>{formatDate(payment.date)}</Box>
                    <Box>{formatMoney(payment.amount, true)}</Box>
                  </Box>
                </LinkText>
              ))}
            </Box>
          ) : (
            <Box />
          )}
          <Typography component="div" sx={LABEL_SX}>
            <ExternalLink
              href="https://www.sosinventory.com/terms-of-service"
              target="_blank"
            >
              {i18n("login.Terms")}
            </ExternalLink>
          </Typography>
        </Box>
      </Box>
      {paymentMethodOpen && (
        <PaymentMethod
          close={() => setPaymentMethodOpen(false)}
          setAccountInformation={setAccountInformation}
        />
      )}
      {planEditOpen && (
        <PlanEdit
          annualPlan={annualPlan}
          onClose={() => setPlanEditOpen(false)}
          currentPlan={accountInformation.plan}
          setAccountInformation={setAccountInformation}
        />
      )}
    </Box>
  );
}

function EditIconButton(props) {
  const { onClick } = props;
  return (
    <IconButton border={1} size="small" onClick={onClick}>
      <Edit />
    </IconButton>
  );
}
