import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { get } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

import { usePlans } from "hooks/usePlans";

export function useWorkers() {
  const [workers, setWorkers] = useState(null);
  const trackWorkers = useSelector(
    (state) => state.userCompanySettings.settings.trackWorkers
  );

  const { hasAtLeastProPlan } = usePlans();

  useEffect(() => {
    async function retrieveWorkers() {
      const response = await get("worker", { inTransaction: true });
      if (response.success) {
        const workers = response.data.records.map((worker) => {
          const { firstName, lastName } = worker;
          return { ...worker, name: `${firstName} ${lastName}` };
        });
        setWorkers(workers);
      } else {
        handleProgramError(
          new Error(
            `useWorkers | unsuccessful call to getAll, message: ${response.message}`
          )
        );
      }
    }

    // only send request if account has worker tracking enabled and Pro account
    if (trackWorkers && hasAtLeastProPlan) {
      retrieveWorkers();
    } else {
      setWorkers([]);
    }
  }, [trackWorkers, hasAtLeastProPlan]);

  return workers;
}
