import { useSelector } from "react-redux";

import {
  Table,
  TableHead,
  TableFooter,
  TableRow,
  TableBody,
} from "@mui/material";

import { AddLines } from "components/EditPage/AddLines";
import { ClearLines } from "components/EditPage/ClearLines";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";

import { EMPTY_LINE } from "services/sosInventoryService/document/schema";
import { textForAddLineOrLines } from "services/utility/lineItems";

import { LinkRow } from "views/Documents/Document/LinkRow";

import { getObjectFromTypeString } from "appConstants";

export function LinkTable(props) {
  const { lines, objectLists, addObjectList, setRecord, errors } = props;

  function clearLines() {
    const lines = new Array(numLinesToAdd).fill(EMPTY_LINE);
    setRecord((prevRecord) => ({ ...prevRecord, lines }));
  }

  function appendRows() {
    const emptyLines = new Array(numLinesToAdd).fill(EMPTY_LINE);
    const newLines = [...lines, ...emptyLines];
    setRecord((prevRecord) => ({ ...prevRecord, lines: newLines }));
  }

  function deleteRow(rowIndex) {
    setRecord((prevRecord) => ({
      ...prevRecord,
      lines: prevRecord.lines.filter((_, index) => index !== rowIndex),
    }));
  }
  function handleRowTypeChange(_, value, rowIndex) {
    const { fullString } = getObjectFromTypeString(value.id);
    addObjectList(fullString);
    setRecord((prevRecord) => ({
      ...prevRecord,
      lines: prevRecord.lines.map((line, index) =>
        index === rowIndex
          ? { ...line, type: value.id, id: null, name: null }
          : line
      ),
    }));
  }

  function handleRowSelectionChange(value, _, rowIndex) {
    const { id = null, name = null } = value || {};
    setRecord((prevRecord) => ({
      ...prevRecord,
      lines: prevRecord.lines.map((line, index) =>
        index === rowIndex ? { ...line, id, name } : line
      ),
    }));
  }

  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  return (
    <Table size="small" padding="none" stickyHeader>
      <TableHead>
        <TableRow>
          <ClearLines setClearLines={clearLines} />
          <LineColumnHeading labelCode="Type" />
          <LineColumnHeading labelCode="Selection" />
        </TableRow>
      </TableHead>
      <TableBody>
        {lines.map((row, i) => (
          <LinkRow
            key={`${row.type}-${i}`}
            rowIndex={i}
            row={row}
            handleRowTypeChange={handleRowTypeChange}
            handleRowSelectionChange={handleRowSelectionChange}
            deleteRow={() => deleteRow(i)}
            setRecord={setRecord}
            objectLists={objectLists}
            lineErrors={errors.line}
          />
        ))}
      </TableBody>
      <TableFooter>
        <TableRow>
          <AddLines
            title={textForAddLineOrLines(numLinesToAdd)}
            onClick={appendRows}
          />
        </TableRow>
      </TableFooter>
    </Table>
  );
}
