import { i18n } from "services/i18nService";

export const ExportVendors = {
  showAddImportColumnsButton: true,
  userHasSufficientPrivilege: () => true,
  planIncludesReport: () => true,
  customReportNameFetchList: [],
  customFieldObjectTypes: ["vendor"],
  columns: [
    { apiName: "VendorId", label: "Vendor #" },
    { apiName: "Name", default: true },
    { apiName: "CompanyName", label: "Company" },
    { apiName: "Salutation" },
    { apiName: "FirstName", label: "First Name" },
    { apiName: "MiddleName", label: "Middle Name" },
    { apiName: "LastName", label: "Last Name" },
    { apiName: "Suffix" },
    { apiName: "VendorAddressLine1", label: "Address Line 1" },
    { apiName: "VendorAddressLine2", label: "Address Line 2" },
    { apiName: "VendorAddressLine3", label: "Address Line 3" },
    { apiName: "VendorAddressLine4", label: "Address Line 4" },
    { apiName: "VendorAddressLine5", label: "Address Line 5" },
    { apiName: "VendorAddressCity", label: "Address City" },
    { apiName: "VendorAddressState", label: "Address State" },
    { apiName: "VendorAddressPostalCode", label: "Address Postal Code" },
    { apiName: "VendorAddressCountry", label: "Address Country" },
    { apiName: "NameOnCheck", label: "Name on Check" },
    { apiName: "Phone" },
    { apiName: "Mobile" },
    { apiName: "Pager" },
    { apiName: "AltPhone" },
    { apiName: "Fax" },
    { apiName: "Email" },
    { apiName: "AccountNumber", label: "Account Number" },
    { apiName: "VendorTaxIdent", label: "Vendor Tax ID", nonImport: true },
    { apiName: "IsVendorEligibleFor1099", label: "1099 Vendor?" },
    { apiName: "Deleted" },
    { apiName: "WebSiteURI", label: "Website" },
    { apiName: "Notes" },
    { apiName: "ShowOnForms", label: "Show On Forms" },
    { apiName: "SyncMessage", label: "Sync Message", nonImport: true },
    { apiName: "Archived" },
    { apiName: "Starred", number: true, nonImport: true },
    { apiName: "Terms" },
    { apiName: "TaxCode", label: "Tax Code" },
    { apiName: "Currency" },
  ],
  settings: [
    {
      label: i18n("reports.FilterLabel.VendorNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedVendors"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showDeleted",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeDeletedVendors"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
