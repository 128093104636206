import { FrmSelectScalar } from "components/formFields/FrmSelectScalar";

import { i18n } from "services/i18nService";

const STATUS = ["Pending", "Accepted", "Rejected", "Closed", "Converted"];

export function FrmStatus(props) {
  const { value, onValueChange, maxWidth } = props;

  return (
    <FrmSelectScalar
      name="status"
      label={i18n("frmLabel.Status")}
      value={value}
      onValueChange={onValueChange}
      options={STATUS}
      maxWidth={maxWidth}
      disableClearable
    />
  );
}
