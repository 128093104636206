import { i18n } from "services/i18nService";

import {
  EXPIRED_RECALLED_OPTIONS,
  ALL_OPTION_WITH_ALL_ID,
} from "views/Reports/reportConstants";

export const ExportLots = {
  userHasSufficientPrivilege: () => true,
  planIncludesReport: (plus) => plus,
  customReportNameFetchList: [],
  customFieldObjectTypes: ["lot"],
  columns: [
    { apiName: "LotNumber", default: true },
    { apiName: "Item", default: true },
    { apiName: "QuantityOnHand", label: "On Hand", number: true },
    { apiName: "Description", default: true },
    { apiName: "Expiration", default: true },
    { apiName: "Recalled" },
    { apiName: "History" },
    { apiName: "Archived" },
    { apiName: "Deleted" },
    { apiName: "Starred", number: true },
    { apiName: "OriginalDate", label: "Original Date" },
  ],
  settings: [
    {
      label: i18n("reports.FilterLabel.Category"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.KeywordSearch"),
      component: "FrmTextFieldWithHoverHelp",
      apiName: "nameContains",
      defaultValue: null,
      helpText: i18n("reports.hoverHelp.PurchaseKeywordSearch"),
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.LotNumberContains"),
      component: "FrmTextField",
      apiName: "nameContains2",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "txnType",
      defaultValue: ALL_OPTION_WITH_ALL_ID,
      label: "Status",
      component: "FrmSelectFromObjects",
      options: EXPIRED_RECALLED_OPTIONS,
      type: "filter",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedTransactions"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showDeleted",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeDeletedItems"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: true,
      component: "FrmCheckbox",
      label: i18n("reports.OptionLabel.HideEmptyLots"),
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
