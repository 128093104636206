import { useRef, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import { Delete } from "@mui/icons-material";
import { IconButton, MenuList, MenuItem, Typography, Box } from "@mui/material";

import { Divider } from "components/Divider";
import { DropDownMenu } from "components/TopNav/DropDownMenu";
import { TopNavIcon } from "components/TopNav/TopNavIcon";
import { Link } from "components/html/Link";
import { StyledBadge } from "components/utility/StyledBadge";

import { i18n } from "services/i18nService";
import {
  get,
  deleteRecords,
  SUCCESS,
} from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

import {
  setNotifications,
  deleteNotifications,
} from "globalState/notificationsSlice";

import { FETCH_NOTIFICATIONS_INTERVAL } from "appConstants";

const sx = {
  color: "primaryLink",
  justifyContent: "center",
  "&:hover": { backgroundColor: "transparent" },
  p: "6px 16px",
};

export function Notifications() {
  const notifications = useSelector((state) => state.notifications);
  const [anchor, setAnchor] = useState(null);
  const bellIconRef = useRef();
  const dispatch = useDispatch();

  useEffect(() => {
    async function getNotifications() {
      const response = await get("notification");
      if (response.success) {
        dispatch(setNotifications(response.data.records));
      } else {
        // do nothing if unsuccessful; this call often fails with a 401
        // on a page (re)load
        return;
      }
    }
    getNotifications();
    const interval = setInterval(
      () => getNotifications(),
      FETCH_NOTIFICATIONS_INTERVAL
    );
    return () => clearInterval(interval);
  }, [dispatch]);

  async function handleDelete(id) {
    const response = await deleteRecords("notification", [id]);
    if (response === SUCCESS) {
      dispatch(deleteNotifications([id]));
    } else {
      handleProgramError(new Error("Unable to delete notification"));
    }
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
    }

    setAnchor(null);
  }

  const openMenu = Boolean(anchor);
  const menuId = openMenu ? "settings-menu" : undefined;

  return (
    <>
      <div ref={bellIconRef}>
        <StyledBadge
          badgeContent={notifications.length}
          color="error"
          max={99}
          overlap="rectangular"
        >
          <TopNavIcon
            image="/images/notification_bell.svg"
            onClick={() => setAnchor(bellIconRef.current)}
            functionText={i18n("iconAltText.messages")}
          />
        </StyledBadge>
      </div>
      <DropDownMenu
        id={menuId}
        open={openMenu}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
        width={525}
      >
        <Box>
          <MenuList
            sx={{ pb: 0 }}
            id="notifications-list"
            onKeyDown={handleListKeyDown}
          >
            <MenuItem
              disableRipple
              sx={{
                display: "flex",
                cursor: "default",
                backgroundColor: "transparent",
              }}
            >
              <Typography variant="h4">
                {i18n("objectType.notification.SentencePlural")}
              </Typography>
            </MenuItem>
            <Divider />
            {notifications.map(({ message, id }, index) => {
              return (
                index <= 10 && (
                  <div style={{ maxWidth: "100%" }} key={index}>
                    <MenuItem
                      disableRipple
                      sx={{
                        display: "flex",
                        cursor: "default",
                        backgroundColor: "transparent",
                        justifyContent: "space-between",
                      }}
                    >
                      <div
                        style={{
                          overflow: "hidden",
                          whiteSpace: "nowrap",
                          textOverflow: "ellipsis",
                        }}
                      >
                        {message}
                      </div>
                      <IconButton
                        onClick={() => handleDelete(id)}
                        sx={{ marginLeft: "1rem" }}
                        size="small"
                      >
                        <Delete sx={{ color: "selectIcon" }} />
                      </IconButton>
                    </MenuItem>
                    <Divider />
                  </div>
                )
              );
            })}
          </MenuList>
          {notifications.length ? (
            <Link
              to="/notification"
              sx={{ textAlign: "center" }}
              onClick={() => setAnchor(null)}
            >
              <Box sx={sx}>{i18n("topNav.notificationMenu.SeeAll")}</Box>
            </Link>
          ) : (
            <Typography sx={{ textAlign: "center", p: 1 }}>
              {i18n("topNav.notificationMenu.NoNotifications")}
            </Typography>
          )}
        </Box>
      </DropDownMenu>
    </>
  );
}
