import { useEffect, useMemo } from "react";
import { useDispatch } from "react-redux";

import { Box, Dialog } from "@mui/material";

import { FrmFieldGrid } from "components/FrmFieldGrid";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmSelectFromObjectsWithHoverHelp } from "components/formFields/FrmSelectFromObjectsWithHoverHelp";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmTextFieldWithHoverHelp } from "components/formFields/FrmTextFieldWithHoverHelp";
import { FrmCheckboxWithHoverHelp } from "components/formFields/frm/FrmCheckboxWithHoverHelp";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { ModalHeader } from "components/utility/ModalHeader";

import { i18n } from "services/i18nService";
import { isValid } from "services/sosInventoryService/settings/user/general/isValid";
import {
  DATE_FORMAT_OPTIONS,
  NOTIFICATION_METHOD_OPTIONS,
  PASSWORD_QUESTIONS_OPTIONS,
  MOBILE_CARRIER_OPTIONS,
} from "services/sosInventoryService/settings/user/general/schema";
import {
  getSettingsValues,
  updateSettingsValues,
} from "services/sosInventoryService/sosApi";
import { getTimeZoneOptions } from "services/utility/dates";
import { setPageDirty } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";

import { useErrors } from "hooks/useErrors";
import { useRecord } from "hooks/useRecord";

import { openAlert } from "globalState/alertSlice";

export function UserSettings(props) {
  const { onClose } = props;
  const [userSettings, setUserSettings, handleInputFieldChange] = useRecord();

  const dispatch = useDispatch();
  const { errors, setErrors, isInError } = useErrors();

  const timeZoneOptions = useMemo(() => getTimeZoneOptions(), []);

  useEffect(() => {
    async function _getSettingsValues() {
      const response = await getSettingsValues("user", "general");
      if (response) {
        setUserSettings(response);
      }
    }
    _getSettingsValues();
  }, [setUserSettings]);

  async function onSave() {
    const errors = isValid(userSettings);
    if (errors.messages.length > 0) {
      setErrors(errors);
      return;
    }

    const { success, message } = await updateSettingsValues(
      "user",
      "general",
      userSettings
    );
    if (success) {
      setPageDirty(false);
      dispatch(openAlert({ type: "success", message: i18n("global.Saved") }));
      onClose();
    } else {
      const errorMessage = message || i18n("settings.UnableToUpdateSettings");
      handleProgramError(new Error(errorMessage));
    }
  }

  return (
    <Dialog
      open
      onClose={onClose}
      maxWidth="lg"
      sx={{ display: "flex", flexDirection: "column" }}
    >
      <ModalHeader
        label={i18n("settings.UserSettings.label")}
        onClose={onClose}
        save={onSave}
        hidePrint
      />

      {userSettings ? null : <Loading />}
      {userSettings && (
        <Box sx={{ height: "100%", overflowY: "auto" }}>
          <Box m={2}>
            <FormErrors errors={errors} setErrors={setErrors} />
            <FrmFieldGrid gridAutoFlow="dense">
              <FrmSelectFromObjects
                name="dateFormat"
                options={DATE_FORMAT_OPTIONS}
                label={i18n("settings.user.general.dateFormat.label")}
                onValueChange={handleInputFieldChange}
                value={userSettings.dateFormat}
                disableClearable
                sx={{ maxWidth: "16rem" }}
              />
              <FrmSelectFromObjectsWithHoverHelp
                selectProps={{
                  name: "timeZone",
                  options: timeZoneOptions,
                  label: i18n("settings.user.general.timeZone.label"),
                  onValueChange: handleInputFieldChange,
                  value: userSettings.timeZone,
                  disableClearable: true,
                  minWidth: "16rem",
                }}
                helpText={i18n("settings.user.general.timeZone.explanation")}
              />
              <FrmSelectFromObjectsWithHoverHelp
                selectProps={{
                  name: "notificationMethod",
                  options: NOTIFICATION_METHOD_OPTIONS,
                  label: i18n("settings.user.general.notificationMethod.label"),
                  onValueChange: handleInputFieldChange,
                  value: userSettings.notificationMethod,
                  minWidth: "16rem",
                  disableClearable: true,
                }}
                helpText={i18n(
                  "settings.user.general.notificationMethod.explanation"
                )}
              />
              <FrmTextFieldWithHoverHelp
                name="name"
                label={i18n("settings.user.general.name.label")}
                onValueBlur={handleInputFieldChange}
                value={userSettings.name}
                helpText={i18n("settings.user.general.name.explanation")}
                maxLength={50}
                error={isInError("name")}
              />
              <FrmTextFieldWithHoverHelp
                name="email"
                label={i18n("settings.user.general.email.label")}
                onValueBlur={handleInputFieldChange}
                value={userSettings.email}
                helpText={i18n("settings.user.general.email.explanation")}
                maxLength={100}
                error={isInError("email")}
              />
              <FrmTextField
                name="confirmEmail"
                label={i18n("settings.user.general.confirmEmail.label")}
                onValueBlur={handleInputFieldChange}
                value={userSettings.confirmEmail}
                maxLength={100}
                error={isInError("confirmEmail")}
              />
              <FrmSelectFromObjectsWithHoverHelp
                selectProps={{
                  name: "passwordQuestion",
                  options: PASSWORD_QUESTIONS_OPTIONS,
                  label: i18n("settings.user.general.passwordQuestion.label"),
                  onValueChange: handleInputFieldChange,
                  value: userSettings.passwordQuestion,
                  disableClearable: true,
                  sx: { width: "100%" },
                }}
                helpText={i18n(
                  "settings.user.general.passwordQuestion.explanation"
                )}
              />
              <FrmTextFieldWithHoverHelp
                name="passwordAnswer"
                label={i18n("settings.user.general.passwordAnswer.label")}
                onValueBlur={handleInputFieldChange}
                value={userSettings.passwordAnswer}
                helpText={i18n(
                  "settings.user.general.passwordAnswer.explanation"
                )}
                maxLength={100}
              />
              <FrmCheckboxWithHoverHelp
                name="smsEnabled"
                value={userSettings.smsEnabled}
                onValueChange={handleInputFieldChange}
                label={i18n("settings.user.general.smsEnabled.label")}
                helpText={i18n("settings.user.general.smsEnabled.explanation")}
              />
              {userSettings.smsEnabled && (
                <>
                  <FrmSelectFromObjects
                    name="mobileCarrier"
                    options={MOBILE_CARRIER_OPTIONS}
                    label={i18n("settings.user.general.mobileCarrier.label")}
                    onValueChange={handleInputFieldChange}
                    value={userSettings.mobileCarrier}
                    minWidth="24rem"
                    disableClearable={true}
                  />
                  <FrmTextField
                    name="mobileNumber"
                    label={i18n("settings.user.general.mobileNumber.label")}
                    onValueBlur={handleInputFieldChange}
                    value={userSettings.mobileNumber}
                    maxLength={21}
                  />
                </>
              )}
            </FrmFieldGrid>
          </Box>
        </Box>
      )}
    </Dialog>
  );
}
