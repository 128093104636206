import { useState } from "react";
import { useSelector } from "react-redux";

import Delete from "@mui/icons-material/Delete";

import { ITEM_CALCULATED_FIELDS } from "appConfig";

import { Decimal, Money } from "classes/DecimalClasses";
import { Loading as LoadingClass } from "classes/Loading";

import { QuickAddItem } from "components/QuickAddItem";
import { LineTableCell } from "components/formFields/LineTableCell";
import { LineAmount } from "components/formFields/line/LineAmount";
import { LineAvailable } from "components/formFields/line/LineAvailable";
import { LineClass } from "components/formFields/line/LineClass";
import { LineCost } from "components/formFields/line/LineCost";
import { LineDescription } from "components/formFields/line/LineDescription";
import { LineDiscount } from "components/formFields/line/LineDiscount";
import { LineDueDate } from "components/formFields/line/LineDueDate";
import { LineInventoryItem } from "components/formFields/line/LineInventoryItem";
import { LineInvoiced } from "components/formFields/line/LineInvoiced";
import { LineJobWorkcenter } from "components/formFields/line/LineJobWorkcenter";
import { LineListPrice } from "components/formFields/line/LineListPrice";
import { LineMargin } from "components/formFields/line/LineMargin";
import { LinePicked } from "components/formFields/line/LinePicked";
import { LineQuantity } from "components/formFields/line/LineQuantity";
import { LineShipped } from "components/formFields/line/LineShipped";
import { LineTax } from "components/formFields/line/LineTax";
import { LineTaxCode } from "components/formFields/line/LineTaxCode";
import { LineUnitprice } from "components/formFields/line/LineUnitprice";
import { LineUom } from "components/formFields/line/LineUom";
import { LineVolumeAndVolumeUnit } from "components/formFields/line/LineVolumeAndVolumeUnit";
import { LineWeightAndWeightUnit } from "components/formFields/line/LineWeightAndWeightUnit";

import {
  getItemRecord,
  clearItemDataFromLine,
} from "services/sosInventoryService/domainLogic";
import {
  createLineFromItem,
  postItemAndCreateLineItem,
} from "services/sosInventoryService/domainLogic";
import { getEmptyRecord as getEmptyItemRecord } from "services/sosInventoryService/item/schema";
import { afterTouchLine } from "services/sosInventoryService/salesTransaction/afterTouchLine";
import { updateLineWithItem } from "services/sosInventoryService/salesTransaction/domainLogic";
import {
  onHandleUomChange,
  onHandleQuantityChange,
} from "services/sosInventoryService/salesTransaction/domainLogic";
import { setPageDirty } from "services/utility/edit";
import { isLineFieldInError } from "services/utility/errors";
import { TYPES } from "services/utility/schema";

import { usePrivileges } from "hooks/usePrivileges";

import { theme } from "SosTheme";
import {
  getSalesDecimalPlaces,
  ITEM_QUICKLIST_FORM_TYPES,
  USER_PRIVILEGE,
  MAX_CHARS_LINE_ITEM_DESCRIPTION,
} from "appConstants";
import { LINE_ITEM_FIELDS, EMPTY_LINE_ITEM } from "editConfig";

// created this wrapper to make drag and drop code easier to see
export function LineItem(props) {
  return <LineItemDetails {...props} />;
}

function LineItemDetails(props) {
  const {
    record,
    customer,
    objectType,
    line,
    lineHandler,
    expandItemGroup,
    items,
    itemsCount,
    addItem,
    taxCodes,
    classes,
    jobWorkcenters,
    lineItemsErrors: errors,
    setErrors,
    showAvailable,
    showPicked,
    showInvoiced,
    showShipped,
    showDueDate,
  } = props;
  const { location, date } = record;

  const [addNewItem, setAddNewItem] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [lineLoading, setLineLoading] = useState(false);

  // SETTINGS
  const roundSalesPrices = useSelector(
    (state) => state.userCompanySettings.settings.roundSalesPrices
  );
  const showVolumeOnSales = useSelector(
    (state) => state.userCompanySettings.settings.showVolumeOnSales
  );
  const showWeightOnSales = useSelector(
    (state) => state.userCompanySettings.settings.showWeightOnSales
  );
  const lineItemDiscountsEnabled = useSelector(
    (state) => state.userCompanySettings.settings.lineItemDiscountsEnabled
  );
  const defaultAssetAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultAssetAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultCOGSAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultCOGSAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultExpenseAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultExpenseAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultIncomeAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultIncomeAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultTaxCode = useSelector(
    (state) => state.userCompanySettings.settings.defaultTaxCode,
    (prev, curr) => curr?.id === prev?.id
  );

  const marginPricingEnabled = useSelector(
    (state) => state.userCompanySettings.settings.marginPricingEnabled
  );

  const { hasPrivilegesOrIsAdmin } = usePrivileges();
  const viewCosts = hasPrivilegesOrIsAdmin(USER_PRIVILEGE.viewCosts);
  const allowPriceChange = hasPrivilegesOrIsAdmin(
    USER_PRIVILEGE.canChangePricingOnSales
  );

  function isInError(field) {
    return isLineFieldInError(field, line.lineNumber, errors);
  }

  function handleValueChange(fieldName, newValue) {
    let newLine;
    if (LINE_ITEM_FIELDS[objectType][TYPES.reference].includes(fieldName)) {
      newLine = { ...line, [fieldName]: { id: newValue } };
    } else {
      newLine = { ...line, [fieldName]: newValue };
    }
    setPageDirty();
    lineHandler({
      type: "update",
      updatedLine: afterTouchLine(newLine, fieldName),
    });
  }

  async function handleItemChange(newItem, userInput) {
    if (!newItem) {
      const updatedLine = clearItemDataFromLine(line);
      setPageDirty();
      lineHandler({ type: "update", updatedLine });
      return;
    }
    if (newItem?.id === "add") {
      return openAddItem(userInput);
    }
    setLineLoading(true);

    // only need available data if the "Available" column
    // is set to be shown in the company settings
    const itemFields = showAvailable
      ? ITEM_CALCULATED_FIELDS[objectType]
      : ITEM_CALCULATED_FIELDS[objectType].filter(
          (field) => field !== "available"
        );

    const item = await getItemRecord(
      newItem.id,
      location?.id,
      date,
      itemFields
    );
    const newLine = await updateLineWithItem(item, line, record);
    setPageDirty();
    lineHandler({
      type: "update",
      updatedLine: afterTouchLine(newLine, "item"),
    });
    setLineLoading(false);
  }

  async function fetchAvailable() {
    const loadingAvailableLine = { ...line, available: new LoadingClass() };
    lineHandler({ type: "update", updatedLine: loadingAvailableLine });
    const item = await getItemRecord(line.item.id, location?.id, date, [
      "available",
    ]);
    const newLine = { ...line, relatedRecords: { item } };
    setPageDirty();
    lineHandler({
      type: "update",
      updatedLine: afterTouchLine(newLine, "available"),
    });
  }

  function handleUomChange(_, uom) {
    onHandleUomChange(uom, record, line, lineHandler);
  }

  function handleTaxCheck(fieldName, newValue) {
    const newLine = {
      ...line,
      [fieldName]: { ...line.taxable, taxable: newValue },
    };
    setPageDirty();
    lineHandler({
      type: "update",
      updatedLine: afterTouchLine(newLine, fieldName),
    });
  }

  function handleDecimalChange(fieldName, newValue) {
    const value = new Decimal(newValue || 0);
    handleNumberChange(fieldName, value);
  }

  function handleMoneyChange(fieldName, newValue) {
    const value = new Money(newValue || 0);
    handleNumberChange(fieldName, value);
  }

  function handleNumberChange(fieldName, newValue) {
    const newLine = { ...line, [fieldName]: newValue };
    setPageDirty();
    lineHandler({
      type: "update",
      updatedLine: afterTouchLine(newLine, fieldName, record),
    });
  }

  async function handleQuantityChange(_, newValue) {
    const quantity = new Decimal(newValue || 0);
    onHandleQuantityChange(quantity, record, line, lineHandler);
  }

  function handleUnitPriceChange(_, newValue) {
    const unitprice = new Money(newValue || 0).round(
      getSalesDecimalPlaces(roundSalesPrices)
    );
    const newLine = { ...line, unitprice };
    setPageDirty();
    lineHandler({
      type: "update",
      updatedLine: afterTouchLine(newLine, "unitprice"),
    });
  }

  function handleReferenceChange(fieldName, newValue) {
    const newLine = { ...line, [fieldName]: newValue };
    setPageDirty();
    lineHandler({
      type: "update",
      updatedLine: afterTouchLine(newLine, fieldName),
    });
  }

  async function onQuickAddItem(name) {
    const newItemSettings = {
      defaultCOGSAccount,
      defaultAssetAccount,
      defaultExpenseAccount,
      defaultIncomeAccount,
      defaultTaxCode,
    };
    const item = { ...getEmptyItemRecord(newItemSettings), name };
    const updatedLine = await postItemAndCreateLineItem(
      item,
      EMPTY_LINE_ITEM[objectType],
      line.lineNumber,
      addItem,
      setErrors
    );
    setPageDirty();
    lineHandler({ type: "update", updatedLine });
    setAddNewItem(false);
  }

  function onAddItem(item) {
    setAddNewItem(false);
    const updatedLine = createLineFromItem(
      item,
      EMPTY_LINE_ITEM[objectType],
      line.lineNumber,
      lineHandler
    );
    setPageDirty();
    lineHandler({ type: "update", updatedLine });
    addItem();
  }

  function handleDelete() {
    setPageDirty();
    lineHandler({ type: "delete", deleteAt: line.lineNumber });
  }

  function openAddItem(userInput) {
    // handle "Add new item" in Item select input field; this does not change the
    // line item, so no need to proceed further into the handler
    setNewItemName(userInput);
    setAddNewItem(true);
  }

  if (!line) {
    return null;
  }

  return (
    <>
      <LineTableCell>
        <div
          onClick={handleDelete}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
            color: theme.palette.icons,
          }}
        >
          <Delete />
        </div>
      </LineTableCell>
      <LineTableCell>
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
          }}
        >
          {line.lineNumber}
        </div>
      </LineTableCell>
      <LineTableCell sx={{ minWidth: "13rem" }}>
        <LineInventoryItem
          lineLoading={lineLoading}
          itemFormType={ITEM_QUICKLIST_FORM_TYPES.SALES}
          line={line}
          items={items}
          itemsCount={itemsCount}
          addItem={addItem}
          onValueChange={handleItemChange}
          allowExpand
          expandItemGroup={expandItemGroup}
          error={isInError("item")}
        />
      </LineTableCell>
      <LineTableCell sx={{ minWidth: "24rem" }}>
        <LineDescription
          value={line.description}
          onValueChange={handleValueChange}
          error={isInError("description")}
          maxLength={MAX_CHARS_LINE_ITEM_DESCRIPTION}
        />
      </LineTableCell>
      {showAvailable && (
        <LineAvailable value={line.available} fetchAvailable={fetchAvailable} />
      )}
      <LineQuantity
        objectType={objectType}
        value={line.quantity}
        onValueChange={handleQuantityChange}
        error={isInError("quantity")}
        disabled={lineLoading}
      />
      <LineUom
        value={line.uom}
        onValueChange={handleUomChange}
        itemUoms={line.itemDetails?.itemUoms}
        error={isInError("uom")}
      />
      {showWeightOnSales && (
        <LineWeightAndWeightUnit
          weight={line.weight}
          weightunit={line.weightunit}
        />
      )}
      {showVolumeOnSales && (
        <LineVolumeAndVolumeUnit
          volume={line.volume}
          volumeunit={line.volumeunit}
        />
      )}
      {lineItemDiscountsEnabled && (
        <LineListPrice
          objectType={objectType}
          onValueChange={handleMoneyChange}
          value={line.listPrice}
        />
      )}
      <LineDiscount
        objectType={objectType}
        onValueChange={handleDecimalChange}
        percentDiscount={line.percentdiscount}
        readOnly={!allowPriceChange}
      />
      {marginPricingEnabled && viewCosts && (
        <>
          <LineCost cost={line.cost} />
          <LineMargin
            objectType={objectType}
            onValueChange={handleDecimalChange}
            margin={line.margin}
          />
        </>
      )}
      <LineUnitprice
        value={line.unitprice}
        objectType={objectType}
        onValueChange={handleUnitPriceChange}
        readOnly={!allowPriceChange}
        error={isInError("unitprice")}
      />
      <LineAmount
        value={line.amount}
        objectType={objectType}
        onValueChange={handleMoneyChange}
        readOnly={!allowPriceChange}
        error={isInError("amount")}
      />
      {showPicked && (
        <LinePicked
          value={line.picked}
          objectType={objectType}
          onValueChange={handleDecimalChange}
          error={isInError("picked")}
        />
      )}
      {showShipped && (
        <LineShipped
          value={line.shipped}
          objectType={objectType}
          onValueChange={handleDecimalChange}
          error={isInError("shipped")}
        />
      )}
      {showInvoiced && (
        <LineInvoiced
          value={line.invoiced}
          objectType={objectType}
          onValueChange={handleDecimalChange}
          error={isInError("invoiced")}
        />
      )}
      {showDueDate && (
        <LineDueDate
          value={line.duedate}
          objectType={objectType}
          onValueChange={handleReferenceChange}
          error={isInError("dueDate")}
        />
      )}
      <LineClass
        value={line.class}
        classes={classes}
        onValueChange={handleReferenceChange}
      />
      <LineTax
        value={line.tax?.taxable}
        onValueChange={handleTaxCheck}
        customer={customer}
        error={isInError("tax")}
      />
      <LineTaxCode
        value={line.taxCode}
        taxCodes={taxCodes}
        onValueChange={handleReferenceChange}
        error={isInError("taxCode")}
      />
      <LineJobWorkcenter
        job={line.job}
        workcenter={line.workcenter}
        value={line.jobWorkcenter}
        jobWorkcenters={jobWorkcenters}
        onValueChange={handleReferenceChange}
      />
      {addNewItem && (
        <QuickAddItem
          open={addNewItem}
          initialNameValue={newItemName}
          onClose={() => setAddNewItem(false)}
          onAdd={onAddItem}
          onQuickAdd={onQuickAddItem}
        />
      )}
    </>
  );
}
