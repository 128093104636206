import { useState } from "react";

import { Box, InputAdornment } from "@mui/material";

import { FrmTextField } from "components/formFields/FrmTextField";

import { i18n } from "services/i18nService";

export function FrmTransactionNumber(props) {
  const { value, onValueChange, name, sx } = props;

  const [nextNumberError, setNextNumberError] = useState(false);

  function handleValueChange(fieldName, newValue) {
    onValueChange(name, { ...value, [fieldName]: newValue });
  }

  function handleNextNumberChange(fieldName, newValue) {
    if (/^\d*$/.test(newValue) && newValue >= 0) {
      handleValueChange(fieldName, newValue);
      setNextNumberError(false);
    } else {
      setNextNumberError(true);
    }
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "10fr 16fr",
        columnGap: 1,
        ...sx,
      }}
    >
      <FrmTextField
        name="prefix"
        label="Prefix"
        value={value.prefix}
        onValueChange={handleValueChange}
        maxLength={16}
      />
      <FrmTextField
        name="nextNumber"
        label="Next number"
        value={value.nextNumber}
        onValueBlur={handleNextNumberChange}
        maxLength={21}
        startAdornment={
          <InputAdornment
            position="start"
            disableTypography
            sx={{ lineHeight: "normal", color: "disabled", marginRight: 0 }}
          >
            {value.prefix}
          </InputAdornment>
        }
        error={nextNumberError}
        helperText={
          nextNumberError ? i18n("settings.nextNumberHelperText") : ""
        }
      />
    </Box>
  );
}
