// serials from the serial endpoint have a *number* property;
// serial values in the transaction record are standard reference
// objects, with a *name* property; these two properties, though
// named differently, are in fact the real-world serial number
import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid2,
  Typography,
  TextField,
} from "@mui/material";

import { SERIAL_ENTRY_LIMIT } from "appConfig";

import { Decimal } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

import { FrmTextField } from "components/formFields/FrmTextField";
import { Upload } from "components/formFields/Serials/SerialsAggregator/Upload";
import { Chipper } from "components/utility/Chipper";

import { i18n } from "services/i18nService";

import { openAlert } from "globalState/alertSlice";

import { theme } from "SosTheme";

const sx = { "& .MuiDialog-paper": { minHeight: "50vh", maxHeight: "93vh" } };

export function SerialsSelect(props) {
  const {
    serials,
    enumeratorOpen,
    onValueChange,
    onClose,
    value: externalValue,
    quantity,
  } = props;

  const [availableSerials, setAvailableSerials] = useState([]);
  const [uploadOpen, setUploadOpen] = useState(false);
  const [value, setValue] = useState(externalValue);
  const [filter, setFilter] = useState("");

  const dispatch = useDispatch();

  const canAddMore = value.length < quantity;

  useEffect(() => {
    setAvailableSerials(
      serials.filter(({ number }) => !value.some(({ name }) => name === number))
    );
  }, [serials, value]);

  function done() {
    onValueChange("serials", value);
    onClose();
  }

  function selectSerial(serial) {
    if (!canAddMore) {
      return;
    }
    const { id, number } = serial;
    const newValue = [{ id, name: number }, ...value];
    setValue(newValue);
  }

  function removeFromSelected(removedSerialName) {
    setValue(value.filter(({ name }) => name !== removedSerialName));
  }

  function getFilteredSerials() {
    const filteredSerials = availableSerials.filter(
      ({ number }) => number.toLowerCase().indexOf(filter.toLowerCase()) >= 0
    );
    return filteredSerials.sort((a, b) => (a.number < b.number ? -1 : 1));
  }

  function handleUploadedSerials(uploadedSerials) {
    //match against available serials
    let newSerials = [...value];
    let allUploadedMatched = true;
    uploadedSerials.forEach((uploadedSerial) => {
      const matchedSerial = availableSerials.find(
        (serial) => serial.number === uploadedSerial
      );
      if (matchedSerial) {
        newSerials.push({ id: matchedSerial.id, name: matchedSerial.number });
      } else {
        allUploadedMatched = false;
      }
    });
    setValue(newSerials);
    if (!allUploadedMatched) {
      const message = i18n("serials.CSVUploadInfo");
      dispatch(openAlert({ type: "info", message }));
    }
  }

  if (!serials) {
    return null;
  }

  const largeQuantity =
    !(quantity instanceof Loading) &&
    quantity.gt(new Decimal(SERIAL_ENTRY_LIMIT));

  return (
    <>
      <Dialog
        open={enumeratorOpen}
        onClose={onClose}
        fullWidth
        maxWidth="md"
        sx={sx}
      >
        <DialogTitle>
          {largeQuantity
            ? i18n("serials.Upload")
            : i18n("serials.SelectUpload")}
        </DialogTitle>

        <DialogContent>
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "repeat(2, 50%)",
              columnGap: "1rem",
              height: "100%",
            }}
          >
            <div>
              <Typography variant="h5">{i18n("serials.Available")}</Typography>
              <TextField
                fullWidth
                value={filter}
                onChange={({ target }) => setFilter(target.value)}
                margin="dense"
                variant="outlined"
                InputLabelProps={{ shrink: true }}
                disabled={!canAddMore}
              />
              <div style={{ maxHeight: "50vh", overflow: "scroll" }}>
                {getFilteredSerials().map((serial) => (
                  <Chipper
                    key={serial.number}
                    onClick={() => selectSerial(serial)}
                    dataTesting="serialSelectChip"
                    disabled={!canAddMore}
                  >
                    {serial.number}
                  </Chipper>
                ))}
              </div>
            </div>
            <div>
              <Typography variant="h5">
                {i18n("global.Selected")} (
                <span
                  style={{
                    color: canAddMore ? "inherit" : theme.palette.accent,
                    fontWeight: canAddMore
                      ? "inherit"
                      : theme.typography.fontWeightBold,
                  }}
                >
                  {value.length} {i18n("global.of")} {quantity.toString()}
                </span>
                )
              </Typography>
              <FrmTextField sx={{ visibility: "hidden" }} />
              <div style={{ maxHeight: "50vh", overflow: "scroll" }}>
                {value.map(({ name }) => (
                  <Chipper key={name} onClick={() => removeFromSelected(name)}>
                    {name}
                  </Chipper>
                ))}
              </div>
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid2 container justifyContent="space-between">
            <Grid2>
              <Button color="secondary" onClick={() => setUploadOpen(true)}>
                {i18n("button.UploadCSV")}
              </Button>
            </Grid2>
            <Grid2>
              <Button color="secondary" onClick={onClose}>
                {i18n("button.Cancel")}
              </Button>
              <Button data-testing="done" color="secondary" onClick={done}>
                {i18n("button.Done")}
              </Button>
            </Grid2>
          </Grid2>
        </DialogActions>
      </Dialog>

      <Upload
        uploadOpen={uploadOpen}
        setUploadOpen={setUploadOpen}
        onClose={() => setUploadOpen(false)}
        quantity={quantity}
        setSerials={handleUploadedSerials}
      />
    </>
  );
}
