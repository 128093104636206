import { Skeleton } from "@mui/material";

export function LineSkeleton(props) {
  const { margin = "0 0.5rem", sx = {} } = props;
  return (
    <Skeleton
      data-testing="lineSkeleton"
      sx={{ margin, ...sx }}
      animation="wave"
    />
  );
}
