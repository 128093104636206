import { Card, CardContent, Grid2 } from "@mui/material";

import { CardGrid } from "components/CardGrid";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmTextField } from "components/formFields/FrmTextField";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function LegalAddress(props) {
  const { name, value, onValueChange } = props;

  const countries = useRelated("country");

  function handleValueBlur(fieldName, fieldValue) {
    return onValueChange(name, { ...value, [fieldName]: fieldValue });
  }

  return (
    <Card variant="outlined" sx={{ backgroundColor: "inherit" }}>
      <CardContent>
        <CardGrid>
          {[1, 2].map((i) => (
            <FrmTextField
              key={"legalAddressLine" + i}
              name={"legalAddressLine" + i}
              label={`Line ${i}`}
              maxLength={400}
              onValueBlur={handleValueBlur}
              value={value ? value["legalAddressLine" + i] : ""}
            />
          ))}
          <FrmTextField
            name="legalCity"
            label={i18n("frmLabel.City")}
            maxLength={255}
            onValueBlur={handleValueBlur}
            value={value?.legalCity}
          />
          <Grid2 container direction="row" spacing={1}>
            <Grid2 size={6}>
              <FrmTextField
                name="legalState"
                label={i18n("frmLabel.StateProvince")}
                maxLength={255}
                onValueBlur={handleValueBlur}
                value={value?.legalState}
              />
            </Grid2>
            <Grid2 size={6}>
              <FrmTextField
                name="legalPostalCode"
                label={i18n("frmLabel.PostalCode")}
                maxLength={30}
                onValueBlur={handleValueBlur}
                value={value?.legalPostalCode}
              />
            </Grid2>
          </Grid2>
          <Grid2 />
          <FrmSelectFromObjects
            name="legalCountry"
            label={i18n("frmLabel.Country")}
            onValueChange={(name, value) =>
              handleValueBlur(name, value?.id || null)
            }
            value={value?.legalCountry ? { id: value?.legalCountry } : null}
            options={countries}
          />
        </CardGrid>
      </CardContent>
    </Card>
  );
}
