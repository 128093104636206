import {
  ExportAdjustments,
  ExportBOMs,
  ExportItems,
  ExportLots,
  ExportSerialInventory,
  ExportTransfers,
  ExportUnitsOfMeasure,
  ExportVendorItemCatalog,
  ExportVendors,
  ExportPurchaseOrders,
  ExportItemReceipts,
  ExportReturnsToVendors,
  ExportCustomers,
  ExportEstimates,
  ExportInvoices,
  ExportSalesOrders,
  ExportSalesReceipts,
  ExportReturns,
  ExportRMAs,
  ExportShipments,
  ExportPickTickets,
  ExportBuilds,
  ExportProcessTransactions,
  ExportPriceTiers,
  ExportJobs,
  ExportRentals,
  ExportRentalReturns,
  ExportWorkOrders,
} from "views/Exports/exportDefinitions/index";

export const EXPORTS = {
  ExportAdjustments,
  ExportBOMs,
  ExportItems,
  ExportLots,
  ExportSerialInventory,
  ExportTransfers,
  ExportUnitsOfMeasure,
  ExportVendorItemCatalog,
  ExportVendors,
  ExportPurchaseOrders,
  ExportItemReceipts,
  ExportReturnsToVendors,
  ExportCustomers,
  ExportEstimates,
  ExportInvoices,
  ExportSalesOrders,
  ExportSalesReceipts,
  ExportReturns,
  ExportRMAs,
  ExportShipments,
  ExportPickTickets,
  ExportBuilds,
  ExportProcessTransactions,
  ExportPriceTiers,
  ExportJobs,
  ExportRentals,
  ExportRentalReturns,
  ExportWorkOrders,
};

export const EXPORTS_BY_CATEGORY = [
  {
    categoryCode: "Inventory",
    planIncludesCategory: () => true,
    reports: [
      "ExportAdjustments",
      "ExportBOMs",
      "ExportItems",
      "ExportLots",
      "ExportSerialInventory",
      "ExportTransfers",
      "ExportUnitsOfMeasure",
      "ExportVendorItemCatalog",
    ],
  },
  {
    categoryCode: "Purchasing",
    planIncludesCategory: () => true,
    reports: [
      "ExportVendors",
      "ExportPurchaseOrders",
      "ExportItemReceipts",
      "ExportReturnsToVendors",
    ],
  },
  {
    categoryCode: "Sales",
    planIncludesCategory: () => true,
    reports: [
      "ExportCustomers",
      "ExportEstimates",
      "ExportInvoices",
      "ExportSalesOrders",
      "ExportSalesReceipts",
      "ExportReturns",
      "ExportRMAs",
      "ExportPriceTiers",
    ],
  },
  {
    categoryCode: "Fulfillment",
    planIncludesCategory: () => true,
    reports: ["ExportShipments", "ExportPickTickets"],
  },
  {
    categoryCode: "Production",
    planIncludesCategory: () => true,
    reports: [
      "ExportBuilds",
      "ExportProcessTransactions",
      "ExportJobs",
      "ExportWorkOrders",
    ],
  },
  {
    categoryCode: "Rentals",
    planIncludesCategory: (plus, pro) => plus || pro,
    reports: ["ExportRentals", "ExportRentalReturns"],
  },
];
