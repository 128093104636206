import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";

import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  CardContent,
  Card,
  Typography,
  Box,
} from "@mui/material";

import { ActionButton } from "components/ActionButton";
import { ButtonProgress } from "components/utility/ButtonProgress";
import { CloseX } from "components/utility/CloseX";

import { i18n } from "services/i18nService";
import {
  getPlans,
  updatePlan,
  getSingleton,
} from "services/sosInventoryService/sosApi";

import { openAlert } from "globalState/alertSlice";

export function PlanEdit(props) {
  const { onClose, annualPlan, currentPlan, setAccountInformation } = props;

  const [plans, setPlans] = useState(null);

  useEffect(() => {
    async function _getAccountInformation() {
      const { success, data } = await getPlans();
      if (success) {
        setPlans(data);
      }
    }
    _getAccountInformation();
  }, []);

  return (
    <Dialog open={true} onClose={onClose} fullWidth>
      <DialogTitle>
        <div style={{ display: "flex", justifyContent: "space-between" }}>
          <Typography variant="h3">
            {i18n("plan.colon.CurrentPlan")}{" "}
            <Box component="span" sx={{ color: "secondary.main" }}>
              {currentPlan.name}
            </Box>
          </Typography>
          <CloseX onClick={onClose} />
        </div>
      </DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", gap: 2 }}>
          {plans?.map(
            (
              {
                id,
                name,
                priceMonthly,
                priceAnnual,
                userLimit,
                extraUserMonthly,
              },
              i
            ) => (
              <PlanCard
                key={i}
                currentPlan={currentPlan}
                id={id}
                name={name}
                priceMonthly={priceMonthly}
                priceAnnual={priceAnnual}
                userLimit={userLimit}
                extraUserMonthly={extraUserMonthly}
                annualPlan={annualPlan}
                onClose={onClose}
                setAccountInformation={setAccountInformation}
              />
            )
          )}
        </Box>
        <Box
          sx={{
            mt: "1rem",
            textAlign: "center",
            "& a": { color: "primaryLink" },
          }}
        >
          <a
            href="https://www.sosinventory.com/features"
            target="_blank"
            rel="noreferrer"
          >
            {i18n("plan.ClickHereForDetailedComparison")}
          </a>
        </Box>
      </DialogContent>
      <DialogActions></DialogActions>
    </Dialog>
  );
}

const PLAN_CONFIG = {
  Companion: [
    i18n("accountInformation.SalesOrders"),
    i18n("accountInformation.BestInClassQbo"),
  ],
  Plus: [
    i18n("accountInformation.UnlimitedLocations"),
    i18n("accountInformation.SerialLotTracking"),
    i18n("accountInformation.Barcoding"),
  ],
  Pro: [
    i18n("accountInformation.JobCosting"),
    i18n("accountInformation.AdvancedManufacturing"),
    i18n("accountInformation.CustomerPortal"),
  ],
};

export function PlanCard(props) {
  const {
    id,
    name,
    priceMonthly,
    priceAnnual,
    userLimit,
    extraUserMonthly,
    annualPlan,
    onClose,
    setAccountInformation,
    currentPlan,
  } = props;

  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const planConfig = PLAN_CONFIG[name];

  async function handleupdatePlan() {
    setIsLoading(true);
    const { success } = await updatePlan({ id });
    if (success) {
      const message = i18n("alert.SuccessUpdatePlan");
      setAccountInformation(await getSingleton("company/accountInfo"));
      dispatch(openAlert({ type: "success", message }));
      onClose();
    } else {
      const message = i18n("alert.ErrorUpdatePlan");
      dispatch(openAlert({ type: "error", message }));
    }
    setIsLoading(false);
  }

  const priceString1 = annualPlan
    ? `$${priceAnnual} / `
    : `$${priceMonthly} / `;
  const priceString2 = annualPlan
    ? `${i18n("accountInformation.year")}`
    : `${i18n("accountInformation.month")}`;

  return (
    <Card
      variant="outlined"
      sx={{
        display: "grid",
        gridTemplateRows: "auto 1fr auto",
        width: "20rem",
        p: "1rem 0",
      }}
    >
      <Typography sx={{ textAlign: "center", mb: 0 }} variant="h4">
        {name}
      </Typography>
      <CardContent sx={{ pt: 0 }}>
        <ul style={{ paddingLeft: "1rem" }}>
          {planConfig?.map((bullet, i) => (
            <li key={i}>{bullet}</li>
          ))}
          <li>
            {userLimit} {i18n("accountInformation.usersIncluded")} $
            {extraUserMonthly} {i18n("accountInformation.eachAdditionalUser")}
          </li>
        </ul>
      </CardContent>
      <Box
        sx={{ textAlign: "center", martinTop: "auto", pl: "16px", pr: "16px" }}
      >
        <Box sx={{ display: "flex", alignItems: "flex-end", pb: "1rem" }}>
          <Typography variant="h4">{priceString1}</Typography>
          <Typography sx={{ ml: "0.25rem" }}>{priceString2}</Typography>
        </Box>
        <div style={{ position: "relative" }}>
          <ActionButton
            disabled={currentPlan.name === name || isLoading}
            sx={{ martinTop: "auto" }}
            onClick={handleupdatePlan}
          >
            {currentPlan.name === name
              ? i18n("accountInformation.CurrentPlan")
              : `${i18n("accountInformation.Choose")} ${name}`}
            {isLoading && <ButtonProgress color="secondary" />}
          </ActionButton>
        </div>
      </Box>
    </Card>
  );
}
