// this page must be able to retrieve its own settings, and save them (those
// settings) when the user clicks "save"
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router";

import { Box, Button } from "@mui/material";

import { Loading } from "components/utility/Loading";

import { i18n } from "services/i18nService";
import { getSettingsValues } from "services/sosInventoryService/sosApi";
import { updateSettingsValues } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";

import { Setting } from "views/Configuration/Setting";
import { SETTINGS } from "views/Configuration/settings/index";

import { openAlert } from "globalState/alertSlice";

export function SettingsPage(props) {
  const { level1, level2 } = props;

  const [settingsValues, setSettingsValues] = useState(null);

  const connectedToQBO = useSelector(
    (state) => state.userCompanySettings.settings.connectedToQBO
  );

  const dispatch = useDispatch();

  const { hash } = useLocation();
  const selectedSettingId = hash.slice(1);

  const settings = SETTINGS[level1][level2];

  useEffect(() => {
    if (selectedSettingId) {
      setTimeout(() => {
        document
          .getElementById(selectedSettingId)
          .scrollIntoView({ behavior: "smooth" });
      }, 750);
    }
  }, [selectedSettingId]);

  useEffect(() => {
    async function _retrieveSettingsValues() {
      const response = await getSettingsValues(level1, level2);
      if (response) {
        setSettingsValues(response);
      }
    }
    _retrieveSettingsValues();
  }, [level1, level2]);

  function onValueChange(apiName, newValue) {
    setSettingsValues((prev) => ({ ...prev, [apiName]: newValue }));
    setPageDirty();
  }

  async function onSave() {
    const { success, message } = await updateSettingsValues(
      level1,
      level2,
      settingsValues
    );
    if (success) {
      setPageDirty(false);
      dispatch(openAlert({ type: "success", message: i18n("global.Saved") }));
    } else {
      const errorMessage = message || i18n("settings.UnableToUpdateSettings");
      handleProgramError(new Error(errorMessage));
    }
  }

  if (!settings) {
    return (
      <Box p={2}>
        The {level1}/{level2} settings page is not yet implemented.
      </Box>
    );
  }

  if (level2 === "quickbooks" && !connectedToQBO) {
    return (
      <Box p={2}>
        The functionality to connect SOS to QuickBooks is not yet implemented in
        v9.
      </Box>
    );
  }

  return (
    <>
      {!settingsValues && <Loading />}
      <Box sx={{ textAlign: "right", p: 2 }}>
        <Button
          disabled={!Boolean(settingsValues)}
          variant="contained"
          size="small"
          onClick={onSave}
        >
          {i18n("global.Save")}
        </Button>
      </Box>
      <Box sx={{ overflowY: "scroll", height: "77vh", pt: 1 }}>
        {Boolean(settingsValues) &&
          settings.map((setting) => (
            <Setting
              key={setting.apiName}
              level1={level1}
              level2={level2}
              setting={setting}
              allSettingsValues={settingsValues}
              value={settingsValues[setting.apiName]}
              onValueChange={onValueChange}
              selectedFromSearch={setting.apiName === selectedSettingId}
            />
          ))}
      </Box>
    </>
  );
}
