import { Paper, Stack, Switch as MuiSwitch, Typography } from "@mui/material";

export function Switch(props) {
  // there must always be two and only two options for a Switch; the first
  // will be the left side of the switch, and the second the right side
  const { checked, name, onChange, label, options } = props;

  return (
    <Paper variant="outlined" sx={{ padding: 1, marginBottom: 1 }}>
      <Typography sx={{ color: "primary" }}>{label}</Typography>
      <Stack direction="row" alignItems="center">
        <Typography>{options[0].name}</Typography>
        <MuiSwitch
          checked={checked}
          name={name}
          onChange={(e) =>
            onChange(e.target.name, e.target.checked ? options[1] : options[0])
          }
        />
        <Typography>{options[1].name}</Typography>
      </Stack>
    </Paper>
  );
}
