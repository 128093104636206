import { i18n } from "services/i18nService";

import globalState from "globalState/globalState";

function getLifoTooltip() {
  const allowPlusFeatures =
    globalState.getState().userCompanySettings.settings.allowPlusFeatures;
  return i18n(
    allowPlusFeatures
      ? "hoverHelp.valuationMethod.LIFO.WithSupportNumber"
      : "hoverHelp.valuationMethod.LIFO"
  );
}

export const SETTINGS = [
  {
    apiName: "allowNegativeInventory",
    component: "FrmCheckbox",
  },
  {
    apiName: "allowNegativeInBins",
    component: "FrmCheckbox",
  },
  {
    apiName: "defaultItemIsTaxable",
    component: "FrmCheckbox",
  },
  {
    apiName: "defaultItemSyncWithQBO",
    component: "FrmCheckbox",
  },
  {
    apiName: "calculateReorderPoints",
    planIncludesSetting: (_, pro) => pro,
    component: "FrmCheckbox",
  },
  {
    apiName: "includeCategoryPrefix",
    component: "FrmCheckbox",
  },
  {
    apiName: "hideItemName",
    component: "FrmCheckbox",
  },
  {
    apiName: "searchAnyPartOfItem",
    component: "FrmCheckbox",
  },
  {
    apiName: "searchItemDescription",
    component: "FrmCheckbox",
  },
  {
    apiName: "inventoryMethod",
    component: "FrmRadioGroup",
    options: [
      { value: "FIFO", label: i18n("settings.valuationMethod.FIFO") },
      { value: "Average", label: i18n("settings.valuationMethod.Average") },
      {
        value: "LIFO",
        label: i18n("settings.valuationMethod.LIFO"),
        disabled: true,
        tooltipFunc: () => getLifoTooltip(),
      },
    ],
  },
  {
    apiName: "valueSerialsBySpecificId",
    component: "FrmCheckbox",
  },
  {
    apiName: "useTransactionsForSerialQOH",
    component: "FrmCheckbox",
    displayIf: "useTransactionsForSerialQOH",
  },
  {
    apiName: "overrideFIFObyLocation",
    component: "FrmCheckbox",
  },
  {
    apiName: "dontShowQuantityWhenEditing",
    component: "FrmCheckbox",
  },
  {
    apiName: "pictureSize",
    component: "FrmImageSize",
  },
  {
    apiName: "unitsOfMeasureEnabled",
    component: "FrmCheckbox",
  },
  {
    apiName: "rentalInventoryEnabled",
    component: "FrmCheckbox",
  },
  {
    apiName: "trackWorkersOnLaborItems",
    component: "FrmCheckbox",
  },
  {
    apiName: "serialLotNumbering",
    component: "FrmSerialLotNumbering",
    explanationOverride: (
      <>
        {i18n("settings.inventory.general.serialLotNumbering.explanation.1")}
        <p>
          {i18n("settings.inventory.general.serialLotNumbering.explanation.2")}
        </p>
        <p>
          {i18n("settings.inventory.general.serialLotNumbering.explanation.3")}
        </p>
        <p>
          {i18n("settings.inventory.general.serialLotNumbering.explanation.4")}
        </p>
        <p>
          {i18n("settings.inventory.general.serialLotNumbering.explanation.5")}
        </p>
      </>
    ),
  },
];
