import { useMemo } from "react";
import { DragDropContext } from "react-beautiful-dnd";

import { Box, Grid2, Paper, Typography } from "@mui/material";

import { ColumnList } from "components/ListPage/Widgets/EditDisplayResults/ColumnList";

import { i18n } from "services/i18nService";

function getAvailableColumns(columns, available) {
  const selectedStrings = columns.map(({ name }) => name);
  const availableColumns = available.filter(
    (c) => !selectedStrings.includes(c.name) && !c.fixed
  );
  const standardColumns = availableColumns
    .filter(({ isCustomField }) => !isCustomField)
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  const customColumns = availableColumns
    .filter(({ isCustomField }) => isCustomField)
    .sort((a, b) => (a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1));
  return [...standardColumns, ...customColumns];
}

export function EditColumns(props) {
  const { objectType, columns, setColumns, allColumns } = props;

  const availableColumns = useMemo(
    () => getAvailableColumns(columns, allColumns),
    [columns, allColumns]
  );

  function onDragEnd(result) {
    const { source, destination } = result;

    // we don't have to worry about the available list; it will get updated
    // automatically when the current column list changes

    // if there's no destination, the item was dropped outside of a droppable
    if (!destination) {
      return;
    }
    // if source and destination are both in available, it's a no-op; this
    // code is included only for completeness, in case we want to do something
    // here later (like indicate it's a no-op somehow)
    if (
      source.droppableId === "available" &&
      destination.droppableId === "available"
    ) {
      return;
    }

    let newColumns = [...columns];
    // if within current...
    if (
      source.droppableId === "current" &&
      destination.droppableId === "current"
    ) {
      const column = newColumns.splice(source.index, 1)[0];
      newColumns.splice(destination.index, 0, column);
      setColumns(newColumns);
    }

    // from current to available
    if (
      source.droppableId === "current" &&
      destination.droppableId === "available"
    ) {
      newColumns.splice(source.index, 1);
      setColumns(newColumns);
    }

    // from available to current
    if (
      source.droppableId === "available" &&
      destination.droppableId === "current"
    ) {
      newColumns.splice(destination.index, 0, availableColumns[source.index]);
      setColumns(newColumns);
    }
  }

  return (
    <Grid2 size={12}>
      <Typography>{i18n("columnSelect.dragDropInstructions")}</Typography>
      <DragDropContext onDragEnd={onDragEnd}>
        <Box mt={2}>
          <div style={{ display: "flex" }}>
            <Paper sx={{ padding: "10px", width: "50%", marginRight: "5px" }}>
              <ColumnList
                objectType={objectType}
                heading={i18n("columnSelect.current")}
                id="current"
                columns={columns}
              />
            </Paper>
            <Paper sx={{ padding: "10px", width: "50%", marginLeft: "5px" }}>
              <ColumnList
                objectType={objectType}
                heading={i18n("columnSelect.available")}
                id="available"
                columns={availableColumns}
              />
            </Paper>
          </div>
        </Box>
      </DragDropContext>
    </Grid2>
  );
}
