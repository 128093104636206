import { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Box,
  Grid2,
  Card,
  Switch,
  CardContent,
  Typography,
} from "@mui/material";

import { CardGrid } from "components/CardGrid";
import { CustomFields } from "components/CustomFields/CustomFields";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { FrmResizeTextField } from "components/formFields/FrmResizeTextField";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmSelectScalarWithObjectOptions } from "components/formFields/FrmSelectScalarWithObjectOptions";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmTextFieldWithHoverHelp } from "components/formFields/FrmTextFieldWithHoverHelp";
import { MultipleSelectField } from "components/formFields/MultipleSelectField";
import { CardHeader } from "components/formFields/formCards/CardHeader";
import { FrmLocation, FrmAddImage } from "components/formFields/frm";
import { FrmCheckbox } from "components/formFields/frm/FrmCheckbox";
import { FrmUnit } from "components/formFields/frm/FrmUnit";
import { DatePicker } from "components/html/Input/DatePicker";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormErrors } from "components/utility/FormErrors";
import { HoverHelp } from "components/utility/HoverHelp";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import {
  isInventoryItemOrAssembly,
  isInventoryItemAssemblyOrCategory,
} from "services/sosInventoryService/domainLogic";
import { getBarcodeImageBlob } from "services/sosInventoryService/sosApi";
import { getRecord } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";
import { imageBlobToBase64 } from "services/utility/images";
import { readImage } from "services/utility/images";
import { getBaseUom } from "services/utility/uoms";

import { useBins } from "hooks/useBins";
import { useChartOfAccounts } from "hooks/useChartOfAccounts";
import { useCustomFieldDefinitions } from "hooks/useCustomFieldDefinitions";
import { useErrors } from "hooks/useErrors";
import { useItems } from "hooks/useItems";
import { usePlans } from "hooks/usePlans";
import { usePrivileges } from "hooks/usePrivileges";
import { useRelated } from "hooks/useRelated";
import { TAX_CODE_TYPE_PURCHASE, TAX_CODE_TYPE_SALES } from "hooks/useTaxCodes";
import { useTaxCodes } from "hooks/useTaxCodes";

import { updateObject, getObject } from "views/Items/Item/controller";

import { openAlert } from "globalState/alertSlice";
import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import {
  ITEM_TYPE_OPTIONS,
  FROM_FORM_ITEM_TYPE_OPTIONS,
  ITEM_TYPES_THAT_CAN_BE_ALWAYS_SHIPPABLE,
  OBJECT_TYPES,
  DEFAULT_DECIMALS_UNROUNDED,
  USER_PRIVILEGE,
  MAX_CHARS_LINE_ITEM_DESCRIPTION,
  ITEM_QUICKLIST_CASES,
  getSalesDecimalPlaces,
} from "appConstants";

const OBJECT_TYPE = OBJECT_TYPES.ITEM.fullString;

export function Item(props) {
  const { id, onClose, onAdd, fromForm, name, newFromId, newFromObjectType } =
    props;

  // TRANSACTION STATE
  const [record, setRecord] = useState();
  const [barcodeImage, setBarcodeImage] = useState(undefined);

  // UI STATE
  const { errors, setErrors, isInError } = useErrors();
  const loadingState = useSelector((state) => state.loading.editModal);

  const { hasAtLeastPlusPlan } = usePlans();
  const { hasPrivilegesOrIsAdmin } = usePrivileges();
  const viewCosts = hasPrivilegesOrIsAdmin(USER_PRIVILEGE.viewCosts);

  const classes = useRelated("class");
  const { items: categories } = useItems(ITEM_QUICKLIST_CASES.CATEGORY);
  const vendors = useRelated("vendor");
  const bins = useBins();
  const purchaseTaxCodes = useTaxCodes(TAX_CODE_TYPE_PURCHASE);
  const salesTaxCodes = useTaxCodes(TAX_CODE_TYPE_SALES);
  const warranties = useRelated("warranty");
  const tags = useRelated("tag");

  const accounts = useChartOfAccounts(true);
  const incomeAccounts = accounts ? accounts.income : null;
  const assetAccounts = accounts ? accounts.asset : null;
  const cogsAccounts = accounts ? accounts.cogs : null;
  const expenseAccounts = accounts ? accounts.expense : null;
  const baseUom = record?.uoms ? getBaseUom(record.uoms || []) : null;

  // SETTINGS

  const roundSalesPrices = useSelector(
    (state) => state.userCompanySettings.settings.roundSalesPrices
  );
  const globalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.globalEnabled
  );
  const classEnabled = useSelector(
    (state) => state.userCompanySettings.settings.classEnabled
  );
  const defaultLocation = useSelector(
    (state) => state.userCompanySettings.settings.defaultLocation,
    (prev, curr) => curr?.id === prev?.id
  );
  const connectedToQBO = useSelector(
    (state) => state.userCompanySettings.settings.connectedToQBO
  );
  const defaultAssetAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultAssetAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultCOGSAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultCOGSAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultExpenseAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultExpenseAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultIncomeAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultIncomeAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultTaxCode = useSelector(
    (state) => state.userCompanySettings.settings.defaultTaxCode,
    (prev, curr) => curr?.id === prev?.id
  );

  const customFieldDefs = useCustomFieldDefinitions(OBJECT_TYPE);

  const formattedBins = bins.map((b) => ({
    id: b.id,
    name: `${b.number} (${b.location.name})`,
  }));

  // OTHER SETUP
  const dispatch = useDispatch();

  // LOAD INITIAL TRANSACTION STATE
  useEffect(() => {
    async function _getTransaction() {
      const settings = {
        defaultAssetAccount,
        defaultCOGSAccount,
        defaultExpenseAccount,
        defaultIncomeAccount,
        defaultTaxCode,
      };
      const UPDATERS = { setRecord, setErrors };
      dispatch(editModalLoadingIndicatorOn());
      await getObject(
        id,
        newFromId,
        newFromObjectType,
        name,
        customFieldDefs,
        UPDATERS,
        settings
      );
      dispatch(editModalLoadingIndicatorOff());
    }
    _getTransaction();
  }, [
    dispatch,
    id,
    name,
    newFromId,
    newFromObjectType,
    customFieldDefs,
    defaultAssetAccount,
    defaultCOGSAccount,
    defaultExpenseAccount,
    defaultIncomeAccount,
    defaultTaxCode,
    setErrors,
  ]);

  useEffect(() => {
    if (record?.barcode) {
      async function getImage() {
        const barcodeImageBlob = await getBarcodeImageBlob(record.barcode);
        imageBlobToBase64(barcodeImageBlob, setBarcodeImage);
      }
      getImage();
    } else {
      setBarcodeImage(undefined);
    }
  }, [record?.barcode]);

  // set the initialLocation for new records; we need to get the full
  // record, so that we have the binTracking property
  useEffect(() => {
    async function getLocation() {
      if (record && !record.id && !record.initialLocation) {
        setRecord({
          ...record,
          initialLocation: await getRecord("location", defaultLocation.id),
        });
      }
    }
    getLocation();
  }, [record?.id, defaultLocation.id, record, setRecord]);

  const binsFilteredByInitialLocation = useBins(
    record?.initialLocation || defaultLocation
  );

  // HANDLE ALL USER INPUT
  function handleInputFieldChange(field, newValue) {
    setPageDirty();
    updateObject(field, newValue, record, setRecord);
  }

  function updateRecordWithImage(imageAsBase64String, imageScaled) {
    setRecord((prevRecord) => ({
      ...prevRecord,
      hasImage: true,
      imageChanged: true,
      imageAsBase64String,
      imageScaled,
    }));
  }

  async function handleImageSelection(e) {
    const { success, unsupportedFileType } = readImage(
      e.target.files[0],
      updateRecordWithImage
    );
    if (!success) {
      const message = i18n("file.FileTypeNotSupported", {
        fileType: unsupportedFileType,
      });
      dispatch(openAlert({ type: "error", message }));
      return;
    }
    // if successful, readImage will call updateRecordWithImage with the
    // image strings (original and scaled)
  }

  function handleDeleteImage() {
    setRecord((prevRecord) => ({
      ...prevRecord,
      imageAsBase64String: null,
      hasImage: false,
      imageChanged: true,
      imageScaled: false,
    }));
  }

  const showSerialLotFields =
    hasAtLeastPlusPlan && isInventoryItemOrAssembly(record?.type);

  const showVariantFields =
    hasAtLeastPlusPlan &&
    (!record?.id || record?.hasVariants) &&
    record?.type !== "Category";

  const showShipFields = ITEM_TYPES_THAT_CAN_BE_ALWAYS_SHIPPABLE.includes(
    record?.type
  );

  // if new transaction with record not defined
  // show null till record set in useObject
  if (!id && !newFromId && !record) {
    return null;
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={OBJECT_TYPE}
        setErrors={setErrors}
        errors={errors}
        text={record?.name || ""}
        handleClose={onClose}
        onAdd={onAdd}
        fromForm={fromForm}
        newFromId={newFromId}
      />

      {loadingState && <Loading />}

      {record && (
        <ObjectEditDialogContent>
          <Box flexGrow="1" position="relative" id="B" m={2} mb={0}>
            <FormErrors errors={errors} setErrors={setErrors} />
            {record ? (
              <Box>
                <FrmFieldGrid gridAutoFlow="dense">
                  <FrmTextFieldWithHoverHelp
                    autoFocus
                    label={i18n("frmLabel.Name")}
                    onValueBlur={handleInputFieldChange}
                    value={record.name}
                    name="name"
                    helpText={i18n("hoverHelp.itemName")}
                    disabled={Boolean(record.variantMaster)}
                    dataTesting="name"
                    error={isInError("name")}
                  />

                  <FrmSelectScalarWithObjectOptions
                    options={
                      fromForm ? FROM_FORM_ITEM_TYPE_OPTIONS : ITEM_TYPE_OPTIONS
                    }
                    label={i18n("frmLabel.Type")}
                    onValueChange={handleInputFieldChange}
                    error={isInError("type")}
                    disabled={Boolean(record.variantMaster)}
                    value={record.type}
                    name="type"
                    dataTesting="itemType"
                  />

                  <FrmTextFieldWithHoverHelp
                    label={i18n("frmLabel.Sku")}
                    onValueBlur={handleInputFieldChange}
                    value={record.sku}
                    name="sku"
                    helpText={i18n("hoverHelp.sku")}
                  />

                  <CardGrid>
                    <FrmSelectFromObjects
                      name="category"
                      options={categories}
                      label={i18n("frmLabel.Category")}
                      onValueChange={handleInputFieldChange}
                      value={record.category}
                      dataTesting="category"
                    />

                    {classEnabled && (
                      <FrmSelectFromObjects
                        name="class"
                        options={classes}
                        label={i18n("frmLabel.DefaultClass")}
                        onValueChange={handleInputFieldChange}
                        value={record.class}
                        error={isInError("class")}
                      />
                    )}
                  </CardGrid>

                  <CardGrid>
                    <FrmSelectFromObjects
                      name="incomeAccount"
                      options={incomeAccounts}
                      label={i18n("frmLabel.IncomeAccount")}
                      onValueChange={handleInputFieldChange}
                      value={record.incomeAccount}
                      error={isInError("incomeAccount")}
                      helperText={
                        connectedToQBO && !record.incomeAccount
                          ? i18n("item.fieldError.incomeAccount")
                          : ""
                      }
                    />

                    {isInventoryItemOrAssembly(record.type) && (
                      <FrmSelectFromObjects
                        name="assetAccount"
                        options={assetAccounts}
                        label={i18n("frmLabel.AssetAccount")}
                        onValueChange={handleInputFieldChange}
                        value={record.assetAccount}
                      />
                    )}

                    {isInventoryItemOrAssembly(record.type) && (
                      <FrmSelectFromObjects
                        name="cogsAccount"
                        options={cogsAccounts}
                        label={i18n("frmLabel.CogsAccount")}
                        onValueChange={handleInputFieldChange}
                        value={record.cogsAccount}
                        error={isInError("cogsAccount")}
                        helperText={
                          connectedToQBO && !record.cogsAccount
                            ? i18n("item.fieldError.cogsAccount")
                            : ""
                        }
                      />
                    )}

                    {!isInventoryItemAssemblyOrCategory(record.type) && (
                      <FrmSelectFromObjects
                        name="expenseAccount"
                        options={expenseAccounts}
                        label={i18n("frmLabel.ExpenseAccount")}
                        onValueChange={handleInputFieldChange}
                        value={record.expenseAccount}
                        error={isInError("expenseAccount")}
                        helperText={
                          connectedToQBO && !record.expenseAccount
                            ? i18n("item.fieldError.expenseAccount")
                            : ""
                        }
                      />
                    )}
                  </CardGrid>

                  {(showSerialLotFields ||
                    showVariantFields ||
                    showShipFields) && (
                    <CardGrid>
                      {showSerialLotFields && (
                        <>
                          <FrmCheckbox
                            name="serialTracking"
                            value={record.serialTracking}
                            onValueChange={handleInputFieldChange}
                            disabled={Boolean(record.variantMaster)}
                            dataTesting="serialTracking"
                            label={i18n("frmLabel.SerialTracking")}
                            sx={{ display: "block" }}
                          />

                          <FrmCheckbox
                            name="lotTracking"
                            value={record.lotTracking}
                            onValueChange={handleInputFieldChange}
                            disabled={Boolean(record.variantMaster)}
                            label={i18n("frmLabel.LotTracking")}
                            sx={{ display: "block" }}
                            dataTesting="lotTracking"
                          />
                        </>
                      )}

                      {showVariantFields && (
                        <div style={{ display: "block" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FrmCheckbox
                              name="hasVariants"
                              value={record.hasVariants}
                              onValueChange={handleInputFieldChange}
                              dataTesting="hasVariants"
                              label={i18n("frmLabel.HasVariants")}
                              disabled={record.id && record.hasVariants}
                              sx={{ marginRight: 0 }}
                            />
                            {!record.id && (
                              <HoverHelp
                                title={i18n("hoverHelp.variantNew")}
                                sx={{ marginLeft: 0.5 }}
                              />
                            )}
                          </div>
                        </div>
                      )}
                      {showShipFields && (
                        <div style={{ display: "block" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FrmCheckbox
                              name="alwaysShippable"
                              value={record.alwaysShippable}
                              onValueChange={handleInputFieldChange}
                              label={i18n("frmLabel.AlwaysShippable")}
                              sx={{ marginRight: 0 }}
                            />
                            <HoverHelp
                              title={i18n("hoverHelp.alwaysShippable")}
                              sx={{ marginLeft: 0.5 }}
                            />
                          </div>
                        </div>
                      )}
                    </CardGrid>
                  )}

                  {record.type !== "Category" && (
                    <Card
                      variant="outlined"
                      sx={{ marginTop: "1.0em", marginBottom: "1.0em" }}
                    >
                      <CardContent>
                        <CardHeader text={i18n("item.headingUsedOn")} />
                        <FrmCheckbox
                          name="showOnSalesForms"
                          value={record.showOnSalesForms}
                          onValueChange={handleInputFieldChange}
                          disabled={Boolean(record.variantMaster)}
                          label={i18n("frmLabel.SalesForms")}
                          sx={{ display: "block" }}
                        />
                        <FrmCheckbox
                          name="showOnPurchasingForms"
                          value={record.showOnPurchasingForms}
                          onValueChange={handleInputFieldChange}
                          disabled={Boolean(record.variantMaster)}
                          label={i18n("frmLabel.PurchasingForms")}
                          sx={{ display: "block" }}
                        />
                        <FrmCheckbox
                          name="showOnProductionForms"
                          value={record.showOnProductionForms}
                          onValueChange={handleInputFieldChange}
                          disabled={Boolean(record.variantMaster)}
                          label={i18n("frmLabel.ProductionForms")}
                          sx={{ display: "block" }}
                        />
                      </CardContent>
                    </Card>
                  )}

                  {!record.id &&
                  !record.serialTracking &&
                  !record.lotTracking &&
                  !record.hasVariants &&
                  isInventoryItemOrAssembly(record.type) ? (
                    <>
                      <Card
                        variant="outlined"
                        sx={{ marginTop: "1.0em", marginBottom: "1.0em" }}
                      >
                        <CardContent>
                          <Grid2 container spacing={1}>
                            <Grid2 size={12}>
                              <CardHeader
                                text={i18n("item.headingStartingInventory")}
                              />
                            </Grid2>

                            <Grid2 size={4}>
                              <FrmTextField
                                number
                                name="initialQuantity"
                                label={i18n("frmLabel.Quantity")}
                                onValueBlur={handleInputFieldChange}
                                value={record.initialQuantity}
                                dataTesting="initialQuantity"
                              />
                            </Grid2>
                            <Grid2 size={8}>
                              <DatePicker
                                key={record.initialQuantityDate}
                                name="initialQuantityDate"
                                label={i18n("frmLabel.AsOf")}
                                value={record.initialQuantityDate}
                                onValueChange={handleInputFieldChange}
                              />
                            </Grid2>

                            <Grid2 size={4}>
                              <FrmTextField
                                money
                                label={i18n("frmLabel.Value")}
                                onValueBlur={handleInputFieldChange}
                                value={record.initialValue}
                                error={isInError("initialValue")}
                                name="initialValue"
                              />
                            </Grid2>

                            <Grid2 size={8}>
                              <FrmLocation
                                name="initialLocation"
                                label={i18n("frmLabel.Location")}
                                onValueChange={handleInputFieldChange}
                                value={record.initialLocation}
                                error={isInError("initialLocation")}
                                width="25rem"
                              />
                            </Grid2>

                            {record?.initialLocation?.binTracking && (
                              <Grid2 size={12}>
                                <FrmSelectFromObjects
                                  name="initialBin"
                                  options={binsFilteredByInitialLocation}
                                  value={record.initialBin || null}
                                  label={i18n("frmLabel.Bin")}
                                  onValueChange={handleInputFieldChange}
                                  error={isInError("initialBin")}
                                />
                              </Grid2>
                            )}
                          </Grid2>
                        </CardContent>
                      </Card>
                    </>
                  ) : (
                    <div />
                  )}

                  <Card
                    variant="outlined"
                    sx={{ marginTop: "1.0em", marginBottom: "1.0em" }}
                  >
                    <CardContent>
                      <CardHeader
                        text={i18n("item.headingSalesTransactionFields")}
                      />
                      <CardGrid>
                        <FrmResizeTextField
                          label={i18n("frmLabel.Description")}
                          minRows={2}
                          onValueBlur={handleInputFieldChange}
                          value={record.description}
                          name="description"
                          placeholder={i18n("item.placeholderSalesDescription")}
                          maxLength={
                            connectedToQBO
                              ? MAX_CHARS_LINE_ITEM_DESCRIPTION
                              : undefined
                          }
                        />
                        <Box sx={{ display: "flex" }}>
                          <Typography component="span">
                            {i18n("item.switchLabelFixedPricing")}
                          </Typography>
                          <Switch
                            size="small"
                            name="useMarkup"
                            checked={record.useMarkup}
                            onChange={({ target: { name, checked } }) =>
                              handleInputFieldChange(name, checked)
                            }
                          />
                          <Typography component="span">
                            {i18n("item.switchLabelMarkupPricing")}
                          </Typography>
                        </Box>

                        {record.useMarkup ? (
                          <FrmTextField
                            money
                            name="markupPercent"
                            label={i18n("frmLabel.MarkupPercent")}
                            onValueBlur={handleInputFieldChange}
                            value={record.markupPercent}
                            sx={{ width: "8em" }}
                            error={isInError("markupPercent")}
                          />
                        ) : (
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "center",
                            }}
                          >
                            <FrmTextField
                              money
                              name="baseSalesPrice"
                              label={i18n("frmLabel.BaseSalesPrice")}
                              onValueBlur={handleInputFieldChange}
                              value={record.baseSalesPrice}
                              error={isInError("baseSalesPrice")}
                              moneyDecimalPlaces={getSalesDecimalPlaces(
                                roundSalesPrices
                              )}
                              sx={{ width: "8em" }}
                              dataTesting="baseSalesPrice"
                            />
                            {baseUom && (
                              <Typography sx={{ marginTop: "0.3em" }}>
                                {i18n("global.per")} {baseUom.name}
                              </Typography>
                            )}
                          </Box>
                        )}

                        {globalEnabled && (
                          <FrmSelectFromObjects
                            name="salesTaxCode"
                            options={salesTaxCodes}
                            label={i18n("frmLabel.SalesTaxCode")}
                            onValueChange={handleInputFieldChange}
                            value={record.salesTaxCode}
                            minWidth="8em"
                          />
                        )}
                        <Grid2>
                          <FrmTextFieldWithHoverHelp
                            money
                            name="minimumPrice"
                            label={i18n("frmLabel.MinimumPrice")}
                            onValueBlur={handleInputFieldChange}
                            value={record.minimumPrice}
                            error={isInError("minimumPrice")}
                            helpText={i18n("hoverHelp.minimumSalesPrice")}
                            dataTesting="minimumPrice"
                            sx={{ width: "6.5rem" }}
                          />
                        </Grid2>

                        <FrmTextField
                          name="customerPartNumber"
                          label={i18n("frmLabel.CustomerPartNumber")}
                          onValueBlur={handleInputFieldChange}
                          value={record.customerPartNumber}
                        />

                        {!globalEnabled && (
                          <FrmCheckbox
                            name="taxable"
                            value={Boolean(record.taxable)}
                            onValueChange={handleInputFieldChange}
                            label={i18n("frmLabel.Taxable")}
                          />
                        )}

                        <div style={{ display: "inline-block" }}>
                          <div
                            style={{ display: "flex", alignItems: "center" }}
                          >
                            <FrmCheckbox
                              name="commissionExempt"
                              value={Boolean(record.commissionExempt)}
                              onValueChange={handleInputFieldChange}
                              label={i18n("frmLabel.CommissionExempt")}
                              sx={{ marginRight: 0 }}
                            />
                            <HoverHelp
                              title={i18n("hoverHelp.commissionExempt")}
                              sx={{ marginLeft: 0.5 }}
                            />
                          </div>
                        </div>

                        {!record.commissionExempt && (
                          <>
                            <FrmTextFieldWithHoverHelp
                              name="commissionRate"
                              label={i18n("frmLabel.CommissionRate")}
                              onValueBlur={handleInputFieldChange}
                              value={record.commissionRate}
                              error={isInError("commissionRate")}
                              helpText={i18n("hoverHelp.commissionRate")}
                              sx={{ width: "10em" }}
                              number
                            />

                            <FrmTextFieldWithHoverHelp
                              name="commissionAmount"
                              label={i18n("frmLabel.CommissionAmount")}
                              onValueBlur={handleInputFieldChange}
                              value={record.commissionAmount}
                              error={isInError("commissionAmount")}
                              helpText={i18n("hoverHelp.commissionAmount")}
                              sx={{ width: "10em" }}
                              number
                            />
                          </>
                        )}
                      </CardGrid>
                    </CardContent>
                  </Card>

                  <Card
                    variant="outlined"
                    sx={{ marginTop: "1.0em", marginBottom: "1.0em" }}
                  >
                    <CardContent>
                      <CardHeader
                        text={i18n("item.headingPurchasingTransactionFields")}
                      />
                      <CardGrid>
                        <FrmResizeTextField
                          name="purchaseDescription"
                          label={i18n("frmLabel.Description")}
                          minRows={2}
                          onValueBlur={handleInputFieldChange}
                          value={record.purchaseDescription}
                          placeholder={i18n(
                            "item.placeholderPurchasingDescription"
                          )}
                          maxLength={connectedToQBO ? 1000 : undefined}
                        />

                        {viewCosts && (
                          <Box
                            sx={{
                              display: "flex",
                              gap: 1,
                              alignItems: "center",
                            }}
                          >
                            <FrmTextField
                              money
                              name="basePurchaseCost"
                              label={i18n("frmLabel.PurchaseCost")}
                              onValueBlur={handleInputFieldChange}
                              value={record.basePurchaseCost}
                              error={isInError("purchaseCost")}
                              moneyDecimalPlaces={DEFAULT_DECIMALS_UNROUNDED}
                              sx={{ width: "8em" }}
                              dataTesting="basePurchaseCost"
                            />
                            {baseUom && (
                              <Typography sx={{ marginTop: "0.3em" }}>
                                {i18n("global.per")} {baseUom.name}
                              </Typography>
                            )}
                          </Box>
                        )}

                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FrmSelectFromObjects
                            name="vendor"
                            options={vendors}
                            label={i18n("frmLabel.PreferredVendor")}
                            onValueChange={handleInputFieldChange}
                            value={record.vendor}
                            sx={{ flexGrow: 1 }}
                            dataTesting="vendor"
                          />
                          {hasAtLeastPlusPlan && (
                            <HoverHelp
                              title={i18n("hoverHelp.preferredVendor")}
                              sx={{ marginLeft: 0.5 }}
                            />
                          )}
                        </div>

                        <FrmTextField
                          name="vendorPartNumber"
                          label={i18n("frmLabel.VendorPartNumber")}
                          onValueBlur={handleInputFieldChange}
                          value={record.vendorPartNumber}
                        />

                        {isInventoryItemOrAssembly(record.type) && (
                          <>
                            <FrmTextFieldWithHoverHelp
                              name="reorderPoint"
                              label={i18n("frmLabel.ReorderPoint")}
                              onValueBlur={handleInputFieldChange}
                              value={record.reorderPoint}
                              error={isInError("reorderPoint")}
                              helpText={i18n("hoverHelp.reorderPoint")}
                              sx={{ width: "8em" }}
                              number
                              dataTesting="reorderPoint"
                            />

                            <FrmTextField
                              name="maxStock"
                              label={i18n("frmLabel.MaxStock")}
                              onValueBlur={handleInputFieldChange}
                              value={record.maxStock}
                              error={isInError("maxStock")}
                              sx={{ width: "8em" }}
                              number
                            />

                            <FrmTextFieldWithHoverHelp
                              name="leadTime"
                              label={i18n("frmLabel.LeadTime")}
                              onValueBlur={handleInputFieldChange}
                              value={record.leadTime}
                              error={isInError("leadTime")}
                              sx={{ width: "8em" }}
                              helpText={i18n("hoverHelp.leadTime")}
                              number
                            />
                          </>
                        )}

                        {bins.length &&
                          isInventoryItemOrAssembly(record.type) && (
                            <FrmSelectFromObjects
                              name="bin"
                              options={formattedBins}
                              label={i18n("frmLabel.DefaultBin")}
                              onValueChange={handleInputFieldChange}
                              value={record.bin}
                            />
                          )}

                        {globalEnabled && (
                          <FrmSelectFromObjects
                            name="purchaseTaxCode"
                            options={purchaseTaxCodes}
                            label={i18n("frmLabel.PurchaseTaxCode")}
                            onValueChange={handleInputFieldChange}
                            value={record.purchaseTaxCode}
                          />
                        )}
                      </CardGrid>
                    </CardContent>
                  </Card>
                </FrmFieldGrid>

                <FrmFieldGrid>
                  <Card
                    variant="outlined"
                    sx={{ marginTop: "1.0em", marginBottom: "1.0em" }}
                  >
                    <CardContent>
                      <CardHeader text={i18n("item.headingSync")} />
                      {!record.hasVariants && (
                        <div style={{ display: "flex", alignItems: "center" }}>
                          <FrmCheckbox
                            name="willSync"
                            value={record.willSync}
                            onValueChange={handleInputFieldChange}
                            label={i18n("frmLabel.SyncWithQuickBooks")}
                            sx={{ marginRight: 0 }}
                          />
                          <HoverHelp
                            title={i18n("hoverHelp.willSync")}
                            sx={{ marginLeft: 0.5 }}
                          />
                        </div>
                      )}

                      <Grid2 size={12}>
                        <FrmCheckbox
                          name="updateBigCommerce"
                          value={record.updateBigCommerce}
                          onValueChange={handleInputFieldChange}
                          label={i18n("frmLabel.UpdateInventoryInBigCommerce")}
                        />
                      </Grid2>

                      <Grid2 size={12}>
                        <FrmCheckbox
                          name="updateShopify"
                          value={record.updateShopify}
                          onValueChange={handleInputFieldChange}
                          label={i18n("frmLabel.UpdateInventoryInShopify")}
                        />
                      </Grid2>
                    </CardContent>
                  </Card>
                  <div />
                </FrmFieldGrid>

                {isInventoryItemOrAssembly(record.type) && (
                  <FrmFieldGrid>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FrmTextField
                        name="weight"
                        label={i18n("frmLabel.Weight")}
                        onValueBlur={handleInputFieldChange}
                        value={record.weight}
                        error={isInError("weight")}
                        sx={{ width: "8em", marginRight: "1.0em" }}
                        number
                      />
                      <FrmUnit
                        name="weightUnit"
                        onValueChange={handleInputFieldChange}
                        value={record.weightUnit}
                        noLabel
                        sx={{ marginTop: "0.25rem" }}
                      />
                    </div>

                    <div style={{ display: "flex", alignItems: "center" }}>
                      <FrmTextField
                        name="volume"
                        label={i18n("frmLabel.Volume")}
                        onValueBlur={handleInputFieldChange}
                        value={record.volume}
                        error={isInError("volume")}
                        sx={{ width: "8em", marginRight: "1.0em" }}
                        number
                      />
                      <FrmUnit
                        name="volumeUnit"
                        onValueChange={handleInputFieldChange}
                        value={record.volumeUnit}
                        noLabel
                        sx={{ marginTop: "0.25rem" }}
                      />
                    </div>

                    <FrmTextField
                      name="url"
                      label={i18n("global.Website")}
                      maxLength={255}
                      onValueBlur={handleInputFieldChange}
                      value={record?.url}
                      sx={{ gridColumnStart: 1 }}
                    />

                    <div>
                      <div style={{ gridColumnStart: "1" }}>
                        {hasAtLeastPlusPlan && (
                          <FrmSelectFromObjects
                            name="warranty"
                            options={warranties}
                            label={i18n("frmLabel.Warranty")}
                            onValueChange={handleInputFieldChange}
                            value={record.warranty}
                          />
                        )}
                      </div>
                    </div>
                  </FrmFieldGrid>
                )}

                <FrmFieldGrid>
                  <div style={{ maxWidth: "20em" }}>
                    <FrmAddImage
                      key={record.imageAsBase64String}
                      record={record}
                      label={i18n(
                        record.imageScaled
                          ? "file.ImageScaledToFit"
                          : "file.Image"
                      )}
                      handleImageChange={handleImageSelection}
                      handleDelete={handleDeleteImage}
                    />
                  </div>

                  {hasAtLeastPlusPlan && (
                    <>
                      <div
                        style={{
                          display: "flex",
                          alignItems: "center",
                          alignSelf: "start",
                          gridColumnStart: 1,
                        }}
                      >
                        <FrmTextFieldWithHoverHelp
                          name="barcode"
                          label={i18n("frmLabel.Barcode")}
                          onValueBlur={handleInputFieldChange}
                          value={record.barcode}
                          helpText={i18n("hoverHelp.barcode")}
                        />
                      </div>
                      <div />
                      {barcodeImage && (
                        <img
                          src={"data:image;base64, " + barcodeImage}
                          alt="barcode"
                        />
                      )}
                    </>
                  )}
                </FrmFieldGrid>

                <FrmFieldGrid>
                  <FrmResizeTextField
                    name="notes"
                    label={i18n("frmLabel.Notes")}
                    minRows={2}
                    onValueBlur={handleInputFieldChange}
                    value={record.notes}
                  />
                  <div />

                  <MultipleSelectField
                    freeSolo
                    createValueOnBlur
                    name="tags"
                    options={tags}
                    onValueChange={handleInputFieldChange}
                    value={record.tags.map((tag) => ({ name: tag }))}
                    label={i18n("frmLabel.Tags")}
                    optionId="name"
                  />
                  <div />
                </FrmFieldGrid>
                <CustomFields
                  customFieldDefinitions={customFieldDefs}
                  customFields={record.customFields || []}
                  onValueChange={handleInputFieldChange}
                  setErrors={setErrors}
                />
              </Box>
            ) : (
              !isInError("record") && (
                <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
              )
            )}
          </Box>
          <VSpace space={4} />
        </ObjectEditDialogContent>
      )}
    </>
  );
}
