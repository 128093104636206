import { useState } from "react";
import { useDispatch } from "react-redux";

import { Typography, Box } from "@mui/material";

import { ActionButton } from "components/ActionButton";
import { ButtonProgress } from "components/utility/ButtonProgress";

import { i18n } from "services/i18nService";
import {
  getSingleton,
  toggleAnnualBilling,
} from "services/sosInventoryService/sosApi";

import { openAlert } from "globalState/alertSlice";

export function AnnualBilling(props) {
  const { annualPlan, setAccountInformation } = props;

  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(false);

  async function handleBillingCycleUpdate() {
    setIsLoading(true);
    const { success, message } = await toggleAnnualBilling(!annualPlan);
    if (success) {
      setAccountInformation(await getSingleton("company/accountInfo"));
      const message = i18n("alert.UpdateBillingCycleSuccess", {
        billingCycle: annualPlan
          ? i18n("accountInformation.annual")
          : i18n("accountInformation.monthly"),
      });
      dispatch(openAlert({ type: "success", message }));
    } else {
      const errorMessage = message || i18n("alert.UpdateBillingCycleError");
      dispatch(openAlert({ type: "error", message: errorMessage }));
    }
    setIsLoading(false);
  }

  return (
    <Box sx={{ display: "flex", gap: 1, alignItems: "center" }}>
      <Typography sx={{ textTransform: "uppercase" }} component="div">
        {annualPlan ? i18n("global.On") : i18n("global.Off")}
      </Typography>
      <div style={{ position: "relative" }}>
        <ActionButton disabled={isLoading} onClick={handleBillingCycleUpdate}>
          {annualPlan
            ? i18n("accountInformation.SwitchToMonthly")
            : i18n("accountInformation.SwitchToAnnual")}
        </ActionButton>
        {isLoading && <ButtonProgress color="secondary" />}
      </div>
    </Box>
  );
}
