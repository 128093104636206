import { useDispatch } from "react-redux";

import { ContentCopy } from "@mui/icons-material";
import { IconButton } from "@mui/material";

import { Tooltip } from "components/Tooltip";

import { i18n } from "services/i18nService";

import { openAlert } from "globalState/alertSlice";

import { theme } from "SosTheme";

export function CopyToClipboard(props) {
  const { copyText } = props;
  const dispatch = useDispatch();

  function copyToClipboard() {
    navigator.clipboard.writeText(copyText);
    const message = i18n("alert.CopiedToClipboard");
    dispatch(openAlert({ type: "success", message }));
  }

  return (
    <Tooltip title={i18n("tooltip.CopyToClipboard")}>
      <IconButton
        sx={{ marginLeft: "1rem" }}
        size="small"
        onClick={copyToClipboard}
      >
        <ContentCopy sx={{ "&:hover": { fill: theme.palette.selectIcon } }} />
      </IconButton>
    </Tooltip>
  );
}
