import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Box, ListItemText, MenuItem, MenuList } from "@mui/material";
import { teal } from "@mui/material/colors";

import { getConfigurationHeight } from "services/utility/misc";
import { dirtyFnCheck } from "services/utility/misc";

import { usePlans } from "hooks/usePlans";
import { usePrivileges } from "hooks/usePrivileges";

import { ConfigurationList } from "views/Configuration/ConfigurationList";
import { SettingsPage } from "views/Configuration/SettingsPage";
import {
  CONFIGURATION_NAVIGATION,
  getLevel2Selections,
  getLevel1Privilege,
  getLevel1Setting,
  getRoutePart,
} from "views/Configuration/navigationStructure";

import { Forbidden } from "Forbidden";

function topLevelStyling(selected) {
  return {
    fontWeight: "bold",
    backgroundColor: selected ? teal[200] : "inherit",
    "&:hover": {
      backgroundColor: selected ? teal[200] : teal[50],
    },
  };
}

function secondLevelStyling(selected) {
  return {
    backgroundColor: selected ? teal[100] : "inherit",
    "&:hover": {
      backgroundColor: selected ? teal[100] : teal[25],
    },
  };
}

const navWrapperStyles = {
  height: "100%",
  overflow: "auto",
  borderRight: "1px solid #cccccc",
};

const listSelections = getLevel2Selections("list");
const user = getRoutePart("user");

export function ConfigurationNav(props) {
  // Level 1 is the left-most nav column, the main config entries
  // Level 2 is the next nav column, the "sub-nav" for the main selection
  // level1 and level2 props are strings, mapping to the routePart properties
  // in SETTINGS
  const { level1, level2 } = props;

  const settings = useSelector((state) => state.userCompanySettings.settings);

  const navigate = useNavigate();
  const { hasAtLeastPlusPlan, hasAtLeastProPlan } = usePlans();
  const { hasPrivilegesOrIsAdmin } = usePrivileges();

  // if a level1 is selected, show the related level2 entries
  const level2Selections = level1 ? getLevel2Selections(level1) : [];

  function handleLevel1Selected(level1Selected) {
    dirtyFnCheck(() => navigate(`/configuration/${level1Selected}`));
  }

  function handleLevel2Selected(level2Selected) {
    dirtyFnCheck(() => navigate(`/configuration/${level1}/${level2Selected}`));
  }

  return (
    <Box
      sx={{
        display: "grid",
        gridTemplateColumns: "10rem 10rem 1fr",
        height: getConfigurationHeight(),
      }}
    >
      {/* level 1 nav */}
      <Box sx={navWrapperStyles}>
        <MenuList sx={{ mt: 1 }}>
          {CONFIGURATION_NAVIGATION.map(
            ({ label, routePart, privilege, setting }) =>
              hasPrivilegesOrIsAdmin(privilege) &&
              (!setting || settings[setting]) ? (
                <MenuItem
                  key={`${level2}-${label}`}
                  onClick={() => handleLevel1Selected(routePart)}
                  sx={topLevelStyling(level1 === routePart)}
                >
                  <ListItemText sx={{ fontWeight: "fontWeightBold" }}>
                    {label}
                  </ListItemText>
                </MenuItem>
              ) : null
          )}
        </MenuList>
      </Box>

      {/* level 2 nav */}
      <Box sx={navWrapperStyles}>
        <MenuList sx={{ mt: 1 }}>
          {level2Selections.map(
            ({
              label,
              routePart,
              planIncludesEntryType,
              privilege,
              hideInLevel2List,
            }) => {
              if (
                (!planIncludesEntryType ||
                  planIncludesEntryType(
                    hasAtLeastPlusPlan,
                    hasAtLeastProPlan
                  )) &&
                hasPrivilegesOrIsAdmin(privilege) &&
                !hideInLevel2List
              ) {
                return (
                  <MenuItem
                    key={label}
                    onClick={() => handleLevel2Selected(routePart)}
                    sx={secondLevelStyling(level2 === routePart)}
                  >
                    <ListItemText>{label}</ListItemText>
                  </MenuItem>
                );
              } else {
                return null;
              }
            }
          )}
        </MenuList>
      </Box>

      <Box>
        <LevelTwoNav level1={level1} level2={level2} />
      </Box>
    </Box>
  );
}

function LevelTwoNav(props) {
  const { level1, level2 } = props;

  const { hasPrivilegesOrIsAdmin } = usePrivileges();
  const { hasAtLeastPlusPlan, hasAtLeastProPlan } = usePlans();

  const settings = useSelector((state) => state.userCompanySettings.settings);

  const privilege = getLevel1Privilege(level1);
  const setting = getLevel1Setting(level1);

  function isSelected(objectType) {
    return level2 === objectType;
  }

  if (!hasPrivilegesOrIsAdmin(privilege)) {
    return <Forbidden />;
  }

  if (setting && !settings[setting]) {
    return <Forbidden />;
  }

  if (level1 !== "list" && level2 !== "user" && Boolean(level2)) {
    return <SettingsPage key={level2} level1={level1} level2={level2} />;
  }

  return [...listSelections, user].map(
    ({ routePart, planIncludesEntryType, privilege }, i) => {
      if (!isSelected(routePart)) {
        return null;
      }
      if (
        (!planIncludesEntryType ||
          planIncludesEntryType(hasAtLeastPlusPlan, hasAtLeastProPlan)) &&
        hasPrivilegesOrIsAdmin(privilege)
      ) {
        return <ConfigurationList key={i} objectType={routePart} />;
      } else {
        return <Forbidden key={i} />;
      }
    }
  );
}
