import { i18n } from "services/i18nService";

import { SUB_COMPONENT } from "views/Reports/reportConstants";

import { ALL_FILTER_OPTION } from "appConstants";

export const ExportInvoices = {
  userHasSufficientPrivilege: () => true,
  planIncludesReport: () => true,
  customReportNameFetchList: [],
  customFieldObjectTypes: ["invoice"],
  columns: [
    { apiName: "InvoiceNumber", label: "Invoice #", default: true },
    { apiName: "Customer" },
    { apiName: "Date", default: true },
    { apiName: "BillAddressLine1", label: "Billing Addr Line 1" },
    { apiName: "BillAddressLine2", label: "Billing Addr Line 2" },
    { apiName: "BillAddressLine3", label: "Billing Addr Line 3" },
    { apiName: "BillAddressLine4", label: "Billing Addr Line 4" },
    { apiName: "BillAddressLine5", label: "Billing Addr Line 5" },
    { apiName: "BillAddressCity", label: "Billing Addr City" },
    { apiName: "BillAddressState", label: "Billing Addr State" },
    { apiName: "BillAddressPostalCode", label: "Billing Addr Postal Code" },
    { apiName: "BillAddressCountry", label: "Billing Addr Country" },
    { apiName: "ShipAddressLine1", label: "Shipping Addr Line 1" },
    { apiName: "ShipAddressLine2", label: "Shipping Addr Line 2" },
    { apiName: "ShipAddressLine3", label: "Shipping Addr Line 3" },
    { apiName: "ShipAddressLine4", label: "Shipping Addr Line 4" },
    { apiName: "ShipAddressLine5", label: "Shipping Addr Line 5" },
    { apiName: "ShipAddressCity", label: "Shipping Addr City" },
    { apiName: "ShipAddressState", label: "Shipping Addr State" },
    { apiName: "ShipAddressPostalCode", label: "Shipping Addr Postal Code" },
    { apiName: "ShipAddressCountry", label: "Shipping Addr Country" },
    { apiName: "Category" },
    { apiName: "Item", default: true },
    { apiName: "SKU" },
    { apiName: "Description", default: true },
    { apiName: "Quantity", default: true },
    { apiName: "UOM" },
    { apiName: "Rate", label: "Unit Price", number: true },
    { apiName: "Amount", number: true },
    { apiName: "AmountValue", label: "Amount Value", number: true },
    { apiName: "BillEmail", label: "Billing Email" },
    { apiName: "BillPhone", label: "Billing Phone" },
    { apiName: "ShipEmail", label: "Shipping Email" },
    { apiName: "ShipPhone", label: "Shipping Phone" },
    { apiName: "BillCompany", label: "Billing Company" },
    { apiName: "BillContact", label: "Billing Contact" },
    { apiName: "ShipCompany", label: "Shipping Company" },
    { apiName: "ShipContact", label: "Shipping Contact" },
    { apiName: "CustomerMessage", label: "Customer Message" },
    { apiName: "Memo", label: "Comment" },
    { apiName: "Shipping", label: "Shipping Amount", number: true },
    { apiName: "ShippingValue", label: "Shipping Amount Value" },
    { apiName: "CustomerPO", label: "Customer PO" },
    { apiName: "Terms" },
    { apiName: "SalesRep", label: "Sales Rep" },
    { apiName: "TaxCode", label: "Tax Code" },
    { apiName: "Currency" },
    { apiName: "ExchangeRate", label: "Exchange Rate", number: true },
    { apiName: "Department" },
    { apiName: "Channel" },
    { apiName: "Class" },
    { apiName: "BalanceRemaining", label: "Balance Remaining", number: true },
    { apiName: "TrackingNumber", label: "Tracking Number" },
    { apiName: "ShipDate", label: "Ship Date" },
    { apiName: "ShipMethod", label: "Ship Method" },
    { apiName: "Job" },
    { apiName: "Tax" },
    { apiName: "TaxValue", label: "Tax Value", number: true },
    { apiName: "TotalAmount", label: "Total Amount" },
    { apiName: "TotalAmountValue", label: "Total Amount Value" },
    { apiName: "DueDate", label: "Due Date" },
    { apiName: "Taxable", label: "Taxable" },
    { apiName: "LineNumber", label: "Line Number", number: true },
    { apiName: "DepositAmount", label: "Deposit Amount" },
    { apiName: "DepositAmountValue", label: "Deposit Amount Value" },
    { apiName: "DiscountAmount", label: "Discount Amount" },
    { apiName: "DiscountAmountValue", label: "Discount Amount Value" },
    { apiName: "DiscountPercent", label: "Discount Percent" },
    { apiName: "DiscountTaxable", label: "Discount Taxable" },
    { apiName: "Archived" },
    { apiName: "Starred", number: true },
    { apiName: "CustomerPartNumber", label: "Customer Part Number" },
    { apiName: "SyncMessage", label: "Sync Message" },
    { apiName: "LastSync", label: "Last Sync" },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date1Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("filter.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Channel"),
      component: "ChannelFilter",
      apiName: "channel",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Class"),
      component: "ClassFilter",
      apiName: "class",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.CustomerType"),
      component: "CustomerTypeFilter",
      apiName: "customerType",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Department"),
      component: "DepartmentFilter",
      apiName: "department",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.SalesRep"),
      component: "SalesRepFilter",
      apiName: "salesReps",
      defaultValue: [ALL_FILTER_OPTION],
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.KeywordSearch"),
      component: "FrmTextFieldWithHoverHelp",
      apiName: "nameContains",
      defaultValue: null,
      helpText: i18n("reports.hoverHelp.PurchaseKeywordSearch"),
      type: "filter",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedTransactions"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
