import { i18n } from "services/i18nService";

import {
  IN_STOCK_OPTION,
  INVENTORY_STATUS_OPTIONS,
} from "views/Reports/reportConstants";

export const ExportSerialInventory = {
  userHasSufficientPrivilege: () => true,
  planIncludesReport: (plus) => plus,
  customReportNameFetchList: [],
  customFieldObjectTypes: ["serial"],
  columns: [
    { apiName: "SerialNumber", label: "Serial Number", default: true },
    { apiName: "Item", default: true },
    { apiName: "Location", default: true },
    { apiName: "Description", default: true },
    { apiName: "Cost", label: "Cost", number: true },
    { apiName: "Net", label: "Net", number: true },
    { apiName: "Customer" },
    { apiName: "History" },
    { apiName: "Deleted" },
    { apiName: "Starred", number: true },
    { apiName: "PictureFile", label: "Picture File" },
    { apiName: "AdjustedOut", label: "Adjusted Out" },
    { apiName: "OriginalDate", label: "Original Date" },
    { apiName: "Warranty" },
    { apiName: "WarrantyStart", label: "Warranty Start" },
    { apiName: "WarrantyEnd", label: "Warranty End" },
    { apiName: "Bin" },
    { apiName: "Vendor" },
  ],
  settings: [
    {
      label: i18n("filter.Location"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: null,
      required: true,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Category"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.KeywordSearch"),
      component: "FrmTextField",
      apiName: "nameContains2",
      defaultValue: null,
      helpText: i18n("reports.hoverHelp.SerialNumberContains"),
      type: "filter",
    },
    {
      label: i18n("filter.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "txnType",
      defaultValue: IN_STOCK_OPTION,
      label: "Status",
      component: "FrmSelectFromObjects",
      options: INVENTORY_STATUS_OPTIONS,
      type: "filter",
    },
    {
      apiName: "showDeleted",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeDeletedSerials"),
      component: "FrmCheckbox",
      type: "option",
    },

    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
