import { useEffect, useState } from "react";

import { Alert as MuiAlert } from "@mui/material";

import { Decimal } from "classes/DecimalClasses";

import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import {
  getSingleton,
  getListPageRecords,
} from "services/sosInventoryService/sosApi";
import { formatMoneyWithAdornments } from "services/utility/formatting";

function atUserLimit(accountInfo) {
  const { plan, activeUsers } = accountInfo;
  return activeUsers >= plan.userLimit;
}

export function MaxUserWarning() {
  const [accountInfo, setAccountInfo] = useState(null);

  useEffect(() => {
    async function _getAccountInfo() {
      const [response, { count }] = await Promise.all([
        await getSingleton("company/accountInfo"),
        await getListPageRecords("user", { status: "active" }),
      ]);

      if (response) {
        setAccountInfo({ ...response, activeUsers: count });
      }
    }
    _getAccountInfo();
  }, []);

  if (!accountInfo || !atUserLimit(accountInfo)) {
    return null;
  }

  const { activeUsers, plan, isTrial } = accountInfo;

  const annualPlan = false;

  const feePeriod = i18n(
    annualPlan
      ? "settings.user.general.feePeriod.year"
      : "settings.user.general.feePeriod.month"
  );
  const fee = formatMoneyWithAdornments(
    annualPlan
      ? plan.extraUserMonthlyPrice.times(new Decimal(12))
      : plan.extraUserMonthlyPrice
  );

  const warningText = i18n(
    isTrial
      ? "settings.user.general.trialMaxUserWarning"
      : "settings.user.general.maxUserWarning",
    { activeUsers, fee, feePeriod }
  );

  return (
    <>
      <MuiAlert severity="warning">{warningText}</MuiAlert>
      <VSpace space={2} />
    </>
  );
}
