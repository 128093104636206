import { useSelector } from "react-redux";

import { Box } from "@mui/material";

import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmTextField } from "components/formFields/FrmTextField";
import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";

import { theme } from "SosTheme";
import { OBJECT_TYPES } from "appConstants";

export function TotalsTax(props) {
  const {
    objectType,
    value,
    taxCode,
    percentValue,
    onPercentChange,
    setRecord,
    taxCodes,
    gridTemplateColumns,
  } = props;

  // SETTINGS
  const automatedSalesTax = useSelector(
    (state) => state.userCompanySettings.settings.automatedSalesTax
  );
  const globalEnabled = useSelector(
    (state) => state.userCompanySettings.settings.globalEnabled
  );

  const disabledTaxFields =
    automatedSalesTax &&
    (objectType === OBJECT_TYPES.SALES_RECEIPT.fullString ||
      objectType === OBJECT_TYPES.INVOICE.fullString);

  function onTaxCodeChange(_, taxCode) {
    const { salesTaxRate } = taxCodes.find(({ id }) => id === taxCode.id);
    setRecord((prevRecord) => ({
      ...prevRecord,
      taxCode,
      taxPercent: salesTaxRate ? salesTaxRate : prevRecord.taxPercent,
      taxUpdated: true,
    }));
  }

  const taxCodesWithPercent = taxCodes?.map((code) => ({
    ...code,
    name: `${code.name} (${code.salesTaxRate.toString()})`,
  }));

  return (
    <Box display="flex" alignItems="center" justifyContent="flex-end">
      <div
        style={{
          display: "grid",
          gridTemplateColumns,
          columnGap: theme.spacing(1),
          alignItems: "center",
        }}
      >
        <FrmSelectFromObjects
          name="taxCode"
          label={i18n(
            `frmLabel.${globalEnabled ? "ShippingDiscountTaxCode" : "TaxCode"}`
          )}
          value={taxCode}
          onValueChange={onTaxCodeChange}
          options={taxCodesWithPercent}
          disabled={disabledTaxFields}
          disableClearable
        />
        <FrmTextField
          number
          name="taxPercent"
          label={i18n(
            `frmLabel.${globalEnabled ? "ShippingDiscountTax" : "TaxPercent"}`
          )}
          value={percentValue?.toString()}
          onValueBlur={onPercentChange}
          dataTesting="taxPercent"
          disabled={disabledTaxFields}
        />
        <div
          style={{
            fontWeight: theme.typography.fontWeightRegular,
            textAlign: "right",
            padding: "0 14px",
          }}
        >
          <Box sx={{ marginTop: "0.35em" }} data-testing="taxAmount">
            {value.toString()}
          </Box>
        </div>
        <div style={{ fontWeight: theme.typography.fontWeightMedium }}>
          <Box
            sx={{ display: "flex", alignItems: "center", marginTop: "0.3em" }}
          >
            {i18n("global.Tax")}
            {automatedSalesTax && (
              <>
                <Box sx={{ display: "flex" }}>
                  <HoverHelp
                    title={i18n(
                      objectType === OBJECT_TYPES.SALES_ORDER.fullString
                        ? "hoverHelp.AutomatedSalesTaxSalesOrder"
                        : "hoverHelp.AutomatedSalesTax"
                    )}
                    sx={{ marginLeft: 0.5 }}
                  />
                </Box>
              </>
            )}
          </Box>
        </div>
      </div>
    </Box>
  );
}
