import { i18n } from "services/i18nService";
import { quantitySerialsIsValid } from "services/utility/isValid";

import { initialErrorState } from "views/common";

import { ITEM_TYPES_REQUIRING_BINS } from "appConstants";

export function isValid(record, relatedRecords, lines) {
  let errors = initialErrorState();

  errors.entity = [];
  lines.forEach(({ lineNumber }) => {
    errors.line[lineNumber] = [];
  });

  // has a valid date
  if (!(record.date instanceof Date)) {
    errors.messages.push(i18n("isValid.Date"));
    errors.entity.push("date");
  }

  lines.forEach((line) => {
    // a bin has been chosen, if the item and location require it
    if (
      relatedRecords.location?.binTracking &&
      line.relatedRecords.item &&
      ITEM_TYPES_REQUIRING_BINS.includes(line.relatedRecords.item?.type) &&
      !line.bin?.id
    ) {
      errors.messages.push(
        i18n("isValid.LineItem.MissingBin", {
          lineNumber: line.lineNumber,
        })
      );
      errors.line[line.lineNumber].push("bin");
    }
  });

  errors = quantitySerialsIsValid(errors, lines, record.autoSerialLots);
  return errors;
}
