import { useState, useEffect } from "react";

import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { Autocomplete, TextField } from "@mui/material";

import { Tooltip } from "components/Tooltip";
import {
  textFieldStyle,
  inputPropStyles,
  clearIndicatorStyles,
} from "components/formFields/lineItemFieldStyle";
import { ButtonProgress } from "components/utility/ButtonProgress";

import { checkForUnexpectedProps } from "services/utility/misc";

function getBorderStyling(error) {
  return {
    "& .MuiOutlinedInput-root": {
      "& fieldset": {
        borderWidth: error ? "1px" : 0,
        borderColor: "warning.error",
      },
    },
  };
}

export function TransactionSelect(props) {
  const {
    line,
    onValueChange,
    value,
    options,
    onInputChange,
    onBlur,
    fetchItemsOnChange,
    loading,
    error,
    ...unexpected
  } = props;
  checkForUnexpectedProps("TransactionSelect", unexpected);

  const [userInput, setUserInput] = useState("");
  const [currentValue, setCurrentValue] = useState(value);

  useEffect(() => setCurrentValue(value), [value]);

  // this gets called whenever the user changes the selected option
  function onChange(_, newValue, reason) {
    if (reason === "clear") {
      setCurrentValue(null);
      setUserInput("");
      onValueChange(null);
      return;
    }
    setCurrentValue(newValue);
    onValueChange(newValue, userInput);
  }

  // this gets called when the component needs to know what to display
  // in the input field; not the value, which is the item id, but the
  // human-friendly *name* of the item
  function getOptionLabel(option) {
    // option or option.id can be blank on a newly inserted line;
    // and if we're adding a new item we don't want any text in the
    // component
    if (!Boolean(option?.id)) {
      return "";
    }
    if (currentValue?.name) {
      return currentValue.name;
    }
    const selectedOption = options.find(({ id }) => id === option.id);
    return selectedOption ? selectedOption.name : "";
  }

  // this gets called for each option, when the selected option changes, to
  // determine which, of all the options, was selected
  function isOptionEqualToValue(option, value) {
    return option.id === value.id || (value === "" && option.id === "");
  }

  // this gets called when the user changes what is in the input field; it
  // should return an array of all the options that meet whatever criteria
  // we want to use; in the current case, we're just looking for a simple
  // substring match
  function filterOptions(options, state) {
    return options.filter(({ name }) => {
      // if we're making API calls for queries, the list of options will
      // always be matches, so no need to check; however, if there's a value
      // already selected, skip this and match against that value's name,
      // below
      if (fetchItemsOnChange && !currentValue?.name) {
        return true;
      }

      // when focusing back to this field, with a value already selected,
      // state.inputValue will be null (the user hasn't typed anything new
      // here); so use the currentValue instead; if no current value, set
      // the value to match against to ""
      const matchValue = state.inputValue
        ? state.inputValue
        : currentValue?.name || "";
      return name.toLowerCase().includes(matchValue.toLowerCase());
    });
  }

  function renderOption(props, option) {
    return (
      <li {...props} key={option.id}>
        <span data-testing="selectOption">{option.name}</span>
      </li>
    );
  }

  const isLoading = !Array.isArray(options);

  return (
    <div style={{ position: "relative" }}>
      <Autocomplete
        fullWidth
        value={currentValue}
        loading={isLoading}
        options={isLoading ? [] : options}
        disableListWrap={true}
        isOptionEqualToValue={isOptionEqualToValue}
        onChange={onChange}
        onBlur={onBlur}
        getOptionLabel={getOptionLabel}
        filterOptions={filterOptions}
        onInputChange={onInputChange}
        data-testing="transactionSelect"
        popupIcon={<KeyboardArrowDownIcon sx={{ color: "selectIcon" }} />}
        sx={clearIndicatorStyles}
        renderInput={(params) => {
          return (
            <Tooltip title={currentValue?.name} placement="top-start">
              <TextField
                {...params}
                disabled={loading}
                variant="outlined"
                margin="dense"
                sx={{ ...textFieldStyle, ...getBorderStyling(error) }}
                onChange={(e) => setUserInput(e.target.value)}
                InputProps={{
                  ...params.InputProps,
                  sx: inputPropStyles,
                  style: { paddingRight: "25px" },
                }}
                InputLabelProps={{ shrink: true }}
                error={error}
              />
            </Tooltip>
          );
        }}
        renderOption={renderOption}
      />
      {loading && <ButtonProgress dataTesting="itemLoading" />}
    </div>
  );
}
