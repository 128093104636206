import { Decimal, Money } from "classes/DecimalClasses";

import {
  FLAT_DISCOUNT_OPTION,
  PERCENT_DISCOUNT_OPTION,
  ITEM_DISCOUNT_OPTION,
  FIELDS_TO_TRANSFORM,
  ITEM_TABLE_FIELDS_TO_TRANSFORM,
} from "services/sosInventoryService/priceTier/schema";
import { transformFields } from "services/utility/afterGet";

function isPriceTierEditLocked(record) {
  const { items, amountDiscount, percentDiscount } = record;
  return (
    [
      items.length,
      amountDiscount.gt(Money.ZERO),
      percentDiscount.gt(Decimal.ZERO),
    ].filter(Boolean).length > 1
  );
}

function getType(record) {
  if (record.items.length) {
    return ITEM_DISCOUNT_OPTION;
  } else if (record.percentDiscount) {
    return PERCENT_DISCOUNT_OPTION;
  } else {
    return FLAT_DISCOUNT_OPTION;
  }
}

export function afterGet(record) {
  const newRecord = transformFields(record, FIELDS_TO_TRANSFORM);

  newRecord.items = record.items.map((line) =>
    transformFields(line, ITEM_TABLE_FIELDS_TO_TRANSFORM)
  );

  newRecord.type = getType(record);
  newRecord.isEditLocked = isPriceTierEditLocked(newRecord);

  return newRecord;
}
