import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { useRelated } from "hooks/useRelated";

export function FrmOrderStage(props) {
  const {
    value,
    onValueChange,
    label,
    maxWidth,
    name = "orderStage",
    disableClearable,
    error,
  } = props;

  const orderStages = useRelated("orderstage");

  return (
    <FrmSelectFromObjects
      name={name}
      options={orderStages}
      value={value}
      label={label}
      onValueChange={onValueChange}
      maxWidth={maxWidth}
      error={error}
      disableClearable={disableClearable}
    />
  );
}
