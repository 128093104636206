import { Decimal, Money } from "classes/DecimalClasses";

import {
  getItemRecord,
  getDefaultBin,
} from "services/sosInventoryService/domainLogic";
import { getItemLocationSettings } from "services/sosInventoryService/sosApi";
import { calculateAdjustmentCostBasis } from "services/sosInventoryService/sosApi";
import { afterTouchLine as transferAfterTouchLine } from "services/sosInventoryService/transfer/afterTouchLine";
import { formatBinInfo } from "services/utility/formatting";
import { filterAndFormatBinOptions } from "services/utility/misc";
import {
  getBaseUom,
  getUomConversionFromUomReference,
} from "services/utility/uoms";

export async function fetchCostBasis(line, date) {
  if (!line.item?.id) {
    return new Money(0);
  }

  const conversion = getUomConversionFromUomReference(line.uom, line.item.uoms);
  const { costBasis } = await calculateAdjustmentCostBasis(
    line.item.id,
    line.quantityDiff.times(conversion),
    date
  );
  return costBasis;
}

export async function updateTransferLineWithItem(item, line, record) {
  const { fromLocation, toLocation } = record;
  if (!item) {
    return line;
  }
  // transfer the appropriate properties from the new inventory
  // item to the line item
  const newLine = {
    ...line,
    item,
    description: item.description,
    uom: getBaseUom(item.uoms),
    onhand: item.onhand,
    relatedRecords: { ...line.relatedRecords, item },
    serials: [],
    itemDetails: {
      itemUoms: item.uoms,
      serialTracking: item.serialTracking,
      lotTracking: item.lotTracking,
      type: item.type,
    },
  };

  // if we have locationBins, update bin options
  if (item.locationBins) {
    const filteredAndFormattedBinOptions = filterAndFormatBinOptions(
      item.locationBins
    );

    newLine.availableBins = filteredAndFormattedBinOptions;
    // and set the default bin on the line
    newLine.fromBin = await getDefaultBin(
      item.id,
      fromLocation.id,
      filteredAndFormattedBinOptions
    );
    const {
      data: { defaultBin },
    } = await getItemLocationSettings(item.id, toLocation.id);
    newLine.toBin = defaultBin;
  } else {
    newLine.availableBins = null;
    newLine.fromBin = null;
  }
  return newLine;
}

export async function updateAdjustmentLineWithItem(item, line, record) {
  const { location } = record;
  if (!item) {
    return line;
  }
  // transfer the appropriate properties from the new inventory
  // item to the line item
  const newLine = {
    ...line,
    item,
    description: item.description,
    uom: getBaseUom(item.uoms),
    available: item.available,
    onhand: item.onhand,
    newQuantity: item.onhand,
    quantityDiff: Decimal.ZERO,
    valueDiff: Decimal.ZERO,
    relatedRecords: { ...line.relatedRecords, item },
    serials: [],
    itemDetails: {
      itemUoms: item.uoms,
      serialTracking: item.serialTracking,
      lotTracking: item.lotTracking,
      type: item.type,
    },
  };

  // if we have locationBins, update bin options
  if (item.locationBins) {
    const filteredAndFormattedBinOptions = formatBinInfo(item.locationBins);
    newLine.availableBins = filteredAndFormattedBinOptions;
    // and set the default bin on the line
    newLine.bin = await getDefaultBin(
      item.id,
      location.id,
      filteredAndFormattedBinOptions
    );
  } else {
    newLine.availableBins = null;
    newLine.bin = null;
  }

  return newLine;
}

export async function updateTransferLineRelatedRecordsItem(
  fromLocation,
  date,
  lines,
  itemFieldsNeeded
) {
  return await Promise.all(
    lines.map(async (line) => {
      if (line.item?.id) {
        const item = await getItemRecord(
          line.item.id,
          fromLocation?.id,
          date,
          itemFieldsNeeded
        );
        const uom = item.uoms.find(({ uom }) => uom.id === line.uom.id);
        const newLine = {
          ...line,
          uom: uom ? { ...uom, id: line.uom.id } : null,
          onhand: item.onhand,
          relatedRecords: { ...line.relatedRecords, item },
        };
        if (item.locationBins) {
          newLine.availableBins = filterAndFormatBinOptions(item.locationBins);
        }
        return transferAfterTouchLine(newLine, "uom");
      }
      return line;
    })
  );
}
