import { Print } from "@mui/icons-material";
import { DialogTitle, IconButton, Button, Box } from "@mui/material";

import { CloseX } from "components/utility/CloseX";
import { ModalTitle } from "components/utility/ModalTitle";

import { i18n } from "services/i18nService";

import { theme } from "SosTheme";

const sx = {
  borderRadius: 1,
  padding: "0.4rem",
  height: "36px",
  border: "1px solid",
  borderColor: "lineSeparatorMinor",
  backgroundColor: "white",
};

export function ModalHeader(props) {
  const { label, onClose, text, save, hidePrint, disableSave } = props;

  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: "dialogTitleBackground",
          borderBottom: "1px solid",
          borderBottomColor: "lineSeparatorMinor",
          padding: "0.5rem 1rem",
        }}
      >
        <div
          style={{
            display: "grid",
            alignItems: "top",
            gridTemplateColumns: "1fr 200px",
          }}
        >
          <div style={{ display: "flex", alignItems: "top" }}>
            <CloseX onClick={onClose} dataTesting="exitEdit" />
            <ModalTitle label={label} text={text} />
          </div>

          <Box sx={{ displayPrint: "none" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "flex-end",
                flexWrap: "wrap",
                gap: theme.spacing(1),
              }}
            >
              {!hidePrint && (
                <IconButton onClick={() => window.print()} sx={sx} size="large">
                  <Print />
                </IconButton>
              )}

              <div style={{ width: "em" }}>
                <Button
                  variant="contained"
                  disabled={disableSave}
                  onClick={save}
                  disableElevation
                  fullWidth
                  data-testing="saveAndClose"
                >
                  {i18n("saveActions.SaveAndClose")}
                </Button>
              </div>
            </div>
          </Box>
        </div>
      </DialogTitle>
    </>
  );
}
