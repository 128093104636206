export function afterGet(record) {
  const newRecord = {
    ...record,
    paymentNumber: {
      prefix: record.prefix,
      nextNumber: record.nextNumber,
    },
    template: record.templateId ? { id: +record.templateId } : null,
  };

  delete newRecord.prefix;
  delete newRecord.nextNumber;

  return newRecord;
}
