import { useEffect, useState } from "react";
import { useSelector } from "react-redux";

import { getScheduleForStandardReport } from "services/sosInventoryService/sosApi";

import { ReportPage } from "views/Reports/ReportPage";

import { Forbidden } from "Forbidden";

export function StandardReport(props) {
  const { reportType, config } = props;

  const [reportConfig, setReportConfig] = useState({
    id: undefined,
    ...config[reportType],
    reportType,
  });

  const restrictedSalesRep = useSelector(
    (state) => state.userCompanySettings.company.restrictedSalesRep
  );

  useEffect(() => {
    async function getSchedule() {
      const schedule = await getScheduleForStandardReport(reportType);
      if (schedule) {
        const scheduleWithSaved = {
          ...schedule,
          saved: true,
        };
        setReportConfig((prev) => ({
          ...prev,
          schedule: scheduleWithSaved,
        }));
      }
    }
    getSchedule();
  }, [reportType]);

  if (restrictedSalesRep) {
    return <Forbidden />;
  }

  return <ReportPage reportConfig={reportConfig} />;
}
