import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { SelectButtonCombo } from "components/formFields/SelectButtonCombo";

import { i18n } from "services/i18nService";
import { afterTouchLine } from "services/sosInventoryService/fulfillmentTransaction/afterTouchLine";
import { getRecord } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";
import { getAddOrderPlaceholder } from "services/utility/misc";
import {
  getBaseUom,
  getUomConversionFromUomReference,
} from "services/utility/uoms";

import { useCustomerData } from "hooks/useCustomerData";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { getObjectFromFullString } from "appConstants";

export function AddCustomerOrder(props) {
  const { customer, lineHandler, objectType, label } = props;

  const dispatch = useDispatch();

  const [value, setValue] = useState(null);
  const options = useCustomerData(customer, objectType);

  // reset the selection whenever the customer changes
  useEffect(() => setValue(null), [customer]);

  async function addOrder() {
    if (!value) {
      return;
    }
    const { objectType, txnId } = value;
    dispatch(editModalLoadingIndicatorOn());
    const sourceOrder = await getRecord(objectType, txnId);
    const nonZeroLines = sourceOrder.lines.filter(({ shipped, quantity }) =>
      shipped.lt(quantity)
    );
    const transactionType = getObjectFromFullString(objectType).shortString;

    const newLines = await Promise.all(
      nonZeroLines.map(async (line) => {
        const item = await getRecord("item", line.item.id);
        const uom = line.uom || getBaseUom(item.uoms);
        const conversion = getUomConversionFromUomReference(uom, item.uoms);
        const newLine = {
          ...line,
          linkedTransaction: {
            id: sourceOrder.id,
            refNumber: sourceOrder.number,
            lineNumber: line.lineNumber,
            transactionType,
          },
          quantity: line.quantity.minus(line.shipped),
          onhand: item.onhand.div(conversion),
          relatedRecords: { item },
          itemDetails: {
            itemWeight: item.weight,
            itemVolume: item.volume,
            itemUoms: item.uoms,
            serialTracking: item.serialTracking,
            lotTracking: item.lotTracking,
            type: item.type,
            useMarkup: item.useMarkup,
            markupPercent: item.markupPercent,
            baseSalesPrice: item.baseSalesPrice,
          },
        };
        delete newLine.id;
        return afterTouchLine(newLine, "quantity");
      })
    );
    if (newLines.length) {
      setPageDirty();
      lineHandler({ type: "append", newLines });
    }

    dispatch(editModalLoadingIndicatorOff());
  }

  return (
    <SelectButtonCombo
      key={options}
      disableClearable
      maxWidth="12rem"
      minWidth="12rem"
      selectLabel={label}
      buttonLabel={i18n("global.Add")}
      value={value}
      onValueChange={(_, { id }) => setValue(options.find((o) => o.id === id))}
      onClick={addOrder}
      options={options}
      placeholder={getAddOrderPlaceholder(
        options,
        i18n("addOrder.SelectOrder")
      )}
      disabled={!Boolean(customer)}
    />
  );
}
