import { i18n } from "services/i18nService";

import {
  OPEN_CLOSED_OPTIONS,
  ALL_OPTION_WITH_ALL_ID,
} from "views/Reports/reportConstants";

export const ExportJobs = {
  userHasSufficientPrivilege: () => true,
  planIncludesReport: (_, pro) => pro,
  customReportNameFetchList: [],
  customFieldObjectTypes: ["job"],
  columns: [
    { apiName: "Name", default: true },
    { apiName: "Customer" },
    { apiName: "JobNumber", label: "Job Number" },
    { apiName: "Description" },
    { apiName: "WorkCenters", label: "Work Centers" },
    { apiName: "AddressLine1", label: "Address Line 1" },
    { apiName: "AddressLine2", label: "Address Line 2" },
    { apiName: "AddressLine3", label: "Address Line 3" },
    { apiName: "AddressLine4", label: "Address Line 4" },
    { apiName: "AddressLine5", label: "Address Line 5" },
    { apiName: "AddressCity", label: "Address City" },
    { apiName: "AddressState", label: "Address State" },
    { apiName: "AddressPostalCode", label: "Address Postal Code" },
    { apiName: "AddressCountry", label: "Address Country" },
    { apiName: "Closed" },
    { apiName: "Deleted" },
    { apiName: "Archived" },
    { apiName: "Starred", number: true },
  ],
  settings: [
    {
      label: i18n("reports.KeywordSearch"),
      component: "FrmTextFieldWithHoverHelp",
      apiName: "nameContains",
      defaultValue: null,
      helpText: i18n("reports.hoverHelp.PurchaseKeywordSearch"),
      type: "filter",
    },
    {
      label: i18n("filter.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "orderType",
      label: i18n("reports.OptionLabel.OpenClosed"),
      component: "FrmSelectFromObjects",
      options: OPEN_CLOSED_OPTIONS,
      defaultValue: ALL_OPTION_WITH_ALL_ID,
      type: "option",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedJobs"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showDeleted",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeDeletedJobs"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
