import { i18n } from "services/i18nService";

export const ExportCustomers = {
  showAddImportColumnsButton: true,
  userHasSufficientPrivilege: () => true,
  planIncludesReport: () => true,
  customReportNameFetchList: [],
  customFieldObjectTypes: ["customer"],
  columns: [
    { apiName: "CustomerId", label: "Customer #" },
    { apiName: "Name", default: true },
    { apiName: "FullName", label: "Name With Parent", nonImport: true },
    { apiName: "CompanyName", label: "Company" },
    { apiName: "Salutation" },
    { apiName: "FirstName", label: "First Name" },
    { apiName: "MiddleName", label: "Middle Name" },
    { apiName: "LastName", label: "Last Name" },
    { apiName: "Suffix" },
    { apiName: "BillAddressLine1", label: "Billing Addr Line 1" },
    { apiName: "BillAddressLine2", label: "Billing Addr Line 2" },
    { apiName: "BillAddressLine3", label: "Billing Addr Line 3" },
    { apiName: "BillAddressLine4", label: "Billing Addr Line 4" },
    { apiName: "BillAddressLine5", label: "Billing Addr Line 5" },
    { apiName: "BillAddressCity", label: "Billing Addr City" },
    { apiName: "BillAddressState", label: "Billing Addr State" },
    { apiName: "BillAddressPostalCode", label: "Billing Addr Postal Code" },
    { apiName: "BillAddressCountry", label: "Billing Addr Country" },
    { apiName: "ShipAddressLine1", label: "Shipping Addr Line 1" },
    { apiName: "ShipAddressLine2", label: "Shipping Addr Line 2" },
    { apiName: "ShipAddressLine3", label: "Shipping Addr Line 3" },
    { apiName: "ShipAddressLine4", label: "Shipping Addr Line 4" },
    { apiName: "ShipAddressLine5", label: "Shipping Addr Line 5" },
    { apiName: "ShipAddressCity", label: "Shipping Addr City" },
    { apiName: "ShipAddressState", label: "Shipping Addr State" },
    { apiName: "ShipAddressPostalCode", label: "Shipping Addr Postal Code" },
    { apiName: "ShipAddressCountry", label: "Shipping Addr Country" },
    { apiName: "PrintAs" },
    { apiName: "Phone" },
    { apiName: "Mobile" },
    { apiName: "Pager" },
    { apiName: "AltPhone" },
    { apiName: "Fax" },
    { apiName: "Email" },
    { apiName: "ResaleNumber", label: "Resale Number" },
    { apiName: "DeliveryMethod", label: "Delivery Method" },
    { apiName: "IsStatementWithParent", label: "Statement With Parent" },
    { apiName: "Deleted" },
    { apiName: "WebSiteURI", label: "Website" },
    { apiName: "BusinessLicense", label: "Business License" },
    { apiName: "ContractorNumber", label: "Contractor Number" },
    { apiName: "FoundUsVia", label: "Found Us Via" },
    { apiName: "Notes" },
    { apiName: "Taxable" },
    { apiName: "SyncMessage", label: "Sync Message", nonImport: true },
    { apiName: "Archived" },
    { apiName: "Starred", nonImport: true },
    { apiName: "CreditHold", label: "Credit Hold" },
    { apiName: "PriceGroup", label: "Price Tier" },
    { apiName: "Terms" },
    { apiName: "SalesRep", label: "Sales Rep" },
    { apiName: "TaxCode", label: "Tax Code" },
    { apiName: "Currency" },
    { apiName: "PaymentMethod", label: "Payment Method" },
    { apiName: "Parent" },
    { apiName: "CustomerType", label: "Customer Type" },
  ],
  settings: [
    {
      label: i18n("reports.FilterLabel.CustomerNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedCustomers"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showDeleted",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeDeletedCustomers"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
