import { Box, Card, CardContent, Typography, Grid2 } from "@mui/material";

import { i18n } from "services/i18nService";

export function Disabled() {
  return (
    <Grid2 container spacing={0} alignItems="center" justifyContent="center">
      <Box sx={{ display: "grid" }} m={2} mb={0}>
        <Box mb={1}>
          <Typography variant="h2">
            {i18n("expiredDisabled.SubscriptionDisabled")}
          </Typography>
        </Box>
        <Card variant="outlined" sx={{ maxWidth: 500 }}>
          <CardContent>
            <Typography sx={{ paddingBottom: "1rem" }}>
              {i18n("expiredDisabled.DisabledMessage")}
            </Typography>
            <Typography sx={{ paddingBottom: "1rem" }}>
              {i18n("expiredDisabled.ContactSupport")}
            </Typography>
          </CardContent>
        </Card>
      </Box>
    </Grid2>
  );
}
