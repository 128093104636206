import { Paper } from "@mui/material";

import { theme } from "SosTheme";

export function CalloutBox(props) {
  const { children } = props;
  return (
    <Paper
      elevation={3}
      sx={{
        padding: 1,
        borderLeft: `5px solid ${theme.palette.primary.main}`,
        backgroundColor: "callOutBox.backgroundColor",
        mb: 2,
      }}
    >
      {children}
    </Paper>
  );
}
