import { useState } from "react";
import { useDispatch } from "react-redux";

import { Box, Dialog } from "@mui/material";

import { FrmFieldGrid } from "components/FrmFieldGrid";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FrmCheckbox } from "components/formFields/frm";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { ModalHeader } from "components/utility/ModalHeader";

import { i18n } from "services/i18nService";
import { isValid } from "services/sosInventoryService/settings/user/password/isValid";
import { updateSettingsValues } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";

import { useErrors } from "hooks/useErrors";

import { openAlert } from "globalState/alertSlice";

const defaultData = {
  showPasswords: false,
  oldPassword: null,
  newPassword: null,
  confirmNewPassword: null,
};

export function UserPassword(props) {
  const { onClose } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [passwordData, setPasswordData] = useState(defaultData);

  function handleInputFieldChange(fieldName, value) {
    setPageDirty();
    setPasswordData((prevRecord) => ({ ...prevRecord, [fieldName]: value }));
  }

  const dispatch = useDispatch();
  const { errors, setErrors, isInError } = useErrors();

  async function onSave() {
    const errors = isValid(passwordData);
    if (errors.messages.length > 0) {
      setErrors(errors);
      return;
    }

    setIsLoading(true);
    const { oldPassword, newPassword } = passwordData;
    const { success, message } = await updateSettingsValues(
      "user",
      "password",
      {
        oldPassword,
        newPassword,
      }
    );
    if (success) {
      setPageDirty(false);
      dispatch(openAlert({ type: "success", message: i18n("global.Saved") }));
      onClose();
    } else {
      const errorMessage = message || i18n("settings.UnableToUpdatePassword");
      dispatch(openAlert({ type: "error", message: errorMessage }));
    }
    setIsLoading(false);
  }

  return (
    <Dialog open onClose={onClose} maxWidth="sm">
      <ModalHeader
        label={i18n("settings.ChangePassword")}
        onClose={onClose}
        save={onSave}
        hidePrint
      />

      {isLoading ? <Loading /> : null}
      <Box sx={{ height: "100%", overflowY: "auto" }}>
        <Box m={2}>
          <FormErrors errors={errors} setErrors={setErrors} />
          <FrmFieldGrid gridAutoFlow="dense">
            <FrmCheckbox
              name="showPasswords"
              value={passwordData.showPasswords}
              label="Show passwords"
              onValueChange={handleInputFieldChange}
            />
            <FrmTextField
              autoFocus
              name="oldPassword"
              label={i18n("settings.OldPassword")}
              onValueChange={handleInputFieldChange}
              value={passwordData.oldPassword}
              type={passwordData.showPasswords ? "text" : "password"}
              error={isInError("oldPassword")}
            />
            <FrmTextField
              name="newPassword"
              value={passwordData.newPassword}
              onValueChange={handleInputFieldChange}
              label={i18n("settings.NewPassword")}
              type={passwordData.showPasswords ? "text" : "password"}
              error={isInError("newPassword")}
            />
            <FrmTextField
              name="confirmNewPassword"
              value={passwordData.confirmNewPassword}
              onValueChange={handleInputFieldChange}
              label={i18n("settings.ConfirmNewPassword")}
              type={passwordData.showPasswords ? "text" : "password"}
              error={isInError("confirmNewPassword")}
            />
          </FrmFieldGrid>
        </Box>
      </Box>
    </Dialog>
  );
}
