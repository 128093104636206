import { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";

import {
  Grid2,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";

import { FrmFieldGrid } from "components/FrmFieldGrid";
import { SortableColumnHeader } from "components/SortableColumnHeader";
import { TableCell } from "components/TableCell";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { CloseX } from "components/utility/CloseX";
import { LinkText } from "components/utility/LinkText";
import { Loading } from "components/utility/Loading";
import { ModalTitle } from "components/utility/ModalTitle";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { getProfitLoss } from "services/sosInventoryService/sosApi";
import { formatDate } from "services/utility/dates";
import { handleProgramError } from "services/utility/errors";
import { formatMoney, formatNumber } from "services/utility/formatting";
import { isNonZeroDecimalOrMoney } from "services/utility/misc";

import { openModal } from "globalState/modalSlice";

import { NO_REF_NUMBER_STRING } from "appConstants";
import { getObjectFromTypeString } from "appConstants";

export function JobProfitLoss(props) {
  const { close, objectType, id, identifierText } = props;
  const [isLoading, setIsLoading] = useState(false);
  const [profitLoss, setProfitLoss] = useState(null);
  const [workCenter, setWorkCenter] = useState(null);
  const [retrievalParams, setRetrievalParams] = useState({
    direction: "asc",
    sort: "",
  });
  const dispatch = useDispatch();

  const events = useMemo(
    () => (profitLoss ? profitLoss.lines.filter((event) => event) : profitLoss),
    [profitLoss]
  );

  useEffect(() => {
    async function _getProfitLoss() {
      try {
        setIsLoading(true);
        const { sort, direction } = retrievalParams;
        const record = await getProfitLoss(objectType, id, {
          jobWorkCenterId: workCenter?.id,
          sort,
          direction,
        });
        setProfitLoss(record);
        setIsLoading(false);
      } catch (e) {
        setIsLoading(false);
        handleProgramError(e);
      }
    }
    _getProfitLoss();
  }, [id, objectType, dispatch, workCenter, retrievalParams]);

  function openEditModal(objectType, id) {
    dispatch(openModal({ objectType, modalProps: { id } }));
  }

  function handleColumnHeaderClick(sortName) {
    const newDirection =
      sortName === retrievalParams.sort
        ? retrievalParams.direction === "asc"
          ? "desc"
          : "asc"
        : "asc";
    setRetrievalParams((prevParams) => ({
      ...prevParams,
      sort: sortName,
      direction: newDirection,
      start: 1,
    }));
  }

  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: "dialogTitleBackground",
          borderBottom: "1px solid",
          borderBottomColor: "lineSeparatorMinor",
          padding: "0.5rem 1rem",
        }}
      >
        <Grid2
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid2 size={8}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CloseX onClick={close} />
              <ModalTitle
                label={i18n("global.ProfitLoss")}
                text={identifierText}
              />
            </div>
          </Grid2>
        </Grid2>
      </DialogTitle>
      {isLoading && <Loading />}
      <DialogContent sx={{ paddingTop: 0 }}>
        <VSpace space={2} />
        <FrmFieldGrid gridAutoFlow="dense">
          <div style={{ maxWidth: "20rem" }}>
            <FrmSelectFromObjects
              name="workcenter"
              label={i18n("frmLabel.WorkCenter")}
              value={workCenter}
              onValueChange={(_, value) => setWorkCenter(value)}
              options={profitLoss ? profitLoss.workCenters : null}
            />
          </div>
        </FrmFieldGrid>
        <TableContainer component={Paper} sx={{ mt: 1, height: "80vh" }}>
          <Table size="small">
            <TableHead>
              <TableRow>
                <SortableColumnHeader
                  isSortable
                  sortName="date"
                  label={i18n("columns.Date")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  isSortable
                  sortName="type"
                  label={i18n("columns.Event")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  isSortable
                  sortName="refNumber"
                  label={i18n("columns.ReferenceNumber")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  isSortable
                  sortName="itemName"
                  label={i18n("columns.Item")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  isSortable
                  sortName="quantity"
                  align="right"
                  label={i18n("columns.TxnQty")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  isSortable
                  sortName="cost"
                  align="right"
                  label={i18n("columns.Cost")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  isSortable
                  sortName="revenue"
                  align="right"
                  label={i18n("columns.Income")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  align="right"
                  sortName="total"
                  label={i18n("columns.NetTotal")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  isSortable
                  sortName="contactName"
                  label={i18n("columns.Entity")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  isSortable
                  sortName="fromLocation"
                  label={i18n("columns.FromLocation")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
                <SortableColumnHeader
                  isSortable
                  sortName="toLocation"
                  label={i18n("columns.ToLocation")}
                  activeSortName={retrievalParams.sort}
                  direction={retrievalParams.direction}
                  onClick={handleColumnHeaderClick}
                />
              </TableRow>
            </TableHead>
            <TableBody>
              {events?.map((line, index) => (
                <TableRow key={index}>
                  <TableCell>{formatDate(line.txnDate)}</TableCell>
                  <TableCell>
                    {i18n(
                      `objectType.${
                        getObjectFromTypeString(line.type).fullString
                      }.Sentence`
                    )}
                  </TableCell>
                  <TableCell>
                    <LinkText
                      onClick={() => {
                        openEditModal(
                          getObjectFromTypeString(line.type).fullString,
                          line.id
                        );
                      }}
                    >
                      {line.refNumber || NO_REF_NUMBER_STRING}
                    </LinkText>
                  </TableCell>
                  <TableCell>{line.itemName}</TableCell>
                  <TableCell align="right">
                    {formatNumber(line.quantity)}
                  </TableCell>
                  <TableCell align="right">
                    {isNonZeroDecimalOrMoney(line.cost)
                      ? formatMoney(line.cost, true, true)
                      : null}
                  </TableCell>
                  <TableCell align="right">
                    {isNonZeroDecimalOrMoney(line.revenue)
                      ? formatMoney(line.revenue, true, true)
                      : null}
                  </TableCell>
                  <TableCell align="right">
                    {formatMoney(line.total, true, true)}
                  </TableCell>
                  <TableCell>{line.contactName}</TableCell>
                  <TableCell>{line.fromLocation}</TableCell>
                  <TableCell>{line.toLocation}</TableCell>
                </TableRow>
              ))}
            </TableBody>
          </Table>
        </TableContainer>
      </DialogContent>
    </>
  );
}
