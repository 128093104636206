// Actual domain logic should be in a domainLogic.js file, at the appropriate
// level in the hierarchy.
import { ITEM_CALCULATED_FIELDS } from "appConfig";
import { IN_TRANSACTION } from "appConfig";

import {
  updateLineItemToBins,
  quickAddCustomer,
  changeCustomer,
} from "services/sosInventoryService/domainLogic";
import {
  updateAvailableBinsAndBin,
  updateLineRelatedRecordsItem,
} from "services/sosInventoryService/domainLogic";
import { getEmptyRecord } from "services/sosInventoryService/rentalReturn/schema";
import {
  get,
  getRecord,
  getRecordFrom,
} from "services/sosInventoryService/sosApi";
import {
  copyCustomFieldValues,
  reconcileCustomFields,
  getCustomFieldDefinitions,
} from "services/utility/customFields";
import { getRelatedReferenceObjects } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";

import globalState from "globalState/globalState";

import { OBJECT_TYPES } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.RENTAL_RETURN.fullString;

export async function getTransaction(
  id,
  newFromId,
  newFromObjectType,
  updaters,
  customFieldDefs
) {
  let record, lines, relatedRecords;

  if (id || newFromObjectType) {
    if (id) {
      record = await getRecord(OBJECT_TYPE, id);
    } else {
      // it's a "populate from" request
      record = await getRecordFrom(OBJECT_TYPE, newFromObjectType, newFromId);
      delete record.id;
    }

    record.customFields = reconcileCustomFields(
      customFieldDefs,
      record.customFields
    );

    lines = record.lines;
    delete record.lines;
    updaters.lineHandler({ type: "set", lines });
    updaters.setRecord(record);

    relatedRecords = await getRelatedReferenceObjects(OBJECT_TYPE, record);

    updaters.setRelatedRecords(relatedRecords);

    // get items for each line item, so that we have updated item info for
    // the calculations below
    lines = await updateLineRelatedRecordsItem(
      record.fromLocation,
      record.date,
      lines,
      ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
    );

    // set the default bin and the available bins for the dropdown
    lines = await updateAvailableBinsAndBin(record.fromLocation, lines);
  } else {
    // new record
    const { settings } = globalState.getState().userCompanySettings;

    const defaultLocation =
      globalState.getState().userCompanySettings.settings.defaultLocation;
    const numLinesToAdd =
      globalState.getState().userCompanySettings.settings.numLinesToAdd;
    const rentalInventoryOutLocation =
      globalState.getState().userCompanySettings.settings
        .rentalInventoryOutLocation;

    record = {
      ...getEmptyRecord(settings),
      customFields: reconcileCustomFields(customFieldDefs, []),
    };

    lines = Array(numLinesToAdd).fill(EMPTY_LINE_ITEM[OBJECT_TYPE]);

    relatedRecords = {};

    if (defaultLocation) {
      record.fromLocation = defaultLocation;
      record.toLocation = defaultLocation;

      const defaultLocationFullRecord = await getRecord(
        "location",
        defaultLocation.id,
        IN_TRANSACTION
      );

      relatedRecords = {
        ...relatedRecords,
        fromLocation: defaultLocationFullRecord,
        toLocation: defaultLocationFullRecord,
      };
    }

    const response = await get("location", { inTransaction: true });
    if (response.success) {
      const outForRentalLocation = response.data.records.find(
        ({ id }) => id === rentalInventoryOutLocation
      );

      //default fromLocation to rentalInventoryOutLocation if present
      if (outForRentalLocation) {
        record.fromLocation = outForRentalLocation;
        relatedRecords = {
          ...relatedRecords,
          fromLocation: outForRentalLocation,
        };
      }

      updaters.setRelatedRecords(relatedRecords);
      updaters.setRecord(record);
    } else {
      handleProgramError(
        new Error(`unsuccessful call to getList, message: ${response.message}`)
      );
    }
  }
  updaters.lineHandler({ type: "set", lines });
}

export async function updateTransaction(
  field,
  newValue,
  transactionCustomFieldDefs,
  currentState,
  updaters
) {
  const { record, lines, relatedRecords } = currentState;
  const { setRecord } = updaters;
  if (!record || !lines || !relatedRecords) {
    handleProgramError(new Error("update Transaction | invalid currentState"));
  }

  let newRecord, newLines, newRelatedRecords, newCustomer;

  switch (field) {
    case "date":
      newRecord = { ...record, date: newValue };
      updaters.setRecord(newRecord);

      if (newValue) {
        // reget items for each line item, so that we have updated item info
        // for the calculations below
        newLines = await updateLineRelatedRecordsItem(
          newRecord.fromLocation,
          newRecord.date,
          lines,
          ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
        );

        // set the default bin and the available bins for the dropdown
        newLines = await updateAvailableBinsAndBin(
          newRecord.fromLocation,
          newLines
        );
      }

      break;

    case "fromLocation":
      newRecord = { ...record, fromLocation: newValue };
      setRecord(newRecord);

      const fromLocation = newValue
        ? await getRecord("location", newValue.id)
        : null;
      newRelatedRecords = { ...relatedRecords, fromLocation };

      // reget items for each line item, so that we have updated item info
      // for the calculations below
      newLines = await updateLineRelatedRecordsItem(
        newRecord.fromLocation,
        newRecord.date,
        lines,
        ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
      );

      // set the default bin and the available bins for the dropdown
      newLines = await updateAvailableBinsAndBin(
        newRecord.fromLocation,
        newLines
      );

      break;
    case "toLocation":
      newRecord = { ...record, toLocation: newValue };
      setRecord(newRecord);

      try {
        const toLocation = newValue
          ? await getRecord("location", newValue?.id)
          : null;
        newRelatedRecords = { ...relatedRecords, toLocation };
        newLines = await updateLineItemToBins(lines, toLocation);
      } catch (e) {
        handleProgramError(e);
      }

      break;

    case "channel":
    case "shippingMethod":
    case "customerNotes":
    case "customerMessage":
    case "comment":
    case "department":
    case "dueDate":
    case "number":
    case "customFields":
    case "trackingNumber":
      newRecord = { ...record, [field]: newValue };
      updaters.setRecord(newRecord);

      break;

    case "customer":
      // if user de-selected customer, all we need to do is set the
      // customer state and the customer field in the record to null
      if (!newValue) {
        newCustomer = null;
        newRecord = { ...record, customer: newValue };
        updaters.setRecord(newRecord);

        break;
      }

      const customerCustomFieldDefs = await getCustomFieldDefinitions(
        "customer"
      );

      // get the newly selected customer record
      newCustomer = await getRecord("customer", newValue.id, IN_TRANSACTION);

      // be sure there are custom field entries for each defined custom field
      // in the customer record
      const customerCustomFields = reconcileCustomFields(
        customerCustomFieldDefs,
        newCustomer.customFields
      );

      // initialize any transaction custom fields to their matching customer
      // custom field values, if any
      const newTransactionCustomFields = copyCustomFieldValues(
        customerCustomFieldDefs,
        customerCustomFields,
        transactionCustomFieldDefs,
        record.customFields
      );

      newRecord = {
        ...record,
        customer: newValue,
        customerNotes: newCustomer.notes,
        customFields: newTransactionCustomFields,
      };
      updaters.setRecord(newRecord);
      newRelatedRecords = { ...relatedRecords, customer: newCustomer };

      break;

    default:
      handleProgramError(
        new Error(`updateTransaction | unknown field (${field})`)
      );
  }

  if (newLines) {
    updaters.lineHandler({ type: "set", lines: newLines });
  }
  if (newRelatedRecords) {
    updaters.setRelatedRecords(newRelatedRecords);
  }
}

export async function quickAddNewCustomer(name, updaters) {
  const response = await quickAddCustomer(name);
  if (typeof response === "object") {
    updaters.setRelatedRecords((prevRecord) => ({
      ...prevRecord,
      customer: response,
    }));

    // update the record with the new customer
    updaters.setRecord((prevRecord) => ({
      ...prevRecord,
      customer: {
        id: response.id,
        name: response.name,
      },
    }));
  } else {
    updaters.setErrors((prev) => ({ ...prev, messages: [response] }));
  }
}

export async function fullAddNewCustomer(
  customer,
  customerCustomFieldDefs,
  transactionCustomFieldDefs,
  transactionCustomFields,
  updaters
) {
  const { id, name, notes } = customer;
  const returnedValues = await changeCustomer(customer, {
    customerCustomFieldDefs,
    transactionCustomFieldDefs,
    transactionCustomFields,
  });
  const { customFields, billing, shipping } = returnedValues;
  updaters.setRecord((prevRecord) => ({
    ...prevRecord,
    customer: { id, name },
    customerNotes: notes,
    customFields,
    billing,
    shipping,
  }));
  updaters.setRelatedRecords((prevRecord) => ({ ...prevRecord, customer }));
}
