import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { i18n } from "services/i18nService";

import { useRelated } from "hooks/useRelated";

export function FrmPriority(props) {
  const {
    value,
    onValueChange,
    maxWidth,
    name = "priority",
    label = i18n("frmLabel.Priority"),
    disableClearable,
    error,
  } = props;

  const priorities = useRelated("priority");
  const sortedPriorities = priorities
    ? priorities.sort((a, b) => (a.id < b.id ? -1 : 1))
    : null;

  return (
    <FrmSelectFromObjects
      name={name}
      options={sortedPriorities}
      value={value}
      label={label}
      onValueChange={onValueChange}
      maxWidth={maxWidth}
      error={error}
      disableClearable={disableClearable}
    />
  );
}
