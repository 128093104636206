import { Fragment, useState } from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";

import {
  List,
  ListItem,
  ListItemIcon,
  Popper,
  Paper,
  Box,
  Typography,
  ListItemText,
} from "@mui/material";

import { NavToggle } from "components/LeftNav/NavToggle";
import { LEFT_NAV_CONFIG } from "components/LeftNav/leftNavConfig";

import { i18n } from "services/i18nService";
import { filterNavigationMenu } from "services/utility/filters";

import { usePlans } from "hooks/usePlans";
import { usePrivileges } from "hooks/usePrivileges";

import { theme } from "SosTheme";

const sx = {
  topNode: {
    padding: theme.spacing(0.5, 2, 0.5, 2),
    cursor: "pointer",
    zIndex: "leftNavFlyout",
    "&:hover svg": { fill: theme.palette.accent },
  },
  highlightedParentNode: {
    backgroundColor: theme.palette.tooltipBackground,
    boxShadow: `0 2px 4px -2px ${theme.palette.primaryText}, 0 1px 1px 0 ${theme.palette.menuHighlight}`,
  },
  icon: { minWidth: "auto" },
  menu: { boxShadow: `2px 2px 4px -3px ${theme.palette.primaryText}` },
  menuHeader: {
    backgroundColor: "tooltipBackground",
    minWidth: "11em",
    height: "35px",
    borderTopRightRadius: `${theme.shape.borderRadius}px`,
  },
  menuBody: {
    border: "1px solid",
    borderColor: "tooltipBackground",
    borderBottomLeftRadius: `${theme.shape.borderRadius}px`,
    borderBottomRightRadius: `${theme.shape.borderRadius}px`,
    boxShadow: `2px 2px 4px -3px ${theme.palette.primaryText}, -1px 0 1px 0 ${theme.palette.menuHighlight}`,
  },
  menuItem: {
    padding: theme.spacing(0.5, 2, 0.5, 2),
    "&:hover": { backgroundColor: "menuHighlight" },
  },
};

export function LeftNavIcons() {
  const [highlightedNode, setHighlightedNode] = useState("");

  const restrictedSalesRep = useSelector(
    (state) => state.userCompanySettings.company.restrictedSalesRep
  );

  return (
    <List component="nav" sx={{ width: "4rem", padding: 0 }}>
      {LEFT_NAV_CONFIG.map((node) => (
        <Fragment key={node.id}>
          {(!node.hideFromRestrictedSalesReps || !restrictedSalesRep) && (
            <MenuNode
              key={node.id}
              menuNode={node}
              menuToggleOn={(node) => setHighlightedNode(node)}
              menuToggleOff={() => setHighlightedNode(null)}
              isHighlighted={highlightedNode === node.id}
            />
          )}
        </Fragment>
      ))}
      <NavToggle listSx={sx.topNode} />
    </List>
  );
}

function MenuNode(props) {
  const { menuToggleOn, menuToggleOff, menuNode, isHighlighted } = props;
  const { id, Icon, subNodes, label } = menuNode;

  const [menuAnchor, setMenuAnchor] = useState();

  const { hasPrivilegesOrIsAdmin } = usePrivileges();

  const { hasAtLeastPlusPlan, hasAtLeastProPlan } = usePlans();

  const { settings } = useSelector((state) => state.userCompanySettings);

  const filteredNodes = filterNavigationMenu(
    subNodes,
    hasAtLeastPlusPlan,
    hasAtLeastProPlan,
    hasPrivilegesOrIsAdmin,
    settings
  );

  const isParentNode = subNodes && subNodes.length > 0;
  const highlightedStyles =
    isHighlighted && isParentNode ? sx.highlightedParentNode : {};

  return (
    <ListItem
      onMouseEnter={() => menuToggleOn(id)}
      onTouchEnd={() => menuToggleOn(id)}
      onMouseLeave={menuToggleOff}
      sx={{ ...sx.topNode, ...highlightedStyles }}
      ref={(item) => setMenuAnchor(item)}
      component={!isParentNode ? NavLink : null}
      to={!isParentNode ? menuNode.to : null}
    >
      <ListItemIcon sx={sx.icon}>
        <Icon sx={{ width: "25px", height: "25px" }} />
      </ListItemIcon>
      {isParentNode && (
        <Popper
          id={`${id}-menu`}
          open={isHighlighted}
          anchorEl={menuAnchor}
          placement="right-start"
          sx={{ zIndex: "leftNavFlyout" }}
          keepMounted
        >
          <Paper sx={sx.menu}>
            <Box px={2} py={0.7} sx={sx.menuHeader}>
              <Typography variant="h4">{i18n(label)}</Typography>
            </Box>
            <Box sx={sx.menuBody}>
              <List sx={{ padding: 0 }}>
                {filteredNodes.map((node) => {
                  return (
                    <ListItem
                      key={node.label}
                      dense
                      component={NavLink}
                      to={node.path}
                      sx={sx.menuItem}
                      onClick={menuToggleOff}
                    >
                      <ListItemText primary={i18n(node.label)} />
                    </ListItem>
                  );
                })}
              </List>
            </Box>
          </Paper>
        </Popper>
      )}
    </ListItem>
  );
}
