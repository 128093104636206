import Delete from "@mui/icons-material/Delete";

import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { LineTableCell } from "components/formFields/LineTableCell";

import { ACTION_OPTIONS } from "services/sosInventoryService/aopRule/schema";
import { setPageDirty } from "services/utility/edit";

import { LineTarget } from "views/AopRules/AopRule/LineTarget";

import { theme } from "SosTheme";

export function LineItem(props) {
  const { line, setRecord, triggerForm, rowIndex, errors } = props;

  function handleDelete() {
    setPageDirty();
    setRecord((prev) => ({
      ...prev,
      lines: prev.lines.filter((_, i) => i !== rowIndex),
    }));
  }

  function handleChange(fieldName, newValue) {
    setPageDirty();
    setRecord((prev) => ({
      ...prev,
      lines: prev.lines.map((line, i) =>
        rowIndex === i ? { ...line, [fieldName]: newValue.id } : line
      ),
    }));
  }

  if (!line) {
    return null;
  }

  return (
    <>
      <LineTableCell>
        <div
          onClick={handleDelete}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
            color: theme.palette.icons,
          }}
        >
          <Delete />
        </div>
      </LineTableCell>
      <LineTableCell>
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
          }}
        >
          {rowIndex + 1}
        </div>
      </LineTableCell>

      <LineTableCell sx={{ minWidth: "10rem" }}>
        <FrmSelectFromObjects
          name="action"
          value={line.action ? { id: line.action } : null}
          onValueChange={handleChange}
          options={ACTION_OPTIONS}
          disableClearable
        />
      </LineTableCell>
      <LineTableCell sx={{ minWidth: "16rem" }}>
        <LineTarget
          line={line}
          triggerForm={triggerForm}
          action={line.action}
          handleReferenceChange={handleChange}
          rowIndex={rowIndex}
          errors={errors}
        />
      </LineTableCell>
    </>
  );
}
