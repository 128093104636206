import { i18n } from "services/i18nService";

import { ALL_FILTER_OPTION } from "appConstants";

export const ExportItems = {
  showAddImportColumnsButton: true,
  userHasSufficientPrivilege: () => true,
  planIncludesReport: () => true,
  customReportNameFetchList: [],
  customFieldObjectTypes: ["item"],
  columns: [
    { apiName: "ItemNumber", label: "Item #", default: true },
    { apiName: "Name", default: true },
    { apiName: "Category" },
    { apiName: "Description", default: true },
    { apiName: "Purchase Description", label: "Purchase Description" },
    { apiName: "SKU" },
    { apiName: "Barcode" },
    { apiName: "Vendor" },
    { apiName: "VendorPartNumber", label: "Vendor Part #" },
    { apiName: "Sales Price", label: "Sales Price", number: true },
    { apiName: "Sales Price %", label: "Sales Price %", number: true },
    { apiName: "Purchase Cost", label: "Purchase Cost", number: true },
    { apiName: "Default Bin", label: "Default Bin" },
    { apiName: "Taxable" },
    { apiName: "Type" },
    { apiName: "ReorderLevel", label: "Reorder Point", number: true },
    { apiName: "SyncWithQuickBooks", label: "Sync With QuickBooks" },
    { apiName: "Deleted" },
    { apiName: "SerialTracking", label: "Serial Tracking" },
    { apiName: "LotTracking", label: "Lot Tracking" },
    { apiName: "SalesItem", label: "Sales Item" },
    { apiName: "PurchasingItem", label: "Purchasing Item" },
    { apiName: "ManufacturingItem", label: "Manufacturing Item" },
    { apiName: "MaxStockLevel", label: "Max Stock", number: true },
    { apiName: "Notes" },
    { apiName: "UsePricePercent", label: "Use Price Percent" },
    { apiName: "Weight", number: true },
    { apiName: "WeightUnit", label: "Weight Unit" },
    { apiName: "Volume", number: true },
    { apiName: "VolumeUnit", label: "Volume Unit" },
    { apiName: "Starred", number: true },
    { apiName: "Boxes", nonImport: true },
    { apiName: "Archived" },
    { apiName: "PictureFile", label: "Picture File", nonImport: true },
    { apiName: "WebUrl", label: "Web Url" },
    { apiName: "SyncMessage", label: "Sync Message", nonImport: true },
    { apiName: "Tags" },
    {
      apiName: "QuickBooksItemType",
      label: "QuickBooks Item Type",
      nonImport: true,
    },
    {
      apiName: "UpdateInventoryInShopify",
      label: "Update Inventory In Shopify",
    },
    {
      apiName: "UpdateInventoryInBigCommerce",
      label: "Update Inventory In BigCommerce",
    },
    { apiName: "Shippable" },
    { apiName: "CustomerPartNumber", label: "Customer Part Number" },
    { apiName: "LeadTime", label: "Lead Time", number: true },
    { apiName: "MinimumPrice", label: "Minimum Price" },
    { apiName: "IncomeAccount", label: "Income Account" },
    { apiName: "AssetAccount", label: "Asset Account" },
    { apiName: "COGSAccount", label: "COGS Account" },
    { apiName: "ExpenseAccount", label: "Expense Account" },
    { apiName: "SalesTaxCode", label: "Sales Tax Code" },
    { apiName: "PurchaseTaxCode", label: "Purchase Tax Code" },
    { apiName: "Class" },
    { apiName: "Warranty" },
    { apiName: "On Hand", number: true, label: "On Hand", default: true },
    { apiName: "ValueOnHand", label: "Cost Basis", number: true },
    { apiName: "Available", number: true, nonImport: true },
    { apiName: "On SO", label: "On SO", number: true, nonImport: true },
    { apiName: "On SR", label: "On SR", number: true, nonImport: true },
    { apiName: "On PO", label: "On PO", number: true, nonImport: true },
    { apiName: "On RMA", label: "On RMA", number: true, nonImport: true },
    { apiName: "On WO", label: "On WO", number: true, nonImport: true },
    { apiName: "On Rental", label: "On Rental", number: true, nonImport: true },
    { apiName: "CommissionAmount", label: "Commission Amount", number: true },
    { apiName: "CommissionExempt", label: "Commission Exempt", number: true },
    { apiName: "CommissionRate", label: "Commission Rate", number: true },
  ],
  settings: [
    {
      label: i18n("filter.Location"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: [ALL_FILTER_OPTION],
      includeAllOption: true,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Category"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.KeywordSearch"),
      component: "FrmTextFieldWithHoverHelp",
      apiName: "nameContains",
      defaultValue: null,
      helpText: i18n("reports.hoverHelp.PurchaseKeywordSearch"),
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedTransactions"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showDeleted",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeDeletedItems"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "suppressIfEmpty",
      defaultValue: true,
      component: "FrmCheckbox",
      label: i18n("reports.OptionLabel.HideRowsWithAllZeros"),
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
