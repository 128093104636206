import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { useFormTemplates } from "hooks/useFormTemplates";

export function FrmFormTemplates(props) {
  const { formType, name, value, onValueChange } = props;
  const formTemplates = useFormTemplates(formType);

  return (
    <FrmSelectFromObjects
      name={name}
      value={value}
      onValueChange={onValueChange}
      options={formTemplates}
      disableClearable
    />
  );
}
