import { useState } from "react";
import { useSelector } from "react-redux";

import {
  TableContainer,
  TableHead,
  Table,
  TableBody,
  TableRow,
  TableFooter,
} from "@mui/material";

import { AddLines } from "components/EditPage/AddLines";
import { ClearLines } from "components/EditPage/ClearLines";
import { LineColumnHeading } from "components/formFields/line/LineColumnHeading";
import { WarningModal } from "components/utility/WarningModal";

import { i18n } from "services/i18nService";
import { EMPTY_LINE } from "services/sosInventoryService/priceTier/schema";
import { setPageDirty } from "services/utility/edit";
import { textForAddLineOrLines } from "services/utility/lineItems";

import { LineItem } from "views/PriceTiers/PriceTier/LineItem";

export function LineItems(props) {
  const { lines, items, itemsCount, setRecord, itemFilter } = props;

  const [clearLines, setClearLines] = useState(false);
  const [defaultItem, setDefaultItem] = useState(null);

  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  function addLines() {
    setPageDirty();
    const item = itemFilter || defaultItem || null;
    const newEmptyLine = { ...EMPTY_LINE, item };
    const lines = new Array(numLinesToAdd).fill(newEmptyLine);
    setRecord((prevRecord) => ({
      ...prevRecord,
      items: [...prevRecord.items, ...lines],
    }));
  }

  function copyItemToNewLine(index) {
    setPageDirty();
    const newLines = [...lines];
    const newLine =
      index === 0 ? EMPTY_LINE : { ...EMPTY_LINE, item: lines[index - 1].item };
    newLines.splice(index, 0, newLine);
    setRecord((prevRecord) => ({ ...prevRecord, items: newLines }));
  }

  function copyDown(item, index) {
    setPageDirty();
    const updatedLine = { ...EMPTY_LINE, item };
    delete updatedLine.isNewRow;
    const newLines = [...lines];

    const copiedToRowIndex = itemFilter
      ? newLines.reduce((seed, line, i) => {
          if (seed) {
            return seed;
          }
          if (i <= index) {
            return null;
          }
          return line.item?.id === itemFilter.id ? i : null;
        }, null)
      : index + 1;

    if (copiedToRowIndex) {
      newLines[copiedToRowIndex] = updatedLine;
      setRecord((prevRecord) => ({ ...prevRecord, items: newLines }));
    }
  }

  function doClearLines() {
    setPageDirty();
    setRecord((prevRecord) => ({
      ...prevRecord,
      items: itemFilter
        ? prevRecord.items.map((line) =>
            line.item?.id === itemFilter.id ? EMPTY_LINE : line
          )
        : prevRecord.items.map(() => EMPTY_LINE),
    }));
    setClearLines(false);
  }

  if (!lines) {
    return null;
  }

  return (
    <>
      <TableContainer sx={{ overflowX: "initial" }}>
        <Table size="small" padding="none" stickyHeader>
          <TableHead>
            <TableRow sx={{ verticalAlign: "bottom", lineHeight: "1" }}>
              <ClearLines
                setClearLines={setClearLines}
                tooltip={
                  itemFilter
                    ? i18n("global.ClearLinesOnDisplayedRows")
                    : i18n("global.ClearLines")
                }
              />
              <LineColumnHeading labelCode="Item" />
              <LineColumnHeading labelCode="Quantity" align="right" />
              <LineColumnHeading labelCode="UnitPrice" align="right" />
            </TableRow>
          </TableHead>

          <TableBody>
            {lines.map((line, index) => {
              return (
                (!itemFilter || itemFilter.id === line.item?.id) && (
                  <TableRow
                    key={index}
                    sx={{
                      position: "inherit",
                      backgroundColor: line.backgroundColor || "none",
                    }}
                  >
                    <LineItem
                      insertEmptyLine={() => copyItemToNewLine(index)}
                      onCopyDown={() => copyDown(line.item, index)}
                      line={line}
                      setRecord={setRecord}
                      items={items}
                      setDefaultItem={setDefaultItem}
                      itemsCount={itemsCount}
                      rowIndex={index}
                      showCopyDown={index + 1 !== lines.length}
                    />
                  </TableRow>
                )
              );
            })}
          </TableBody>
          <TableFooter>
            <TableRow>
              <AddLines
                title={textForAddLineOrLines(numLinesToAdd)}
                onClick={addLines}
              />
            </TableRow>
          </TableFooter>
        </Table>
      </TableContainer>
      <WarningModal
        title={i18n("global.ClearLines")}
        message={i18n("global.clearLinesMessage")}
        open={clearLines}
        onOk={doClearLines}
        onClose={() => setClearLines(false)}
      />
    </>
  );
}
