export function afterGet(record) {
  const newRecord = { ...record };

  // the field "stars" will come from API as "lightgray|gold" or "a|b|c|d",
  // where the options are CSS color names; we will turn this into an *array*
  // of color names in this object, e.g. ["lightgray", "crimson", "gold", "seagreen"]
  newRecord.stars = record.stars.split("|");
  newRecord.userTimeZone =
    record.userTimeZone || record.defaultTimeZone || "device";
  // reformat the two currency fields into a standard reference object
  newRecord.homeCurrency = {
    id: record.homeCurrencyId,
    code: record.homeCurrency,
  };

  return newRecord;
}
