import { i18n } from "services/i18nService";
import {
  EMAIL_OPTION,
  ASSIGN_TO_USER_OPTION,
  NOTIFY_USER_OPTION,
  CREATE_TRANSACTION_OPTION,
  SET_PRIORITY_OPTION,
  MOVE_STAGE_OPTION,
} from "services/sosInventoryService/aopRule/schema";

import { initialErrorState } from "views/common";

const ACTION_TARGET_MAP = [
  { action: ASSIGN_TO_USER_OPTION.id, target: "assignUserId" },
  { action: NOTIFY_USER_OPTION.id, target: "notifyUserId" },
  { action: CREATE_TRANSACTION_OPTION.id, target: "createFormType" },
  { action: SET_PRIORITY_OPTION.id, target: "priorityId" },
  { action: MOVE_STAGE_OPTION.id, target: "orderStageId" },
  { action: EMAIL_OPTION.id, target: "emailTemplateId" },
];

export function isValid(record) {
  const errors = initialErrorState();
  errors.entity = [];

  if (!record.name || !record.name.trim().length) {
    errors.messages.push(i18n("isValid.Name"));
    errors.entity.push("name");
  }

  if (!record.triggerAction) {
    errors.messages.push(i18n("aopRule.FormAction"));
    errors.entity.push("triggerAction");
  }

  record.lines.forEach((line, index) => {
    errors.line[index] = [];
    const foundTarget = ACTION_TARGET_MAP.find(
      ({ action }) => action === line.action
    );
    if (foundTarget && !line[foundTarget.target]) {
      errors.messages.push(
        i18n("isValid.SelectTarget", { lineNumber: index + 1 })
      );
      errors.line[index].push("target");
    }
  });

  return errors;
}
