import { Box, Typography } from "@mui/material";

import { Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { formatMoney } from "services/utility/formatting";

const MONEY_SX = { textAlign: "right" };

export function CurrentCharges(props) {
  const {
    subscription,
    billableUsers,
    billableUsersCharge,
    billableIntegrations,
    billableIntegrationsCharge,
    billableSmsUsers,
    billableSmsUsersCharge,
    salesTax,
    discount,
  } = props;

  return (
    <Box
      sx={{ display: "grid", gridTemplateColumns: "10em 5em", columnGap: 1 }}
    >
      <Typography>{i18n("accountInformation.subscription")}</Typography>
      <Typography component="div" sx={MONEY_SX}>
        {formatMoney(subscription, true)}
      </Typography>
      {!billableUsersCharge.eq(Money.ZERO) && (
        <>
          <Typography>
            {i18n("accountInformation.additionalUsers")} ({billableUsers})
          </Typography>
          <Typography component="div" sx={MONEY_SX}>
            {formatMoney(billableUsersCharge, true)}
          </Typography>
        </>
      )}
      {!billableSmsUsersCharge.eq(Money.ZERO) && (
        <>
          <Typography>
            {i18n("accountInformation.additionalSmsUsers")} ({billableSmsUsers})
          </Typography>
          <Typography component="div" sx={MONEY_SX}>
            {formatMoney(billableSmsUsersCharge, true)}
          </Typography>
        </>
      )}
      {!billableIntegrationsCharge.eq(Money.ZERO) && (
        <>
          <Typography>
            {i18n("accountInformation.integrations")} ({billableIntegrations})
          </Typography>
          <Typography component="div" sx={MONEY_SX}>
            {formatMoney(billableIntegrationsCharge, true)}
          </Typography>
        </>
      )}
      {!discount.eq(Money.ZERO) && (
        <>
          <Typography>{i18n("accountInformation.discount")}</Typography>
          <Typography component="div" sx={MONEY_SX}>
            {formatMoney(discount, true)}
          </Typography>
        </>
      )}
      {!salesTax.eq(Money.ZERO) && (
        <>
          <Typography>{i18n("accountInformation.salesTax")}</Typography>
          <Typography component="div" sx={MONEY_SX}>
            {formatMoney(salesTax, true)}
          </Typography>
        </>
      )}
      <Typography sx={{ fontWeight: "bold", textTransform: "uppercase" }}>
        {i18n("global.Total")}
      </Typography>
      <Typography
        component="div"
        sx={{ ...MONEY_SX, borderTop: "1px solid black", fontWeight: "bold" }}
      >
        {formatMoney(
          subscription
            .plus(billableUsersCharge)
            .plus(billableSmsUsersCharge)
            .plus(billableIntegrationsCharge)
            .plus(discount)
            .plus(salesTax),
          true
        )}
      </Typography>
    </Box>
  );
}
