import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";

import {
  Public,
  Description,
  Reply,
  BlurLinear,
  RemoveCircle,
  SyncAlt,
  Person,
  LocalShipping,
  Build,
  Work,
  AttachMoney,
} from "@mui/icons-material";
import { MenuList, Grid2, Box } from "@mui/material";

import { DropDownMenu } from "components/TopNav/DropDownMenu";
import { MenuHeader } from "components/TopNav/MenuHeader";
import { MenuListItemText } from "components/TopNav/MenuListItemText";
import { NavListItemIcon } from "components/TopNav/NavListItemIcon";
import { NavMenuItem } from "components/TopNav/NavMenuItem";
import { TopNavIcon } from "components/TopNav/TopNavIcon";

import { i18n } from "services/i18nService";

import { usePlans } from "hooks/usePlans";
import { usePrivileges } from "hooks/usePrivileges";

import { openModal } from "globalState/modalSlice";

import { OBJECT_TYPES } from "appConstants";

export function AddMenu() {
  const dispatch = useDispatch();
  const { hasAtLeastPlusPlan, hasAtLeastProPlan } = usePlans();
  const { canEditObjectType } = usePrivileges();
  const [anchor, setAnchor] = useState(null);

  const paymentsBoarded = useSelector(
    (state) => state.userCompanySettings.settings.paymentsBoarded
  );

  function openEditModal(objectType) {
    setAnchor(null);
    dispatch(openModal({ objectType }));
  }

  function handleListKeyDown(event) {
    if (event.key === "Tab") {
      event.preventDefault();
    }

    setAnchor(null);
  }
  const openMenu = Boolean(anchor);
  const menuId = openMenu ? "add-menu" : undefined;

  return (
    <>
      <TopNavIcon
        refs={anchor}
        image="/images/create.svg"
        onClick={(e) => setAnchor(e.currentTarget)}
        functionText={i18n("iconAltText.create")}
        dataTesting="addMenu"
      />
      <DropDownMenu
        id={menuId}
        open={openMenu}
        anchorEl={anchor}
        onClose={() => setAnchor(null)}
      >
        <Box mx={2} my={1}>
          <Grid2 container spacing={3}>
            <Grid2 size={4}>
              <MenuList id="sync-list-grow" onKeyDown={handleListKeyDown}>
                <MenuHeader>{i18n("global.Inventory")}</MenuHeader>
                {canEditObjectType(OBJECT_TYPES.ITEM.fullString) && (
                  <NavMenuItem
                    onClick={() => openEditModal(OBJECT_TYPES.ITEM.fullString)}
                    dataTesting="addMenuItem"
                  >
                    <NavListItemIcon>
                      <BlurLinear />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("objectType.item.Sentence")}
                    />
                  </NavMenuItem>
                )}
                {canEditObjectType(OBJECT_TYPES.ADJUSTMENT.fullString) && (
                  <NavMenuItem
                    onClick={() =>
                      openEditModal(OBJECT_TYPES.ADJUSTMENT.fullString)
                    }
                    dataTesting="addMenuAdjustment"
                  >
                    <NavListItemIcon>
                      <RemoveCircle />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("objectType.adjustment.Sentence")}
                    />
                  </NavMenuItem>
                )}

                {hasAtLeastPlusPlan &&
                  canEditObjectType(OBJECT_TYPES.TRANSFER.fullString) && (
                    <NavMenuItem
                      onClick={() =>
                        openEditModal(OBJECT_TYPES.TRANSFER.fullString)
                      }
                      dataTesting="addMenuTransfer"
                    >
                      <NavListItemIcon>
                        <SyncAlt />
                      </NavListItemIcon>
                      <MenuListItemText
                        primary={i18n("objectType.transfer.Sentence")}
                      />
                    </NavMenuItem>
                  )}
              </MenuList>
            </Grid2>
            <Grid2 size={4}>
              <MenuList id="sync-list-grow" onKeyDown={handleListKeyDown}>
                <MenuHeader>{i18n("global.Purchasing")}</MenuHeader>
                {canEditObjectType(OBJECT_TYPES.VENDOR.fullString) && (
                  <NavMenuItem
                    onClick={() =>
                      openEditModal(OBJECT_TYPES.VENDOR.fullString)
                    }
                    dataTesting="addMenuVendor"
                  >
                    <NavListItemIcon>
                      <Public />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("objectType.vendor.Sentence")}
                    />
                  </NavMenuItem>
                )}
                {canEditObjectType(OBJECT_TYPES.PURCHASE_ORDER.fullString) && (
                  <NavMenuItem
                    onClick={() =>
                      openEditModal(OBJECT_TYPES.PURCHASE_ORDER.fullString)
                    }
                    dataTesting="addMenuPurchaseOrder"
                  >
                    <NavListItemIcon>
                      <Description />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("objectType.purchaseorder.Sentence")}
                    />
                  </NavMenuItem>
                )}
                {canEditObjectType(OBJECT_TYPES.ITEM_RECEIPT.fullString) && (
                  <NavMenuItem
                    onClick={() =>
                      openEditModal(OBJECT_TYPES.ITEM_RECEIPT.fullString)
                    }
                    dataTesting="addMenuItemReceipt"
                  >
                    <NavListItemIcon>
                      <Description />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("objectType.itemreceipt.Sentence")}
                    />
                  </NavMenuItem>
                )}
                {hasAtLeastPlusPlan &&
                  canEditObjectType(
                    OBJECT_TYPES.RETURN_TO_VENDOR.fullString
                  ) && (
                    <NavMenuItem
                      onClick={() =>
                        openEditModal(OBJECT_TYPES.RETURN_TO_VENDOR.fullString)
                      }
                      dataTesting="addMenuReturnToVendor"
                    >
                      <NavListItemIcon>
                        <Reply />
                      </NavListItemIcon>
                      <MenuListItemText
                        primary={i18n("objectType.returntovendor.Sentence")}
                      />
                    </NavMenuItem>
                  )}
              </MenuList>
            </Grid2>
            <Grid2 size={4}>
              <MenuList id="sync-list-grow" onKeyDown={handleListKeyDown}>
                <MenuHeader>{i18n("global.Sales")}</MenuHeader>
                {canEditObjectType(OBJECT_TYPES.CUSTOMER.fullString) && (
                  <NavMenuItem
                    onClick={() =>
                      openEditModal(OBJECT_TYPES.CUSTOMER.fullString)
                    }
                    dataTesting="addMenuCustomer"
                  >
                    <NavListItemIcon>
                      <Person />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("objectType.customer.Sentence")}
                    />
                  </NavMenuItem>
                )}

                {canEditObjectType(OBJECT_TYPES.ESTIMATE.fullString) && (
                  <NavMenuItem
                    onClick={() =>
                      openEditModal(OBJECT_TYPES.ESTIMATE.fullString)
                    }
                    dataTesting="addMenuEstimate"
                  >
                    <NavListItemIcon>
                      <Description />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("objectType.estimate.Sentence")}
                    />
                  </NavMenuItem>
                )}

                {canEditObjectType(OBJECT_TYPES.SALES_ORDER.fullString) && (
                  <NavMenuItem
                    onClick={() =>
                      openEditModal(OBJECT_TYPES.SALES_ORDER.fullString)
                    }
                    dataTesting="addMenuSalesOrder"
                  >
                    <NavListItemIcon>
                      <Description />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("objectType.salesorder.Sentence")}
                    />
                  </NavMenuItem>
                )}

                {canEditObjectType(OBJECT_TYPES.INVOICE.fullString) && (
                  <NavMenuItem
                    onClick={() =>
                      openEditModal(OBJECT_TYPES.INVOICE.fullString)
                    }
                    dataTesting="addMenuInvoice"
                  >
                    <NavListItemIcon>
                      <Description />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("objectType.invoice.Sentence")}
                    />
                  </NavMenuItem>
                )}

                {canEditObjectType(OBJECT_TYPES.SALES_RECEIPT.fullString) && (
                  <NavMenuItem
                    onClick={() =>
                      openEditModal(OBJECT_TYPES.SALES_RECEIPT.fullString)
                    }
                    dataTesting="addMenuSalesReceipt"
                  >
                    <NavListItemIcon>
                      <Description />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("objectType.salesreceipt.Sentence")}
                    />
                  </NavMenuItem>
                )}

                {canEditObjectType(OBJECT_TYPES.PAYMENT.fullString) &&
                  paymentsBoarded && (
                    <NavMenuItem
                      onClick={() =>
                        openEditModal(OBJECT_TYPES.PAYMENT.fullString)
                      }
                      dataTesting="addMenuPayment"
                    >
                      <NavListItemIcon>
                        <AttachMoney />
                      </NavListItemIcon>
                      <MenuListItemText
                        primary={i18n("objectType.payment.Sentence")}
                      />
                    </NavMenuItem>
                  )}

                {canEditObjectType(OBJECT_TYPES.RETURN.fullString) && (
                  <NavMenuItem
                    onClick={() =>
                      openEditModal(OBJECT_TYPES.RETURN.fullString)
                    }
                    dataTesting="addMenuReturn"
                  >
                    <NavListItemIcon>
                      <Description />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("objectType.return.Sentence")}
                    />
                  </NavMenuItem>
                )}

                {hasAtLeastPlusPlan &&
                  canEditObjectType(OBJECT_TYPES.RMA.fullString) && (
                    <NavMenuItem
                      onClick={() => openEditModal(OBJECT_TYPES.RMA.fullString)}
                      dataTesting="addMenuRma"
                    >
                      <NavListItemIcon>
                        <Description />
                      </NavListItemIcon>
                      <MenuListItemText
                        primary={i18n("objectType.rma.Sentence")}
                      />
                    </NavMenuItem>
                  )}
              </MenuList>
            </Grid2>
          </Grid2>
          <Grid2 container spacing={3}>
            <Grid2 size={4}>
              <MenuList id="sync-list-grow" onKeyDown={handleListKeyDown}>
                <MenuHeader>{i18n("global.Fulfillment")}</MenuHeader>
                {canEditObjectType(OBJECT_TYPES.SHIPMENT.fullString) && (
                  <NavMenuItem
                    onClick={() =>
                      openEditModal(OBJECT_TYPES.SHIPMENT.fullString)
                    }
                    dataTesting="addMenuShipment"
                  >
                    <NavListItemIcon>
                      <LocalShipping />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("objectType.shipment.Sentence")}
                    />
                  </NavMenuItem>
                )}

                {hasAtLeastPlusPlan &&
                  canEditObjectType(OBJECT_TYPES.PICK_TICKET.fullString) && (
                    <NavMenuItem
                      onClick={() =>
                        openEditModal(OBJECT_TYPES.PICK_TICKET.fullString)
                      }
                      dataTesting="addMenuPickTicket"
                    >
                      <NavListItemIcon>
                        <Description />
                      </NavListItemIcon>
                      <MenuListItemText
                        primary={i18n("objectType.pickticket.Sentence")}
                      />
                    </NavMenuItem>
                  )}
              </MenuList>
            </Grid2>
            <Grid2 size={4}>
              <MenuList id="sync-list-grow" onKeyDown={handleListKeyDown}>
                <MenuHeader>{i18n("global.Production")}</MenuHeader>
                {canEditObjectType(OBJECT_TYPES.BUILD.fullString) && (
                  <NavMenuItem
                    onClick={() => openEditModal(OBJECT_TYPES.BUILD.fullString)}
                    dataTesting="addMenuBuild"
                  >
                    <NavListItemIcon>
                      <Build />
                    </NavListItemIcon>
                    <MenuListItemText
                      primary={i18n("objectType.build.Sentence")}
                    />
                  </NavMenuItem>
                )}

                {hasAtLeastProPlan && (
                  <div>
                    {canEditObjectType(OBJECT_TYPES.PROCESS.fullString) && (
                      <NavMenuItem
                        onClick={() =>
                          openEditModal(OBJECT_TYPES.PROCESS.fullString)
                        }
                        dataTesting="addMenuProcess"
                      >
                        <NavListItemIcon>
                          <Build />
                        </NavListItemIcon>
                        <MenuListItemText
                          primary={i18n("objectType.process.Sentence")}
                        />
                      </NavMenuItem>
                    )}

                    {canEditObjectType(OBJECT_TYPES.JOB.fullString) && (
                      <NavMenuItem
                        onClick={() =>
                          openEditModal(OBJECT_TYPES.JOB.fullString)
                        }
                        dataTesting="addMenuJob"
                      >
                        <NavListItemIcon>
                          <Work />
                        </NavListItemIcon>
                        <MenuListItemText
                          primary={i18n("objectType.job.Sentence")}
                        />
                      </NavMenuItem>
                    )}

                    {canEditObjectType(OBJECT_TYPES.WORK_ORDER.fullString) && (
                      <NavMenuItem
                        onClick={() =>
                          openEditModal(OBJECT_TYPES.WORK_ORDER.fullString)
                        }
                        dataTesting="addMenuWorkOrder"
                      >
                        <NavListItemIcon>
                          <Description />
                        </NavListItemIcon>
                        <MenuListItemText
                          primary={i18n("objectType.workorder.Sentence")}
                        />
                      </NavMenuItem>
                    )}
                  </div>
                )}
              </MenuList>
            </Grid2>
            <Grid2 size={4}>
              <MenuList id="sync-list-grow" onKeyDown={handleListKeyDown}>
                <MenuHeader>{i18n("objectType.rental.Sentence")}</MenuHeader>

                {hasAtLeastPlusPlan && (
                  <div>
                    {canEditObjectType(OBJECT_TYPES.RENTAL.fullString) && (
                      <NavMenuItem
                        onClick={() =>
                          openEditModal(OBJECT_TYPES.RENTAL.fullString)
                        }
                        dataTesting="addMenuRental"
                      >
                        <NavListItemIcon>
                          <Description />
                        </NavListItemIcon>
                        <MenuListItemText
                          primary={i18n("objectType.rental.Sentence")}
                        />
                      </NavMenuItem>
                    )}

                    {canEditObjectType(
                      OBJECT_TYPES.RENTAL_RETURN.fullString
                    ) && (
                      <NavMenuItem
                        onClick={() =>
                          openEditModal(OBJECT_TYPES.RENTAL_RETURN.fullString)
                        }
                        dataTesting="addMenuRentalReturn"
                      >
                        <NavListItemIcon>
                          <Description />
                        </NavListItemIcon>
                        <MenuListItemText
                          primary={i18n("objectType.rentalreturn.Sentence")}
                        />
                      </NavMenuItem>
                    )}
                  </div>
                )}
              </MenuList>
            </Grid2>
          </Grid2>
        </Box>
      </DropDownMenu>
    </>
  );
}
