import { i18n } from "services/i18nService";
import {
  extractName,
  extractId,
  formatMoneyWithoutAdornments,
  formatDecimal,
} from "services/utility/formatting";
import { getReportDecimalTotal } from "services/utility/reports";

import { NONE_GROUP_BY_OPTION } from "views/Reports/reportConstants";

import { ALL_FILTER_OPTION, OBJECT_TYPES } from "appConstants";

export const GROUP_BY_OPTIONS = [
  NONE_GROUP_BY_OPTION,
  { id: "Category", name: i18n("reports.OptionLabel.Category") },
  { id: "Vendor", name: i18n("reports.OptionLabel.Vendor") },
];

export const ReorderReport = {
  userHasSufficientPrivilege: (p) =>
    p.viewInventory || p.viewItems || p.editItems,
  planIncludesReport: () => true,
  customReportNameFetchList: [
    { objectType: OBJECT_TYPES.ITEM.fullString, customReportKey: "categories" },
    {
      objectType: OBJECT_TYPES.LOCATION.fullString,
      customReportKey: "locations",
    },
    { objectType: OBJECT_TYPES.VENDOR.fullString },
  ],
  customFieldObjectTypes: "item",
  isJsonReport: true,
  reportAction: { id: "Checkbox", label: "" },
  columns: [
    { apiName: "item", formatFunc: extractId, label: "Item #" },
    {
      apiName: "Name",
      jsonName: "item",
      formatFunc: extractName,
      linkColumn: true,
      linkObjectType: "item",
      default: true,
    },
    { apiName: "Category", jsonName: "category", formatFunc: extractName },
    { apiName: "Description", jsonName: "description", default: true },
    { apiName: "Purchase Description", jsonName: "purchaseDescription" },
    { apiName: "SKU", jsonName: "sku" },
    { apiName: "Barcode", jsonName: "barcode" },
    { apiName: "Vendor", jsonName: "vendor", formatFunc: extractName },
    {
      apiName: "VendorPartNumber",
      jsonName: "vendorPartNumber",
      label: "Vendor Part #",
    },
    {
      apiName: "Sales Price",
      jsonName: "salesPrice",
      formatFunc: formatMoneyWithoutAdornments,
      number: true,
    },
    {
      apiName: "Purchase Cost",
      jsonName: "purchaseCost",
      formatFunc: formatMoneyWithoutAdornments,
      number: true,
    },
    {
      apiName: "Default Bin",
      formatFunc: extractName,
      jsonName: "defaultBin",
    },
    {
      apiName: "Asset Account",
      formatFunc: extractName,
      jsonName: "assetAccount",
    },
    {
      apiName: "Lead Time",
      jsonName: "leadTime",
      formatFunc: formatDecimal,
      number: true,
    },
    { apiName: "Tags", jsonName: "tags" },
    {
      apiName: "On Hand",
      jsonName: "onHand",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      number: true,
    },
    {
      apiName: "Available",
      jsonName: "available",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      default: true,
      number: true,
    },
    {
      apiName: "On SO",
      jsonName: "onSo",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      inventoryColumn: true,
      inventoryType: "onSo",
      number: true,
    },
    {
      apiName: "On SR",
      jsonName: "onSr",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      inventoryColumn: true,
      inventoryType: "onSr",
      number: true,
    },
    {
      apiName: "On PO",
      jsonName: "onPo",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      inventoryColumn: true,
      inventoryType: "onPo",
      number: true,
    },
    {
      apiName: "On RMA",
      jsonName: "onRma",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      inventoryColumn: true,
      inventoryType: "onRma",
      number: true,
    },
    {
      apiName: "On WO",
      jsonName: "onWo",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      inventoryColumn: true,
      inventoryType: "onWo",
      number: true,
    },
    {
      apiName: "On Rental",
      jsonName: "onRental",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      inventoryColumn: true,
      inventoryType: "onRental",
      number: true,
    },
    { apiName: "Vendor Terms", jsonName: "vendorTerms" },
    {
      apiName: "Reorder Point",
      jsonName: "reorder",
      formatFunc: formatDecimal,
      number: true,
      alwaysShow: true,
    },
    {
      apiName: "Max Stock",
      jsonName: "maxStock",
      formatFunc: formatDecimal,
      number: true,
      alwaysShow: true,
    },
    {
      apiName: "Needed",
      jsonName: "needed",
      formatFunc: formatDecimal,
      footerFunc: getReportDecimalTotal,
      number: true,
      alwaysShow: true,
    },
  ],
  settings: [
    {
      label: i18n("filter.Location"),
      component: "LocationFilter",
      apiName: "locations",
      defaultValue: [ALL_FILTER_OPTION],
      includeAllOption: true,
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "includeNulls",
      defaultValue: false,
      label: i18n("reports.OptionLabel.TreatEmptyReorderPointsAsZero"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "includePOs",
      defaultValue: true,
      label: i18n("reports.OptionLabel.IncludePOsInCalculation"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "includeRMAs",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeRMAsInCalculation"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "includeRentals",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeRentalsInCalculation"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "includeWOs",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeWOsInCalculation"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "addToReorderList",
      defaultValue: false,
      label: i18n("reports.OptionLabel.AutoAddToReorderList"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "groupBy",
      label: i18n("reports.OptionLabel.GroupBy"),
      component: "FrmButtonGroup",
      defaultValue: NONE_GROUP_BY_OPTION,
      options: GROUP_BY_OPTIONS,
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
