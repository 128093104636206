import { columnInfo as columnInfoAdjustment } from "services/sosInventoryService/adjustment/schema";
import { columnInfo as columnInfoAlert } from "services/sosInventoryService/alert/schema";
import { columnInfo as columnInfoAopRule } from "services/sosInventoryService/aopRule/schema";
import { columnInfo as columnInfoBin } from "services/sosInventoryService/bin/schema";
import { columnInfo as columnInfoBuild } from "services/sosInventoryService/build/schema";
import { columnInfo as columnInfoChannel } from "services/sosInventoryService/channel/schema";
import { columnInfo as columnInfoClass } from "services/sosInventoryService/class/schema";
import { columnInfo as columnInfoCustomField } from "services/sosInventoryService/customField/schema";
import { columnInfo as columnInfoCustomer } from "services/sosInventoryService/customer/schema";
import { columnInfo as columnInfoCustomerMessage } from "services/sosInventoryService/customerMessage/schema";
import { columnInfo as columnInfoCustomerType } from "services/sosInventoryService/customerType/schema";
import { columnInfo as columnInfoDeletedItem } from "services/sosInventoryService/deletedItem/schema";
import { columnInfo as columnInfoDepartment } from "services/sosInventoryService/department/schema";
import { columnInfo as columnInfoDocument } from "services/sosInventoryService/document/schema";
import { columnInfo as columnInfoEstimate } from "services/sosInventoryService/estimate/schema";
import { columnInfo as columnInfoFormTemplate } from "services/sosInventoryService/formTemplate/schema";
import { columnInfo as columnInfoFsPayment } from "services/sosInventoryService/fsPayment/schema";
import { columnInfo as columnInfoInvoice } from "services/sosInventoryService/invoice/schema";
import { columnInfo as columnInfoItem } from "services/sosInventoryService/item/schema";
import { columnInfo as columnInfoItemReceipt } from "services/sosInventoryService/itemReceipt/schema";
import { columnInfo as columnInfoJob } from "services/sosInventoryService/job/schema";
import { columnInfo as columnInfoLocation } from "services/sosInventoryService/location/schema";
import { columnInfo as columnInfoLot } from "services/sosInventoryService/lot/schema";
import { columnInfo as columnInfoOrderStage } from "services/sosInventoryService/orderStage/schema";
import { columnInfo as columnInfoPayment } from "services/sosInventoryService/payment/schema";
import { columnInfo as columnInfoPaymentMethod } from "services/sosInventoryService/paymentMethod/schema";
import { columnInfo as columnInfoPickTicket } from "services/sosInventoryService/pickTicket/schema";
import { columnInfo as columnInfoPriceTier } from "services/sosInventoryService/priceTier/schema";
import { columnInfo as columnInfoPriority } from "services/sosInventoryService/priority/schema";
import { columnInfo as columnInfoProcess } from "services/sosInventoryService/process/schema";
import { columnInfo as columnInfoProcessTemplate } from "services/sosInventoryService/processTemplate/schema";
import { columnInfo as columnInfoPurchaseOrder } from "services/sosInventoryService/purchaseOrder/schema";
import { columnInfo as columnInfoRental } from "services/sosInventoryService/rental/schema";
import { columnInfo as columnInfoRentalReturn } from "services/sosInventoryService/rentalReturn/schema";
import { columnInfo as columnInfoReorder } from "services/sosInventoryService/reorder/schema";
import { columnInfo as columnInfoReturn } from "services/sosInventoryService/return/schema";
import { columnInfo as columnInfoReturnToVendor } from "services/sosInventoryService/returnToVendor/schema";
import { columnInfo as columnInfoRma } from "services/sosInventoryService/rma/schema";
import { columnInfo as columnInfoSalesOrder } from "services/sosInventoryService/salesOrder/schema";
import { columnInfo as columnInfoSalesReceipt } from "services/sosInventoryService/salesReceipt/schema";
import { columnInfo as columnInfoSalesRep } from "services/sosInventoryService/salesRep/schema";
import { columnInfo as columnInfoSerial } from "services/sosInventoryService/serial/schema";
import { columnInfo as columnInfoShipMethod } from "services/sosInventoryService/shipMethod/schema";
import { columnInfo as columnInfoShipment } from "services/sosInventoryService/shipment/schema";
import { columnInfo as columnInfoSyncItem } from "services/sosInventoryService/syncItem/schema";
import { columnInfo as columnInfoTag } from "services/sosInventoryService/tag/schema";
import { columnInfo as columnInfoTask } from "services/sosInventoryService/task/schema";
import { columnInfo as columnInfoTemplateLibrary } from "services/sosInventoryService/templateLibrary/schema";
import { columnInfo as columnInfoTerm } from "services/sosInventoryService/term/schema";
import { columnInfo as columnInfoTransfer } from "services/sosInventoryService/transfer/schema";
import { columnInfo as columnInfoUom } from "services/sosInventoryService/uom/schema";
import { columnInfo as columnInfoUpsShipment } from "services/sosInventoryService/upsShipment/schema";
import { columnInfo as columnInfoUser } from "services/sosInventoryService/user/schema";
import { columnInfo as columnInfoVendor } from "services/sosInventoryService/vendor/schema";
import { columnInfo as columnInfoVendorItem } from "services/sosInventoryService/vendorItem/schema";
import { columnInfo as columnInfoVendorMessage } from "services/sosInventoryService/vendorMessage/schema";
import { columnInfo as columnInfoWarranty } from "services/sosInventoryService/warranty/schema";
import { columnInfo as columnInfoWorkCenter } from "services/sosInventoryService/workCenter/schema";
import { columnInfo as columnInfoWorkOrder } from "services/sosInventoryService/workOrder/schema";
import { columnInfo as columnInfoWorker } from "services/sosInventoryService/worker/schema";

import { hasPrivilegesOrIsAdmin } from "hooks/usePrivileges";

import globalState from "globalState/globalState";

import { PLAN_PLUS, PLAN_PRO, OBJECT_TYPES } from "appConstants";

export const COLUMN_INFO = {
  adjustment: () => pruneForPlanAndPrivilege(columnInfoAdjustment),
  alert: () => pruneForPlanAndPrivilege(columnInfoAlert),
  aoprule: () => pruneForPlanAndPrivilege(columnInfoAopRule),
  bins: () => pruneForPlanAndPrivilege(columnInfoBin),
  build: () => pruneForPlanAndPrivilege(columnInfoBuild),
  class: () => pruneForPlanAndPrivilege(columnInfoClass),
  channel: () => pruneForPlanAndPrivilege(columnInfoChannel),
  customfield: () => pruneForPlanAndPrivilege(columnInfoCustomField),
  customermessage: () => pruneForPlanAndPrivilege(columnInfoCustomerMessage),
  customertype: () => pruneForPlanAndPrivilege(columnInfoCustomerType),
  customer: () => pruneForPlanAndPrivilege(columnInfoCustomer),
  department: () => pruneForPlanAndPrivilege(columnInfoDepartment),
  document: () => pruneForPlanAndPrivilege(columnInfoDocument),
  estimate: () => pruneForPlanAndPrivilege(columnInfoEstimate),
  formtemplate: () => pruneForPlanAndPrivilege(columnInfoFormTemplate),
  fspayment: () => pruneForPlanAndPrivilege(columnInfoFsPayment),
  deleteditem: () => pruneForPlanAndPrivilege(columnInfoDeletedItem),
  invoice: () => pruneForPlanAndPrivilege(columnInfoInvoice),
  item: () => pruneForPlanAndPrivilege(columnInfoItem),
  itemreceipt: () => pruneForPlanAndPrivilege(columnInfoItemReceipt),
  job: () => pruneForPlanAndPrivilege(columnInfoJob),
  location: () => pruneForPlanAndPrivilege(columnInfoLocation),
  lot: () => pruneForPlanAndPrivilege(columnInfoLot),
  payment: () => pruneForPlanAndPrivilege(columnInfoPayment),
  paymentmethod: () => pruneForPlanAndPrivilege(columnInfoPaymentMethod),
  pickticket: () => pruneForPlanAndPrivilege(columnInfoPickTicket),
  process: () => pruneForPlanAndPrivilege(columnInfoProcess),
  processtemplate: () => pruneForPlanAndPrivilege(columnInfoProcessTemplate),
  priority: () => pruneForPlanAndPrivilege(columnInfoPriority),
  pricetier: () => pruneForPlanAndPrivilege(columnInfoPriceTier),
  orderstage: () => pruneForPlanAndPrivilege(columnInfoOrderStage),
  purchaseorder: () => pruneForPlanAndPrivilege(columnInfoPurchaseOrder),
  reorder: () => pruneForPlanAndPrivilege(columnInfoReorder),
  returntovendor: () => pruneForPlanAndPrivilege(columnInfoReturnToVendor),
  salesorder: () => pruneForPlanAndPrivilege(columnInfoSalesOrder),
  salesreceipt: () => pruneForPlanAndPrivilege(columnInfoSalesReceipt),
  salesrep: () => pruneForPlanAndPrivilege(columnInfoSalesRep),
  serial: () => pruneForPlanAndPrivilege(columnInfoSerial),
  shipment: () => pruneForPlanAndPrivilege(columnInfoShipment),
  shipmethod: () => pruneForPlanAndPrivilege(columnInfoShipMethod),
  tag: () => pruneForPlanAndPrivilege(columnInfoTag),
  task: () => pruneForPlanAndPrivilege(columnInfoTask),
  templatelibrary: () => pruneForPlanAndPrivilege(columnInfoTemplateLibrary),
  terms: () => pruneForPlanAndPrivilege(columnInfoTerm),
  transfer: () => pruneForPlanAndPrivilege(columnInfoTransfer),
  user: () => pruneForPlanAndPrivilege(columnInfoUser),
  vendor: () => pruneForPlanAndPrivilege(columnInfoVendor),
  workorder: () => pruneForPlanAndPrivilege(columnInfoWorkOrder),
  rental: () => pruneForPlanAndPrivilege(columnInfoRental),
  rentalreturn: () => pruneForPlanAndPrivilege(columnInfoRentalReturn),
  return: () => pruneForPlanAndPrivilege(columnInfoReturn),
  syncitem: () => pruneForPlanAndPrivilege(columnInfoSyncItem),
  rma: () => pruneForPlanAndPrivilege(columnInfoRma),
  vendoritem: () => pruneForPlanAndPrivilege(columnInfoVendorItem),
  vendormessage: () => pruneForPlanAndPrivilege(columnInfoVendorMessage),
  warranty: () => pruneForPlanAndPrivilege(columnInfoWarranty),
  workcenter: () => pruneForPlanAndPrivilege(columnInfoWorkCenter),
  worker: () => pruneForPlanAndPrivilege(columnInfoWorker),
  uom: () => pruneForPlanAndPrivilege(columnInfoUom),
  upsshipment: () => pruneForPlanAndPrivilege(columnInfoUpsShipment),
};

export const FILTERS = {
  adjustment: ["dates", "archive", "locations"],
  alert: [],
  aoprule: [],
  bins: [],
  build: ["dates", "archive", "locations"],
  channel: [],
  customfield: [],
  customermessage: [],
  customertype: [],
  class: [],
  customer: ["archive"],
  department: [],
  deleteditem: ["itemType", "categories", "tags"],
  document: [],
  estimate: ["dates", "archive", "status", "customers"],
  formtemplate: ["type"],
  fspayment: ["dates", "customers"],
  invoice: ["dates", "archive", "status", "customers"],
  itemreceipt: ["archive", "dates", "locations", "vendors"],
  item: [
    "itemType",
    "categories",
    "archive",
    "locations",
    "vendors",
    "variants",
    "tags",
  ],
  job: ["archive", "openClose", "customers"],
  location: [],
  lot: ["lotTrackedItems", "status", "archive", "expirationDate"],
  payment: ["dates", "archive", "locations", "customers"],
  paymentmethod: [],
  pickticket: [
    "dates",
    "archive",
    "openClose",
    "locations",
    "customers",
    "assignedTo",
  ],
  priority: [],
  pricetier: [],
  orderstage: [],
  process: ["dates", "archive", "locations"],
  processtemplate: [],
  purchaseorder: ["dates", "archive", "vendors", "assignedTo", "openClose"],
  rental: ["dates", "archive", "openClose", "fromLocation", "customers"],
  rentalreturn: ["dates", "archive", "customers", "locations"],
  reorder: ["items", "vendors", "locations"],
  return: ["dates", "archive", "locations", "customers"],
  returntovendor: ["dates", "archive", "locations", "vendors"],
  rma: ["dates", "archive", "openClose", "customers"],
  salesorder: ["dates", "archive", "openClose", "customers", "assignedTo"],
  salesreceipt: ["dates", "archive", "openClose", "customers", "assignedTo"],
  salesrep: [],
  serial: ["serializedItems", "locations", "status", "customers"],
  shipment: ["dates", "archive", "locations", "customers", "assignedTo"],
  shipmethod: [],
  syncitem: ["action", "objectType"],
  tag: [],
  task: ["status"],
  templatelibrary: ["type"],
  terms: [],
  transfer: ["dates", "archive", "toLocation", "fromLocation"],
  uom: [],
  upsshipment: [],
  user: ["status"],
  vendor: ["archive"],
  vendoritem: ["items", "vendors"],
  vendormessage: [],
  warranty: [],
  workcenter: [],
  worker: [],
  workorder: ["dates", "archive", "openClose", "customers", "assignedTo"],
};

export const DEFAULT_FILTER_SETTINGS = { ShowArchived: "No" };
export const OBJECT_SPECIFIC_DEFAULT_FILTERS = {
  adjustment: { FromDate: null, ToDate: null, ShowArchived: "No" },
  alert: {},
  aoprule: {},
  bins: {},
  build: { FromDate: null, ToDate: null, ShowArchived: "No" },
  channel: {},
  class: {},
  customfield: {},
  customermessage: {},
  customertype: {},
  deleteditem: {},
  department: {},
  document: {},
  estimate: { FromDate: null, ToDate: null, ShowArchived: "No" },
  formtemplate: {},
  fspayment: { FromDate: null, ToDate: null },
  invoice: { FromDate: null, ToDate: null, ShowArchived: "No" },
  location: {},
  lot: { FromExpiration: null, ToExpiration: null, ShowArchived: "No" },
  orderstage: {},
  paymentmethod: {},
  process: { FromDate: null, ToDate: null, ShowArchived: "No" },
  pricetier: {},
  priority: {},
  processtemplate: {},
  purchaseorder: { FromDate: null, ToDate: null, ShowArchived: "No" },
  rental: { FromDate: null, ToDate: null, ShowArchived: "No" },
  rentalreturn: { FromDate: null, ToDate: null, ShowArchived: "No" },
  reorder: {},
  returntovendor: { FromDate: null, ToDate: null, ShowArchived: "No" },
  rma: { FromDate: null, ToDate: null, ShowArchived: "No" },
  salesorder: { FromDate: null, ToDate: null, ShowArchived: "No" },
  salesreceipt: { FromDate: null, ToDate: null, ShowArchived: "No" },
  salesrep: {},
  serial: {},
  shipment: { FromDate: null, ToDate: null, ShowArchived: "No" },
  shipmethod: {},
  tag: {},
  templatelibrary: {},
  terms: {},
  task: { ShowStatus: "active" },
  transfer: { FromDate: null, ToDate: null, ShowArchived: "No" },
  vendoritem: {},
  vendormessage: {},
  warranty: {},
  workcenter: {},
  workorder: { FromDate: null, ToDate: null, ShowArchived: "No" },
  worker: {},
  user: {},
  uom: {},
  upsshipment: {},
};

export function getIdentifier(objectType, record) {
  switch (objectType) {
    case OBJECT_TYPES.ADJUSTMENT.fullString:
    case OBJECT_TYPES.BIN.fullString:
    case OBJECT_TYPES.BUILD.fullString:
    case OBJECT_TYPES.ESTIMATE.fullString:
    case OBJECT_TYPES.FS_PAYMENT.fullString:
    case OBJECT_TYPES.INVOICE.fullString:
    case OBJECT_TYPES.ITEM_RECEIPT.fullString:
    case OBJECT_TYPES.LOT.fullString:
    case OBJECT_TYPES.PAYMENT.fullString:
    case OBJECT_TYPES.PICK_TICKET.fullString:
    case OBJECT_TYPES.PROCESS.fullString:
    case OBJECT_TYPES.PURCHASE_ORDER.fullString:
    case OBJECT_TYPES.RENTAL.fullString:
    case OBJECT_TYPES.RENTAL_RETURN.fullString:
    case OBJECT_TYPES.RETURN.fullString:
    case OBJECT_TYPES.RETURN_TO_VENDOR.fullString:
    case OBJECT_TYPES.RMA.fullString:
    case OBJECT_TYPES.SALES_ORDER.fullString:
    case OBJECT_TYPES.SALES_RECEIPT.fullString:
    case OBJECT_TYPES.SERIAL.fullString:
    case OBJECT_TYPES.SHIPMENT.fullString:
    case OBJECT_TYPES.TRANSFER.fullString:
    case OBJECT_TYPES.WORK_ORDER.fullString:
      return record.number;
    case OBJECT_TYPES.ALERT.fullString:
    case OBJECT_TYPES.AOP_RULE.fullString:
    case OBJECT_TYPES.CHANNEL.fullString:
    case OBJECT_TYPES.CLASS.fullString:
    case OBJECT_TYPES.CUSTOM_FIELD.fullString:
    case OBJECT_TYPES.CUSTOMER.fullString:
    case OBJECT_TYPES.CUSTOMER_MESSAGE.fullString:
    case OBJECT_TYPES.CUSTOMER_TYPE.fullString:
    case OBJECT_TYPES.DELETED_ITEM.fullString:
    case OBJECT_TYPES.DEPARTMENT.fullString:
    case OBJECT_TYPES.FORM_TEMPLATE.fullString:
    case OBJECT_TYPES.ITEM.fullString:
    case OBJECT_TYPES.JOB.fullString:
    case OBJECT_TYPES.LOCATION.fullString:
    case OBJECT_TYPES.ORDER_STAGE.fullString:
    case OBJECT_TYPES.PAYMENT_METHOD.fullString:
    case OBJECT_TYPES.PRICE_TIER.fullString:
    case OBJECT_TYPES.PRIORITY.fullString:
    case OBJECT_TYPES.PROCESS_TEMPLATE.fullString:
    case OBJECT_TYPES.SHIP_METHOD.fullString:
    case OBJECT_TYPES.TAG.fullString:
    case OBJECT_TYPES.TASK.fullString:
    case OBJECT_TYPES.TEMPLATE_LIBRARY.fullString:
    case OBJECT_TYPES.UPS_SHIPMENT.fullString:
    case OBJECT_TYPES.TERM.fullString:
    case OBJECT_TYPES.UOM.fullString:
    case OBJECT_TYPES.USER.fullString:
    case OBJECT_TYPES.VENDOR.fullString:
    case OBJECT_TYPES.VENDOR_MESSAGE.fullString:
    case OBJECT_TYPES.WARRANTY.fullString:
    case OBJECT_TYPES.WORK_CENTER.fullString:
      return record.name;
    case OBJECT_TYPES.REORDER.fullString:
    case OBJECT_TYPES.VENDOR_ITEM.fullString:
      return record.item.name;
    case OBJECT_TYPES.DOCUMENT.fullString:
      return record.fileName;
    case OBJECT_TYPES.SALES_REP.fullString:
    case OBJECT_TYPES.WORKER.fullString:
      return `${record.firstName} ${record.lastName}`;
    default:
      break;
  }
}

// eliminate columns that aren't available in the company's plan
function pruneForPlanAndPrivilege(columnInfo) {
  const { company, settings } = globalState.getState().userCompanySettings;

  // plan
  const { allowPlusFeatures, allowProFeatures } = settings;
  const planFilteredColumns = columnInfo.filter(
    ({ planRestriction }) =>
      !planRestriction ||
      planRestriction.some(
        (plan) =>
          (plan === PLAN_PLUS && allowPlusFeatures) ||
          (plan === PLAN_PRO && allowProFeatures)
      )
  );

  // privileges
  const { userPrivileges } = company;
  const planAndPrivilegeFilteredColumns = planFilteredColumns.filter(
    ({ privilegeRestriction }) =>
      !privilegeRestriction ||
      hasPrivilegesOrIsAdmin(privilegeRestriction, userPrivileges)
  );

  return planAndPrivilegeFilteredColumns;
}

export const HIDE_COLUMN_SELECTOR = [
  OBJECT_TYPES.ALERT.fullString,
  OBJECT_TYPES.AOP_RULE.fullString,
  OBJECT_TYPES.BIN.fullString,
  OBJECT_TYPES.CHANNEL.fullString,
  OBJECT_TYPES.CLASS.fullString,
  OBJECT_TYPES.CUSTOM_FIELD.fullString,
  OBJECT_TYPES.CUSTOMER_MESSAGE.fullString,
  OBJECT_TYPES.CUSTOMER_TYPE.fullString,
  OBJECT_TYPES.DEPARTMENT.fullString,
  OBJECT_TYPES.DOCUMENT.fullString,
  OBJECT_TYPES.FORM_TEMPLATE.fullString,
  OBJECT_TYPES.LOCATION.fullString,
  OBJECT_TYPES.ORDER_STAGE.fullString,
  OBJECT_TYPES.PAYMENT_METHOD.fullString,
  OBJECT_TYPES.PROCESS_TEMPLATE.fullString,
  OBJECT_TYPES.PRICE_TIER.fullString,
  OBJECT_TYPES.PRIORITY.fullString,
  OBJECT_TYPES.SALES_REP.fullString,
  OBJECT_TYPES.SHIP_METHOD.fullString,
  OBJECT_TYPES.SYNC_ITEM.fullString,
  OBJECT_TYPES.TAG.fullString,
  OBJECT_TYPES.TASK.fullString,
  OBJECT_TYPES.TERM.fullString,
  OBJECT_TYPES.TEMPLATE_LIBRARY.fullString,
  OBJECT_TYPES.UOM.fullString,
  OBJECT_TYPES.UPS_SHIPMENT.fullString,
  OBJECT_TYPES.USER.fullString,
  OBJECT_TYPES.VENDOR_ITEM.fullString,
  OBJECT_TYPES.VENDOR_MESSAGE.fullString,
  OBJECT_TYPES.WARRANTY.fullString,
  OBJECT_TYPES.WORK_CENTER.fullString,
  OBJECT_TYPES.WORKER.fullString,
];

export const HIDE_SEARCH_FIELD = [OBJECT_TYPES.SYNC_ITEM.fullString];
