import { i18n } from "services/i18nService";
import { formatNameLinkField, extractName } from "services/utility/formatting";

import { OBJECT_TYPES } from "appConstants";

export const columnInfo = [
  {
    name: "name",
    fixed: true,
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    defaultSort: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    fixedRightOfAction: true,
    fixed: true,
    sortable: true,
  },
  {
    name: "form",
    heading: i18n("columns.Type"),
    formatFunc: extractName,
    fixedRightOfAction: true,
    fixed: true,
  },
  {
    name: "userEmail",
    heading: i18n("columns.LastUpdatedBy"),
    fixedRightOfAction: true,
    fixed: true,
    sortable: true,
  },
];

export const LINE_ACTIONS = ["delete", "downloadTemplate"];
export const BATCH_ACTIONS = ["restoreDefaultTemplates", "delete"];

export const ADJUSTMENT_FORM_TYPE = {
  id: OBJECT_TYPES.ADJUSTMENT.typeString,
  name: i18n(`objectType.${OBJECT_TYPES.ADJUSTMENT.fullString}.Sentence`),
};

export const FORM_TYPES = [
  ADJUSTMENT_FORM_TYPE,
  {
    id: OBJECT_TYPES.BIN.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.BIN.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.BUILD.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.BUILD.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.CUSTOMER.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.CUSTOMER.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.ESTIMATE.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.ESTIMATE.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.INVOICE.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.INVOICE.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.ITEM.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.ITEM.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.ITEM_RECEIPT.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.ITEM_RECEIPT.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.LOT.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.LOT.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.PAYMENT.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.PAYMENT.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.PICK_TICKET.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.PICK_TICKET.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.PROCESS.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.PROCESS.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.PURCHASE_ORDER.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.PURCHASE_ORDER.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.RENTAL.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.RENTAL.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.RENTAL_RETURN.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.RENTAL_RETURN.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.RETURN.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.RETURN.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.RETURN_TO_VENDOR.typeString,
    name: i18n(
      `objectType.${OBJECT_TYPES.RETURN_TO_VENDOR.fullString}.Sentence`
    ),
  },
  {
    id: OBJECT_TYPES.RMA.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.RMA.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.SALES_ORDER.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.SALES_ORDER.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.SALES_RECEIPT.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.SALES_RECEIPT.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.SERIAL.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.SERIAL.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.SHIPMENT.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.SHIPMENT.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.UPS_SHIPMENT.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.UPS_SHIPMENT.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.TRANSFER.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.TRANSFER.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.VENDOR.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.VENDOR.fullString}.Sentence`),
  },
  {
    id: OBJECT_TYPES.WORK_ORDER.typeString,
    name: i18n(`objectType.${OBJECT_TYPES.WORK_ORDER.fullString}.Sentence`),
  },
];

export function getEmptyRecord() {
  return {
    name: null,
    fileName: null,
    description: null,
    form: ADJUSTMENT_FORM_TYPE,
    content: null,
  };
}
