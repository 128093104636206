export const SETTINGS = [
  {
    apiName: "searchByAnyPartOfVendor",
    component: "FrmCheckbox",
  },
  {
    apiName: "defaultExpenseAccount",
    component: "FrmAccount",
    accountType: "expense",
  },
  {
    apiName: "updateDefaultCostOnPurchase",
    component: "FrmCheckbox",
  },
  {
    apiName: "includeWOonReorderReport",
    component: "FrmCheckbox",
  },
  {
    apiName: "showCustomerOnPurchaseForms",
    component: "FrmCheckbox",
  },
  {
    apiName: "showWeightOnPurchasingForms",
    component: "FrmCheckbox",
  },
  {
    apiName: "showVolumeOnPurchasingForms",
    component: "FrmCheckbox",
  },
];
