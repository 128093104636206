import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import {
  Grid2,
  DialogTitle,
  DialogContent,
  TableContainer,
  Paper,
  Table,
  TableHead,
  TableRow,
  TableBody,
} from "@mui/material";

import { HistoryLineItems } from "components/HistoryLineItems";
import { TableCell } from "components/TableCell";
import { CloseX } from "components/utility/CloseX";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { LinkText } from "components/utility/LinkText";
import { Loading } from "components/utility/Loading";
import { ModalTitle } from "components/utility/ModalTitle";

import { i18n } from "services/i18nService";
import { getHistory } from "services/sosInventoryService/sosApi";
import {
  formatDate,
  formatZonelessDateTime,
  isoToDateTime,
  isoToLocalDateTime,
} from "services/utility/dates";
import {
  formatMoney,
  INCLUDE_ADORNMENTS,
  formatSpaceIntoLines,
} from "services/utility/formatting";
import { checkForUnexpectedProps } from "services/utility/misc";

import { openModal } from "globalState/modalSlice";

import { getObjectFromTypeString, getObjectFromFullString } from "appConstants";
import { OBJECT_TYPES } from "appConstants";

const COLUMNS = {
  vendor: {
    txnDate: true,
    type: true,
    refNumber: true,
    location: true,
    lines: true,
    total: true,
  },
  customer: {
    txnDate: true,
    type: true,
    refNumber: true,
    location: true,
    lines: true,
    total: true,
    customerPO: true,
  },
};

const DATE_ONLY_FIELDS = [
  OBJECT_TYPES.PURCHASE_ORDER.typeString,
  OBJECT_TYPES.SALES_RECEIPT.typeString,
  OBJECT_TYPES.INVOICE.typeString,
];

const NON_STANDARD_DATETIME_FIELDS = [
  OBJECT_TYPES.ESTIMATE.typeString,
  OBJECT_TYPES.RMA.typeString,
  OBJECT_TYPES.SALES_ORDER.typeString,
];

// three types of dates to handle:
//   - "standard" SOS date/time values, which are converted from UTC
//     time to the current user's time zone
//   - "standard" and "non-standard" SOS date values (no time component),
//     which should display only the date portion (with no time zone
//     conversion)
//   - "non-standard" date/time values that are in the creating user's
//     local time and should not be converted to the current user's
//     time zone
function formatDatetime(type, txnDate) {
  if (NON_STANDARD_DATETIME_FIELDS.includes(type)) {
    return formatSpaceIntoLines(formatZonelessDateTime(isoToDateTime(txnDate)));
  }
  if (DATE_ONLY_FIELDS.includes(type)) {
    return formatSpaceIntoLines(formatDate(isoToDateTime(txnDate)));
  }
  return formatSpaceIntoLines(
    formatZonelessDateTime(isoToLocalDateTime(txnDate))
  );
}

export function CustomerVendorHistory(props) {
  const { onClose, objectType, id, identifierText, ...unexpected } = props;
  checkForUnexpectedProps("TransactionHistory", unexpected);

  const [history, setHistory] = useState(undefined);
  const [isLoading, setIsLoading] = useState(false);

  const dispatch = useDispatch();
  const recordsChanged = useSelector((state) => state.recordsChanged);

  function showColumn(columnName) {
    return COLUMNS[objectType][columnName];
  }

  useEffect(() => {
    async function retrieveHistory() {
      setIsLoading(true);
      const { fullString } = getObjectFromFullString(objectType);
      const { data } = await getHistory(fullString, id);
      setHistory(data);
      setIsLoading(false);
    }
    retrieveHistory();
  }, [objectType, id, recordsChanged]);

  function openEditModal(objectType, id) {
    dispatch(openModal({ objectType, modalProps: { id } }));
  }

  return (
    <>
      <DialogTitle
        sx={{
          backgroundColor: "dialogTitleBackground",
          borderBottom: "1px solid",
          borderBottomColor: "lineSeparatorMinor",
          padding: "0.5rem 1rem",
        }}
      >
        <Grid2
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
          <Grid2 size={8}>
            <div style={{ display: "flex", alignItems: "center" }}>
              <CloseX onClick={onClose} />
              <ModalTitle
                label={i18n("global.History")}
                text={identifierText}
              />
            </div>
          </Grid2>
        </Grid2>
      </DialogTitle>
      {isLoading && <Loading />}
      <DialogContent>
        {history ? (
          <TableContainer component={Paper} sx={{ mt: 1, height: "80vh" }}>
            <Table size="small">
              <TableHead>
                <TableRow>
                  {showColumn("txnDate") && (
                    <TableCell sx={{ verticalAlign: "bottom" }}>
                      {i18n("transactionHistory.txnDate")}
                    </TableCell>
                  )}
                  {showColumn("type") && (
                    <TableCell sx={{ verticalAlign: "bottom" }}>
                      {i18n("transactionHistory.type")}
                    </TableCell>
                  )}
                  {showColumn("refNumber") && (
                    <TableCell sx={{ verticalAlign: "bottom" }}>
                      {i18n("transactionHistory.refNumber")}
                    </TableCell>
                  )}
                  {showColumn("location") && (
                    <TableCell sx={{ verticalAlign: "bottom" }}>
                      {i18n("transactionHistory.location")}
                    </TableCell>
                  )}
                  {showColumn("lines") && (
                    <TableCell sx={{ verticalAlign: "bottom" }}>
                      {i18n("transactionHistory.lines")}
                    </TableCell>
                  )}
                  {showColumn("total") && (
                    <TableCell sx={{ verticalAlign: "bottom" }} align="right">
                      {i18n("transactionHistory.total")}
                    </TableCell>
                  )}
                  {showColumn("customerPO") && (
                    <TableCell sx={{ verticalAlign: "bottom" }}>
                      {i18n("transactionHistory.customerPO")}
                    </TableCell>
                  )}
                </TableRow>
              </TableHead>
              <TableBody>
                {history.map((transaction, index) => (
                  <TableRow key={index}>
                    {showColumn("txnDate") && (
                      <TableCell sx={{ verticalAlign: "top" }}>
                        {formatDatetime(transaction.type, transaction.txnDate)}
                      </TableCell>
                    )}

                    {showColumn("type") && (
                      <TableCell sx={{ verticalAlign: "top" }}>
                        {i18n(
                          `objectType.${
                            getObjectFromTypeString(transaction.type).fullString
                          }.Sentence`
                        )}
                      </TableCell>
                    )}

                    {showColumn("refNumber") && (
                      <TableCell sx={{ verticalAlign: "top" }}>
                        <LinkText
                          onClick={() => {
                            openEditModal(
                              getObjectFromTypeString(transaction.type)
                                .fullString,
                              transaction.id
                            );
                          }}
                        >
                          {transaction.refNumber}
                        </LinkText>
                      </TableCell>
                    )}

                    {showColumn("location") && (
                      <TableCell
                        sx={{ verticalAlign: "top", whiteSpace: "pre-wrap" }}
                      >
                        {transaction.location}
                      </TableCell>
                    )}

                    {showColumn("lines") && (
                      <TableCell sx={{ verticalAlign: "top", width: "13em" }}>
                        <HistoryLineItems
                          transactionType={transaction.type}
                          lines={transaction.lines}
                        />
                      </TableCell>
                    )}

                    {showColumn("total") && (
                      <TableCell sx={{ verticalAlign: "top" }} align="right">
                        {formatMoney(transaction.total, INCLUDE_ADORNMENTS)}
                      </TableCell>
                    )}

                    {showColumn("customerPO") && (
                      <TableCell sx={{ verticalAlign: "top" }}>
                        {transaction.customerPO}
                      </TableCell>
                    )}
                  </TableRow>
                ))}
              </TableBody>
            </Table>
          </TableContainer>
        ) : (
          <FixedLoadingIndicator text={`${i18n("global.Loading")}...`} />
        )}
      </DialogContent>
    </>
  );
}
