// Actual domain logic should be in a domainLogic.js file, at the appropriate
// level in the hierarchy.
import { NEW_RECORD_SKELETON } from "appConfig";
import { IN_TRANSACTION } from "appConfig";

import { Loading } from "classes/Loading";

import { calculateCostAndMargin } from "services/sosInventoryService/salesTransaction/domainLogic";
import { getRecord, getRecordFrom } from "services/sosInventoryService/sosApi";
import { reconcileCustomFields } from "services/utility/customFields";
import { getRelatedReferenceObjects } from "services/utility/edit";
import { formatCardOnFile } from "services/utility/formatting";
import { calculateMarginPercent } from "services/utility/misc";

import globalState from "globalState/globalState";

import { SALES_TRANSACTIONS_WITH_LOCATION } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

export async function getTransaction(
  id,
  newFromId,
  newFromObjectType,
  updaters,
  customFieldDefs,
  objectType
) {
  const getEmptyRecord = NEW_RECORD_SKELETON[objectType];
  let record, lines, relatedRecords;

  const { settings } = globalState.getState().userCompanySettings;

  const { defaultLocation, numLinesToAdd, marginPricingEnabled } = settings;

  if (id || newFromObjectType) {
    if (id) {
      record = await getRecord(objectType, id);
    } else {
      // it's a "populate from" request
      record = await getRecordFrom(objectType, newFromObjectType, newFromId);
      // when using getRecordFrom for sales, calculate discount amount off of percent value
      record.discountType = "discountPercent";
      delete record.id;

      if (SALES_TRANSACTIONS_WITH_LOCATION.includes(objectType)) {
        record.location = defaultLocation;
      }
    }
    record.customFields = reconcileCustomFields(
      customFieldDefs,
      record.customFields
    );

    lines = record.lines;
    delete record.lines;

    lines = lines.map((line) => {
      const { itemDetails, cost, unitprice, quantity } = line;
      if (!marginPricingEnabled && !itemDetails.useMarkup) {
        return line;
      }
      if (cost === null) {
        // set cost & margin to Loading class if cost is null
        // TODO: update once https://app.clickup.com/t/2rxvm9p fixed
        line.cost = new Loading();
        line.margin = new Loading();
      } else {
        // if cost is defined, use the value to calculate margin
        line.margin = calculateMarginPercent(cost, unitprice, quantity);
      }
      return line;
    });
    updaters.lineHandler({ type: "set", lines });

    relatedRecords = await getRelatedReferenceObjects(objectType, record);
    if (relatedRecords.customer?.hasCardOnFile) {
      record.paymentInfo = formatCardOnFile(null, relatedRecords.customer);
      record.storeCustomerToken = true;
      record.hasCardOnFile = true;
    }

    updaters.setRelatedRecords(relatedRecords);
    updaters.setRecord(record);

    // recalculates cost/margin if necessary
    // TODO: update once https://app.clickup.com/t/2rxvm9p fixed
    lines = await calculateCostAndMargin(record.date, lines);

    updaters.lineHandler({ type: "set", lines });
  } else {
    // new record
    record = {
      ...getEmptyRecord(settings),
      customFields: reconcileCustomFields(customFieldDefs, []),
    };

    // for non-UTC dates, set the dateChanged flag true
    if (record.hasOwnProperty("dateChanged")) {
      record.dateChanged = true;
    }

    lines = Array(numLinesToAdd).fill(EMPTY_LINE_ITEM[objectType]);
    updaters.lineHandler({ type: "set", lines });
    // set location based on settings
    if (SALES_TRANSACTIONS_WITH_LOCATION.includes(objectType)) {
      const txnLocation = defaultLocation || null;
      record.location = txnLocation;
      if (txnLocation) {
        const location = await getRecord(
          "location",
          txnLocation.id,
          IN_TRANSACTION
        );
        updaters.setRelatedRecords({ location });
      }
    }
    updaters.setRecord(record);
  }
}
