import { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import { Link as MuiLink } from "@mui/material";
import {
  TextField,
  Button,
  Typography,
  Grid2,
  Paper,
  Box,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import { grey } from "@mui/material/colors";

import { ExternalLink } from "components/html/ExternalLink";
import { FadedHR } from "components/html/FadedHR";
import { ButtonProgress } from "components/utility/ButtonProgress";
import { FormError } from "components/utility/FormError";

import { i18n } from "services/i18nService";
import { login } from "services/utility/authentication";

import {
  EMAIL_NOT_VERIFIED_INTUIT_STRING,
  INTUIT_ACCOUNT_MANAGER_URL,
} from "appConstants";
import { v4 as uuidv4 } from "uuid";

const AUTH_REDIRECT_URL = `${process.env.REACT_APP_V9_URL}/login/auth`;

const sx = {
  paper: { p: 4, width: "36em" },
  background: {
    minHeight: "100vh",
    width: "100%",
    backgroundImage: "linear-gradient(#b0c8f6, #5b8dee)",
    justifyContent: "center",
    alignItems: "center",
  },
  hr: { color: grey[400], borderStyle: "solid" },
  logo: {
    width: "120px",
    height: "120px",
    backgroundColor: grey[200],
    borderRadius: "50%",
    "& img": { width: "75px" },
  },
  rememberMe: { borderRight: "dashed 1px", borderRightColor: grey[400] },
  linksPadding: {
    padding: "0 10px 0 10px",
    borderLeft: "dashed 1px",
    borderLeftColor: grey[400],
  },
  buttonAndLinkContainer: { verticalAlign: "middle" },
  buttonAndLink: { display: "inline-block" },
  password: { fontFamily: "sans-serif" },
};

export function Login() {
  const { state, pathname } = useLocation();
  const navigate = useNavigate();
  const rememberMe = Boolean(localStorage.getItem("rememberMe"));
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);

  const [inputs, setInputs] = useState({
    username: "",
    password: "",
    rememberMe,
  });

  useEffect(() => {
    if (state?.authError) {
      setError(state?.authError);
    }
  }, [state?.authError]);

  function clearError() {
    setError(null);
    if (state?.authError) {
      navigate({ pathname, replace: true });
    }
  }

  async function handleSubmit(event) {
    if (event) {
      event.preventDefault();
    }
    await login(inputs.username, inputs.password, setError, setIsLoading);
  }

  function handleInputChange(event) {
    const { id, value } = event.target;
    setInputs((inputs) => ({ ...inputs, [id]: value }));
  }

  function handleRememberMeChange(event) {
    const { id, checked } = event.target;
    if (checked) {
      localStorage.setItem("rememberMe", true);
    } else {
      localStorage.removeItem("rememberMe");
    }
    setInputs((inputs) => ({ ...inputs, [id]: checked }));
  }

  function handleQboLogin() {
    const qboState = uuidv4();
    localStorage.setItem("qboState", qboState);
    window.location.href = `https://appcenter.intuit.com/connect/oauth2?client_id=${process.env.REACT_APP_INTUIT_CLIENT_ID}&scope=openid%20email%20intuit_name&redirect_uri=${AUTH_REDIRECT_URL}&response_type=code&state=${qboState}`;
  }

  return (
    <Grid2 container spacing={0} sx={sx.background} data-testing="loginPage">
      <Paper sx={sx.paper} elevation={5}>
        <Box sx={{ display: "flex", alignItems: "center", mb: 2 }}>
          <Typography
            variant="h2"
            sx={{ color: "primaryText", fontWeight: "fontWeightRegular" }}
          >
            {i18n("login.Title")}
          </Typography>

          <Box
            sx={sx.logo}
            mx="auto"
            justifyContent="center"
            alignItems="center"
            display="flex"
          >
            <img alt="SOS Inventory Logo" src="/images/sos_logo.svg"></img>
          </Box>
        </Box>

        {error &&
          (error === EMAIL_NOT_VERIFIED_INTUIT_STRING ? (
            <IntuitEmailNotVerifiedError clear={clearError} />
          ) : (
            <FormError message={error} clear={clearError} />
          ))}

        <form onSubmit={handleSubmit} align="center">
          <TextField
            id="username"
            label={i18n("login.EmailField")}
            type="email"
            required
            onChange={handleInputChange}
            value={inputs.username}
            variant="outlined"
            autoFocus
            fullWidth
            size="small"
            inputProps={{
              readOnly: isLoading,
            }}
            data-testing="username"
          />
          <br />
          <TextField
            id="password"
            label={i18n("login.PasswordField")}
            type="password"
            required
            onChange={handleInputChange}
            value={inputs.password}
            variant="outlined"
            fullWidth
            margin="dense"
            inputProps={{
              readOnly: isLoading,
            }}
            InputProps={{ sx: sx.password }}
            data-testing="password"
          />
          <br />
          <Box
            mt={2}
            mb={2}
            align="center"
            sx={sx.buttonAndLinkContainer}
            display="flex"
            justifyContent="center"
          >
            <Box mr={2} pr={2} sx={sx.rememberMe}>
              <FormControlLabel
                control={
                  <Checkbox
                    id="rememberMe"
                    name="RememberMe"
                    onChange={handleRememberMeChange}
                    checked={inputs.rememberMe}
                    data-testing="rememberMe"
                  />
                }
                label={i18n("login.RememberMe")}
                labelPlacement="start"
              />
            </Box>
            <div style={{ position: "relative" }}>
              <Button
                type="submit"
                disabled={isLoading}
                variant="contained"
                sx={sx.buttonAndLink}
                data-testing="submit"
              >
                {i18n("login.SignIn")}
              </Button>
              {isLoading && <ButtonProgress />}
            </div>
          </Box>
        </form>
        <FadedHR color={grey[400]} />
        <Box my={1}>
          <Typography variant="body2" align="center">
            {i18n("global.or")}
          </Typography>
        </Box>
        <Box
          mx="auto"
          mb={2}
          justifyContent="center"
          alignItems="center"
          display="flex"
        >
          <div style={{ cursor: "pointer" }} onClick={handleQboLogin}>
            <img
              alt={i18n("login.QBOAltText")}
              src="/images/Sign_in_blue_btn_med_default.png"
              style={{ width: "auto", maxHeight: "36px" }}
            ></img>
          </div>
        </Box>
        <FadedHR color={grey[400]} />
        <Box my={2}>
          <Typography variant="body2" align="center">
            {i18n("login.Agreement")}{" "}
            <ExternalLink
              href="https://www.sosinventory.com/terms-of-service"
              target="_blank"
            >
              {i18n("login.Terms")}
            </ExternalLink>{" "}
            and{" "}
            <ExternalLink
              href="https://www.sosinventory.com/privacy-policy"
              target="_blank"
            >
              {i18n("login.Privacy")}
            </ExternalLink>
          </Typography>
        </Box>
        <Box>
          <Typography variant="body2" align="center">
            {i18n("login.Subtitle")}
          </Typography>
        </Box>
      </Paper>
    </Grid2>
  );
}

export function IntuitEmailNotVerifiedError(props) {
  const { clear } = props;
  return (
    <FormError
      message={
        <>
          <Typography component="span">
            {i18n("auth.IntuitEmailNotVerified.1")}
          </Typography>{" "}
          <MuiLink
            sx={{ color: "primaryLink" }}
            href={INTUIT_ACCOUNT_MANAGER_URL}
            underline="none"
          >
            {i18n("auth.IntuitEmailNotVerified.2")}
          </MuiLink>
        </>
      }
      clear={clear}
    />
  );
}
