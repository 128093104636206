import { Grid2, Typography } from "@mui/material";

const fixedStyles = {
  position: "fixed",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
};

export function FixedLoadingIndicator(props) {
  const { text, isFixed = true } = props;
  return (
    <div style={isFixed ? fixedStyles : {}}>
      <Grid2 container direction="column" display="flex" alignItems="center">
        {text && (
          <Typography color="primary" variant="h4">
            {text}
          </Typography>
        )}
      </Grid2>
    </div>
  );
}
