import { theme } from "SosTheme";

export function TotalsLine(props) {
  const { label, value, units, gridTemplateColumns, dataTesting } = props;
  return (
    <div
      style={{
        display: "grid",
        gridTemplateColumns,
        columnGap: theme.spacing(1),
        alignItems: "center",
        padding: "1rem 0",
      }}
    >
      <div></div>
      <div></div>
      <div style={{ textAlign: "right" }}>
        <span
          style={{
            fontWeight: theme.typography.fontWeightRegular,
            padding: "0 14px",
          }}
          data-testing={dataTesting}
        >
          {value.toString()}
          {units ? ` ${units}` : ""}
        </span>
      </div>
      <div
        style={{
          fontWeight: theme.typography.fontWeightMedium,
          textAlign: "left",
        }}
      >
        {label}
      </div>
    </div>
  );
}
