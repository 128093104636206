import Delete from "@mui/icons-material/Delete";

import { Decimal, Money } from "classes/DecimalClasses";

import { CopyDownLineItem } from "components/CopyDownLineItem";
import { InsertLineItem } from "components/InsertLineItem";
import { LineItemTextField } from "components/formFields/LineItemTextField";
import { LineTableCell } from "components/formFields/LineTableCell";
import { LineInventoryItem } from "components/formFields/line/LineInventoryItem";
import { LineQuantity } from "components/formFields/line/LineQuantity";

import { setPageDirty } from "services/utility/edit";

import { theme } from "SosTheme";
import { OBJECT_TYPES } from "appConstants";
import { LINE_ITEM_METADATA } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.PRICE_TIER.fullString;

export function LineItem(props) {
  const {
    line,
    items,
    itemsCount,
    setRecord,
    setDefaultItem,
    insertEmptyLine,
    onCopyDown,
    rowIndex,
    showCopyDown,
  } = props;

  function handleChange(fieldName, newValue) {
    setPageDirty();
    setRecord((prev) => ({
      ...prev,
      items: prev.items.map((line, i) =>
        rowIndex === i ? { ...line, [fieldName]: newValue } : line
      ),
    }));
  }

  function handleItemChange(item) {
    setPageDirty();
    setDefaultItem(item);
    setRecord((prev) => {
      const tableHasMatchingItem = Boolean(
        prev.items.find((l) => l.item?.id === item?.id)
      );

      const rowAbove = prev.items[rowIndex - 1];
      const rowBelow = prev.items[rowIndex + 1];
      const itemBelowDoesNotMatch = rowBelow?.item?.id !== item?.id;
      const itemAboveDoesNotMatch = rowAbove?.item?.id !== item?.id;

      const isUnordered =
        tableHasMatchingItem && itemBelowDoesNotMatch && itemAboveDoesNotMatch;

      const items = prev.items.map((line, i) =>
        rowIndex === i ? { ...line, item, isUnordered } : line
      );
      return { ...prev, items };
    });
  }

  function handleDelete() {
    setPageDirty();
    setRecord((prev) => ({
      ...prev,
      items: prev.items.filter((_, i) => i !== rowIndex),
    }));
  }

  if (!line) {
    return null;
  }

  const { isUnordered, quantity, price, isNewRow } = line;
  const backgroundColor = isUnordered ? "background.lineItems" : undefined;

  return (
    <>
      <LineTableCell sx={{ position: "relative", backgroundColor }}>
        <InsertLineItem onClick={insertEmptyLine} />
        {showCopyDown && <CopyDownLineItem onCopyDown={onCopyDown} />}
        <div
          onClick={handleDelete}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
            color: theme.palette.icons,
          }}
        >
          <Delete />
        </div>
      </LineTableCell>
      <LineTableCell sx={{ minWidth: "13rem", backgroundColor }}>
        <LineInventoryItem
          line={line}
          items={items}
          itemsCount={itemsCount}
          onValueChange={handleItemChange}
          disabled={!isNewRow}
        />
      </LineTableCell>
      <LineQuantity
        objectType={OBJECT_TYPE}
        value={quantity}
        onValueChange={(name, value) =>
          handleChange(name, new Decimal(value || 0))
        }
        backgroundColor={backgroundColor}
      />
      <LineTableCell sx={{ minWidth: "6rem", backgroundColor }}>
        <LineItemTextField
          money
          name="price"
          value={price}
          onValueChange={(name, value) =>
            handleChange(name, new Money(value || 0))
          }
          metadata={LINE_ITEM_METADATA.pricetier.price}
        />
      </LineTableCell>
    </>
  );
}
