import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

import { Button, Dialog, DialogActions, DialogContent } from "@mui/material";
import { Box, Typography } from "@mui/material";

import { Layout } from "Layout";

import { NEW_RECORD_SKELETON } from "appConfig";

import { Decimal, Money } from "classes/DecimalClasses";

import { ActionButton } from "components/ActionButton";
import { EditModalHeader } from "components/EditModalHeader";
import { FrmFieldGrid } from "components/FrmFieldGrid";
import { ObjectEditDialogContent } from "components/ObjectEditDialogContent";
import { Tooltip } from "components/Tooltip";
import { FrmButtonGroup } from "components/formFields/FrmButtonGroup";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmTextField } from "components/formFields/FrmTextField";
import { FixedLoadingIndicator } from "components/utility/FixedLoadingIndicator";
import { FormError } from "components/utility/FormError";
import { FormErrors } from "components/utility/FormErrors";
import { Loading } from "components/utility/Loading";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import {
  TYPE_OPTIONS,
  FLAT_DISCOUNT_OPTION,
  PERCENT_DISCOUNT_OPTION,
  ITEM_DISCOUNT_OPTION,
} from "services/sosInventoryService/priceTier/schema";
import { getRecord } from "services/sosInventoryService/sosApi";

import { useErrors } from "hooks/useErrors";
import { useItems } from "hooks/useItems";
import { useRecord } from "hooks/useRecord";

import { LineItems } from "views/PriceTiers/PriceTier/LineItems";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { ITEM_QUICKLIST_CASES } from "appConstants";

function dedupeItemTableItems(items) {
  return [
    ...new Map(
      items.filter(({ item }) => item).map(({ item }) => [item.id, item])
    ).values(),
  ];
}

function currentTypeHasValue(record) {
  if (record.type.id === ITEM_DISCOUNT_OPTION.id) {
    return (
      record.items.length && record.items.filter(({ item }) => item).length
    );
  } else if (record.type.id === FLAT_DISCOUNT_OPTION.id) {
    return record.amountDiscount.gt(Money.ZERO);
  } else if (record.type.id === PERCENT_DISCOUNT_OPTION.id) {
    return record.percentDiscount.gt(Decimal.ZERO);
  }
}

export function PriceTier(props) {
  const { id, onClose, objectType } = props;

  const dispatch = useDispatch();
  const [record, setRecord, handleInputFieldChange] = useRecord();
  const { errors, setErrors, isInError } = useErrors();
  const [itemFilter, setItemFilter] = useState(null);
  const [pendingTypeUpdate, setPendingTypeUpdate] = useState(null);

  // RELATED RECORD LISTS
  const { items, itemsCount } = useItems(ITEM_QUICKLIST_CASES.ALL);

  const loadingState = useSelector((state) => state.loading.editModal);

  const numLinesToAdd = useSelector(
    (state) => state.userCompanySettings.settings.numLinesToAdd
  );

  useEffect(() => {
    async function _getRecord() {
      if (id) {
        dispatch(editModalLoadingIndicatorOn());
        const response = await getRecord(objectType, id);
        setRecord(response);
        dispatch(editModalLoadingIndicatorOff());
      } else {
        const emptyRecord = NEW_RECORD_SKELETON[objectType]({ numLinesToAdd });
        setRecord(emptyRecord);
      }
    }
    _getRecord();
  }, [id, dispatch, setRecord, objectType, numLinesToAdd]);

  function handleTypeSwitch(name, value) {
    const typeHasValue = currentTypeHasValue(record);
    if (typeHasValue) {
      setPendingTypeUpdate(value);
      return;
    }
    handleInputFieldChange(name, value);
  }

  function handleDialogConfirm() {
    handleInputFieldChange("type", pendingTypeUpdate);
    setPendingTypeUpdate(null);
  }

  function sortItemTable() {
    setRecord((prevRecord) => {
      const items = prevRecord.items
        // remove isUnordered since we're sorting the table
        .map(({ quantity, item, price, isNewRow }) => ({
          quantity,
          item,
          price,
          isNewRow,
        }))
        // sort by name, then by quantity
        .sort((a, b) => {
          const nameComparison = a.item?.name?.localeCompare(b.item?.name);
          return nameComparison !== 0
            ? nameComparison
            : a.quantity - b.quantity;
        });

      return { ...prevRecord, items };
    });
  }

  // if new transaction with record not defined
  // show null till record set in useObject
  if (!id && !record) {
    return null;
  }

  return (
    <>
      <EditModalHeader
        record={record}
        setRecord={setRecord}
        objectType={objectType}
        setErrors={setErrors}
        text={record?.name || ""}
        handleClose={onClose}
        disableSave={record?.isEditLocked}
      />
      {loadingState && <Loading />}
      <ObjectEditDialogContent>
        <Layout
          pageTitle={
            id
              ? `${i18n(`objectType.${objectType}.Sentence`)} ${
                  record?.number || ""
                }`
              : i18n(`objectType.${objectType}.New`)
          }
        >
          {record?.isEditLocked ? (
            <FormError message={i18n("error.PriceTierEditLock")} />
          ) : (
            <Box flexGrow="1" position="relative" m={2} mb={0}>
              <FormErrors errors={errors} setErrors={setErrors} />
              {record ? (
                <Box>
                  <FrmFieldGrid gridAutoFlow="dense">
                    <div style={{ gridColumn: "1 / 2", maxWidth: "24rem" }}>
                      <FrmTextField
                        name="name"
                        label={i18n("frmLabel.Name")}
                        onValueBlur={handleInputFieldChange}
                        value={record.name}
                        error={isInError("name")}
                      />
                    </div>
                  </FrmFieldGrid>
                  <VSpace space={1} />

                  <Box sx={{ display: "flex", alignItems: "center", gap: 2 }}>
                    <FrmButtonGroup
                      label="Type"
                      name="type"
                      options={TYPE_OPTIONS}
                      value={record.type}
                      onValueChange={handleTypeSwitch}
                    />
                    {record.type?.id === ITEM_DISCOUNT_OPTION.id && (
                      <Typography sx={{ pt: "8px" }}>
                        {i18n("global.QuantitiesAreInBaseUOM")}
                      </Typography>
                    )}
                  </Box>
                  <VSpace space={2} />
                  {record.type?.id === FLAT_DISCOUNT_OPTION.id && (
                    <FrmTextField
                      name="amountDiscount"
                      money
                      label={i18n("frmLabel.FlatDiscount")}
                      sx={{ maxWidth: "8rem" }}
                      value={record.amountDiscount}
                      onValueBlur={(name, value) =>
                        handleInputFieldChange(name, new Money(value || 0))
                      }
                      error={isInError("amountDiscount")}
                    />
                  )}
                  {record.type?.id === PERCENT_DISCOUNT_OPTION.id && (
                    <FrmTextField
                      number
                      name="percentDiscount"
                      label={i18n("frmLabel.DiscountPercent")}
                      sx={{ maxWidth: "8rem" }}
                      value={record.percentDiscount}
                      onValueBlur={(name, value) =>
                        handleInputFieldChange(name, new Decimal(value || 0))
                      }
                      error={isInError("percentDiscount")}
                    />
                  )}

                  {record.type?.id === ITEM_DISCOUNT_OPTION.id && (
                    <>
                      <Box
                        sx={{ display: "flex", alignItems: "center", gap: 2 }}
                      >
                        <Box sx={{ width: "30rem" }}>
                          <FrmSelectFromObjects
                            name="item"
                            label={i18n("frmLabel.FilterByItem")}
                            value={itemFilter}
                            options={dedupeItemTableItems(record.items)}
                            onValueChange={(_, item) => setItemFilter(item)}
                          />
                        </Box>
                        <Tooltip
                          title={i18n("tooltip.SortTableByNameThenQuantity")}
                        >
                          <div>
                            <ActionButton onClick={sortItemTable}>
                              {i18n("global.SortTable")}
                            </ActionButton>
                          </div>
                        </Tooltip>
                      </Box>
                      <LineItems
                        lines={record.items}
                        setRecord={setRecord}
                        items={items}
                        itemsCount={itemsCount}
                        itemFilter={itemFilter}
                      />
                    </>
                  )}
                </Box>
              ) : (
                !isInError("record") && (
                  <FixedLoadingIndicator
                    text={`${i18n("global.Loading")}...`}
                  />
                )
              )}
            </Box>
          )}
          <VSpace space={4} />
        </Layout>
      </ObjectEditDialogContent>
      <Dialog
        open={Boolean(pendingTypeUpdate)}
        onClose={() => setPendingTypeUpdate(null)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent data-testing="filters">
          <VSpace space={2} />
          <Typography sx={{ paddingBottom: "1rem" }}>
            {i18n("warning.SwitchPriceTierType", {
              type: i18n(`warning.PriceTierType.${record?.type?.id}`),
            })}
          </Typography>
        </DialogContent>
        <DialogActions
          sx={{ display: "flex", justifyContent: "space-between" }}
        >
          <div>
            <Button
              onClick={() => setPendingTypeUpdate(null)}
              color="secondary"
              data-testing="clear"
            >
              {i18n("button.Cancel")}
            </Button>
          </div>
          <Button
            onClick={handleDialogConfirm}
            color="secondary"
            data-testing="ok"
          >
            {i18n("button.Confirm")}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}
