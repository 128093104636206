import { Typography, Box } from "@mui/material";

import { Loading } from "classes/Loading";

import { LineItemNumericCalculatorField } from "components/formFields/LineItemNumericCalculatorField";
import { LineItemText } from "components/formFields/LineItemText";
import { LineSkeleton } from "components/formFields/LineSkeleton";
import { LineTableCell } from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";

import { DEFAULT_DECIMALS_UNROUNDED } from "appConstants";
import { LINE_ITEM_METADATA } from "editConfig";

export function LineNewQuantityWithLot(props) {
  const {
    value,
    lotTracked,
    lot,
    lots,
    quantityDiff,
    onValueChange,
    onValueWithLotChange,
    objectType,
    error,
    disabled = false,
  } = props;

  const isLoading = value instanceof Loading;
  const isLotOnhandLoading = lotTracked && !lot?.onhand && !Array.isArray(lots);

  function getLotNewQuantity() {
    if (lot.onhand) {
      return lot.onhand.plus(quantityDiff);
    }
    return lots.find(({ id }) => id === lot.id).onhand.plus(quantityDiff);
  }

  return (
    <LineTableCell sx={{ minWidth: lotTracked && lot ? "8rem" : "5.5rem" }}>
      {isLoading ? (
        <LineSkeleton />
      ) : (
        <>
          {lotTracked ? (
            <>
              <LineItemText
                decimal
                decimalPlaces={DEFAULT_DECIMALS_UNROUNDED}
                value={value}
                dataTesting="lineOnHand"
                sx={{ color: "staticTableCellText" }}
              />
              {lot && (
                <Box sx={{ display: "flex", alignItems: "center", pl: 1 }}>
                  <Typography sx={{ fontSize: "0.8125rem" }}>
                    {i18n("columns.colon.Lot")}
                  </Typography>
                  {isLotOnhandLoading ? (
                    <LineSkeleton sx={{ flexGrow: 1 }} />
                  ) : (
                    <LineItemNumericCalculatorField
                      name="newLotQuantity"
                      value={getLotNewQuantity()}
                      disabled={disabled}
                      onValueChange={onValueWithLotChange}
                      metadata={LINE_ITEM_METADATA[objectType].newQuantity}
                      error={error}
                    />
                  )}
                </Box>
              )}
            </>
          ) : (
            <LineItemNumericCalculatorField
              name="newQuantity"
              value={value}
              disabled={disabled}
              onValueChange={onValueChange}
              metadata={LINE_ITEM_METADATA[objectType].newQuantity}
              error={error}
              dataTesting="lineNewQuantity"
            />
          )}
        </>
      )}
    </LineTableCell>
  );
}
