import { Decimal, Money } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import { formatNameLinkField } from "services/utility/formatting";
import {
  inventoryItemTypeEditStrFunction,
  decimalTypeEditStrFunction,
} from "services/utility/list";
import { TYPES } from "services/utility/schema";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    fixed: true,
    defaultSort: true,
    linkField: true,
    sortable: true,
  },
];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

export const FLAT_DISCOUNT_OPTION = {
  name: "Flat discount",
  id: "amountDiscount",
};
export const PERCENT_DISCOUNT_OPTION = {
  name: "Percent discount",
  id: "percentDiscount",
};
export const ITEM_DISCOUNT_OPTION = { name: "Item quantity", id: "items" };

export const TYPE_OPTIONS = [
  FLAT_DISCOUNT_OPTION,
  PERCENT_DISCOUNT_OPTION,
  ITEM_DISCOUNT_OPTION,
];

export const EMPTY_LINE = {
  item: null,
  quantity: Decimal.ZERO,
  price: Money.ZERO,
  isNewRow: true,
};

export function getEmptyRecord({ numLinesToAdd }) {
  const items = new Array(numLinesToAdd).fill(EMPTY_LINE);
  return {
    name: null,
    percentDiscount: Decimal.ZERO,
    type: ITEM_DISCOUNT_OPTION,
    amountDiscount: Money.ZERO,
    item: null,
    items,
  };
}

export const LINE_ITEM_METADATA = {
  lineNumber: {},
  item: {
    type: TYPES.reference,
    editStr: inventoryItemTypeEditStrFunction,
    initialValue: { id: null, name: null, description: null },
  },
  quantity: {
    type: TYPES.decimal,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Decimal(0),
  },
  price: {
    type: TYPES.money,
    currency: true,
    placesRight: 5,
    editStr: decimalTypeEditStrFunction,
    initialValue: new Money(0),
  },
};

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["amountDiscount", "percentDiscount"],
  MONEY_FIELDS: [],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const ITEM_TABLE_FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: ["quantity"],
  MONEY_FIELDS: ["price"],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};
