import ClearIcon from "@mui/icons-material/Clear";
import { Box, Typography, Grid2, IconButton } from "@mui/material";

export function FormError(props) {
  const { message, clear } = props;

  return (
    <Box
      p={2}
      my={2}
      bgcolor="tooltipBackground"
      borderLeft={4}
      borderColor="error.main"
    >
      <Grid2 spacing={1} container alignItems="center">
        <Grid2 size={11}>
          <Typography>{message}</Typography>
        </Grid2>
        <Grid2 sx={{ textAlign: "right" }} size={1}>
          {clear && (
            <IconButton onClick={clear} size="small">
              <ClearIcon color="error" />
            </IconButton>
          )}
        </Grid2>
      </Grid2>
    </Box>
  );
}
