import { getFsAuthKey } from "services/sosInventoryService/sosApi";

import {
  FULLSTEAM_PROD_SCRIPT_URL,
  FULLSTEAM_TEST_SCRIPT_URL,
  MAIN_SOS_TEST_ACCOUNT_ID,
  STRIPE_SCRIPT_URL,
} from "appConstants";

export function addJqueryScript() {
  // add jquery to body (fullsteam hosted forms needs this)
  if (!window.$) {
    const script = document.createElement("script");
    script.src = "https://code.jquery.com/jquery-3.6.4.min.js";
    document.body.appendChild(script);
  }
}

export function addFullsteamFormsScript(companyId) {
  // add fullsteam hosted forms to body
  if (!window.launchFullsteamHostedPayments) {
    const script = document.createElement("script");
    script.src =
      companyId === MAIN_SOS_TEST_ACCOUNT_ID
        ? FULLSTEAM_TEST_SCRIPT_URL
        : FULLSTEAM_PROD_SCRIPT_URL;
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);
  }
}

export function addStripeScript() {
  // add stripe hosted forms to body
  if (!window.Stripe) {
    const script = document.createElement("script");
    script.src = STRIPE_SCRIPT_URL;
    script.type = "text/javascript";
    script.async = true;
    document.body.appendChild(script);
  }
}

export async function launchHostedPayments(
  type,
  postalCode,
  contact,
  fullsteamComplete
) {
  const { authKey } = await getFsAuthKey();
  if (authKey) {
    const isAch = type === "ach";
    const params = {
      authenticationKey: authKey,
      operationType: "Token",
      zip: postalCode || "",
      ignoreFormSubmit: true,
      showNameOnAccount: true,
      showZip: true,
      nameOnAccount: contact || "",
      formId: "main",
      submitText: "Verify",
      paymentSourceType: isAch ? "ACH" : "Card",
      cardEntryContext: isAch ? "ACH" : "WebMerchantEnteredPhoneOrder",
      completionCallback: fullsteamComplete,
    };
    if (type === "ach") {
      params.achAuthorizationType = 2;
    }
    window.launchFullsteamHostedPayments(params);
  }
}
