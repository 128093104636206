import { FilterSelectField } from "components/formFields/FilterSelectField";

import { i18n } from "services/i18nService";

import { ALL_FILTER_OPTION } from "appConstants";
import { ITEM_TYPE_OPTIONS } from "appConstants";

const options = [ALL_FILTER_OPTION, ...ITEM_TYPE_OPTIONS];

export function EditItemTypeFilter(props) {
  const { filters, setFilters } = props;
  const { filterSettings } = filters;
  const { Type } = filterSettings;

  function handleChange(_, value) {
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: { ...prevParams.filterSettings, Type: value?.id || "" },
      startingRecord: 1,
    }));
  }

  return (
    <FilterSelectField
      options={options}
      onValueChange={handleChange}
      value={Type ? { id: Type } : ALL_FILTER_OPTION}
      label={i18n("filter.Type")}
      dataTesting="typeFilter"
      disableClearable
    />
  );
}
