import { useState } from "react";

import { Delete } from "@mui/icons-material";
import { TableRow, IconButton } from "@mui/material";

import { TRANSACTION_MAX_RESULTS, DEBOUNCE_WAIT } from "appConfig";

import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { LineTableCell } from "components/formFields/LineTableCell";
import {
  outlinedStyles,
  textFieldStyle,
} from "components/formFields/lineItemFieldStyle";

import { ATTACHABLE_OBJECT_TYPES } from "services/sosInventoryService/document/schema";
import { getListPageRecords } from "services/sosInventoryService/sosApi";
import { getDocumentSelectionName } from "services/utility/documents";
import { isLineFieldInError } from "services/utility/errors";

import { TransactionSelect } from "views/Documents/Document/TransactionSelect";

import { theme } from "SosTheme";
import {
  getObjectFromTypeString,
  getObjectFromFullString,
  NO_COLUMNS,
} from "appConstants";

export function LinkRow(props) {
  const {
    row,
    rowIndex,
    handleRowTypeChange,
    handleRowSelectionChange,
    deleteRow,
    objectLists,
    lineErrors,
  } = props;

  const fullString = row.type
    ? getObjectFromTypeString(row.type).fullString
    : null;
  const { data: list, totalCount } = objectLists[fullString] || {};

  const [queryList, setQueryList] = useState(null);

  const [timeoutId, setTimeoutId] = useState();

  function isInError(field) {
    return isLineFieldInError(field, rowIndex, lineErrors);
  }

  function handleInputChange(query, reason) {
    // checking for reset avoids running this API call on initial component
    // load
    if (reason === "reset") {
      return;
    }

    clearTimeout(timeoutId);
    setTimeoutId(
      setTimeout(async () => {
        const response = await getListPageRecords(fullString, {
          inTransaction: true,
          columns: [NO_COLUMNS],
          maxResults: TRANSACTION_MAX_RESULTS,
          query,
        });

        if (response?.data) {
          const data = response.data.map((record) => {
            const { typeString } = getObjectFromFullString(fullString);
            const name = getDocumentSelectionName(record, typeString);
            return { id: record.id, name };
          });
          setQueryList(data);
        }
      }, DEBOUNCE_WAIT)
    );
  }

  const fetchItemsOnChange = list && list.length < totalCount;

  // does items contain the item referenced? if not, add it (this can happen if
  // an inventory item was looked up by name, barcode, or SKU, which ignores the
  // "show on purchasing forms" flag)
  let listPlus = list ? [...list] : null;
  if (row?.id && list) {
    const foundItem = list?.find(({ id }) => id === row.id);
    if (!foundItem) {
      listPlus.unshift({ id: row.id, name: row.name });
    }
  }

  return (
    <TableRow sx={{ position: "inherit" }}>
      <LineTableCell
        sx={{
          width: "3rem",
          textAlign: "center",
          position: "relative",
          borderLeft: `1px solid ${theme.palette.lineSeparatorMinor}`,
        }}
      >
        <IconButton size="small" onClick={deleteRow}>
          <Delete />
        </IconButton>
      </LineTableCell>
      <LineTableCell sx={{ ...textFieldStyle, ...outlinedStyles }}>
        <FrmSelectFromObjects
          name="type"
          options={ATTACHABLE_OBJECT_TYPES}
          onValueChange={(name, value) =>
            handleRowTypeChange(name, value, rowIndex)
          }
          value={row.type ? { id: row.type } : null}
          disableClearable
        />
      </LineTableCell>
      <LineTableCell>
        <TransactionSelect
          value={row?.id ? { id: row.id, name: row.name } : null}
          onValueChange={(value, userInput) =>
            handleRowSelectionChange(value, userInput, rowIndex)
          }
          onInputChange={(_, value, reason) =>
            fetchItemsOnChange && handleInputChange(value, reason)
          }
          onBlur={() => fetchItemsOnChange && setQueryList(listPlus)}
          options={queryList || listPlus}
          error={isInError("selection")}
          fetchItemsOnChange={fetchItemsOnChange}
        />
      </LineTableCell>
    </TableRow>
  );
}
