import { i18n } from "services/i18nService";
import { formatBooleanYesOrBlank } from "services/utility/formatting";

import { OBJECT_TYPES } from "appConstants";

export const columnInfo = [
  {
    name: "name",
    fixed: true,
    heading: i18n("columns.Name"),
    defaultSort: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "description",
    heading: i18n("columns.Description"),
    fixedRightOfAction: true,
    fixed: true,
    sortable: true,
  },
  {
    name: "enabled",
    heading: i18n("columns.Enabled"),
    formatFunc: formatBooleanYesOrBlank,
    fixedRightOfAction: true,
    fixed: true,
  },
];

export const LINE_ACTIONS = ["delete"];
export const BATCH_ACTIONS = ["delete"];

export const EMPTY_LINE = {
  action: null,
  actionEmail: null,
  createFormType: null,
  assignUserId: 0,
  emailTemplateId: 0,
  notifyUserId: 0,
  orderStageId: 0,
  priorityId: 0,
};

export function getEmptyRecord({ numLinesToAdd }) {
  const lines = new Array(numLinesToAdd).fill(EMPTY_LINE);
  return {
    lines,
    channel: null,
    customer: null,
    description: null,
    enabled: true,
    location: null,
    name: null,
    priority: null,
    triggerAction: null,
    triggerForm: OBJECT_TYPES.ESTIMATE.typeString,
    vendor: null,
  };
}

export const ASSIGN_TO_USER_OPTION = {
  name: i18n("aopRule.action.AssignToAUser"),
  id: "Assign",
};

export const CREATE_TRANSACTION_OPTION = {
  name: i18n("aopRule.action.CreateANewTransaction"),
  id: "Create",
};
export const NOTIFY_USER_OPTION = {
  name: i18n("aopRule.action.NotifyAUser"),
  id: "Notify",
};
export const SET_PRIORITY_OPTION = {
  name: i18n("aopRule.action.SetThePriority"),
  id: "Priority",
};

export const EMAIL_OPTION = {
  name: i18n("aopRule.action.SendAnEmail"),
  id: "Email",
};

export const MOVE_STAGE_OPTION = {
  name: i18n("aopRule.action.MoveToStage"),
  id: "Stage",
};

export const ACTION_OPTIONS = [
  ASSIGN_TO_USER_OPTION,
  CREATE_TRANSACTION_OPTION,
  NOTIFY_USER_OPTION,
  EMAIL_OPTION,
  SET_PRIORITY_OPTION,
  MOVE_STAGE_OPTION,
];

const TRIGGER_ACTION_GENERIC_OPTIONS = [
  { id: "created", name: "Created" },
  { id: "edited", name: "Edited" },
];

const INVOICED_TRIGGER_ACTION = { id: "invoiced", name: "Invoiced" };
const SHIPPED_TRIGGER_ACTION = { id: "shipped", name: "Shipped" };
const PAID_TRIGGER_ACTION = { id: "paid", name: "Paid" };

export const TRIGGER_ACTION_OPTIONS = {
  [OBJECT_TYPES.ESTIMATE.typeString]: TRIGGER_ACTION_GENERIC_OPTIONS,
  [OBJECT_TYPES.INVOICE.typeString]: [
    ...TRIGGER_ACTION_GENERIC_OPTIONS,
    PAID_TRIGGER_ACTION,
  ],
  [OBJECT_TYPES.PICK_TICKET.typeString]: TRIGGER_ACTION_GENERIC_OPTIONS,
  [OBJECT_TYPES.PURCHASE_ORDER.typeString]: TRIGGER_ACTION_GENERIC_OPTIONS,
  [OBJECT_TYPES.SALES_ORDER.typeString]: [
    ...TRIGGER_ACTION_GENERIC_OPTIONS,
    INVOICED_TRIGGER_ACTION,
    SHIPPED_TRIGGER_ACTION,
  ],
  [OBJECT_TYPES.SALES_RECEIPT.typeString]: [
    ...TRIGGER_ACTION_GENERIC_OPTIONS,
    SHIPPED_TRIGGER_ACTION,
  ],
  [OBJECT_TYPES.SHIPMENT.typeString]: TRIGGER_ACTION_GENERIC_OPTIONS,
};

const ESTIMATE_OPTION = {
  id: OBJECT_TYPES.ESTIMATE.typeString,
  name: i18n(`objectType.${OBJECT_TYPES.ESTIMATE.fullString}.Sentence`),
};
const INVOICE_OPTION = {
  id: OBJECT_TYPES.INVOICE.typeString,
  name: i18n(`objectType.${OBJECT_TYPES.INVOICE.fullString}.Sentence`),
};

const PICK_TICKET_OPTION = {
  id: OBJECT_TYPES.PICK_TICKET.typeString,
  name: i18n(`objectType.${OBJECT_TYPES.PICK_TICKET.fullString}.Sentence`),
};

const PURCHASE_ORDER_OPTION = {
  id: OBJECT_TYPES.PURCHASE_ORDER.typeString,
  name: i18n(`objectType.${OBJECT_TYPES.PURCHASE_ORDER.fullString}.Sentence`),
};

const SALES_ORDER_OPTION = {
  id: OBJECT_TYPES.SALES_ORDER.typeString,
  name: i18n(`objectType.${OBJECT_TYPES.SALES_ORDER.fullString}.Sentence`),
};

const SALES_RECEIPT_OPTION = {
  id: OBJECT_TYPES.SALES_RECEIPT.typeString,
  name: i18n(`objectType.${OBJECT_TYPES.SALES_RECEIPT.fullString}.Sentence`),
};

const SHIPMENT_OPTION = {
  id: OBJECT_TYPES.SHIPMENT.typeString,
  name: i18n(`objectType.${OBJECT_TYPES.SHIPMENT.fullString}.Sentence`),
};

const ITEM_RECEIPT_OPTION = {
  id: OBJECT_TYPES.ITEM_RECEIPT.typeString,
  name: i18n(`objectType.${OBJECT_TYPES.ITEM_RECEIPT.fullString}.Sentence`),
};

export const TRIGGER_FORM_OPTIONS = [
  ESTIMATE_OPTION,
  INVOICE_OPTION,
  PICK_TICKET_OPTION,
  PURCHASE_ORDER_OPTION,
  SALES_ORDER_OPTION,
  SALES_RECEIPT_OPTION,
  SHIPMENT_OPTION,
];

export const CREATE_TARGET_OPTIONS = {
  [OBJECT_TYPES.ESTIMATE.fullString]: [
    INVOICE_OPTION,
    SALES_ORDER_OPTION,
    SALES_RECEIPT_OPTION,
  ],
  [OBJECT_TYPES.INVOICE.fullString]: [
    PICK_TICKET_OPTION,
    SALES_ORDER_OPTION,
    SHIPMENT_OPTION,
  ],
  [OBJECT_TYPES.PICK_TICKET.fullString]: [SHIPMENT_OPTION],
  [OBJECT_TYPES.PURCHASE_ORDER.fullString]: [ITEM_RECEIPT_OPTION],
  [OBJECT_TYPES.SALES_ORDER.fullString]: [
    INVOICE_OPTION,
    PICK_TICKET_OPTION,
    SHIPMENT_OPTION,
  ],
  [OBJECT_TYPES.SALES_RECEIPT.fullString]: [
    PICK_TICKET_OPTION,
    SALES_ORDER_OPTION,
    SHIPMENT_OPTION,
  ],
  [OBJECT_TYPES.SHIPMENT.fullString]: [
    INVOICE_OPTION,
    PICK_TICKET_OPTION,
    SALES_ORDER_OPTION,
    SALES_RECEIPT_OPTION,
  ],
};
