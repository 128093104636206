import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

export function cancellationIsValid(data) {
  const errors = initialErrorState();

  if (!data.reason) {
    errors.messages.push(i18n("isValid.CancellationReason"));
    errors.entity.push("reason");
  }

  return errors;
}
