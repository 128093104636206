import { i18n } from "services/i18nService";
import { formatDate } from "services/utility/dates";
import {
  formatNameLinkField,
  formatBooleanYesOrBlank,
  formatAdmin,
} from "services/utility/formatting";

import { USER_EDIT_PRIVILEGES } from "appConstants";

export const columnInfo = [
  {
    name: "name",
    heading: i18n("columns.Name"),
    formatFunc: formatNameLinkField,
    fixed: true,
    linkField: true,
    sortable: true,
  },
  {
    name: "email",
    heading: i18n("columns.Email"),
    minWidth: "12em",
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "lastLogin",
    heading: i18n("columns.LastLogin"),
    formatFunc: formatDate,
    minWidth: "12em",
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "active",
    heading: i18n("columns.Active"),
    formatFunc: formatBooleanYesOrBlank,
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
  {
    name: "admin",
    heading: i18n("columns.Admin"),
    formatFunc: formatAdmin,
    fixed: true,
    fixedRightOfAction: true,
    sortable: true,
  },
  {
    name: "locked",
    heading: i18n("columns.Locked"),
    formatFunc: formatBooleanYesOrBlank,
    sortable: true,
    fixed: true,
    fixedRightOfAction: true,
  },
];

export const LINE_ACTIONS = ["activate", "unlock"];
export const BATCH_ACTIONS = ["activate"];

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [],
  DATE_FIELDS: ["lastLogin"],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export function getEmptyRecord() {
  const privileges = {
    [USER_EDIT_PRIVILEGES.activityLog]: false,
    [USER_EDIT_PRIVILEGES.inventorySettings]: true,
    [USER_EDIT_PRIVILEGES.salesSettings]: true,
    [USER_EDIT_PRIVILEGES.purchasingSettings]: true,
    [USER_EDIT_PRIVILEGES.qboAccounts]: false,
    [USER_EDIT_PRIVILEGES.qboBills]: false,
    [USER_EDIT_PRIVILEGES.qboBillPayments]: false,
    [USER_EDIT_PRIVILEGES.qboCashPurchases]: false,
    [USER_EDIT_PRIVILEGES.qboChecks]: false,
    [USER_EDIT_PRIVILEGES.qboCharges]: false,
    [USER_EDIT_PRIVILEGES.qboEmployees]: false,
    [USER_EDIT_PRIVILEGES.qboJournalEntries]: false,
    [USER_EDIT_PRIVILEGES.qboPaymentsReceived]: false,
    [USER_EDIT_PRIVILEGES.qboSalesReceipts]: false,
    [USER_EDIT_PRIVILEGES.sync]: true,
    [USER_EDIT_PRIVILEGES.viewItems]: true,
    [USER_EDIT_PRIVILEGES.editItems]: true,
    [USER_EDIT_PRIVILEGES.viewCosts]: true,
    [USER_EDIT_PRIVILEGES.viewInventory]: true,
    [USER_EDIT_PRIVILEGES.adjustInventory]: true,
    [USER_EDIT_PRIVILEGES.transferInventory]: true,
    [USER_EDIT_PRIVILEGES.changePricingOnSales]: true,
    [USER_EDIT_PRIVILEGES.viewCustomers]: true,
    [USER_EDIT_PRIVILEGES.editCustomers]: true,
    [USER_EDIT_PRIVILEGES.viewEstimates]: true,
    [USER_EDIT_PRIVILEGES.editEstimates]: true,
    [USER_EDIT_PRIVILEGES.viewSalesOrders]: true,
    [USER_EDIT_PRIVILEGES.editSalesOrders]: true,
    [USER_EDIT_PRIVILEGES.viewInvoices]: true,
    [USER_EDIT_PRIVILEGES.editInvoices]: true,
    [USER_EDIT_PRIVILEGES.viewShipments]: true,
    [USER_EDIT_PRIVILEGES.editShipments]: true,
    [USER_EDIT_PRIVILEGES.editSalesReps]: true,
    [USER_EDIT_PRIVILEGES.editShippingMethods]: true,
    [USER_EDIT_PRIVILEGES.processPayments]: true,
    [USER_EDIT_PRIVILEGES.viewVendors]: true,
    [USER_EDIT_PRIVILEGES.editVendors]: true,
    [USER_EDIT_PRIVILEGES.viewPurchaseOrders]: true,
    [USER_EDIT_PRIVILEGES.editPurchaseOrders]: true,
    [USER_EDIT_PRIVILEGES.approvePurchaseOrders]: false,
    [USER_EDIT_PRIVILEGES.receiveInventory]: true,
    [USER_EDIT_PRIVILEGES.viewJobs]: true,
    [USER_EDIT_PRIVILEGES.editJobs]: true,
    [USER_EDIT_PRIVILEGES.viewWorkers]: true,
    [USER_EDIT_PRIVILEGES.editWorkers]: true,
    [USER_EDIT_PRIVILEGES.buildAssemblies]: true,
    [USER_EDIT_PRIVILEGES.viewRentals]: true,
    [USER_EDIT_PRIVILEGES.editRentals]: true,
  };
  return {
    name: null,
    email: null,
    confirmEmail: null,
    admin: false,
    privileges,
  };
}

export const PRIVILEGE_OPTIONS = [
  {
    groupLabel: i18n("privilege.Admin"),
    groupPrivileges: [
      USER_EDIT_PRIVILEGES.activityLog,
      USER_EDIT_PRIVILEGES.inventorySettings,
      USER_EDIT_PRIVILEGES.salesSettings,
      USER_EDIT_PRIVILEGES.purchasingSettings,
    ],
  },
  {
    groupLabel: i18n("privilege.QuickBooks"),
    groupPrivileges: [
      USER_EDIT_PRIVILEGES.qboAccounts,
      USER_EDIT_PRIVILEGES.qboBills,
      USER_EDIT_PRIVILEGES.qboBillPayments,
      USER_EDIT_PRIVILEGES.qboCashPurchases,
      USER_EDIT_PRIVILEGES.qboChecks,
      USER_EDIT_PRIVILEGES.qboCharges,
      USER_EDIT_PRIVILEGES.qboEmployees,
      USER_EDIT_PRIVILEGES.qboJournalEntries,
      USER_EDIT_PRIVILEGES.qboPaymentsReceived,
      USER_EDIT_PRIVILEGES.qboSalesReceipts,
      USER_EDIT_PRIVILEGES.sync,
    ],
  },
  {
    groupLabel: i18n("privilege.Inventory"),
    groupPrivileges: [
      {
        label: i18n("objectType.item.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.viewItems,
            label: i18n("privilege.View"),
          },
          {
            privilege: USER_EDIT_PRIVILEGES.editItems,
            label: i18n("privilege.AddEditDelete"),
          },
          { privilege: USER_EDIT_PRIVILEGES.viewCosts, label: "View costs" },
        ],
      },
      {
        label: "Inventory levels",
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.viewInventory,
            label: i18n("privilege.View"),
          },
        ],
      },
      {
        label: i18n("objectType.adjustment.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.adjustInventory,
            label: i18n("privilege.ViewAddEditDelete"),
          },
        ],
      },
      {
        label: i18n("objectType.transfer.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.transferInventory,
            label: i18n("privilege.ViewAddEditDelete"),
          },
        ],
      },
    ],
  },
  {
    groupLabel: i18n("privilege.Sales"),
    groupPrivileges: [
      {
        label: "General",
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.changePricingOnSales,
            label: i18n("privilege.ChangeSalesPricing"),
          },
        ],
      },
      {
        label: i18n("objectType.customer.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.viewCustomers,
            label: i18n("privilege.View"),
          },
          {
            privilege: USER_EDIT_PRIVILEGES.editCustomers,
            label: i18n("privilege.AddEditDelete"),
          },
        ],
      },
      {
        label: i18n("objectType.estimate.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.viewEstimates,
            label: i18n("privilege.View"),
          },
          {
            privilege: USER_EDIT_PRIVILEGES.editEstimates,
            label: i18n("privilege.AddEditDelete"),
          },
        ],
      },
      {
        label: i18n("objectType.salesorder.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.viewSalesOrders,
            label: i18n("privilege.View"),
          },
          {
            privilege: USER_EDIT_PRIVILEGES.editSalesOrders,
            label: i18n("privilege.AddEditDelete"),
          },
        ],
      },
      {
        label: i18n("objectType.invoice.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.viewInvoices,
            label: i18n("privilege.View"),
          },
          {
            privilege: USER_EDIT_PRIVILEGES.editInvoices,
            label: i18n("privilege.AddEditDelete"),
          },
        ],
      },
      {
        label: i18n("objectType.shipment.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.viewShipments,
            label: i18n("privilege.View"),
          },
          {
            privilege: USER_EDIT_PRIVILEGES.editShipments,
            label: i18n("privilege.AddEditDelete"),
          },
        ],
      },
      {
        label: i18n("objectType.salesrep.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.editSalesReps,
            label: i18n("privilege.AddEditDelete"),
          },
        ],
      },
      {
        label: i18n("objectType.shipmethod.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.editShippingMethods,
            label: i18n("privilege.AddEditDelete"),
          },
        ],
      },
      {
        label: i18n("objectType.payment.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.processPayments,
            label: i18n("privilege.ProcessCCOrACH"),
          },
        ],
      },
    ],
  },
  {
    groupLabel: i18n("privilege.Purchasing"),
    groupPrivileges: [
      {
        label: i18n("objectType.vendor.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.viewVendors,
            label: i18n("privilege.View"),
          },
          {
            privilege: USER_EDIT_PRIVILEGES.editVendors,
            label: i18n("privilege.AddEditDelete"),
          },
        ],
      },
      {
        label: i18n("objectType.purchaseorder.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.viewPurchaseOrders,
            label: i18n("privilege.View"),
          },
          {
            privilege: USER_EDIT_PRIVILEGES.editPurchaseOrders,
            label: i18n("privilege.AddEditDelete"),
          },
          {
            privilege: USER_EDIT_PRIVILEGES.approvePurchaseOrders,
            label: "Approve",
          },
        ],
      },
      {
        label: "Inventory",
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.receiveInventory,
            label: i18n("privilege.Receive"),
          },
        ],
      },
    ],
  },
  {
    groupLabel: i18n("privilege.Production"),
    groupPrivileges: [
      {
        label: i18n("objectType.job.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.viewJobs,
            label: i18n("privilege.View"),
          },
          {
            privilege: USER_EDIT_PRIVILEGES.editJobs,
            label: i18n("privilege.AddEditDelete"),
          },
        ],
      },
      {
        label: i18n("objectType.worker.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.viewWorkers,
            label: i18n("privilege.View"),
          },
          {
            privilege: USER_EDIT_PRIVILEGES.editWorkers,
            label: i18n("privilege.AddEditDelete"),
          },
        ],
      },
      {
        label: i18n("privilege.BuildAssemblies"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.buildAssemblies,
            label: i18n("privilege.ViewAddEditDelete"),
          },
        ],
      },
    ],
  },
  {
    groupLabel: i18n("privilege.Rental"),
    groupPrivileges: [
      {
        label: i18n("objectType.rental.SentencePlural"),
        privileges: [
          {
            privilege: USER_EDIT_PRIVILEGES.viewRentals,
            label: i18n("privilege.View"),
          },
          {
            privilege: USER_EDIT_PRIVILEGES.editRentals,
            label: i18n("privilege.AddEditDelete"),
          },
        ],
      },
    ],
  },
];
