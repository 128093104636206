import Delete from "@mui/icons-material/Delete";

import { Decimal } from "classes/DecimalClasses";

import { LineTableCell } from "components/formFields/LineTableCell";
import { LineAmount } from "components/formFields/line/LineAmount";
import { LineClass } from "components/formFields/line/LineClass";
import { LineDescription } from "components/formFields/line/LineDescription";
import { LineJobWorkcenter } from "components/formFields/line/LineJobWorkcenter";
import { LineLinkedTransactionSelect } from "components/formFields/line/LineLinkedTransactionSelect";

import { setPageDirty } from "services/utility/edit";
import { isLineFieldInError } from "services/utility/errors";
import { TYPES } from "services/utility/schema";

import { theme } from "SosTheme";
import { OBJECT_TYPES } from "appConstants";
import { LINE_ITEM_FIELDS } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.PAYMENT.fullString;

// created this wrapper to make drag and drop code easier to see
export function LineItem(props) {
  return <LineItemDetails {...props} />;
}

function LineItemDetails(props) {
  const {
    relatedRecords,
    line,
    lineHandler,
    classes,
    jobWorkcenters,
    lineItemsErrors: errors,
  } = props;

  function isInError(field) {
    return isLineFieldInError(field, line.lineNumber, errors);
  }

  function handleValueChange(fieldName, newValue) {
    let newLine;
    const lineItemFields = LINE_ITEM_FIELDS[OBJECT_TYPE];
    if (lineItemFields[TYPES.reference].includes(fieldName)) {
      newLine = { ...line, [fieldName]: { id: newValue } };
    } else if (lineItemFields[TYPES.decimal].includes(fieldName)) {
      newLine = { ...line, [fieldName]: new Decimal(newValue || 0) };
    } else {
      newLine = { ...line, [fieldName]: newValue };
    }
    setPageDirty();
    lineHandler({ type: "update", updatedLine: newLine });
  }

  function handleReferenceChange(fieldName, newValue) {
    const updatedLine = { ...line, [fieldName]: newValue };
    setPageDirty();
    lineHandler({ type: "update", updatedLine });
  }

  function handleDelete() {
    setPageDirty();
    lineHandler({ type: "delete", deleteAt: line.lineNumber });
  }

  function handleLinkedTransactionChange(_, newValue) {
    const updatedLine = { ...line, linkedTransaction: newValue };
    lineHandler({ type: "update", updatedLine });
  }

  if (!line || !relatedRecords) {
    return null;
  }

  return (
    <>
      <LineTableCell>
        <div
          onClick={handleDelete}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
            color: theme.palette.icons,
          }}
        >
          <Delete />
        </div>
      </LineTableCell>
      <LineTableCell>
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
          }}
        >
          {line.lineNumber}
        </div>
      </LineTableCell>
      <LineTableCell sx={{ minWidth: "24rem" }}>
        <LineDescription
          value={line.description}
          onValueChange={handleValueChange}
          error={isInError("description")}
        />
      </LineTableCell>
      <LineAmount
        value={line.amount}
        objectType={OBJECT_TYPE}
        onValueChange={handleValueChange}
        error={isInError("amount")}
      />
      <LineLinkedTransactionSelect
        objectType={OBJECT_TYPE}
        value={line.linkedTransaction}
        onValueChange={handleLinkedTransactionChange}
        error={isInError("linkedTransaction")}
      />
      <LineClass
        value={line.class}
        classes={classes}
        onValueChange={handleReferenceChange}
      />
      <LineJobWorkcenter
        job={line.job}
        workcenter={line.workcenter}
        value={line.jobWorkcenter}
        jobWorkcenters={jobWorkcenters}
        onValueChange={handleReferenceChange}
      />
    </>
  );
}
