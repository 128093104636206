import { productionTransactionIsValid } from "services/sosInventoryService/productionTransaction/isValid";
import { quantitySerialsIsValid } from "services/utility/isValid";

export function isValid(record, __, ___, outputs, inputs) {
  let errors = productionTransactionIsValid(record, outputs, inputs);

  errors = quantitySerialsIsValid(
    errors,
    outputs,
    record.autoSerialLots,
    "output"
  );

  errors = quantitySerialsIsValid(
    errors,
    inputs,
    record.autoSerialLots,
    "input"
  );

  return errors;
}
