import { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";

import { FilterList } from "@mui/icons-material";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { EditActionFilter } from "components/ListPage/Widgets/EditDisplayResults/EditActionFilter";
import { EditArchiveFilter } from "components/ListPage/Widgets/EditDisplayResults/EditArchiveFilter";
import { EditAssignedToFilter } from "components/ListPage/Widgets/EditDisplayResults/EditAssignedToFilter";
import { EditCategoryFilter } from "components/ListPage/Widgets/EditDisplayResults/EditCategoryFilter";
import { EditClosedFilter } from "components/ListPage/Widgets/EditDisplayResults/EditClosedFilter";
import { EditCustomerFilter } from "components/ListPage/Widgets/EditDisplayResults/EditCustomerFilter";
import { EditDateFilter } from "components/ListPage/Widgets/EditDisplayResults/EditDateFilter";
import { EditExpirationDateFilter } from "components/ListPage/Widgets/EditDisplayResults/EditExpirationDate";
import { EditItemFilter } from "components/ListPage/Widgets/EditDisplayResults/EditItemFilter";
import { EditItemTypeFilter } from "components/ListPage/Widgets/EditDisplayResults/EditItemTypeFilter";
import { EditLocationFilter } from "components/ListPage/Widgets/EditDisplayResults/EditLocationFilter";
import { EditMaxResults } from "components/ListPage/Widgets/EditDisplayResults/EditMaxResults";
import { EditStatusFilter } from "components/ListPage/Widgets/EditDisplayResults/EditStatusFilter";
import { EditSyncObjectFilter } from "components/ListPage/Widgets/EditDisplayResults/EditSyncObjectFilter";
import { EditTagsFilter } from "components/ListPage/Widgets/EditDisplayResults/EditTagsFilter";
import { EditToFromLocationFilters } from "components/ListPage/Widgets/EditDisplayResults/EditToFromLocationFilters";
import { EditTypeFilter } from "components/ListPage/Widgets/EditDisplayResults/EditTypeFilter";
import { EditVariantsFilter } from "components/ListPage/Widgets/EditDisplayResults/EditVariantsFilter";
import { EditVendorFilter } from "components/ListPage/Widgets/EditDisplayResults/EditVendorFilter";
import { FILTERS } from "components/ListPage/listConfig";

import { i18n } from "services/i18nService";

import { ITEM_QUICKLIST_CASES } from "appConstants";

export function Filters(props) {
  const { objectType, retrievalParams, setRetrievalParams } = props;

  const location = useLocation();
  const navigate = useNavigate();
  const [open, setOpen] = useState(false);
  const [filters, setFilters] = useState(retrievalParams);
  const [dateError, setDateError] = useState({ to: null, from: null });
  const [dateFilter, setDateFilter] = useState("");

  useEffect(() => setFilters(retrievalParams), [retrievalParams, setFilters]);

  function handleClose(isSaved = false) {
    setOpen(false);
    isSaved && setFilters(retrievalParams);
  }

  function handleClear() {
    setDateFilter("");
    setFilters((prevParams) => ({
      ...prevParams,
      filterSettings: {
        ToDate: null,
        FromDate: null,
        ToExpiration: null,
        FromExpiration: null,
        ShowClosed: prevParams.filterSettings?.ShowClosed,
        Action: "",
        SyncObject: "",
      },
      startingRecord: 1,
    }));
  }

  function handleApply() {
    setRetrievalParams({ ...filters, persistUpdate: true });
    handleClose(true);
    const queryParams = new URLSearchParams(location.search);
    const status = queryParams.get("status");
    if (status) {
      queryParams.delete("status");
      navigate(location.pathname, { replace: true });
    }
  }

  function getShowFilter(type) {
    return FILTERS[objectType].includes(type);
  }

  return (
    <>
      <Button data-testing="filterButton" onClick={() => setOpen(true)}>
        <FilterList sx={{ marginRight: "0.5rem" }} />
        {i18n("filter.label")}
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
        <DialogTitle>{i18n("filter.Heading")}</DialogTitle>
        <DialogContent data-testing="filters" sx={{ width: "100%" }}>
          <div style={{ display: "grid", rowGap: "1rem" }}>
            <EditMaxResults filters={filters} setFilters={setFilters} />
            {getShowFilter("action") && (
              <EditActionFilter filters={filters} setFilters={setFilters} />
            )}
            {getShowFilter("dates") && (
              <EditDateFilter
                filters={filters}
                setFilters={setFilters}
                setDateError={setDateError}
                setDateFilter={setDateFilter}
                dateFilter={dateFilter}
              />
            )}
            {getShowFilter("expirationDate") && (
              <EditExpirationDateFilter
                filters={filters}
                setFilters={setFilters}
                setDateError={setDateError}
                setDateFilter={setDateFilter}
                dateFilter={dateFilter}
              />
            )}
            {getShowFilter("objectType") && (
              <EditSyncObjectFilter filters={filters} setFilters={setFilters} />
            )}
            {getShowFilter("itemType") && (
              <EditItemTypeFilter filters={filters} setFilters={setFilters} />
            )}
            {getShowFilter("type") && (
              <EditTypeFilter filters={filters} setFilters={setFilters} />
            )}
            {getShowFilter("categories") && (
              <EditCategoryFilter filters={filters} setFilters={setFilters} />
            )}
            {getShowFilter("items") && (
              <EditItemFilter
                filters={filters}
                setFilters={setFilters}
                type={ITEM_QUICKLIST_CASES.ALL}
              />
            )}
            {getShowFilter("completed") && (
              <EditItemFilter
                filters={filters}
                setFilters={setFilters}
                type={ITEM_QUICKLIST_CASES.ALL}
              />
            )}
            {getShowFilter("lotTrackedItems") && (
              <EditItemFilter
                filters={filters}
                setFilters={setFilters}
                type={ITEM_QUICKLIST_CASES.LOT}
              />
            )}
            {getShowFilter("serializedItems") && (
              <EditItemFilter
                filters={filters}
                setFilters={setFilters}
                type={ITEM_QUICKLIST_CASES.SERIAL}
              />
            )}
            {getShowFilter("archive") && (
              <EditArchiveFilter filters={filters} setFilters={setFilters} />
            )}
            {getShowFilter("openClose") && (
              <EditClosedFilter filters={filters} setFilters={setFilters} />
            )}
            {getShowFilter("status") && (
              <EditStatusFilter
                filters={filters}
                setFilters={setFilters}
                objectType={objectType}
              />
            )}
            {getShowFilter("locations") && (
              <EditLocationFilter filters={filters} setFilters={setFilters} />
            )}
            {(getShowFilter("toLocation") || getShowFilter("fromLocation")) && (
              <EditToFromLocationFilters
                showTo={getShowFilter("toLocation")}
                showFrom={getShowFilter("fromLocation")}
                filters={filters}
                setFilters={setFilters}
              />
            )}
            {getShowFilter("vendors") && (
              <EditVendorFilter filters={filters} setFilters={setFilters} />
            )}
            {getShowFilter("customers") && (
              <EditCustomerFilter filters={filters} setFilters={setFilters} />
            )}
            {getShowFilter("assignedTo") && (
              <EditAssignedToFilter filters={filters} setFilters={setFilters} />
            )}
            {getShowFilter("tags") && (
              <EditTagsFilter filters={filters} setFilters={setFilters} />
            )}
            {getShowFilter("variants") && (
              <EditVariantsFilter filters={filters} setFilters={setFilters} />
            )}
          </div>
        </DialogContent>

        <DialogActions
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
          data-testing="dialogActions"
        >
          <div>
            <Button
              color="secondary"
              onClick={handleClear}
              data-testing="clear"
            >
              {i18n("button.Clear")}
            </Button>
          </div>
          <div>
            <Button
              color="secondary"
              onClick={handleClose}
              data-testing="filter-cancel"
            >
              {i18n("button.Cancel")}
            </Button>
            <Button
              color="secondary"
              onClick={handleApply}
              disabled={dateError.to || dateError.from}
              data-testing="ok"
            >
              {i18n("button.Apply")}
            </Button>
          </div>
        </DialogActions>
      </Dialog>
    </>
  );
}
