import { useState } from "react";
import { useSelector } from "react-redux";

import Delete from "@mui/icons-material/Delete";

import { ITEM_CALCULATED_FIELDS } from "appConfig";

import { Decimal } from "classes/DecimalClasses";

import { QuickAddItem } from "components/QuickAddItem";
import { LineTableCell } from "components/formFields/LineTableCell";
import { LineClass } from "components/formFields/line/LineClass";
import { LineDescription } from "components/formFields/line/LineDescription";
import { LineInventoryItemBin } from "components/formFields/line/LineInventoryItemBin";
import { LineJobWorkcenter } from "components/formFields/line/LineJobWorkcenter";
import { LineLinkedTransaction } from "components/formFields/line/LineLinkedTransaction";
import { LineLotWithExpiration } from "components/formFields/line/LineLotWithExpiration";
import { LineQuantity } from "components/formFields/line/LineQuantity";
import { LineSerialsEnter } from "components/formFields/line/LineSerialsEnter";
import { LineUom } from "components/formFields/line/LineUom";
import { LineWaste } from "components/formFields/line/LineWaste";

import { i18n } from "services/i18nService";
import {
  createLineFromItem,
  postItemAndCreateLineItem,
  getItemRecord,
  clearItemDataFromLine,
} from "services/sosInventoryService/domainLogic";
import { getEmptyRecord as getEmptyItemRecord } from "services/sosInventoryService/item/schema";
import { updateLineWithItem } from "services/sosInventoryService/productionTransaction/domainLogic";
import { setPageDirty } from "services/utility/edit";
import { isLineFieldInError } from "services/utility/errors";
import { isFinishedGoods } from "services/utility/jobWorkCenter";
import { TYPES } from "services/utility/schema";

import { usePlans } from "hooks/usePlans";

import { theme } from "SosTheme";
import { OBJECT_TYPES } from "appConstants";
import { OUTPUT_LINE_ITEM_FIELDS, EMPTY_OUTPUT_LINE_ITEM } from "editConfig";
import { OUTPUT_METADATA } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.PROCESS.fullString;

// created this wrapper to make drag and drop code easier to see
export function LineItem(props) {
  return <LineItemDetails {...props} />;
}

function LineItemDetails(props) {
  const {
    objectType,
    record,
    line,
    classes,
    lineHandler,
    items,
    bins,
    relatedRecords,
    jobWorkcenters,
    itemsCount,
    addItem,
    lineItemsErrors: errors,
    setErrors,
  } = props;
  const { date, location, workcenter, autoSerialLotNumbering } = record;

  const [addNewItem, setAddNewItem] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [lineLoading, setLineLoading] = useState(false);
  const { hasAtLeastPlusPlan } = usePlans();

  // SETTINGS
  const defaultAssetAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultAssetAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultCOGSAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultCOGSAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultExpenseAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultExpenseAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultIncomeAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultIncomeAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultTaxCode = useSelector(
    (state) => state.userCompanySettings.settings.defaultTaxCode,
    (prev, curr) => curr?.id === prev?.id
  );

  function isInError(field) {
    return isLineFieldInError(field, line.lineNumber, errors);
  }

  function handleValueChange(fieldName, newValue) {
    let newLine;
    const lineItemFields = OUTPUT_LINE_ITEM_FIELDS[OBJECT_TYPE];
    if (lineItemFields[TYPES.decimal].includes(fieldName)) {
      newLine = { ...line, [fieldName]: new Decimal(newValue || 0) };
    } else {
      newLine = { ...line, [fieldName]: newValue };
    }
    lineHandler({ type: "update", updatedLine: newLine });
  }

  function handleCheckUpdate(fieldName, newValue) {
    const updatedLine = { ...line, [fieldName]: newValue };
    setPageDirty();
    lineHandler({ type: "update", updatedLine });
  }

  function handleUomChange(_, uom) {
    setPageDirty();
    const oldUom = line.uom;
    const updatedLine = { ...line, uom, oldUom };
    lineHandler({ type: "update", updatedLine });
  }

  async function handleItemChange(newItem, userInput) {
    if (!newItem) {
      const updatedLine = clearItemDataFromLine(line);
      setPageDirty();
      lineHandler({ type: "update", updatedLine });
      return;
    }
    if (newItem?.id === "add") {
      return openAddItem(userInput);
    }
    setLineLoading(true);
    const item = await getItemRecord(
      newItem.id,
      location?.id,
      date,
      ITEM_CALCULATED_FIELDS[objectType]
    );
    const updatedLine = await updateLineWithItem(item, line, record);
    setPageDirty();
    lineHandler({ type: "update", updatedLine });
    setLineLoading(false);
  }

  async function onQuickAddItem(name) {
    const newItemSettings = {
      defaultCOGSAccount,
      defaultAssetAccount,
      defaultExpenseAccount,
      defaultIncomeAccount,
      defaultTaxCode,
    };
    const item = { ...getEmptyItemRecord(newItemSettings), name };
    const updatedLine = await postItemAndCreateLineItem(
      item,
      EMPTY_OUTPUT_LINE_ITEM[OBJECT_TYPE],
      line.lineNumber,
      addItem,
      setErrors,
      autoSerialLotNumbering
    );
    setPageDirty();
    lineHandler({ type: "update", updatedLine });
    setAddNewItem(false);
  }

  function onAddItem(item) {
    setAddNewItem(false);
    const updatedLine = createLineFromItem(
      item,
      EMPTY_OUTPUT_LINE_ITEM[OBJECT_TYPE],
      line.lineNumber,
      lineHandler
    );
    setPageDirty();
    lineHandler({ type: "update", updatedLine });
    addItem();
  }

  function handleLinkedTransactionChange(_, newValue) {
    const updatedLine = { ...line, linkedTransaction: newValue };
    setPageDirty();
    lineHandler({ type: "update", updatedLine });
  }

  function handleDelete() {
    setPageDirty();
    lineHandler({ type: "delete", deleteAt: line.lineNumber });
  }

  function openAddItem(userInput) {
    // handle "Add new item" in Item select input field; this does not change the
    // line item, so no need to proceed further into the handler
    setNewItemName(userInput);
    setAddNewItem(true);
  }

  if (!line) {
    return null;
  }

  return (
    <>
      <LineTableCell>
        <div
          onClick={handleDelete}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
            color: theme.palette.icons,
          }}
        >
          <Delete />
        </div>
      </LineTableCell>
      <LineTableCell>
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
          }}
        >
          {line.lineNumber}
        </div>
      </LineTableCell>
      <LineInventoryItemBin
        lineLoading={lineLoading}
        bins={bins}
        location={relatedRecords.location}
        handleItemChange={handleItemChange}
        handleReferenceChange={handleValueChange}
        objectType={OBJECT_TYPE}
        line={line}
        items={items}
        itemsCount={itemsCount}
        addItem={addItem}
        isInError={isInError}
      />
      <LineTableCell sx={{ minWidth: "24rem" }}>
        <LineDescription
          value={line.description}
          onValueChange={handleValueChange}
          error={isInError("description")}
        />
        {isFinishedGoods(workcenter) &&
          !autoSerialLotNumbering &&
          hasAtLeastPlusPlan && (
            <>
              {line.itemDetails?.serialTracking && (
                <LineSerialsEnter
                  autoNumber={autoSerialLotNumbering}
                  value={line.serials}
                  onValueChange={handleValueChange}
                  quantity={line.quantity}
                  error={isInError("serials")}
                />
              )}
              {line.itemDetails?.lotTracking && (
                <LineLotWithExpiration
                  lot={line.lot}
                  lotExpiration={line.lotExpiration}
                  onValueChange={handleValueChange}
                  handleDateChange={handleValueChange}
                  itemLotTracked={line.itemDetails?.lotTracking}
                  quantity={line.quantity}
                  metadata={OUTPUT_METADATA[OBJECT_TYPE]}
                  lotError={isInError("lot")}
                  lotExpirationError={isInError("lotExpiration")}
                />
              )}
            </>
          )}
      </LineTableCell>
      <LineQuantity
        objectType={OBJECT_TYPE}
        value={line.quantity}
        onValueChange={handleValueChange}
        error={isInError("quantity")}
        disabled={lineLoading}
      />
      <LineUom
        value={line.uom}
        onValueChange={handleUomChange}
        itemUoms={line.itemDetails?.itemUoms}
        error={isInError("uom")}
      />
      <LineLinkedTransaction
        objectType={OBJECT_TYPE}
        transactionLabel={i18n("workOrder.labelAbbreviatedNumber")}
        value={line.linkedTransaction}
        onValueChange={handleLinkedTransactionChange}
        error={isInError("linkedTransaction")}
      />
      <LineWaste value={line.waste} onValueChange={handleCheckUpdate} />

      <LineClass
        value={line.class}
        classes={classes}
        onValueChange={handleValueChange}
      />
      <LineJobWorkcenter
        job={line.job}
        workcenter={line.workcenter}
        value={line.jobWorkcenter}
        jobWorkcenters={jobWorkcenters}
        onValueChange={handleValueChange}
      />
      {addNewItem && (
        <QuickAddItem
          open={addNewItem}
          initialNameValue={newItemName}
          onClose={() => setAddNewItem(false)}
          onAdd={onAddItem}
          onQuickAdd={onQuickAddItem}
        />
      )}
    </>
  );
}
