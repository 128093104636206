import { Decimal, Money } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

import { getSalesTax } from "services/sosInventoryService/salesTransaction/taxAndTotals";
import { totalWeight, weightUnit } from "services/utility/afterTouchLine";
import { isNonZeroDecimalOrMoney } from "services/utility/misc";

import { DEFAULT_DECIMALS_ROUNDED } from "appConstants";

export const ZERO_TOTALS = {
  total: Money.ZERO,
  tax: Money.ZERO,
  amount: Money.ZERO,
  cost: Money.ZERO,
  globalTax: Money.ZERO,
  margin: Decimal.ZERO,
  quantity: Decimal.ZERO,
  taxableAmount: Money.ZERO,
  volume: Decimal.ZERO,
  weight: Decimal.ZERO,
};

export function updateTotals(setTotals, record, lines, taxCodes, customer) {
  if (!lines || lines.length === 0 || !record) {
    setTotals(ZERO_TOTALS);
    return;
  }

  const quantity = lines.reduce(
    (total, line) => total.plus(line.quantity),
    Decimal.ZERO
  );

  const amount = lines.reduce(
    (total, { amount }) =>
      amount instanceof Loading ? total : total.plus(amount),
    Money.ZERO
  );

  const weight = totalWeight(lines);
  const weightunit = weightUnit(lines);

  // if we ever support more than cbm for volume, this will have to be
  // changed to mirror the weight calculation
  const volume = lines.reduce(
    (total, { volume }) => total.plus(volume),
    Decimal.ZERO
  );

  const cost = lines.reduce(
    (total, { cost }) =>
      cost && !(cost instanceof Loading) ? total.plus(cost) : total,
    Money.ZERO
  );

  const margin =
    !(cost instanceof Loading) && isNonZeroDecimalOrMoney(cost)
      ? amount
          .minus(cost)
          .div(cost)
          .times(new Decimal(100))
          .round(DEFAULT_DECIMALS_ROUNDED, Decimal.roundUp)
      : Decimal.ZERO;

  const tax = getSalesTax({ ...record, taxCodes, lines, customer });

  const total = amount
    .plus(tax)
    .plus(record.shippingAmount)
    .plus(record.discountAmount);

  setTotals({
    total,
    tax,
    quantity,
    cost,
    margin,
    amount,
    weight,
    weightunit,
    volume,
    volumeunit: "cbm",
  });
}
