import { Box } from "@mui/material";

import { CopyToClipboard } from "components/formFields/frm/CopyToClipboard";
import { Link } from "components/html/Link";

export function FrmLinkWithClipboardCopy(props) {
  const { link, label, hideIcon } = props;

  return (
    <Box sx={{ pt: 1, pb: 1, pl: "12px" }}>
      <Link component="a" rel="noreferrer" href={link} target="_blank">
        {label}
      </Link>
      {!hideIcon && <CopyToClipboard copyText={link} />}
    </Box>
  );
}
