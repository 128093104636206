import { ITEM_CALCULATED_FIELDS } from "appConfig";

import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";
import {
  get,
  getRecord,
  postRecord,
  getItemLocationSettings,
  getRecordWithParams,
  getItemBom,
  getByIds,
  calculateBomCostBasis,
} from "services/sosInventoryService/sosApi";
import {
  copyCustomFieldValues,
  reconcileCustomFields,
} from "services/utility/customFields";
import {
  dateToSosISODateTime,
  removeDateSeconds,
} from "services/utility/dates";
import { setPageDirty } from "services/utility/edit";
import { handleProgramError } from "services/utility/errors";
import { formatContact, formatBinInfo } from "services/utility/formatting";
import { filterAndFormatBinOptions } from "services/utility/misc";
import {
  getBaseUom,
  getUomConversionFromUomReference,
} from "services/utility/uoms";

import globalState from "globalState/globalState";
import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { ITEM_TYPES } from "appConstants";
import { EMPTY_LINE_ITEM } from "editConfig";

export function isInventoryItemAssemblyOrCategory(type) {
  return (
    type === ITEM_TYPES.INVENTORY_ITEM ||
    type === ITEM_TYPES.ASSEMBLY ||
    type === ITEM_TYPES.CATEGORY
  );
}

export function isInventoryItemOrAssembly(type) {
  return type === ITEM_TYPES.INVENTORY_ITEM || type === ITEM_TYPES.ASSEMBLY;
}

// determine the best units of measure to use; of the units passed in,
// favor in this order: kg, lb, oz, g
export function determineBestUnitToDisplay(units) {
  if (units.includes("kg")) {
    return "kg";
  }
  if (units.includes("lb")) {
    return "lb";
  }
  if (units.includes("oz")) {
    return "oz";
  }
  return "g";
}

/**
 * @name    getDefaultBin
 *
 * @summary returns a bin reference object, which should be the default
 *          provided in the UI when a new inventory item is selected;
 *          it takes into account available bins (which may be limited
 *          due to inventory level) and location, of course
 *
 * @param   itemId (number) - inventory item id
 *
 * @param   locationId (number)
 *
 * @param   availableBins (array of bin reference objects)
 *
 * @returns bin reference object, or null if no default is relevant
 */
export async function getDefaultBin(itemId, locationId, availableBins) {
  if (!itemId || !locationId || availableBins.length === 0) {
    return null;
  }

  let itemLocation;
  const response = await getItemLocationSettings(itemId, locationId);
  if (response.success) {
    itemLocation = response.data;
  } else {
    handleProgramError(
      new Error(i18n("error.CouldNotRetrieveItemLocationSettings"))
    );
  }

  // if we found this item/location and we have location/bin info and
  // the default bin is in the bin options...
  let defaultBin = null;
  if (itemLocation?.defaultBin?.id) {
    defaultBin = availableBins.find((bin) => {
      return bin.id === itemLocation.defaultBin.id;
    });
  }
  return defaultBin;
}

/** @name    getItemRecord
 *
 * @summary retrieves an item record, using one of two sosApi functions,
 * depending on whether we can specify a location (and thus get the
 * locationBins property) or not
 *
 * @param id (number) - item id
 *
 * @param location (location reference object or null)
 *
 * @param transactionDateTime (Date) - the date/time of the relevant
 * transaction; will be used to populate the locationBins property with
 * the correct inventory quantity at that date/time
 *
 * @return item record (with locationBins property, if location  was
 * specified)
 */
export async function getItemRecord(
  itemId,
  locationId,
  transactionDateTime,
  columns
) {
  try {
    return await getRecordWithParams("item", itemId, {
      locationId,
      dt: transactionDateTime
        ? dateToSosISODateTime(removeDateSeconds(transactionDateTime))
        : "",
      inTransaction: true,
      columns: columns ? columns.join(",") : "",
    });
  } catch (e) {
    handleProgramError(e);
  }
}

export async function getMultipleItemRecords(
  itemIdArray,
  locationId = "",
  transactionDateTime,
  columns
) {
  try {
    return await getByIds("item", itemIdArray, columns, {
      locationId,
      dt: transactionDateTime
        ? dateToSosISODateTime(transactionDateTime || new Date())
        : "",
      inTransaction: true,
    });
  } catch (e) {
    handleProgramError(e);
  }
}

/**
 * @name    extractBillingAndShippingFromCustomer
 *
 * @summary combines a number of customer fields into *billing* and
 *          *shipping* fields suitable for hydration of the respective
 *          fields in a transaction record
 *
 * @param   customer (Object) - a customer object as it comes back from
 *          the API
 *
 * @returns { billing, shipping } (Object) - in format used by transactions
 */
export function extractBillingAndShippingFromCustomer(customer) {
  const { billing, shipping, contact, companyName, phone, email } =
    customer || {};

  const contactDetails = {
    company: companyName,
    contact: formatContact(contact),
    phone,
    email,
  };

  const billingAddress = {
    address: billing,
    ...contactDetails,
  };

  const shippingAddress = {
    address: shipping,
    ...contactDetails,
  };

  return { billing: billingAddress, shipping: shippingAddress };
}

// updates line.onhand, assuming that line.relatedRecords.item is current
export function updateLineItemOnhand(lines) {
  return lines.map((line) => {
    const item = line.relatedRecords.item;
    if (item) {
      const conversion = getUomConversionFromUomReference(line.uom, item.uoms);
      const onhand = item.onhand.div(conversion).round(2, Decimal.roundDown);
      return { ...line, onhand };
    } else {
      return { ...line, onhand: null };
    }
  });
}

/**
 * @name    quickAddCustomerOrVendor
 *
 * @summary creates a new customer record, given just a name
 *
 * @param   name (string) - name for the new customer record
 *
 * @returns new customer record, or undefined if add failed
 */
export async function quickAddCustomer(name) {
  const newObject = { name, showOnForms: true };
  const { success, record, message } = await postRecord("customer", newObject);
  return success ? record : message;
}

/**
 * @name    quickAddVendor
 *
 * @summary creates a new vendor record, given just a name
 *
 * @param   name (string) - name for the new vendor record
 *
 * @returns new vendor record, or undefined if add failed
 */
export async function quickAddVendor(name) {
  const newObject = { name, showOnForms: true };
  const { success, record, message } = await postRecord("vendor", newObject);
  return success ? record : message;
}

// call this when the customer changes
export function changeCustomer(
  newCustomer,
  {
    customerCustomFieldDefs,
    transactionCustomFieldDefs,
    transactionCustomFields,
  }
) {
  // be sure there are custom field entries for each defined custom field
  // in the customer record...
  const customerCustomFields = reconcileCustomFields(
    customerCustomFieldDefs,
    newCustomer.customFields
  );

  // ...then initialize any transaction custom fields to their matching customer
  // custom field values, if any
  const newTransactionCustomFields = copyCustomFieldValues(
    customerCustomFieldDefs,
    customerCustomFields,
    transactionCustomFieldDefs,
    transactionCustomFields
  );

  const { billing, shipping, contact, companyName, phone, email } =
    newCustomer || {};

  const contactDetails = {
    company: companyName,
    contact: formatContact(contact),
    phone,
    email,
  };
  const billingAddress = {
    address: billing,
    ...contactDetails,
  };
  const shippingAddress = {
    address: shipping,
    ...contactDetails,
  };

  return {
    customFields: newTransactionCustomFields,
    billing: billingAddress,
    shipping: shippingAddress,
  };
}

export function changeVendor(
  newVendor,
  { vendorCustomFieldDefs, transactionCustomFieldDefs, transactionCustomFields }
) {
  // be sure there are custom field entries for each defined custom field
  // in the vendor record...
  const vendorCustomFields = reconcileCustomFields(
    vendorCustomFieldDefs,
    newVendor.customFields
  );

  // ...then initialize any transaction custom fields to their matching customer
  // custom field values, if any
  const newTransactionCustomFields = copyCustomFieldValues(
    vendorCustomFieldDefs,
    vendorCustomFields,
    transactionCustomFieldDefs,
    transactionCustomFields
  );
  const { address, contact, companyName, phone, email } = newVendor || {};

  const contactDetails = {
    company: companyName,
    contact: formatContact(contact),
    phone,
    email,
  };
  const billing = { address, ...contactDetails };
  return { customFields: newTransactionCustomFields, billing };
}

// updates line.relatedRecords.item for all lines, based on item, location,
// and date
export async function updateLineRelatedRecordsItem(
  location,
  date,
  lines,
  itemFieldsNeeded
) {
  return await Promise.all(
    lines.map(async (line) => {
      if (line.item?.id) {
        const item = await getItemRecord(
          line.item.id,
          location?.id,
          date,
          itemFieldsNeeded
        );
        return { ...line, relatedRecords: { ...line.relatedRecords, item } };
      }
      return line;
    })
  );
}

export function removeLotAndSerialValues(lineItems) {
  return lineItems.map((lineItem) => ({
    ...lineItem,
    lot: null,
    serial: null,
  }));
}

export async function updateAvailableBinsAndBin(
  location,
  lines,
  binType = "bin",
  showAllBins = false
) {
  return await Promise.all(
    lines.map(
      async (line) =>
        await updateSingleLineAvailableBinsAndBin(
          location,
          line,
          binType,
          showAllBins
        )
    )
  );
}

export async function updateSingleLineAvailableBinsAndBin(
  location,
  line,
  binType = "bin",
  showAllBins = false
) {
  const newLine = { ...line };
  // if we have an item record in relatedRecords, set availableBins
  if (
    newLine.relatedRecords.item?.id &&
    newLine.relatedRecords.item?.locationBins
  ) {
    const filteredAndFormattedBinOptions = showAllBins
      ? formatBinInfo(newLine.relatedRecords.item.locationBins)
      : filterAndFormatBinOptions(newLine.relatedRecords.item.locationBins);
    newLine.availableBins = filteredAndFormattedBinOptions;
  } else {
    newLine.availableBins = [];
  }
  // if there is an existing bin value, be sure it is in the list of
  // available bins; if not, null it
  if (line[binType] && newLine.availableBins) {
    const inInAvailableBins = newLine.availableBins.find(
      (bin) => line[binType].id === bin.id
    );
    if (!inInAvailableBins) {
      line[binType] = null;
    }
  }
  // if there is no existing bin value, set to the default, if any
  if (!line[binType] && line.relatedRecords.item?.id) {
    newLine[binType] = await getDefaultBin(
      newLine.relatedRecords.item.id,
      location?.id,
      newLine.availableBins
    );
  }
  return newLine;
}

// get all bins for a location
export async function getAllBinsForLocation(locationId) {
  const location = await getRecord("location", locationId);
  return location.bins;
}

export function createLineFromItem(item, emptyLineData, lineNumber) {
  return {
    ...emptyLineData,
    lineNumber,
    item: { id: item.id, name: item.name },
    class: item.class,
    onhand: item.onhand,
    description: item.description,
    unitprice: item.baseSalesPrice,
    relatedRecords: { item },
    itemDetails: {
      serialTracking: item.serialTracking,
      lotTracking: item.lotTracking,
      itemWeight: item.weight,
      itemVolume: item.volume,
      itemUoms: item.uoms,
      type: item.type,
      useMarkup: item.useMarkup,
      markupPercent: item.markupPercent,
      baseSalesPrice: item.baseSalesPrice,
    },
  };
}

export async function postItemAndCreateLineItem(
  item,
  emptyLineData,
  lineNumber,
  addItem,
  setErrors
) {
  globalState.dispatch(editModalLoadingIndicatorOn());
  const { success, record, message } = await postRecord("item", item);
  if (success) {
    addItem();
    globalState.dispatch(editModalLoadingIndicatorOff());
    return createLineFromItem(record, emptyLineData, lineNumber);
  } else {
    globalState.dispatch(editModalLoadingIndicatorOff());
    setErrors((prev) => ({ ...prev, messages: [message] }));
  }
}

export async function getAndSortWorkCenters() {
  const response = await get("workcenter", { inTransaction: true });
  if (response.success) {
    return response.data.records.sort((a, b) => {
      if (a.id === 1) {
        return 1;
      }
      return a.sortOrder < b.sortOrder ? -1 : 1;
    });
  } else {
    handleProgramError(
      new Error(
        `getAndSortWorkCenters | unsuccessful call to getAll, message: ${response.message}`
      )
    );
  }
}

export async function updateLineItemToBins(lines, location) {
  return await Promise.all(
    lines.map(async (line) => {
      if (line.item?.id && location) {
        let itemLocation;
        const response = await getItemLocationSettings(
          line.item.id,
          location.id
        );
        if (response.success) {
          itemLocation = response.data;
        } else {
          handleProgramError(
            new Error(i18n("error.CouldNotRetrieveItemLocationSettings"))
          );
        }
        const toBin = itemLocation ? itemLocation.defaultBin : null;
        return { ...line, toBin };
      }
      return { ...line, toBin: null };
    })
  );
}

export function clearItemDataFromLine(line) {
  const item = { id: null, name: null };
  const relatedRecords = { ...line.relatedRecords, item: null };
  return { ...line, item, relatedRecords, uom: null, itemDetails: {} };
}

export async function expandSalesItemGroup(
  lineToExpand,
  lineHandler,
  objectType
) {
  globalState.dispatch(editModalLoadingIndicatorOn());
  const bom = await getItemBom(lineToExpand.item.id);
  const bomIds = bom.map((bomItem) => bomItem.componentItem.id);
  const data = await getByIds(
    "item",
    bomIds,
    ITEM_CALCULATED_FIELDS[objectType]
  );

  lineHandler({ type: "delete", deleteAt: lineToExpand.lineNumber });
  const lines = [];
  bom.forEach((bomItem, index) => {
    const item = data.find(({ id }) => id === bomItem.componentItem.id);
    if (item) {
      const newLine = {
        ...lineToExpand,
        lineNumber: lineToExpand.lineNumber + index,
        item: bomItem.componentItem,
        description: item.description,
        weightunit: item.weightUnit,
        volumeunit: item.volumeUnit,
        listPrice: item.salesPrice,
        uom: getBaseUom(item.uoms),
        vendorPartNumber: item.vendorPartNumber,
        basePurchaseCost: item.basePurchaseCost,
        quantity: bomItem.quantity.times(lineToExpand.quantity),
        unitprice: item.baseSalesPrice,
        amount: EMPTY_LINE_ITEM[objectType].amount,
        relatedRecords: { item },
        itemDetails: {
          itemWeight: item.weight,
          itemVolume: item.volume,
          itemUoms: item.uoms,
          type: item.type,
          useMarkup: item.useMarkup,
          markupPercent: item.markupPercent,
          baseSalesPrice: item.baseSalesPrice,
          serialTracking: item.serialTracking,
          lotTracking: item.lotTracking,
        },
      };
      lines.push(newLine);
    }
  });
  setPageDirty();
  lineHandler({
    type: "insertMany",
    insertAt: lineToExpand.lineNumber,
    lines,
  });
  globalState.dispatch(editModalLoadingIndicatorOff());
}

export async function expandBomItemGroup(itemToExpand) {
  const bom = await getItemBom(itemToExpand.componentItem.id);
  const bomIds = bom.map((bomItem) => bomItem.componentItem.id);
  const data = await getByIds("item", bomIds);

  return await Promise.all(
    bom
      .map(async (bomItem) => {
        const item = data.find(({ id }) => id === bomItem.componentItem.id);
        const { costBasis } = await calculateBomCostBasis(item.id);
        if (item) {
          const newLine = {
            componentItem: bomItem.componentItem,
            description: item.description,
            quantity: bomItem.quantity.times(itemToExpand.quantity),
            cost: costBasis,
            total: costBasis.times(bomItem.quantity),
            typeOfItem: item.type,
          };
          return newLine;
        }
        return null;
      })
      .filter((bomItem) => bomItem)
  );
}
