import { i18n } from "services/i18nService";

import { initialErrorState } from "views/common";

const atleastOneLetterAndNumberRegex = /^(?=.*[A-Za-z])(?=.*\d).+$/;

export function isValid(record) {
  const { oldPassword, newPassword, confirmNewPassword } = record;

  const errors = initialErrorState();
  errors.entity = [];

  if (!oldPassword) {
    errors.messages.push(i18n("isValid.OldPassword"));
    errors.entity.push("oldPassword");
  }

  if (
    !newPassword ||
    newPassword.length < 8 ||
    !atleastOneLetterAndNumberRegex.test(newPassword)
  ) {
    errors.messages.push(i18n("isValid.NewPassword"));
    errors.entity.push("newPassword");
  }
  if (newPassword !== confirmNewPassword) {
    errors.messages.push(i18n("isValid.PasswordMismatch"));
    errors.entity.push("confirmNewPassword");
    errors.entity.push("newPassword");
  }

  return errors;
}
