import { useState } from "react";
import { useDispatch } from "react-redux";

import {
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";

import { FrmButtonGroup } from "components/formFields/FrmButtonGroup";
import { FrmEmail } from "components/formFields/FrmEmail";
import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";
import { FrmSelectScalarWithObjectOptions } from "components/formFields/FrmSelectScalarWithObjectOptions";
import { HoverHelp } from "components/utility/HoverHelp";

import { i18n } from "services/i18nService";
import { isValidSchedule } from "services/utility/reports";

import { SCHEDULED_REPORT_OPTIONS } from "views/Reports/reportConstants";

import { openAlert } from "globalState/alertSlice";

export function ScheduleDialog(props) {
  const {
    schedule: initialSchedule,
    scheduleDialogOpen,
    setScheduleDialogOpen,
    ftpServers,
    runImmediate,
    scheduleFunction,
    scheduleErrors,
    setScheduleErrors,
  } = props;

  const [schedule, setSchedule] = useState(initialSchedule);

  const dispatch = useDispatch();

  function doSchedule() {
    const errors = isValidSchedule(schedule);
    if (errors.messages?.length) {
      setScheduleErrors(errors);
      dispatch(
        openAlert({ type: "error", message: errors.messages?.join(" ") })
      );
      return;
    }
    scheduleFunction(schedule);
  }

  return (
    <Dialog
      open={scheduleDialogOpen}
      onClose={() => setScheduleDialogOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>{i18n("reports.Action.Schedule")}</DialogTitle>
      <DialogContent>
        <Box sx={{ display: "flex", flexDirection: "column", gap: 1 }}>
          <FrmButtonGroup
            label={i18n("reports.Schedule.Frequency")}
            value={{ id: schedule?.frequency }}
            onValueChange={(_, frequency) => {
              setSchedule((prev) => ({
                ...prev,
                frequency: frequency.id,
              }));
              setScheduleErrors((prev) => ({
                ...prev,
                entity: prev.entity.filter((entity) => entity !== "frequency"),
              }));
            }}
            options={SCHEDULED_REPORT_OPTIONS.FREQUENCY}
            name="frequency"
            error={scheduleErrors.entity?.includes("frequency")}
          />
          {schedule?.frequency === "Daily" && (
            <FrmSelectScalarWithObjectOptions
              label={i18n("reports.Schedule.Time")}
              options={SCHEDULED_REPORT_OPTIONS.TIMES}
              value={schedule?.timeOfDay}
              onValueChange={(_, timeOfDay) => {
                setSchedule((prev) => ({
                  ...prev,
                  timeOfDay,
                }));
              }}
              error={scheduleErrors.entity?.includes("timeOfDay")}
            />
          )}
          {schedule?.frequency === "Weekly" && (
            <FrmButtonGroup
              label={i18n("reports.Schedule.Day")}
              value={{ id: schedule?.dayOfWeek }}
              onValueChange={(_, dayOfWeek) => {
                setSchedule((prev) => ({
                  ...prev,
                  dayOfWeek: dayOfWeek.id,
                }));
                setScheduleErrors((prev) => ({
                  ...prev,
                  entity: prev.entity.filter(
                    (entity) => entity !== "dayOfWeek"
                  ),
                }));
              }}
              options={SCHEDULED_REPORT_OPTIONS.DAYS}
              name="dayOfWeek"
              error={scheduleErrors.entity?.includes("dayOfWeek")}
            />
          )}
          <FrmEmail
            label={i18n("reports.Schedule.Email")}
            value={schedule?.email}
            error={schedule?.emailValid}
            setEmailValid={(isValid) => (schedule.emailValid = isValid)}
            onBlur={(_, email) => {
              setSchedule((prev) => ({
                ...prev,
                email,
              }));
            }}
          />
          <FrmSelectFromObjects
            label={i18n("reports.Schedule.FTP")}
            options={ftpServers}
            value={schedule?.ftp || null}
            onValueChange={(_, ftp) => {
              setSchedule((prev) => ({
                ...prev,
                ftp,
              }));
            }}
          />
        </Box>
      </DialogContent>
      <DialogActions sx={{ display: "flex", justifyContent: "space-between" }}>
        <div>
          <Button color="secondary" onClick={() => runImmediate(schedule)}>
            {i18n("button.Test")}
          </Button>
          <HoverHelp
            title={i18n("reports.hoverHelp.RunThisReportNow")}
            sx={{ top: "0.4em", left: "0.25em" }}
          />
        </div>
        <div>
          <Button
            color="secondary"
            onClick={() => setScheduleDialogOpen(false)}
          >
            {i18n("button.Cancel")}
          </Button>
          <Button onClick={doSchedule} color="secondary">
            {i18n("global.Save")}
          </Button>
        </div>
      </DialogActions>
    </Dialog>
  );
}
