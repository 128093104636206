import { Money } from "classes/DecimalClasses";

import { FIELDS_TO_TRANSFORM } from "services/sosInventoryService/company/accountInfo/schema";
import { transformFields } from "services/utility/afterGet";

export function afterGet(record) {
  const newRecord = transformFields(record, FIELDS_TO_TRANSFORM);
  newRecord.plan.extraUserMonthlyPrice = new Money(
    record.plan.extraUserMonthlyPrice
  );

  return newRecord;
}
