import { useState, useEffect } from "react";
import { useDispatch } from "react-redux";

import { SelectButtonCombo } from "components/formFields/SelectButtonCombo";

import { i18n } from "services/i18nService";
import { afterTouchLine } from "services/sosInventoryService/moreTransaction/afterTouchLine";
import { getRecord } from "services/sosInventoryService/sosApi";
import { setPageDirty } from "services/utility/edit";
import { getAddOrderPlaceholder } from "services/utility/misc";
import { getBaseUom } from "services/utility/uoms";
import { getUomConversionFromUomReference } from "services/utility/uoms";

import { useCustomerData } from "hooks/useCustomerData";

import {
  editModalLoadingIndicatorOn,
  editModalLoadingIndicatorOff,
} from "globalState/loadingSlice";

import { OBJECT_TYPES } from "appConstants";

const RENTAL_RETURN = OBJECT_TYPES.RENTAL_RETURN.fullString;

export function AddRental(props) {
  const { customer, lineHandler } = props;

  const dispatch = useDispatch();

  const [value, setValue] = useState(null);
  const options = useCustomerData(customer, RENTAL_RETURN);

  // reset the selection whenever the customer changes
  useEffect(() => setValue(null), [customer]);

  async function addRental() {
    if (!value) {
      return;
    }

    const { objectType, txnId } = value;
    dispatch(editModalLoadingIndicatorOn());
    const sourceRental = await getRecord(objectType, txnId);
    const nonZeroLines = sourceRental.lines.filter(({ returned, quantity }) =>
      returned.lt(quantity)
    );

    const newLines = await Promise.all(
      nonZeroLines.map(async (line) => {
        const item = await getRecord("item", line.item.id);
        const uom = line.uom || getBaseUom(item.uoms);
        const conversion = getUomConversionFromUomReference(uom, item.uoms);
        const newLine = {
          ...line,
          linkedTransaction: {
            id: sourceRental.id,
            refNumber: sourceRental.number,
            lineNumber: line.lineNumber,
            transactionType: OBJECT_TYPES.RENTAL.shortString,
          },
          quantity: line.quantity.minus(line.returned),
          onhand: item.onhand.div(conversion),
          relatedRecords: { item },
          itemDetails: {
            itemWeight: item.weight,
            itemVolume: item.volume,
            itemUoms: item.uoms,
            serialTracking: item.serialTracking,
            lotTracking: item.lotTracking,
            type: item.type,
          },
        };
        delete newLine.id;
        return afterTouchLine(newLine, "quantity");
      })
    );

    if (newLines.length) {
      setPageDirty();
      lineHandler({ type: "append", newLines });
    }
    dispatch(editModalLoadingIndicatorOff());
  }

  return (
    <SelectButtonCombo
      key={options}
      disableClearable
      minWidth="12rem"
      maxWidth="12rem"
      selectLabel={i18n("frmLabel.AddRental")}
      buttonLabel={i18n("global.Add")}
      value={value}
      onValueChange={(_, { id }) => setValue(options.find((o) => o.id === id))}
      onClick={addRental}
      options={options}
      placeholder={getAddOrderPlaceholder(
        options,
        i18n("addOrder.SelectRental")
      )}
      disabled={!Boolean(customer)}
    />
  );
}
