// serials from the serial endpoint have a *number* property;
// serial values in the transaction record are standard reference
// objects, with a *name* property; these two properties, though
// named differently, are in fact the real-world serial number
import { useState, useEffect, useRef } from "react";
import { useDispatch } from "react-redux";

import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Grid2,
  Typography,
  TextField,
} from "@mui/material";

import { SERIAL_ENTRY_LIMIT } from "appConfig";

import { Decimal } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

import { ActionButton } from "components/ActionButton";
import { Upload } from "components/formFields/Serials/SerialsAggregator/Upload";
import { Chipper } from "components/utility/Chipper";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import { normalizeSerials } from "services/utility/serials";

import { openAlert } from "globalState/alertSlice";

import { theme } from "SosTheme";

const styles = { "& .MuiDialog-paper": { height: "90%" } };

export function SerialsEnter(props) {
  const {
    enumeratorOpen,
    onValueChange,
    onClose,
    value,
    quantity,
    autoNumber,
  } = props;

  const dispatch = useDispatch();
  const [uploadOpen, setUploadOpen] = useState(false);
  const [serials, setSerials] = useState();
  const [userEntry, setUserEntry] = useState("");

  const userEntryField = useRef(null);

  const largeQuantity =
    !(quantity instanceof Loading) &&
    quantity.gt(new Decimal(SERIAL_ENTRY_LIMIT));

  useEffect(() => {
    setSerials(value ? value.filter((v) => v.name) : null);
  }, [value]);

  function inputHasValue(input) {
    return input && input.replace(/\s/g, "").length;
  }

  function handleAdd(value) {
    if (serials.find(({ name }) => name === value)) {
      dispatch(
        openAlert({
          type: "error",
          message: i18n("alert.ErrorDuplicateSerial", {
            name: value,
          }),
        })
      );
    } else {
      const newSerials = [...serials, { id: 0, name: value }];
      setSerials(newSerials);
      setUserEntry("");
    }
  }

  function done() {
    const newSerials = normalizeSerials(serials, quantity, true, autoNumber);
    onValueChange("serials", newSerials);
    onClose();
  }

  function removeSerial(removedSerial) {
    setSerials((prevSerials) =>
      prevSerials.filter(({ name }) => name !== removedSerial.name)
    );
  }

  function onKeyUp(e) {
    const { target, key } = e;
    if ((key === "Tab" || key === "Enter") && inputHasValue(target?.value)) {
      handleAdd(userEntry);
    }
  }

  function onKeyDown(e) {
    if (e.key === "Tab") {
      e.preventDefault();
    }
  }

  function setFromUpload(uploadedSerials) {
    const newSerials = uploadedSerials.map((name) => ({ id: -1, name }));
    const currentAndNewSerials = serials.concat(newSerials);
    setSerials(currentAndNewSerials);
  }

  if (!serials) {
    return null;
  }

  const canAddMore = serials.length < quantity;

  return (
    <>
      <Dialog
        open={enumeratorOpen}
        onClose={onClose}
        fullWidth
        maxWidth="sm"
        sx={styles}
      >
        <DialogTitle>
          {largeQuantity ? i18n("serials.Enter") : i18n("serials.EnterUpload")}
        </DialogTitle>
        <DialogContent>
          <div>
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                alignItems: "center",
              }}
            >
              <TextField
                margin="dense"
                variant="outlined"
                value={userEntry}
                InputLabelProps={{ shrink: true }}
                sx={{ width: "85%", marginTop: "4px" }}
                onChange={(e) => setUserEntry(e.target.value)}
                onKeyUp={onKeyUp}
                onKeyDown={onKeyDown}
                inputRef={userEntryField}
                disabled={!canAddMore}
                data-testing="serialEnter"
              />
              <ActionButton
                onClick={() => {
                  handleAdd(userEntry);
                  userEntryField.current.focus();
                }}
                disabled={!canAddMore || !inputHasValue(userEntry)}
              >
                {i18n("global.Add")}
              </ActionButton>
            </div>
            <VSpace space={2} />
            <Typography variant="h5">
              {i18n("global.Entered")} (
              <span
                style={{
                  color: canAddMore ? "inherit" : theme.palette.accent,
                  fontWeight: canAddMore
                    ? "inherit"
                    : theme.typography.fontWeightBold,
                }}
              >
                {serials.length} of {quantity.toString()}
              </span>
              ) - {i18n("serials.ClickToRemove")}
            </Typography>
            <div style={{ maxHeight: "calc(100% - 80px", overflow: "auto" }}>
              {serials.map((serial) => (
                <Chipper key={serial.name} onClick={() => removeSerial(serial)}>
                  {serial.name}
                </Chipper>
              ))}
            </div>
          </div>
        </DialogContent>
        <DialogActions>
          <Grid2 container justifyContent="space-between">
            <Grid2>
              <Button color="secondary" onClick={() => setUploadOpen(true)}>
                {i18n("button.UploadCSV")}
              </Button>
            </Grid2>
            <Grid2>
              <Button color="secondary" onClick={onClose}>
                {i18n("button.Cancel")}
              </Button>
              <Button data-testing="done" color="secondary" onClick={done}>
                {i18n("button.Done")}
              </Button>
            </Grid2>
          </Grid2>
        </DialogActions>
      </Dialog>

      <Upload
        uploadOpen={uploadOpen}
        setUploadOpen={setUploadOpen}
        setSerials={setFromUpload}
      />
    </>
  );
}
