import { i18n } from "services/i18nService";

export const ExportPriceTiers = {
  showAddImportColumnsButton: true,
  userHasSufficientPrivilege: () => true,
  planIncludesReport: (plus) => plus,
  customReportNameFetchList: [],
  customFieldObjectTypes: [],
  columns: [
    { apiName: "Tier", default: true },
    { apiName: "Item", default: true },
    { apiName: "Price", default: true },
    { apiName: "Quantity", default: true },
    { apiName: "SosAccount", alwaysShow: true, nonImport: true },
  ],
  settings: [
    {
      label: i18n("reports.FilterLabel.Category"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Customer"),
      component: "CustomerFilter",
      apiName: "customer",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.KeywordSearch"),
      component: "FrmTextFieldWithHoverHelp",
      apiName: "nameContains2",
      defaultValue: null,
      helpText: i18n("reports.hoverHelp.PurchaseKeywordSearch"),
      type: "filter",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
