import { i18n } from "services/i18nService";

export const ExportBOMs = {
  showAddImportColumnsButton: true,
  userHasSufficientPrivilege: () => true,
  planIncludesReport: () => true,
  customReportNameFetchList: [],
  customFieldObjectTypes: [],
  columns: [
    { apiName: "Assembly", default: true },
    { apiName: "Component", default: true },
    { apiName: "Quantity", default: true, number: true },
    { apiName: "Notes", default: true },
    {
      apiName: "LineNumber",
      label: "Line Number",
      default: true,
    },
    {
      apiName: "SosAccount",
      label: "SOS Account",
      alwaysShow: true,
      nonImport: true,
    },
  ],
  settings: [
    {
      label: i18n("reports.FilterLabel.AssemblyNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ComponentNameContains"),
      component: "FrmTextField",
      apiName: "nameContains2",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedAssemblies"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showDeleted",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeDeletedAssemblies"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
