import { useState } from "react";
import { useParams, useNavigate } from "react-router-dom";

import SearchIcon from "@mui/icons-material/Search";
import {
  Box,
  Button,
  Fade,
  InputAdornment,
  InputBase,
  List,
  ListItem,
  Typography,
} from "@mui/material";

import { i18n } from "services/i18nService";
import { retrieveSettings } from "services/sosInventoryService/settings";
import { dirtyFnCheck } from "services/utility/misc";

import { useEditModal } from "hooks/useEditModal";

import { ConfigurationNav } from "views/Configuration/ConfigurationNav";
import {
  CONFIGURATION_NAVIGATION,
  ROUTE_PART_TO_LABEL_LEVEL1,
  ROUTE_PART_TO_LABEL_LEVEL2,
} from "views/Configuration/navigationStructure";
import { SETTINGS } from "views/Configuration/settings";

import { TOP_NAV_BAR_HEIGHT } from "appConstants";
import Fuse from "fuse.js";

const SECTION_SX = {
  fontSize: "0.6em",
  color: "grey",
  ml: "0.5em",
  textTransform: "uppercase",
};
// create the data for the search function
let settingsForSearch = [];
// start with the data for each individual setting
Object.entries(SETTINGS).forEach(([level1, value]) => {
  Object.entries(value).forEach(([level2, settings]) => {
    settings.forEach(({ explanationOverride, apiName }) => {
      const explanation = explanationOverride
        ? explanationOverride
        : i18n(`settings.${level1}.${level2}.${apiName}.explanation`);
      settingsForSearch.push({
        navPath: `/configuration/${level1}/${level2}#${apiName}`,
        label: i18n(`settings.${level1}.${level2}.${apiName}.label`),
        explanation,
        level1,
        level2,
      });
    });
  });
});
// add entries for each first and second level in the nav structure
CONFIGURATION_NAVIGATION.forEach(({ routePart, label, level2Selections }) => {
  settingsForSearch.push({ navPath: `/configuration/${routePart}`, label });

  level2Selections.forEach((level2) => {
    settingsForSearch.push({
      navPath: `/configuration/${routePart}/${level2.routePart}`,
      label: `${label} / ${level2.label}`,
      extraSearchTerms: level2.extraSearchTerms,
    });
  });
});

const fuseOptions = {
  keys: ["label", "explanation", "extraSearchTerms"],
  includeScore: true,
};
const fuse = new Fuse(settingsForSearch, fuseOptions);

function filteredSettings(searchString) {
  if (!searchString) {
    return settingsForSearch;
  }
  const fuseResults = fuse.search(searchString);
  const results = fuseResults.map(({ item }) => item);
  return results;
}

export function ConfigurationLayout(props) {
  const { level1 } = props;
  const { id, level2 } = useParams();

  const [searchString, setSearchString] = useState("");
  const [searchInputFocused, setSearchInputFocused] = useState(null);

  const navigate = useNavigate();

  useEditModal(level2, id);

  function exitConfiguration() {
    dirtyFnCheck(() => {
      retrieveSettings();
      navigate("/");
    });
  }

  return (
    <Fade in={true} timeout={1000}>
      <Box sx={{ position: "fixed", top: TOP_NAV_BAR_HEIGHT, width: "100%" }}>
        <Box
          sx={{
            display: "flex",
            justifyContent: "space-between",
            gap: 1,
            alignItems: "center",
            px: 2,
            py: 1,
            backgroundColor: "accent",
            width: "100%",
          }}
        >
          <Typography variant="h4" sx={{ color: "white" }}>
            {i18n("configuration.Configuration")}
          </Typography>

          <Box sx={{ position: "relative" }}>
            <InputBase
              id="searchField"
              value={searchString}
              onClick={() => setSearchInputFocused(true)}
              onBlur={() => {
                setSearchInputFocused(false);
                setSearchString("");
              }}
              onChange={(e) => setSearchString(e.target.value)}
              sx={{
                width: "20em",
                height: "2.5em",
                backgroundColor: "configurationSearch.searchInputField",
                color: "white",
                borderRadius: 1,
                paddingLeft: 1,
              }}
              placeholder="search..."
              startAdornment={
                <InputAdornment
                  position="start"
                  sx={{ color: "configurationSearch.searchIcon" }}
                >
                  <SearchIcon />
                </InputAdornment>
              }
            />

            <List
              sx={{
                display: searchInputFocused ? "inherit" : "none",
                maxHeight: "50vh",
                width: "30em",
                position: "absolute",
                top: "100%",
                left: "0px",
                backgroundColor: "white",
                zIndex: "dropDownMenu",
                borderRadius: 1,
                overflowY: "scroll",
              }}
            >
              {filteredSettings(searchString).map(
                ({ label, navPath, level1, level2 }, i) => (
                  <ListItem
                    key={i}
                    onMouseDown={(e) => e.preventDefault()}
                    onClick={() => {
                      setSearchInputFocused(false);
                      setSearchString("");
                      document.getElementById("searchField").blur();
                      navigate(navPath);
                    }}
                    sx={{
                      "&:hover": {
                        backgroundColor: "configurationSearch.selectedItem",
                        cursor: "pointer",
                      },
                    }}
                  >
                    <Box>
                      <Typography component="span">{label}</Typography>
                      {level1 && (
                        <Typography component="span" sx={SECTION_SX}>
                          {ROUTE_PART_TO_LABEL_LEVEL1[level1]}
                        </Typography>
                      )}
                      {level2 && (
                        <Typography component="span" sx={SECTION_SX}>
                          {`→ ${ROUTE_PART_TO_LABEL_LEVEL2[level2]}`}
                        </Typography>
                      )}
                    </Box>
                  </ListItem>
                )
              )}
            </List>
          </Box>

          <Button
            size="small"
            variant="outlined"
            sx={{
              backgroundColor: "exitConfiguration",
              borderColor: "white",
              color: "white",
              underline: "none",
            }}
            onClick={exitConfiguration}
          >
            {i18n("global.ExitConfiguration")}
          </Button>
        </Box>
        {/* level2 will be set for lists, and null for settings pages; for settings
      pages, level2 will come in settingLevel2 from the router */}
        <ConfigurationNav level1={level1} level2={level2} />
      </Box>
    </Fade>
  );
}
