import { useEffect, useState } from "react";

import { get } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";
import { formatName } from "services/utility/formatting";

export function useSalesReps() {
  const [records, setRecords] = useState(null);

  useEffect(() => {
    async function retrieveRecords() {
      const { success, data, message } = await get("salesrep", {
        inTransaction: true,
      });
      if (success) {
        const salesReps = data.records.map((rep) => ({
          ...rep,
          name: formatName(rep),
        }));
        setRecords(salesReps);
      } else {
        handleProgramError(
          new Error(
            `useRelated | unsuccessful call to getAll, message: ${message}`
          )
        );
      }
    }
    retrieveRecords();
  }, []);

  return records;
}
