export function beforeSave(record) {
  const newRecord = {
    ...record,
    prefix: record.paymentNumber.prefix,
    nextNumber: record.paymentNumber.nextNumber,
    templateId: record.template.id,
  };

  delete newRecord.paymentNumber;

  return newRecord;
}
