import { i18n } from "services/i18nService";

export const FIELDS_TO_TRANSFORM = {
  DECIMAL_FIELDS: [],
  MONEY_FIELDS: [
    "subscription",
    "billableUsersCharge",
    "billableIntegrationsCharge",
    "billableSmsUsersCharge",
    "discount",
    "salesTax",
  ],
  DATE_FIELDS: [],
  DATETIME_FIELDS: [],
  NON_UTC_DATETIME_FIELDS: [],
};

export const CANCELLATION_REASON_OPTIONS = [
  {
    id: 1,
    name: i18n("accountInformation.MovedToAnotherSoftware"),
    reasonString: "Moved to another software",
  },
  {
    id: 2,
    name: i18n("accountInformation.BusinessClosed"),
    reasonString: "Business closed",
  },
  {
    id: 3,
    name: i18n("accountInformation.CompanySold"),
    reasonString: "Company sold",
  },
  {
    id: 4,
    name: i18n("accountInformation.NoLongerUsing"),
    reasonString: "No longer using SOS Inventory",
  },
  {
    id: 5,
    name: i18n("accountInformation.CostConcerns"),
    reasonString: "Cost concerns",
  },
  {
    id: 6,
    name: i18n("accountInformation.MovingToNewAccount"),
    reasonString: "Moving to new SOS Inventory account",
  },
  {
    id: 7,
    name: i18n("accountInformation.FeaturesMissingSoftwareIssues"),
    reasonString: "Features missing / Software Issues",
  },
  { id: 8, name: i18n("accountInformation.Other"), reasonString: "Other" },
];
