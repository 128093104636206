import { useDispatch } from "react-redux";

import { Card, Box } from "@mui/material";

import { IMAGE_DIMENSION } from "appConfig";

import { ActionButton } from "components/ActionButton";
import { CardHeader } from "components/formFields/formCards/CardHeader";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";
import {
  readImage,
  downloadImage,
  getImageType,
} from "services/utility/images";

import { openAlert } from "globalState/alertSlice";

const IMAGE_DIMENSTION_NUMBER = parseInt(IMAGE_DIMENSION);

export function FrmAddLogo(props) {
  const {
    name,
    value: imageAsBase64String,
    onValueChange,
    logoWidth,
    logoHeight,
    logoImageType,
  } = props;

  const dispatch = useDispatch();

  function updateRecordWithImage(imageAsBase64String, _, fileType) {
    onValueChange(name, imageAsBase64String);
    onValueChange("logoImageType", getImageType(fileType));
  }

  async function handleImageSelection(e) {
    const { success, unsupportedFileType } = readImage(
      e.target.files[0],
      updateRecordWithImage,
      true // exclude BMP flag
    );

    if (!success) {
      const message = i18n("file.FileTypeNotSupported", {
        fileType: unsupportedFileType,
      });
      dispatch(openAlert({ type: "error", message }));
      return;
    }
    // if successful, readImage will call updateRecordWithImage with the
    // image strings (original and scaled)
  }

  function handleDownload() {
    downloadImage(imageAsBase64String, `logo.${logoImageType}`);
  }

  function handleClear() {
    onValueChange(name, null);
    onValueChange("logoImageType", null);
  }

  const imageScaled =
    IMAGE_DIMENSTION_NUMBER !== +logoHeight ||
    IMAGE_DIMENSTION_NUMBER !== +logoWidth;

  return (
    <Card
      variant="outlined"
      sx={{
        marginTop: "1.0em",
        marginBottom: "1.0em",
        padding: "1.0em",
        backgroundColor: "inherit",
      }}
    >
      {imageAsBase64String && imageScaled && (
        <CardHeader text={i18n("file.LogoScaledToFit")} />
      )}
      <Box sx={{ display: "flex", alignItems: "center" }}>
        <div
          style={{
            width: imageAsBase64String ? IMAGE_DIMENSION : "auto",
            height: imageAsBase64String ? IMAGE_DIMENSION : "auto",
          }}
        >
          {imageAsBase64String && (
            <img
              src={"data:image;base64, " + imageAsBase64String}
              alt="Item"
              style={{ maxWidth: IMAGE_DIMENSION, maxHeight: IMAGE_DIMENSION }}
            />
          )}
        </div>
        {imageAsBase64String && (
          <ActionButton
            component="label"
            onClick={handleDownload}
            sx={{ marginLeft: "1.0em" }}
          >
            {i18n("settings.company.logo.DownloadLogo")}
          </ActionButton>
        )}
      </Box>
      <VSpace space={1} />
      <ActionButton component="label" htmlFor="itemImage">
        {imageAsBase64String
          ? i18n("settings.company.logo.ChangeLogo")
          : i18n("settings.company.logo.AddLogo")}
        <input
          id="itemImage"
          type="file"
          style={{ display: "none" }}
          onChange={handleImageSelection}
        />
      </ActionButton>
      {imageAsBase64String && (
        <ActionButton
          component="label"
          onClick={handleClear}
          sx={{ marginLeft: "1.0em" }}
        >
          {i18n("settings.company.logo.ClearLogo")}
        </ActionButton>
      )}
    </Card>
  );
}
