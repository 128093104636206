import { i18n } from "services/i18nService";
import { isBetaEnvironment } from "services/utility/misc";

import { OBJECT_TYPES, USER_PRIVILEGE } from "appConstants";

export const NON_GATED_FOR_BETA_CONFIG_NAV = [
  {
    routePart: "list",
    label: i18n("settings.list.label"),
    privilege: [
      USER_PRIVILEGE.editSalesReps,
      USER_PRIVILEGE.viewWorkers,
      USER_PRIVILEGE.editWorkers,
    ],
    level2Selections: [
      {
        routePart: OBJECT_TYPES.ALERT.fullString,
        label: i18n("objectType.alert.SentencePlural"),
        planIncludesEntryType: (_, pro) => pro,
      },
      {
        routePart: OBJECT_TYPES.AOP_RULE.fullString,
        label: i18n("objectType.aoprule.SentencePlural"),
        planIncludesEntryType: (_, pro) => pro,
      },
      {
        routePart: OBJECT_TYPES.CHANNEL.fullString,
        label: i18n("objectType.channel.SentencePlural"),
        extraSearchTerms: [i18n("configuration.searchTerm.ShowOn")],
      },
      {
        routePart: OBJECT_TYPES.CUSTOM_FIELD.fullString,
        label: i18n("objectType.customfield.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.CUSTOMER_MESSAGE.fullString,
        label: i18n("objectType.customermessage.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.CUSTOMER_TYPE.fullString,
        label: i18n("objectType.customertype.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.DOCUMENT.fullString,
        label: i18n("objectType.document.SentencePlural"),
        planIncludesEntryType: (_, pro) => pro,
        extraSearchTerms: [i18n("configuration.searchTerm.AttachedTo")],
      },
      {
        routePart: OBJECT_TYPES.FORM_TEMPLATE.fullString,
        label: i18n("objectType.formtemplate.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.LOCATION.fullString,
        label: i18n("objectType.location.SentencePlural"),
        extraSearchTerms: [
          i18n("configuration.searchTerm.Address"),
          i18n("configuration.searchTerm.BinTracking"),
          i18n("configuration.searchTerm.NonNettable"),
          i18n("configuration.searchTerm.SalesTaxRate"),
        ],
      },
      {
        routePart: OBJECT_TYPES.ORDER_STAGE.fullString,
        label: i18n("objectType.orderstage.SentencePlural"),
        extraSearchTerms: [i18n("configuration.searchTerm.SortOrder")],
      },
      {
        routePart: OBJECT_TYPES.PAYMENT_METHOD.fullString,
        label: i18n("objectType.paymentmethod.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.PRICE_TIER.fullString,
        label: i18n("objectType.pricetier.SentencePlural"),
        planIncludesEntryType: (plus) => plus,
      },
      {
        routePart: OBJECT_TYPES.PRIORITY.fullString,
        label: i18n("objectType.priority.SentencePlural"),
        extraSearchTerms: [i18n("configuration.searchTerm.SortOrder")],
      },
      {
        routePart: OBJECT_TYPES.PROCESS_TEMPLATE.fullString,
        label: i18n("objectType.processtemplate.SentencePlural"),
        planIncludesEntryType: (_, pro) => pro,
        extraSearchTerms: [
          i18n("configuration.searchTerm.Outputs"),
          i18n("configuration.searchTerm.Inputs"),
        ],
      },
      {
        routePart: OBJECT_TYPES.SALES_REP.fullString,
        label: i18n("objectType.salesrep.SentencePlural"),
        privilege: USER_PRIVILEGE.editSalesReps,
        extraSearchTerms: [
          i18n("configuration.searchTerm.FirstName"),
          i18n("configuration.searchTerm.LastName"),
          i18n("configuration.searchTerm.Commission"),
          i18n("configuration.searchTerm.RestrictCustomers"),
        ],
      },
      {
        routePart: OBJECT_TYPES.SHIP_METHOD.fullString,
        label: i18n("objectType.shipmethod.SentencePlural"),
        planIncludesEntryType: (_, pro) => pro,
        extraSearchTerms: [
          i18n("configuration.searchTerm.CarrierCode"),
          i18n("configuration.searchTerm.ShipStationCarrier"),
          i18n("configuration.searchTerm.ShipStationService"),
        ],
      },
      {
        routePart: OBJECT_TYPES.TAG.fullString,
        label: i18n("objectType.tag.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.TEMPLATE_LIBRARY.fullString,
        label: i18n("objectType.templatelibrary.SentencePlural"),
        hideInLevel2List: true,
      },
      {
        routePart: OBJECT_TYPES.TERM.fullString,
        label: i18n("objectType.terms.SentencePlural"),
        extraSearchTerms: [
          i18n("configuration.searchTerm.DueDays"),
          i18n("configuration.searchTerm.DiscountDays"),
          i18n("configuration.searchTerm.DiscountPercent"),
          i18n("configuration.searchTerm.DayOfMonthDue"),
          i18n("configuration.searchTerm.DueNextMonthDays"),
          i18n("configuration.searchTerm.DiscountDayOfMonth"),
          i18n("configuration.searchTerm.DateDiscountPercent"),
        ],
      },
      {
        routePart: OBJECT_TYPES.UOM.fullString,
        label: i18n("objectType.uom.SentencePlural"),
        planIncludesEntryType: (plus) => plus,
        extraSearchTerms: [i18n("configuration.searchTerm.Abbreviation")],
      },
      {
        routePart: OBJECT_TYPES.VENDOR_MESSAGE.fullString,
        label: i18n("objectType.vendormessage.SentencePlural"),
      },
      {
        routePart: OBJECT_TYPES.VENDOR_ITEM.fullString,
        label: i18n("objectType.vendoritem.SentencePlural"),
        planIncludesEntryType: (plus) => plus,
        extraSearchTerms: [
          i18n("configuration.searchTerm.VendorPartNumber"),
          i18n("configuration.searchTerm.LeadTime"),
        ],
      },
      {
        routePart: OBJECT_TYPES.WARRANTY.fullString,
        label: i18n("objectType.warranty.SentencePlural"),
        planIncludesEntryType: (plus) => plus,
        extraSearchTerms: [i18n("configuration.searchTerm.Duration")],
      },
      {
        routePart: OBJECT_TYPES.WORK_CENTER.fullString,
        label: i18n("objectType.workcenter.SentencePlural"),
        planIncludesEntryType: (_, pro) => pro,
        extraSearchTerms: [
          i18n("configuration.searchTerm.SortOrder"),
          i18n("configuration.searchTerm.AssetAccount"),
        ],
      },
      {
        routePart: OBJECT_TYPES.WORKER.fullString,
        label: i18n("objectType.worker.SentencePlural"),
        privilege: [USER_PRIVILEGE.viewWorkers, USER_PRIVILEGE.editWorkers],
        planIncludesEntryType: (_, pro) => pro,
        extraSearchTerms: [
          i18n("configuration.searchTerm.FirstName"),
          i18n("configuration.searchTerm.LastName"),
        ],
      },
    ],
  },
  {
    routePart: "company",
    label: i18n("settings.company.label"),
    level2Selections: [
      {
        routePart: "general",
        label: i18n("settings.general.label"),
      },
      {
        routePart: "accounting",
        label: i18n("settings.accounting.label"),
      },
      {
        routePart: "nameAndAddress",
        label: i18n("settings.nameAndAddress.label"),
      },
      {
        routePart: "logo",
        label: i18n("settings.logo.label"),
      },
      {
        routePart: "user",
        label: i18n("settings.userAdministration.label"),
        extraSearchTerms: [i18n("configuration.searchTerm.UserEmail")],
      },
    ],
  },
  {
    routePart: "inventory",
    label: i18n("settings.inventory.label"),
    privilege: USER_PRIVILEGE.inventorySettings,
    level2Selections: [
      {
        routePart: "general",
        label: i18n("settings.general.label"),
        privilege: USER_PRIVILEGE.inventorySettings,
      },
      {
        routePart: "accounts",
        label: i18n("settings.accounts.label"),
        privilege: USER_PRIVILEGE.inventorySettings,
      },
      {
        routePart: "transactions",
        label: i18n("settings.transactions.label"),
        privilege: USER_PRIVILEGE.inventorySettings,
      },
      {
        routePart: "barcode",
        label: i18n("settings.barcode.label"),
        privilege: USER_PRIVILEGE.inventorySettings,
      },
    ],
  },
  {
    routePart: "purchasing",
    label: i18n("settings.purchasing.label"),
    privilege: USER_PRIVILEGE.purchasingSettings,
    level2Selections: [
      {
        routePart: "general",
        label: i18n("settings.general.label"),
        privilege: USER_PRIVILEGE.purchasingSettings,
      },
      {
        routePart: "purchaseOrders",
        label: i18n("objectType.purchaseorder.SentencePlural"),
        privilege: USER_PRIVILEGE.purchasingSettings,
      },
      {
        routePart: "itemReceipts",
        label: i18n("objectType.itemreceipt.SentencePlural"),
        privilege: USER_PRIVILEGE.purchasingSettings,
      },
      {
        routePart: "returnsToVendors",
        label: i18n("objectType.returntovendor.SentencePlural"),
        privilege: USER_PRIVILEGE.purchasingSettings,
      },
    ],
  },
  {
    routePart: "sales",
    label: "Sales",
    privilege: USER_PRIVILEGE.salesSettings,
    level2Selections: [
      {
        routePart: "general",
        label: i18n("settings.general.label"),
        privilege: USER_PRIVILEGE.salesSettings,
      },
      {
        routePart: "salesOrders",
        label: i18n("objectType.salesorder.SentencePlural"),
        privilege: USER_PRIVILEGE.salesSettings,
      },
      {
        routePart: "estimates",
        label: i18n("objectType.estimate.SentencePlural"),
        privilege: USER_PRIVILEGE.salesSettings,
      },
      {
        routePart: "invoices",
        label: i18n("objectType.invoice.SentencePlural"),
        privilege: USER_PRIVILEGE.salesSettings,
      },
      {
        routePart: "salesReceipts",
        label: i18n("objectType.salesreceipt.SentencePlural"),
        privilege: USER_PRIVILEGE.salesSettings,
      },
      {
        routePart: "rmas",
        label: i18n("objectType.rma.SentencePlural"),
        privilege: USER_PRIVILEGE.salesSettings,
      },
      {
        routePart: "returns",
        label: i18n("objectType.return.SentencePlural"),
        privilege: USER_PRIVILEGE.salesSettings,
      },
      {
        routePart: "customerPortal",
        label: i18n("settings.customerPortal.label"),
        privilege: USER_PRIVILEGE.salesSettings,
      },
    ],
  },
  {
    routePart: "fulfillment",
    label: i18n("settings.fulfillment.label"),
    level2Selections: [
      {
        routePart: "shipments",
        label: i18n("objectType.shipment.SentencePlural"),
      },
      {
        routePart: "pickTickets",
        label: i18n("objectType.pickticket.SentencePlural"),
      },
    ],
  },
  {
    routePart: "production",
    label: i18n("settings.production.label"),
    level2Selections: [
      {
        routePart: "workOrders",
        label: i18n("objectType.workorder.SentencePlural"),
      },
      {
        routePart: "jobs",
        label: i18n("objectType.job.SentencePlural"),
      },
      {
        routePart: "builds",
        label: i18n("objectType.build.SentencePlural"),
      },
      {
        routePart: "processes",
        label: i18n("objectType.process.SentencePlural"),
      },
    ],
  },
  {
    routePart: "rentals",
    label: i18n("objectType.rental.SentencePlural"),
    level2Selections: [
      {
        routePart: "rentals",
        label: i18n("objectType.rental.SentencePlural"),
      },
      {
        routePart: "rentalReturns",
        label: i18n("objectType.rentalreturn.SentencePlural"),
      },
    ],
  },
  {
    routePart: "payments",
    label: i18n("settings.payments.label"),
    setting: "paymentsBoarded",
    level2Selections: [
      {
        routePart: "general",
        label: i18n("settings.general.label"),
        setting: "paymentsBoarded",
      },
    ],
  },
];

const GATED_FOR_BETA_CONFIG_NAV = [
  {
    routePart: "connections",
    label: i18n("settings.connections.label"),
    level2Selections: [
      {
        routePart: "quickbooks",
        label: i18n("settings.quickbooks.label"),
      },
      {
        routePart: "shipstation",
        label: i18n("settings.shipstation.label"),
      },
      {
        routePart: "ups",
        label: i18n("settings.ups.label"),
      },
      {
        routePart: "cart",
        label: i18n("settings.cart.label"),
      },
      {
        routePart: "ftp",
        label: i18n("settings.ftp.label"),
      },
    ],
  },
];

// TODO: remove this isBetaEnvironment ternary once
// connections and payments configuration is complete
export const CONFIGURATION_NAVIGATION = isBetaEnvironment
  ? NON_GATED_FOR_BETA_CONFIG_NAV
  : [...NON_GATED_FOR_BETA_CONFIG_NAV, ...GATED_FOR_BETA_CONFIG_NAV];

export function getLevel2Selections(level1RoutePart) {
  return CONFIGURATION_NAVIGATION.find(
    ({ routePart }) => routePart === level1RoutePart
  ).level2Selections;
}

export function getRoutePart(level2Route) {
  return CONFIGURATION_NAVIGATION.find(({ level2Selections }) =>
    level2Selections.find(({ routePart }) => routePart === level2Route)
  ).level2Selections.find(({ routePart }) => routePart === level2Route);
}

export function getLevel1Privilege(level1RoutePart) {
  return CONFIGURATION_NAVIGATION.find(
    ({ routePart }) => routePart === level1RoutePart
  )?.privilege;
}

export function getLevel1Setting(level1RoutePart) {
  return CONFIGURATION_NAVIGATION.find(
    ({ routePart }) => routePart === level1RoutePart
  )?.setting;
}

let level1Mappings = {};
CONFIGURATION_NAVIGATION.forEach((entry) => {
  level1Mappings[entry.routePart] = entry.label;
});
export const ROUTE_PART_TO_LABEL_LEVEL1 = level1Mappings;

let level2Mappings = {};
CONFIGURATION_NAVIGATION.forEach((level1Entry) => {
  level1Entry.level2Selections.forEach((level2Entry) => {
    level2Mappings[level2Entry.routePart] = level2Entry.label;
  });
});
export const ROUTE_PART_TO_LABEL_LEVEL2 = level2Mappings;
