import { Decimal } from "classes/DecimalClasses";

import { getUomConversion } from "services/utility/afterTouchLine";

// when this field (the key) changes, we should run the (value) list of
// updater functions
const UPDATERS = {
  uom: [updateOnHand, updateOnSO],
};

// this should be called each time an item receipt line item is modified;
// it returns a new copy of the line, with any necessary changes made
export function afterTouchOutputLine(line, changedField, record) {
  if (!UPDATERS[changedField]) {
    return line;
  }
  let newLine = { ...line };

  UPDATERS[changedField].forEach((updater) => {
    newLine = updater(newLine, record);
  });

  return newLine;
}

function updateOnHand(line) {
  const {
    uom,
    relatedRecords: { item },
    itemDetails,
  } = line;

  // calculate the conversion using the item details UOM data
  const conversion = getUomConversion(itemDetails, uom);

  const onhand = item.id ? item.onhand.div(conversion) : new Decimal(0);
  return { ...line, onhand };
}

function updateOnSO(line) {
  const {
    uom,
    relatedRecords: { item },
    itemDetails,
  } = line;

  // calculate the conversion using the item details UOM data
  const conversion = getUomConversion(itemDetails, uom);

  const onSO = item.id ? item.onSO.div(conversion) : new Decimal(0);
  return { ...line, onSO };
}
