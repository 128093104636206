import { i18n } from "services/i18nService";

export const ExportVendorItemCatalog = {
  userHasSufficientPrivilege: () => true,
  planIncludesReport: (plus) => plus,
  customReportNameFetchList: [],
  customFieldObjectTypes: [],
  columns: [
    { apiName: "Vendor", default: true },
    { apiName: "Item", default: true },
    { apiName: "Price", default: true, number: true },
    { apiName: "VendorPartNumber", label: "Vendor Part Number" },
  ],
  settings: [
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
