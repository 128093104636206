import { Decimal } from "classes/DecimalClasses";

export const OUTPUT_ZERO_TOTALS = {
  quantity: new Decimal(0),
};

export function updateOutputTotals(lines, setTotals) {
  if (!lines || lines.length === 0) {
    setTotals(OUTPUT_ZERO_TOTALS);
    return;
  }
  const quantity = lines.reduce((total, line) => {
    return total.plus(line.quantity);
  }, new Decimal(0));
  setTotals({ quantity });
}
