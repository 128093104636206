import { i18n } from "services/i18nService";

import { SUB_COMPONENT } from "views/Reports/reportConstants";

export const ExportAdjustments = {
  userHasSufficientPrivilege: () => true,
  planIncludesReport: () => true,
  customReportNameFetchList: [],
  customFieldObjectTypes: ["adjustment"],
  columns: [
    { apiName: "AdjustmentNumber", label: "Adjustment #", default: true },
    { apiName: "Location", default: true },
    { apiName: "Date", default: true },
    { apiName: "Account" },
    { apiName: "Item", default: true },
    { apiName: "SKU" },
    { apiName: "QuantityDiff", label: "Quantity", default: true, number: true },
    { apiName: "UOM" },
    { apiName: "ValueDiff", label: "Value", number: true },
    { apiName: "Description", default: true },
    { apiName: "Memo", label: "Comment" },
    { apiName: "LineNumber", label: "Line Number" },
    { apiName: "Department" },
    { apiName: "Lot" },
    { apiName: "Bin" },
    { apiName: "Job" },
    { apiName: "Class" },
    { apiName: "Category" },
    { apiName: "Archived" },
    { apiName: "Starred" },
  ],
  settings: [
    {
      apiName: "date1",
      macroApiName: "date1Macro",
      headerText: i18n("filter.heading.from"),
      component: "DateFilter",
      label: i18n("reports.FilterLabel.FromDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date1Macro",
      component: SUB_COMPONENT,
      defaultValue: null,
      type: "filter",
    },
    {
      apiName: "date2",
      macroApiName: "date2Macro",
      component: "DateFilter",
      label: i18n("reports.FilterLabel.ToDate"),
      datePrefix: "endOf",
      defaultValue: () => new Date(),
      type: "filter",
    },
    {
      apiName: "date2Macro",
      defaultValue: null,
      component: SUB_COMPONENT,
      type: "filter",
    },
    {
      label: i18n("filter.Locations"),
      component: "LocationsFilter",
      apiName: "locations",
      defaultValue: null,
      placeholder: i18n("reports.placeholder.DefaultIsAll", {
        type: i18n("reports.locations"),
      }),
      type: "filter",
    },
    {
      label: i18n("filter.Item"),
      component: "ItemFilter",
      apiName: "item",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Vendor"),
      component: "VendorFilter",
      apiName: "vendor",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("filter.Categories"),
      component: "CategoryFilter",
      apiName: "categories",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Class"),
      component: "ClassFilter",
      apiName: "class",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.Department"),
      component: "DepartmentFilter",
      apiName: "department",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.FilterLabel.ItemNameContains"),
      component: "FrmTextField",
      apiName: "nameContains2",
      defaultValue: null,
      type: "filter",
    },
    {
      label: i18n("reports.KeywordSearch"),
      component: "FrmTextFieldWithHoverHelp",
      apiName: "nameContains",
      defaultValue: null,
      helpText: i18n("reports.hoverHelp.CommentKeywordSearch"),
      type: "filter",
    },
    {
      apiName: "showArchived",
      defaultValue: false,
      label: i18n("reports.OptionLabel.IncludeArchivedTransactions"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "showFromTo",
      defaultValue: false,
      label: i18n("reports.OptionLabel.ShowFromAndToDates"),
      component: "FrmCheckbox",
      type: "option",
    },
    {
      apiName: "header",
      defaultValue: "",
      label: i18n("global.Header"),
      component: "FrmTextField",
      type: "option",
    },
    {
      apiName: "footer",
      defaultValue: "",
      label: i18n("global.Footer"),
      component: "FrmTextField",
      type: "option",
    },
  ],
};
