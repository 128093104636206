import { Decimal } from "classes/DecimalClasses";

import { i18n } from "services/i18nService";

export function quantitySerialsIsValid(
  errors,
  lines,
  autoSerialLots,
  lineType = "line"
) {
  const newErrors = { ...errors };

  lines.forEach(({ lineNumber, quantity, serials, relatedRecords }) => {
    // if not auto-numbered, number of serial numbers must match quantity
    if (relatedRecords.item?.serialTracking && !autoSerialLots) {
      const nonBlankSerials = serials
        ? serials.filter(({ name }) => name !== "")
        : [];
      if (!new Decimal(nonBlankSerials.length).eq(quantity)) {
        newErrors.messages.push(
          i18n("isValid.LineItem.QuantitySerialsMismatch", {
            lineNumber,
            lineType,
          })
        );
        newErrors[lineType][lineNumber].push("quantity", "serials");
      }
    }
  });

  return newErrors;
}
