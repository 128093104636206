import { useState } from "react";
import { useSelector } from "react-redux";

import Delete from "@mui/icons-material/Delete";

import { ITEM_CALCULATED_FIELDS } from "appConfig";

import { Decimal } from "classes/DecimalClasses";

import { QuickAddItem } from "components/QuickAddItem";
import { LineTableCell } from "components/formFields/LineTableCell";
import { LineClass } from "components/formFields/line/LineClass";
import { LineDescription } from "components/formFields/line/LineDescription";
import { LineInventoryItem } from "components/formFields/line/LineInventoryItem";
import { LineJobWorkcenter } from "components/formFields/line/LineJobWorkcenter";
import { LineQuantity } from "components/formFields/line/LineQuantity";
import { LineReturned } from "components/formFields/line/LineReturned";
import { LineUom } from "components/formFields/line/LineUom";
import { LineVolumeAndVolumeUnit } from "components/formFields/line/LineVolumeAndVolumeUnit";
import { LineWeightAndWeightUnit } from "components/formFields/line/LineWeightAndWeightUnit";

import {
  createLineFromItem,
  postItemAndCreateLineItem,
  getItemRecord,
} from "services/sosInventoryService/domainLogic";
import { getEmptyRecord as getEmptyItemRecord } from "services/sosInventoryService/item/schema";
import { afterTouchLine } from "services/sosInventoryService/moreTransaction/afterTouchLine";
import { updateLineWithItem } from "services/sosInventoryService/moreTransaction/domainLogic";
import { setPageDirty } from "services/utility/edit";
import { isLineFieldInError } from "services/utility/errors";
import { TYPES } from "services/utility/schema";

import { theme } from "SosTheme";
import { OBJECT_TYPES, ITEM_QUICKLIST_FORM_TYPES } from "appConstants";
import { LINE_ITEM_FIELDS, EMPTY_LINE_ITEM } from "editConfig";

const OBJECT_TYPE = OBJECT_TYPES.RMA.fullString;

// created this wrapper to make drag and drop code easier to see
export function LineItem(props) {
  return <LineItemDetails {...props} />;
}

function LineItemDetails(props) {
  const {
    line,
    record,
    lineHandler,
    expandItemGroup,
    items,
    classes,
    jobWorkcenters,
    itemsCount,
    addItem,
    lineItemsErrors: errors,
    setErrors,
  } = props;
  const { date } = record;

  const [addNewItem, setAddNewItem] = useState(false);
  const [newItemName, setNewItemName] = useState("");
  const [lineLoading, setLineLoading] = useState(false);

  // SETTINGS
  const showWeightOnSales = useSelector(
    (state) => state.userCompanySettings.settings.showWeightOnSales
  );
  const showVolumeOnSales = useSelector(
    (state) => state.userCompanySettings.settings.showVolumeOnSales
  );
  const defaultAssetAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultAssetAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultCOGSAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultCOGSAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultExpenseAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultExpenseAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultIncomeAccount = useSelector(
    (state) => state.userCompanySettings.settings.defaultIncomeAccount,
    (prev, curr) => curr?.id === prev?.id
  );
  const defaultTaxCode = useSelector(
    (state) => state.userCompanySettings.settings.defaultTaxCode,
    (prev, curr) => curr?.id === prev?.id
  );

  function isInError(field) {
    return isLineFieldInError(field, line.lineNumber, errors);
  }

  function handleValueChange(fieldName, newValue) {
    let newLine;
    const lineItemFields = LINE_ITEM_FIELDS[OBJECT_TYPE];
    if (lineItemFields[TYPES.reference].includes(fieldName)) {
      newLine = { ...line, [fieldName]: { id: newValue } };
    } else if (lineItemFields[TYPES.decimal].includes(fieldName)) {
      newLine = { ...line, [fieldName]: new Decimal(newValue || 0) };
    } else {
      newLine = { ...line, [fieldName]: newValue };
    }
    setPageDirty();
    lineHandler({
      type: "update",
      updatedLine: afterTouchLine(newLine, fieldName),
    });
  }

  function handleReferenceChange(fieldName, newValue) {
    const newLine = { ...line, [fieldName]: newValue };
    setPageDirty();
    lineHandler({
      type: "update",
      updatedLine: afterTouchLine(newLine, fieldName),
    });
  }

  async function handleItemChange(newItem, userInput) {
    if (!newItem) {
      const updatedLine = {
        ...EMPTY_LINE_ITEM[OBJECT_TYPE],
        lineNumber: line.lineNumber,
      };
      setPageDirty();
      return lineHandler({ type: "update", updatedLine });
    }
    if (newItem?.id === "add") {
      return openAddItem(userInput);
    }
    setLineLoading(true);
    const item = await getItemRecord(
      newItem.id,
      "",
      date,
      ITEM_CALCULATED_FIELDS[OBJECT_TYPE]
    );
    const newLine = await updateLineWithItem(item, line, record);
    const updatedLine = afterTouchLine(newLine, "item");
    setPageDirty();
    lineHandler({ type: "update", updatedLine });
    setLineLoading(false);
  }

  function handleUomChange(_, uom) {
    setPageDirty();
    const updatedLine = afterTouchLine({ ...line, uom }, "uom");
    lineHandler({ type: "update", updatedLine });
  }

  async function onQuickAddItem(name) {
    const newItemSettings = {
      defaultCOGSAccount,
      defaultAssetAccount,
      defaultExpenseAccount,
      defaultIncomeAccount,
      defaultTaxCode,
    };
    const item = { ...getEmptyItemRecord(newItemSettings), name };
    const updatedLine = await postItemAndCreateLineItem(
      item,
      EMPTY_LINE_ITEM[OBJECT_TYPE],
      line.lineNumber,
      addItem,
      setErrors
    );
    setPageDirty();
    lineHandler({ type: "update", updatedLine });
    setAddNewItem(false);
  }

  function onAddItem(item) {
    setAddNewItem(false);
    const updatedLine = createLineFromItem(
      item,
      EMPTY_LINE_ITEM[OBJECT_TYPE],
      line.lineNumber,
      lineHandler
    );
    setPageDirty();
    lineHandler({ type: "update", updatedLine });
    addItem();
  }

  function handleDelete() {
    setPageDirty();
    lineHandler({ type: "delete", deleteAt: line.lineNumber });
  }

  function openAddItem(userInput) {
    // handle "Add new item" in Item select input field; this does not change the
    // line item, so no need to proceed further into the handler
    setNewItemName(userInput);
    setAddNewItem(true);
  }

  if (!line) {
    return null;
  }

  return (
    <>
      <LineTableCell>
        <div
          onClick={handleDelete}
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
            color: theme.palette.icons,
          }}
        >
          <Delete />
        </div>
      </LineTableCell>
      <LineTableCell>
        <div
          style={{
            cursor: "pointer",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            padding: "0 0.5rem",
          }}
        >
          {line.lineNumber}
        </div>
      </LineTableCell>
      <LineTableCell sx={{ minWidth: "13rem" }}>
        <LineInventoryItem
          lineLoading={lineLoading}
          itemFormType={ITEM_QUICKLIST_FORM_TYPES.SALES}
          line={line}
          items={items}
          itemsCount={itemsCount}
          addItem={addItem}
          onValueChange={handleItemChange}
          allowExpand
          expandItemGroup={expandItemGroup}
          error={isInError("item")}
        />
      </LineTableCell>
      <LineTableCell sx={{ minWidth: "24rem" }}>
        <LineDescription
          value={line.description}
          onValueChange={handleValueChange}
          error={isInError("description")}
        />
      </LineTableCell>
      <LineQuantity
        objectType={OBJECT_TYPE}
        value={line.quantity}
        onValueChange={handleValueChange}
        error={isInError("quantity")}
        disabled={lineLoading}
      />
      <LineUom
        value={line.uom}
        onValueChange={handleUomChange}
        itemUoms={line.itemDetails?.itemUoms}
        error={isInError("uom")}
      />
      {showWeightOnSales && (
        <LineWeightAndWeightUnit
          weight={line.weight}
          weightunit={line.weightunit}
        />
      )}
      {showVolumeOnSales && (
        <LineVolumeAndVolumeUnit
          volume={line.volume}
          volumeunit={line.volumeunit}
        />
      )}
      <LineReturned
        objectType={OBJECT_TYPE}
        value={line.returned}
        onValueChange={handleValueChange}
      />
      <LineClass
        value={line.class}
        classes={classes}
        onValueChange={handleReferenceChange}
      />
      <LineJobWorkcenter
        job={line.job}
        workcenter={line.workcenter}
        value={line.jobWorkcenter}
        jobWorkcenters={jobWorkcenters}
        onValueChange={handleReferenceChange}
      />
      {addNewItem && (
        <QuickAddItem
          open={addNewItem}
          initialNameValue={newItemName}
          onClose={() => setAddNewItem(false)}
          onAdd={onAddItem}
          onQuickAdd={onQuickAddItem}
        />
      )}
    </>
  );
}
