import { Decimal, Money } from "classes/DecimalClasses";
import { Loading } from "classes/Loading";

import globalState from "globalState/globalState";

export function getTaxRate(taxCodeId, taxCodes) {
  if (!taxCodeId) {
    return Decimal.ZERO;
  }
  const rate = taxCodes.find(({ id }) => id === taxCodeId).salesTaxRate;
  // rates are stored as percents, so divide by 100 to get rate
  return rate.div(new Decimal(100));
}

// Calculates the taxable subtotal by summing line amounts marked as "taxable".
// Only includes line amounts explicitly marked as taxable
export function getTaxableSubTotal(lines) {
  return lines.reduce(
    (seed, { amount, tax }) =>
      !(amount instanceof Loading) && amount && tax?.taxable
        ? seed.plus(amount)
        : seed,
    Money.ZERO
  );
}

// Calculates the tax amount for a given taxable subtotal.
// This taxable subtotal is different than the transaction subtotal
// as it only includes values from lines marked as "taxable".
// This function is intended for use with non-global accounts only.
export function taxCalculation(
  lines,
  discountTaxable,
  discountAmount,
  taxPercent,
  shippingTaxable,
  shippingAmount
) {
  const taxableSubTotal = getTaxableSubTotal(lines);

  const taxablePlusDiscount = taxableSubTotal.plus(
    discountTaxable ? discountAmount : Money.ZERO
  );
  // don't tax an amount < 0
  const positiveTaxableAmount = taxablePlusDiscount.lt(Money.ZERO)
    ? Money.ZERO
    : taxablePlusDiscount;
  const totalTaxable = positiveTaxableAmount.plus(
    shippingTaxable ? shippingAmount : Money.ZERO
  );
  return totalTaxable.times(taxPercent).times(new Decimal(0.01));
}

// Calculates the taxable subtotal for global accounts by summing the line tax amounts.
// Each line's tax amount is determined by multiplying the tax code rate by the line amount.
export function getGlobalUnadjustedTaxAmount(lines, taxCodes) {
  return lines.reduce((total, { amount, taxCode }) => {
    const lineTax =
      amount instanceof Loading || !amount
        ? Money.ZERO
        : amount.times(getTaxRate(taxCode?.id, taxCodes));
    return total.plus(lineTax);
  }, Money.ZERO);
}

// Calculates the total tax amount for a given unadjusted tax amount.
// This initial unadjusted tax amount does not include the "taxable
// discount amount" and "taxable shipping amount" offsets.
// This function is intended for use with global accounts.
export function globalTaxCalculation(
  lines,
  discountTaxable,
  discountAmount,
  taxPercent,
  shippingTaxable,
  shippingAmount,
  taxCodes
) {
  const unadjustedTaxAmount = getGlobalUnadjustedTaxAmount(lines, taxCodes);

  const taxableDiscountAmount = discountTaxable
    ? discountAmount.times(taxPercent).times(new Decimal(0.01))
    : Money.ZERO;

  const taxableShippingAmount = shippingTaxable
    ? shippingAmount.times(taxPercent).times(new Decimal(0.01))
    : Money.ZERO;

  const tax = unadjustedTaxAmount
    .plus(taxableDiscountAmount)
    .plus(taxableShippingAmount);

  // don't tax an amount < 0
  return tax.lt(Money.ZERO) ? Money.ZERO : tax;
}

// Calculates the total sales tax by summing up all applicable tax amounts.
// Supports both global and non-global accounts, determining the applicable context within the function.
export function getSalesTax(record) {
  const { globalEnabled, shippingTaxable } =
    globalState.getState().userCompanySettings.settings;

  if (globalEnabled) {
    return globalTaxCalculation(
      record.lines,
      record.discountTaxable,
      record.discountAmount,
      record.taxPercent,
      shippingTaxable,
      record.shippingAmount,
      record.taxCodes
    );
  }

  if (!record.customer?.tax?.taxable) {
    return Money.ZERO;
  }

  return taxCalculation(
    record.lines,
    record.discountTaxable,
    record.discountAmount,
    record.taxPercent,
    shippingTaxable,
    record.shippingAmount
  );
}

// Calculates the sales subtotal by summing the amounts from all lines.
export function getSalesSubTotal(record) {
  return record.lines.reduce(
    (seed, { amount }) => (amount ? seed.plus(amount) : seed),
    Money.ZERO
  );
}

// Calculates the total sales amount by combining the subtotal, tax amount, shipping amount, and discount amount.
// Applies discounts and adds tax and shipping to determine the final total.
export function getSalesTotal(record) {
  const { shippingAmount, discountAmount } = record;
  const subTotal = getSalesSubTotal(record);
  const taxAmount = getSalesTax(record);
  const amount = subTotal
    .plus(taxAmount)
    .plus(shippingAmount)
    .plus(discountAmount);

  return amount;
}

// Calculates the sales payment by subtracting the sales total from the record balance.
// The result represents the amount that has already been paid.
export function getSalesPayment(record) {
  const amount = getSalesTotal(record);
  return amount.minus(record.balance);
}
