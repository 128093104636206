import { FrmSelectFromObjects } from "components/formFields/FrmSelectFromObjects";

import { useRelated } from "hooks/useRelated";

export function FrmVendor(props) {
  const { value, onValueChange, label, disabled, sx, error } = props;

  const locations = useRelated("vendor");

  return (
    <FrmSelectFromObjects
      name="vendor"
      options={locations}
      disabled={disabled}
      value={value}
      label={label}
      onValueChange={onValueChange}
      error={error}
      sx={sx}
    />
  );
}
