import {
  FLAT_DISCOUNT_OPTION,
  PERCENT_DISCOUNT_OPTION,
  ITEM_DISCOUNT_OPTION,
} from "services/sosInventoryService/priceTier/schema";

export function beforeSave(record) {
  const newRecord = { ...record };

  // only keep the price tier data corresponding to their
  // selected price tier type.
  if (newRecord.type.id === ITEM_DISCOUNT_OPTION.id) {
    newRecord.amountDiscount = 0;
    newRecord.percentDiscount = 0;
  } else if (newRecord.type.id === FLAT_DISCOUNT_OPTION.id) {
    newRecord.items = [];
    newRecord.percentDiscount = 0;
  } else if (newRecord.type.id === PERCENT_DISCOUNT_OPTION.id) {
    newRecord.items = [];
    newRecord.amountDiscount = 0;
  }

  // remove values that are only tracked on the FE
  delete newRecord.type;
  delete newRecord.isEditLocked;
  delete newRecord.isUnordered;
  return newRecord;
}
