import { Delete } from "@mui/icons-material";
import { Box } from "@mui/material";

import { Tooltip } from "components/Tooltip";
import { LineTableHeadingCell } from "components/formFields/LineTableCell";

import { i18n } from "services/i18nService";

export function ClearLines(props) {
  const { setClearLines, tooltip = i18n("global.ClearLines") } = props;
  return (
    <LineTableHeadingCell align="center" simpleCenteredContents={true}>
      <Tooltip title={tooltip}>
        <Box
          onClick={() => setClearLines(true)}
          sx={{
            cursor: "pointer",
            marginBottom: "-0.2rem",
            color: "warning.error",
            paddingLeft: "0.2rem",
          }}
        >
          <Delete />
        </Box>
      </Tooltip>
    </LineTableHeadingCell>
  );
}
