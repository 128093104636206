import { useEffect, useState } from "react";

import { get } from "services/sosInventoryService/sosApi";
import { handleProgramError } from "services/utility/errors";

export function useLocations(onlyBinTracked = false) {
  const [locations, setLocations] = useState(null);

  useEffect(() => {
    async function retrieveRecords() {
      const { success, data, message } = await get("location", {
        inTransaction: true,
      });
      if (success) {
        const newLocations = onlyBinTracked
          ? data.records.filter(({ binTracking }) => binTracking)
          : data.records;
        setLocations(newLocations);
      } else {
        handleProgramError(
          new Error(
            `useRelated | unsuccessful call to getAll, message: ${message}`
          )
        );
      }
    }
    retrieveRecords();
  }, [onlyBinTracked]);

  return locations;
}
