import { Box, Typography } from "@mui/material";

import { ActionButton } from "components/ActionButton";
import { VSpace } from "components/utility/VSpace";

import { i18n } from "services/i18nService";

import { EditColumns } from "views/Reports/EditColumns";

export function Columns(props) {
  const {
    columns,
    availableColumns,
    setColumns,
    showAddImportColumnsButton,
    addImportColumns,
  } = props;

  return (
    <Box>
      <Box
        sx={{
          display: "flex",
          alignItems: "center",
          justifyContent: "space-between",
        }}
      >
        <Typography variant="h5" color="primaryText" fontWeight="Bold">
          {i18n("reports.WidgetText.Columns")}
        </Typography>
        {showAddImportColumnsButton && (
          <ActionButton
            headerButton
            onClick={addImportColumns}
            dataTesting="setToOtherAddress"
          >
            {i18n("reports.WidgetText.AddAllImportColumns")}
          </ActionButton>
        )}
      </Box>
      <VSpace space={1} />
      <EditColumns
        columns={columns}
        setColumns={setColumns}
        allColumns={availableColumns}
      />
    </Box>
  );
}
