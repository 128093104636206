import { Typography } from "@mui/material";

import { Decimal } from "classes/DecimalClasses";

import { Tooltip } from "components/Tooltip";
import { LineItemAutocompleteField } from "components/formFields/LineItemAutocompleteField";
import { subLineWrapperStyle } from "components/formFields/lineItemFieldStyle";

import { i18n } from "services/i18nService";

import { theme } from "SosTheme";
import {
  REQUIRE_BIN_SELECTION_FOR_LOT_SERIAL_SELECT,
  OBJECT_TYPES,
} from "appConstants";

function getLotOptions(lots, objectType) {
  if (objectType === OBJECT_TYPES.ADJUSTMENT.fullString) {
    return lots;
  }
  return lots ? lots.filter(({ onhand }) => onhand.gt(new Decimal(0))) : null;
}

export function LineLotSelect(props) {
  const { value, bin, location, lots, objectType, onValueChange } = props;

  function isLotSelectDisabled(bin, location) {
    if (!REQUIRE_BIN_SELECTION_FOR_LOT_SERIAL_SELECT.includes(objectType)) {
      return false;
    }
    return location?.binTracking && !bin?.id;
  }

  function getValue() {
    if (lots && value) {
      return lots.find(({ id }) => value.id === id);
    }
    return value;
  }

  const disabled = isLotSelectDisabled(bin, location);

  const styles = {
    ...subLineWrapperStyle,
    backgroundColor: disabled ? theme.palette.staticTableCell : "inherit",
  };

  return (
    <Tooltip title={disabled ? i18n("tooltip.SelectBinFirst") : ""}>
      <div style={styles}>
        <Typography
          sx={{ flex: "0 1 auto", fontSize: "0.8125rem", width: "2.2em" }}
        >
          {i18n("columns.colon.Lot")}
        </Typography>
        <LineItemAutocompleteField
          name="lot"
          disabled={disabled}
          options={getLotOptions(lots, objectType)}
          value={getValue()}
          onValueChange={onValueChange}
          dataTesting="lotSelect"
        />
      </div>
    </Tooltip>
  );
}
